import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const LibraryService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetLibrary({ page, pageSize, searchText, filterStatus, reload }) {
    const axiosPrivate = useAxiosPrivate();
    const [library, setLibrary] = useState({
      library: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getLibrary({
          page,
          pageSize,
          searchText,
          axiosPrivate,
          filterStatus,
        })
      )
        .then((res) => {
          setLibrary(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, searchText, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      library_data: library,
    };
  },
  GetLibraryLandingPage({ page, pageSize, searchText, filterStatus, reload }) {
    const [library, setLibrary] = useState({
      library: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getLibraryLandingPage({
          page,
          pageSize,
          searchText,
          filterStatus,
        })
      )
        .then((res) => {
          setLibrary(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      library_data: library,
    };
  },
  async getLibrary({ page, pageSize, searchText, axiosPrivate, filterStatus }) {
    const response = await axiosPrivate.get(
      `${this._url}/library?page=${page}&page_size=${pageSize}&searchText=${searchText}&filterStatus=${filterStatus}`
    );
    console.log("response", response);
    return {
      library: response?.data?.results,
      totalItems: response.data.count,
    };
  },

  async getLibraryLandingPage({
    page,
    pageSize,
    searchText,
    axiosPrivate,
    filterStatus,
  }) {
    try {
      const response = await makeAxiosRequest(
        "GET",
        `${this._url}/library/p/getall/?page=${page}&page_size=${pageSize}&search=${searchText}&filtertype=${filterStatus}`
      );
      return {
        library: response?.results,
        totalItems: response?.count,
      };
    } catch (err) {
      throw err;
    }
  },

  async addLibrary(payload) {
    let response = await makeAxiosRequest(
      "POST",
      `${this._url}/library`,
      payload
    );
    if (response?.error) {
      throw Error(response.message ?? "Something went wrong");
    }

    return response;
  },
};

export default LibraryService;
