import React, { useEffect, useMemo, useState } from "react";
import "./Programs.css";
import { Card, Container } from "react-bootstrap";
import ProgramService from "../../../../services/programs.service";
import { RiShareCircleLine } from "react-icons/ri";
import { Heading, Subtitle } from "styles/App.styles";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Programs = () => {
  const [click, setClick] = useState(false);
  const [programDetails, setProgramDetails] = useState("");
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 4,
  });

  const { page, pageSize } = searchQuery;
  const { programs_data } = ProgramService.GetProgramsLandingPage({
    page,
    pageSize,
  });

  const { totalItems, programs } = useMemo(
    () => ({
      programs: programs_data?.programs,
      totalItems: programs_data.totalItems,
    }),
    [programs_data]
  );
  const reversPrograms = [...programs].reverse();

  const handleClickOpen = (p) => {
    setClick(true);
    setProgramDetails(p);
  };

  return (
    <Container style={{ width: "90%", margin: "50px auto" }}>
      <div className="heading">
        <Heading>Programs</Heading>
        <Subtitle className="subtitle text-center">
          Campus access policies and guidelines to help keep our community safe.
        </Subtitle>
      </div>
      <div className="row">
        {reversPrograms?.map((p, index) => (
          <div
            style={{
              backgroundColor:
                programDetails?.id === p?.id && click ? "#E24F57" : "#3b3b3b",
            }}
            className="programs_card"
            key={index}
            onClick={() => handleClickOpen(p)}
          >
            <div className="card-header">{p.title}</div>
            <hr />
            <div className="cardBody">
              <Card.Text className="card-details">
                {parse(`${p?.description}`)}
              </Card.Text>
            </div>
          </div>
        ))}
      </div>
      {click && (
        <div className="program_card_details">
          <h4 className="card_Heading_landing">{programDetails?.title}</h4>
          <p className="card_text_landingPage">
            {parse(`${programDetails?.description}`)}
          </p>
        </div>
      )}
    </Container>
  );
};

export default Programs;
