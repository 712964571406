import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "./texteditor.css";

function MyEditor({
  editorState,
  onEditorStateChange,
  handlePastedText,
  _uploadImageCallBack,
  handlePastedFiles,
}) {
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      handlePastedText={handlePastedText}
      handlePastedFiles={handlePastedFiles}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbar={{
        image: {
          uploadCallback: _uploadImageCallBack,
          previewImage: true,
          alt: { present: true, mandatory: false },
          inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
        },
      }}
    />
  );
}
export default MyEditor;
