import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const PublicationService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetPublication({ page, pageSize, searchText, filterStatus, reload }) {
    const axiosPrivate = useAxiosPrivate();
    const [publication, setPublication] = useState({
      publication: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getPublication({
          page,
          pageSize,
          searchText,
          axiosPrivate,
          filterStatus,
        })
      )
        .then((res) => {
          setPublication(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, searchText, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      publication_data: publication,
    };
  },
  GetPublicationLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    reload,
  }) {
    const [publication, setPublication] = useState({
      publication: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getPublicationLandingPage({
          page,
          pageSize,
          searchText,
          filterStatus,
        })
      )
        .then((res) => {
          setPublication(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      publication_data: publication,
    };
  },
  async getPublication({
    page,
    pageSize,
    searchText,
    axiosPrivate,
    filterStatus,
  }) {
    const response = await axiosPrivate.get(
      `${this._url}/publication?page=${page}&page_size=${pageSize}&searchText=${searchText}&filterStatus=${filterStatus}`
    );
    return {
      publication: response?.data?.results,
      totalItems: response.data.count,
    };
  },
  async getPublicationLandingPage({
    page,
    pageSize,
    searchText,
    axiosPrivate,
    filterStatus,
  }) {
    try {
      const response = await makeAxiosRequest(
        "GET",
        `${this._url}/publication/p/getall/?page=${page}&page_size=${pageSize}&search=${searchText}&filtertype=${filterStatus}`
      );
      return {
        publication: response?.results,
        totalItems: response?.count,
      };
    } catch (err) {
      throw err;
    }
  },

  async addPublication(payload) {
    let response = await makeAxiosRequest(
      "POST",
      `${this._url}/publication`,
      payload
    );
    if (response?.error) {
      throw Error(response.message ?? "Something went wrong");
    }

    return response;
  },
};

export default PublicationService;
