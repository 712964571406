import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import "./glimpse.css";
import p1 from "../../../Assets/Images/2.png";
import p2 from "../../../Assets/Images/34TH MCMC-COMMONERS NIGHT-2.JPG";
import p3 from "../../../Assets/Images/34TH MCMC-COMMONERS NIGHT-3.JPG";
import p4 from "../../../Assets/Images/34TH MCMC-COMMONERS NIGHT.JPG";
import p5 from "../../../Assets/Images/5.png";
import p6 from "../../../Assets/Images/IMAGE-2.png";
import p7 from "../../../Assets/Images/MATCH COMBINED PIC.JPG";
import { Container } from "react-bootstrap";
import { Heading } from "styles/App.styles";
import { Box } from "@mui/material";
import Carousel from "react-elastic-carousel";

const useStyles = makeStyles({
  container: {
    padding: "60px 0",
    "@media (max-width: 600px)": {
      padding: "70px 15px",
    },
  },
  gridBox: {
    margin: "80px 0",
    "@media (max-width: 600px)": {
      margin: "0 0 40px 0",
      padding: "0 20px",
    },
  },
  mobileView: {
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse !important",
    },
  },
  imageBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    "@media (max-width: 600px)": {
      width: "100%",
      height: "200px",
    },
  },
  contentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    borderRadius: "4px",
    "@media (max-width: 600px)": {
      height: "150px",
    },
  },
  heading: {
    fontWeight: "700 !important",
    fontSize: "32px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "var(--primary) !important",
    marginBottom: "20px !important",
    "@media (max-width: 600px)": {
      fontSize: "28px !important",
    },
  },
  content: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
    textTransform: "capitalize",
    color: "var(--body-text-color) !important",
  },
});

const videoProperties = [
  {
    id: 1,
    src: p1,
  },
  {
    id: 2,
    src: p2,
  },
  {
    id: 3,
    src: p3,
  },
  {
    id: 4,
    src: p4,
  },
  {
    id: 5,
    src: p5,
  },
  {
    id: 6,
    src: p6,
  },
  {
    id: 7,
    src: p7,
  },
];

const Glimpse = () => {
  const classes = useStyles();

  const [wordData, setWordData] = useState(videoProperties[0]);
  const handleClick = (index) => {
    const wordSlider = videoProperties[index];
    setWordData(wordSlider);
  };
  return (
    <>
      <Container
        className={classes.container}
        // style={{ height: "fit-content", padding: " 50px 15px" }}
      >
        <Box className="heading" style={{ marginBottom: "16px" }}>
          <Heading>Some Quick Glimpse</Heading>
        </Box>
        <Box className={classes.imageBox}>
          <div className="main">
            <img
              src={wordData.src}
              width="100%"
              height="100%"
              style={{ borderRadius: "4px" }}
              className={classes.image}
              alt="images"
            />
            <div className="flex_row">
              <Carousel itemsToShow={5} itemsToScroll={1} focusOnSelect={true}>
                {videoProperties.map((data, i) => (
                  <div className="glimpse_thumbnail" key={i}>
                    <img
                      src={data?.src}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "4px", cursor: "pointer" }}
                      className={
                        wordData?.id === data?.id ? "clicked" : "thumbImg"
                      }
                      onClick={() => handleClick(i)}
                      alt="images"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Glimpse;
