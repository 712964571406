import { Button, FormControlLabel } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import "../../pages/dataTable.css";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { format } from "date-fns";
import { getDateObject } from "helpers/common";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "components/atoms/Loading";

const useStyles = makeStyles({
  addStudentBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const AddStudent = ({ index, toast, reload, handleClose }) => {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("student", index?.id);
      const res = await axiosPrivate({
        method: "post",
        url: `irsa_classes/enrollstudents/${id}`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      handleClose();
      setLoading(false);
      reload("reload");
      toast.success(res?.data);
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          {loading ? (
            <Button className={classes.addStudentBtn} disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          ) : (
            <Button
              className={classes.addStudentBtn}
              type="submit"
              onClick={handleSubmit}
            >
              Add
            </Button>
          )}
        </>
      }
    />
  );
};

function getFullName(params) {
  return `${params.row.firstname || ""} ${params.row.lastname || ""}`;
}

function setFullName(params) {
  const [firstname, lastname] = params.value.toString().split(" ");
  return { ...params.row, firstname, lastname };
}

function parseFullName(value) {
  return String(value)
    .split(" ")
    .map((str) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : ""))
    .join(" ");
}

const AddStudentInClass = ({ handleClose, toast, classData, reload }) => {
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [students, setStudents] = useState([]);

  useEffect(() => {
    async function getTeacherDetails() {
      setLoading(true);
      try {
        const { data } = await axiosPrivate.get(
          `/irsa_classes/getstudentstoenroll/${classData?.id}`
        );
        setStudents(data);
        setLoading(false);
      } catch (err) {
        if (err?.response) {
          console.log(err?.response);
        } else if (err?.request) {
          console.log(err?.request);
        } else {
          console.log(err?.message);
        }
      }
    }
    getTeacherDetails();
  }, []);

  const columns = [
    {
      field: "roll_number",
      type: "string",
      headerName: "Roll Number",
      flex: 1,
    },
    {
      field: "batch",
      flex: 1,
      headerName: "Batch",
    },
    {
      field: "createdAt",
      type: "date",
      flex: 1,
      headerName: "Join Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "fullName",
      type: "string",
      flex: 1,
      headerName: "Full Name",
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "email", headerName: "Email", flex: 1.5 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <AddStudent
              handleClose={handleClose}
              toast={toast}
              reload={reload}
              index={params?.row}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              background: "#FBFBFB",
              borderRadius: "4px",
            }}
          >
            <DataGrid
              sx={{
                boxShadow: 2,
                color: "rgba(0, 0, 0, 0.87)",
                "& .MuiDataGrid-cell:hover": {
                  color: "var(--admin)",
                },
              }}
              autoHeight
              rows={students}
              getRowId={(students) => students?.id}
              columns={columns}
              ColumnWidth="*"
            />
          </div>
        </>
      )}
    </>
  );
};

export default AddStudentInClass;
