import { useMemo, useState } from "react";
import Loading from "components/atoms/Loading";
import { Box, Button, FormControlLabel, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClassesService from "services/classes.service";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { getDateObject } from "helpers/common";
import AttendanceCalender from "./attendanceCalender";
import StudentList from "./StudentList";
import moment from "moment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      margin: "0",
    },
  },
  subtitle: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginTop: "5px",
    color: "var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
});

const ClassDetails = ({ index }) => {
  const navigate = useNavigate();

  const handleClickOpenDetail = () => {
    navigate(`/dashboard/classes/${index?.id}`);
  };

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          <div onClick={handleClickOpenDetail}>{index?.title}</div>
        </>
      }
    />
  );
};
const Actions = ({ index, setOpen }) => {
  const classId = (data) => {
    setOpen(index?.id);
  };

  return (
    <>
      <Box>
        <Button onClick={classId}>Attendance</Button>
      </Box>
    </>
  );
};

function getFullName(params) {
  return `${params?.row?.teacher_details?.firstname || ""} ${
    params?.row?.teacher_details?.lastname || ""
  }`;
}

function setFullName(params) {
  const [firstname, lastname] = params.row.toString().split(" ");
  return { ...params.row, firstname, lastname };
}

function parseFullName(value) {
  return String(value)
    .split(" ")
    .map((str) => (str?.length > 0 ? str[0].toUpperCase() + str.slice(1) : ""))
    .join(" ");
}

const Attendance = () => {
  const classes = useStyles();
  const [open, setOpen] = useState("1");
  const [handle, setHandleEdit] = useState(false);
  const [classId, setClassId] = useState("");
  const [data, setData] = useState("");
  const [reload, setReload] = useState(false);
  const [date, setDate] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 100,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize } = searchQuery;

  const { loading, classes_data } = ClassesService.GetClasses(
    page,
    pageSize,
    reload
  );

  const { totalItems, classesData } = useMemo(
    () => ({
      classesData: classes_data?.classes,
      totalItems: classes_data?.totalItems,
    }),
    [classes_data]
  );
  const handleEditState = (data) => {
    setHandleEdit(data);
  };
  const setStateToOpenClassPage = () => {
    setOpen("1");
  };
  const setStateToOpen = (data) => {
    setOpen("2");
    setClassId(data);
  };
  const setStateToOpenList = () => {
    setOpen("3");
  };

  const receivedData = (data) => {
    setDate(moment(data).format("DD/MM/YYYY"));
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <ClassDetails index={params?.row} />
          </div>
        );
      },
    },
    {
      field: "batch",
      width: 100,
      headerName: "Batch",
    },
    {
      field: "year",
      width: 100,
      headerName: "Year",
    },
    {
      field: "startDate",
      type: "date",
      flex: 0.5,
      headerName: "Start Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "endDate",
      type: "date",
      flex: 0.5,
      headerName: "End Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "course",
      headerName: "Course",
      width: 180,
      renderCell: (params) => {
        return (
          <>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              {params?.row?.course_details?.title}
            </div>
          </>
        );
      },
    },
    {
      field: "fullName",
      type: "string",
      width: 200,
      headerName: "Teacher Name",
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Actions
              setOpen={setStateToOpen}
              index={params?.row}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box className={classes.btnBox}>
        <Box>
          <Typography className={classes.pageTitle}>Attendance</Typography>
          <Typography className={classes.subtitle}>
            You can add attendance of classes here
          </Typography>
        </Box>
      </Box>
      {open === "1" && (
        <div style={{ margin: "10px 0" }}>
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : totalItems > 0 ? (
            <>
              <div
                style={{
                  width: "100%",
                  background: "#FBFBFB",
                  borderRadius: "4px",
                }}
              >
                <DataGrid
                  sx={{
                    boxShadow: 2,
                    color: "rgba(0, 0, 0, 0.87)",
                    "& .MuiDataGrid-cell:hover": {
                      color: "var(--admin)",
                    },
                  }}
                  autoHeight
                  pageSize={25}
                  rowsPerPageOptions={[5]}
                  pagination
                  rows={classesData}
                  getRowId={(classesData) => classesData.id}
                  columns={columns}
                  ColumnWidth="*"
                />
              </div>
            </>
          ) : (
            <h2 className="text-center">No Record found</h2>
          )}
        </div>
      )}

      {open === "2" && (
        <div style={{ margin: "10px 0" }}>
          <AttendanceCalender
            classId={classId}
            setStateToOpenList={setStateToOpenList}
            setStateToOpenClassPage={setStateToOpenClassPage}
            myFunc={receivedData}
            handleEditState={handleEditState}
          />
        </div>
      )}
      {open === "3" && (
        <StudentList
          classId={classId}
          selectDate={date}
          setStateToOpenList={setStateToOpenList}
          setStateToOpen={setStateToOpen}
          handle={handle}
          data={data}
        />
      )}
    </>
  );
};

export default Attendance;
