import React, { useEffect, useState } from "react";
import "./backToTop.css";
import { HiOutlineArrowCircleUp } from "react-icons/hi";

const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <>
      <div className="back-to-top-btn">
        <HiOutlineArrowCircleUp
          onClick={scrollToTop}
          style={{
            display: visible ? "inline" : "none",
            height: "80px",
            width: "80px",
          }}
        />
      </div>
    </>
  );
};

export default BackToTop;
