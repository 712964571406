import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import {
  UilEstate,
  UilUser,
  UilFileCheckAlt,
  UilBookOpen,
  UilUserMd,
  UilNewspaper,
  UilBook,
  UilBloggerAlt,
  UilInbox,
} from "@iconscout/react-unicons";

const useStyles = makeStyles({
  container: {
    color: "white",
    height: "100%",
    padding: "20px 0",
    backgroundColor: "#FFFFFF",
    position: "sticky",
    top: 0,
    "@media (min-width: 600px)": {
      backgroundColor: "white",
      color: "#555",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    cursor: "pointer",
    "@media (max-width: 320px)": {
      padding: "10px 0 !important",
    },
    "@media (max-width: 600px)": {
      padding: "10px 8px",
    },
  },
  icon: {
    color: "#000",
    marginRight: "10px",
    "@media (min-width: 600px)": {
      fontSize: "18px",
    },
  },
  text: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "var(--link-text-color)",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  root: {
    cursor: "pointer",
    "&$selected": {
      backgroundColor: "var(--admin)",
      color: "#FFFFFF !important",
      cursor: "pointer",
    },
  },
});

export const MainListItems = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { user } = useAuth();
  const styles = useStyles();

  return (
    <>
      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/statistics"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/statistics", { replace: true });
        }}
      >
        <Tooltip title="Statistics" placement="right">
          <ListItemIcon>
            <UilEstate
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/statistics"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Statistics"
          style={{
            color:
              location.pathname === "/dashboard/statistics"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      {/* <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/attendance"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/attendance", { replace: true });
        }}
      >
        <Tooltip title="Attendance" placement="right">
          <ListItemIcon>
            <UilClockEight
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/attendance"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Attendance"
          style={{
            color:
              location.pathname === "/dashboard/attendance"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton> */}

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/inbox" ? "var(--admin)" : "white",
        }}
        onClick={() => {
          navigate("/dashboard/inbox", { replace: true });
        }}
      >
        <Tooltip title="Inbox" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilInbox
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/inbox"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Inbox"
          style={{
            color:
              location.pathname === "/dashboard/inbox"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      {user?.isSuperAdmin === true && (
        <ListItemButton
          style={{
            background:
              location.pathname === "/dashboard/admin"
                ? "var(--admin)"
                : "white",
          }}
          onClick={() => {
            navigate("/dashboard/admin", { replace: true });
          }}
        >
          <Tooltip title="Admin" placement="right">
            <ListItemIcon className={styles.icon}>
              <UilUser
                style={{
                  width: "24px",
                  height: "24px",
                  color:
                    location.pathname === "/dashboard/admin"
                      ? "#fff"
                      : "var(--icons-color)",
                }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary="Admin"
            style={{
              color:
                location.pathname === "/dashboard/admin"
                  ? "#fff"
                  : "var(--link-text-color)",
            }}
          />
        </ListItemButton>
      )}

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/programs"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/programs", { replace: true });
        }}
      >
        <Tooltip title="Programs" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilFileCheckAlt
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/programs"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Programs"
          style={{
            color:
              location.pathname === "/dashboard/programs"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/courses"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/courses", { replace: true });
        }}
      >
        <Tooltip title="Courses" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilBookOpen
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/courses"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Courses"
          style={{
            color:
              location.pathname === "/dashboard/courses"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/classes"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/classes", { replace: true });
        }}
      >
        <Tooltip title="Classes" placement="right">
          <ListItemIcon className={styles.icon}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 16.7397V4.6697C22 3.4697 21.02 2.5797 19.83 2.6797H19.77C17.67 2.8597 14.48 3.9297 12.7 5.0497L12.53 5.1597C12.24 5.3397 11.76 5.3397 11.47 5.1597L11.22 5.0097C9.44 3.8997 6.26 2.8397 4.16 2.6697C2.97 2.5697 2 3.4697 2 4.6597V16.7397C2 17.6997 2.78 18.5997 3.74 18.7197L4.03 18.7597C6.2 19.0497 9.55 20.1497 11.47 21.1997L11.51 21.2197C11.78 21.3697 12.21 21.3697 12.47 21.2197C14.39 20.1597 17.75 19.0497 19.93 18.7597L20.26 18.7197C21.22 18.5997 22 17.6997 22 16.7397Z"
                stroke={
                  location.pathname === "/dashboard/classes"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 5.49023V20.4902"
                stroke={
                  location.pathname === "/dashboard/classes"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.75 8.49023H5.5"
                stroke={
                  location.pathname === "/dashboard/classes"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.5 11.4902H5.5"
                stroke={
                  location.pathname === "/dashboard/classes"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Classes"
          style={{
            color:
              location.pathname === "/dashboard/classes"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/instructor"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/instructor", { replace: true });
        }}
      >
        <Tooltip title="Instructor" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilUserMd
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/instructor"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Teacher"
          style={{
            color:
              location.pathname === "/dashboard/instructor"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/student"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/student", { replace: true });
        }}
      >
        <Tooltip title="Students" placement="right">
          <ListItemIcon className={styles.icon}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 6V13.5"
                stroke={
                  location.pathname === "/dashboard/student"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.08105 20.2504C5.83149 19.0994 6.85722 18.1538 8.06534 17.4993C9.27345 16.8447 10.6258 16.502 11.9998 16.502C13.3738 16.502 14.7262 16.8447 15.9343 17.4993C17.1424 18.1538 18.1681 19.0994 18.9186 20.2504"
                stroke={
                  location.pathname === "/dashboard/student"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 6L12 9L3 6L12 3L21 6Z"
                stroke={
                  location.pathname === "/dashboard/student"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.8719 7.70605C16.5599 8.45735 17.0144 9.39257 17.1801 10.3977C17.3458 11.4028 17.2155 12.4345 16.805 13.3668C16.3946 14.2992 15.7218 15.092 14.8686 15.6486C14.0154 16.2053 13.0187 16.5016 12 16.5016C10.9813 16.5016 9.9846 16.2053 9.13141 15.6486C8.27822 15.092 7.6054 14.2992 7.19497 13.3668C6.78453 12.4345 6.65422 11.4028 6.81991 10.3977C6.98561 9.39257 7.44015 8.45735 8.12813 7.70605"
                stroke={
                  location.pathname === "/dashboard/student"
                    ? "#fff"
                    : "var(--icons-color)"
                }
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Students"
          style={{
            color:
              location.pathname === "/dashboard/student"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/newsevents"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/newsevents", { replace: true });
        }}
      >
        <Tooltip title="News" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilNewspaper
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/newsevents"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="News & Events"
          style={{
            color:
              location.pathname === "/dashboard/newsevents"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/library"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/library", { replace: true });
        }}
      >
        <Tooltip title="Library" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilBook
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/library"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Library"
          style={{
            color:
              location.pathname === "/dashboard/library"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/publication"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/publication", { replace: true });
        }}
      >
        <Tooltip title="Publication" placement="right">
          <ListItemIcon className={styles.icon}>
            <UilBloggerAlt
              style={{
                width: "24px",
                height: "24px",
                color:
                  location.pathname === "/dashboard/publication"
                    ? "#fff"
                    : "var(--icons-color)",
              }}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Publication"
          style={{
            color:
              location.pathname === "/dashboard/publication"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/resources"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/resources", { replace: true });
        }}
      >
        <Tooltip title="Resources" placement="right">
          <ListItemIcon className={styles.icon}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 5C3.5 4.17157 4.17157 3.5 5 3.5H19C19.8284 3.5 20.5 4.17157 20.5 5V19C20.5 19.8284 19.8284 20.5 19 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V5Z"
                stroke={
                  location.pathname === "/dashboard/resources"
                    ? "#fff"
                    : "var(--icons-color)"
                }
              />
              <path
                d="M5 18.1183V17.9593C5.24502 18.4637 5.65462 18.8733 6.15897 19.1183H6C5.44739 19.1183 5 18.6709 5 18.1183ZM9 5.11828H18C18.5526 5.11828 19 5.56567 19 6.11828V15.1183C19 15.6709 18.5526 16.1183 18 16.1183H9C8.44739 16.1183 8 15.6709 8 15.1183V6.11828C8 5.56567 8.44739 5.11828 9 5.11828ZM16.125 11.8683C17.0225 11.8683 17.75 11.1407 17.75 10.2433C17.75 9.62098 17.4002 9.08038 16.8865 8.80738C17.3877 8.64459 17.75 8.17375 17.75 7.61828C17.75 6.92792 17.1904 6.36828 16.5 6.36828H10.5C9.80964 6.36828 9.25 6.92792 9.25 7.61828C9.25 8.17375 9.61231 8.64459 10.1135 8.80738C9.5998 9.08038 9.25 9.62098 9.25 10.2433C9.25 10.9188 9.66225 11.4981 10.2489 11.7433C9.66225 11.9884 9.25 12.5677 9.25 13.2433C9.25 14.1407 9.97754 14.8683 10.875 14.8683H13.125C14.0225 14.8683 14.75 14.1407 14.75 13.2433C14.75 12.6643 14.4472 12.1561 13.9914 11.8683H16.125Z"
                stroke={
                  location.pathname === "/dashboard/resources"
                    ? "#fff"
                    : "var(--icons-color)"
                }
              />
            </svg>
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Resources"
          style={{
            color:
              location.pathname === "/dashboard/resources"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>

      <ListItemButton
        style={{
          background:
            location.pathname === "/dashboard/stories"
              ? "var(--admin)"
              : "white",
        }}
        onClick={() => {
          navigate("/dashboard/stories", { replace: true });
        }}
      >
        <Tooltip title="Stories" placement="right">
          <ListItemIcon className={styles.icon}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 5C3.5 4.17157 4.17157 3.5 5 3.5H19C19.8284 3.5 20.5 4.17157 20.5 5V19C20.5 19.8284 19.8284 20.5 19 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V5Z"
                stroke={
                  location.pathname === "/dashboard/stories"
                    ? "#fff"
                    : "var(--icons-color)"
                }
              />
              <path
                d="M5 18.1183V17.9593C5.24502 18.4637 5.65462 18.8733 6.15897 19.1183H6C5.44739 19.1183 5 18.6709 5 18.1183ZM9 5.11828H18C18.5526 5.11828 19 5.56567 19 6.11828V15.1183C19 15.6709 18.5526 16.1183 18 16.1183H9C8.44739 16.1183 8 15.6709 8 15.1183V6.11828C8 5.56567 8.44739 5.11828 9 5.11828ZM16.125 11.8683C17.0225 11.8683 17.75 11.1407 17.75 10.2433C17.75 9.62098 17.4002 9.08038 16.8865 8.80738C17.3877 8.64459 17.75 8.17375 17.75 7.61828C17.75 6.92792 17.1904 6.36828 16.5 6.36828H10.5C9.80964 6.36828 9.25 6.92792 9.25 7.61828C9.25 8.17375 9.61231 8.64459 10.1135 8.80738C9.5998 9.08038 9.25 9.62098 9.25 10.2433C9.25 10.9188 9.66225 11.4981 10.2489 11.7433C9.66225 11.9884 9.25 12.5677 9.25 13.2433C9.25 14.1407 9.97754 14.8683 10.875 14.8683H13.125C14.0225 14.8683 14.75 14.1407 14.75 13.2433C14.75 12.6643 14.4472 12.1561 13.9914 11.8683H16.125Z"
                stroke={
                  location.pathname === "/dashboard/stories"
                    ? "#fff"
                    : "var(--icons-color)"
                }
              />
            </svg>
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary="Stories"
          style={{
            color:
              location.pathname === "/dashboard/stories"
                ? "#fff"
                : "var(--link-text-color)",
          }}
        />
      </ListItemButton>
    </>
  );
};
