import React, { useState } from "react";
import { BsCalendar2Date } from "react-icons/bs";
import "./style.css";
import parse from "html-react-parser";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Model from "components/atoms/Model";
import EditNewsEvents from "components/Forms/EditNewsEvent";
import { Button } from "react-bootstrap";
import AlertDialogSlide from "components/atoms/AlertDialog";
import img1 from "../../../Assets/Images/culture.jpg";

function CardImage(props) {
  const isImageURL = props?.image;
  let listOfClasses = null;

  if (props.effect) {
    listOfClasses = "styleImage bw";
  } else {
    listOfClasses = "styleImage";
  }

  if (isImageURL) {
    return (
      <div className={listOfClasses} onClick={props.onClick}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={isImageURL}
          alt="Events"
        />
      </div>
    );
  }
  return null;
}

function CardContent(props) {
  return (
    <>
      <div className="styleCardContent">
        <p className="styleCardTitle" onClick={props.onClick}>
          {props.title}
        </p>
        <p className="styleDescription">{parse(`${props.description}`)}</p>
      </div>
    </>
  );
}

export default function Card(props) {
  const { user } = useAuth();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [bwEffect, setBwEffect] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleEffect = () => {
    setBwEffect(() => !bwEffect);
  };

  const detailsPage = (id) => {
    navigate(props.isLandingPage ? `news/${id}` : `${id}`);
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="styleCard" id={props.id}>
        <Model
          openModel={open}
          handleClose={handleClose}
          bodyContent={
            <EditNewsEvents
              reload={props.reload}
              id={props.id}
              Newstitle={props.title}
              Newsimage={props.image}
              Newscontent={props.description}
              handleClose={handleClose}
              toast={toast}
            />
          }
          title="Add News and Events"
        />
        <CardImage
          image={
            props.image?.startsWith("/")
              ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${props.image}`
              : props.image ?? img1
          }
          width={props.width}
          effect={bwEffect}
          onClick={toggleEffect}
        />
        <CardContent
          title={props.title}
          description={props.description}
          date={props.date}
          onClick={() => detailsPage(props.id)}
        />

        <div className="card-date">
          <BsCalendar2Date
            style={{ color: "#8294aa", fontSize: "18", marginTop: "3px" }}
          />
          <p className="styledateLabel">{props.date}</p>
        </div>
        {/* {user?.role === "admin" && (
          <div style={{ display: "flex" }}>
            <Button
              variant="primary"
              className="mx-1"
              onClick={handleClickOpen}
            >
              Edit
            </Button>
            <AlertDialogSlide
              btnComponent={({ onClick }) => (
                <>
                  <Button className="mx-1" variant="danger" onClick={onClick}>
                    Delete
                  </Button>
                </>
              )}
              // deleteAdmin={({ onClose }) =>
              //   deleteNews(props.id, onClose, props.reload)
              // }
            />
          </div>
        )} */}
      </div>
    </>
  );
}

// 2. Defaults /////////////////////////////////////////////
Card.defaultProps = {
  width: 350,
  title: "Template - Card Title",
  date: "date label",
  description: "Template description textbox",
  isLandingPage: false,
};
