import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import "./forms.css";
import { EditorState, ContentState, convertFromHTML, Modifier } from "draft-js";
import { convertToHTML } from "draft-convert";
import MyEditor from "components/atoms/TestEditor";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import {
  InputBase,
  Typography,
  Button,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import TagsInput from "./TagsInput";
import Select from "components/atoms/Select";
import pdfFile from "components/Assets/Images/pdf.png";
import parse from "html-react-parser";
import { resourcesOption } from "docs/data";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#FFFFFF",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const EditResources = ({ handleClose, toast, resource, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(resource?.title);
  const [preview, setPreview] = useState("");

  const [description, setDescription] = useState(resource?.description);
  const [type, setType] = useState({
    value: resource?.type,
    label: resource?.type,
  });
  const [url, setUrl] = useState(resource?.url ?? null);
  const [keywords, setKeywords] = useState(resource?.keywordss);
  const [file, setFile] = useState(
    resource?.file?.startsWith("/")
      ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${resource?.file}`
      : resource?.file
  );
  const [image, setImage] = useState(
    resource?.image?.startsWith("/")
      ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${resource?.image}`
      : resource?.image
  );

  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [isFileUpdate, setIsFileUpdate] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    titleErr: "",
    descriptionErr: "",
    typeErr: "",
    fileError: "",
    imageErr: "",
    keywordsErr: "",
  });
  const { titleErr, descriptionErr, typeErr, fileError, imageErr } =
    validationErrors;

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleErr: "Please provide title",
      }));
    }
    if (description === null) {
      setValidationErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (!type?.value) {
      setValidationErrors((prev) => ({
        ...prev,
        typeErr: "Please provide type",
      }));
    }

    if (!file && !url) {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "Please provide file or enter url below",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (!keywords) {
      setValidationErrors((prev) => ({
        ...prev,
        keywordsErr: "Please provide Keywords",
      }));
    }
    if (
      !title ||
      description === null ||
      (!url && !file) ||
      image === null ||
      !keywords
    ) {
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("description", description);
        isImageUpdate && bodyFormData.append("image", image);
        bodyFormData.append("type", type?.value);
        isFileUpdate && bodyFormData.append("document", file);
        url && bodyFormData.append("url", url);
        bodyFormData.append("keywords", JSON.stringify(keywords));

        const res = await axiosPrivate({
          method: "put",
          url: `resource/${resource?.id}`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    } else {
      console.log("error");
    }
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return;
    }
    if (file.size > 10000000) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `Maximum file size 10MB`,
      }));
      return false;
    }
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
    setImage(e.target.files[0]);

    var reader = new FileReader();

    reader.onload = function (e) {
      setPreview(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const onUploadFile = ({ target }) => {
    if (target.files[0].type !== "application/pdf") {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "only pdf files are allowed",
      }));
      setFile("");
      return;
    }

    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    setFile(target.files[0]);
  };
  const handlePastedText = (text, html = "") => {
    // if they try to paste something they shouldn't let's handle it
    if (text.indexOf("text that should not be pasted") !== -1) {
      // we'll add a message for the offending user to the editor state
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "nice try, chump!"
      );

      // update our state with the new editor content
      this.onChange(
        EditorState.push(editorState, newContent, "insert-characters")
      );
      return true;
    } else {
      return false;
    }
  };

  const blocksFromHTML = convertFromHTML(resource.description);
  const EditorContent = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(EditorContent)
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setValidationErrors((prev) => ({
      ...prev,
      descriptionErr: "",
    }));
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setDescription(currentContentAsHTML);
  };

  function handleSelectedTags(items) {
    setKeywords(items);
  }

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Form>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Title</Form.Label>
                <div
                  className={
                    titleErr ? classes.inputFieldErr : classes.inputField
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        titleErr: "",
                      }));
                    }}
                  />
                </div>
                {titleErr ? (
                  <Typography className={classes.errorMsg}>
                    {titleErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Select
                  label={
                    <Form.Label className={classes.label}>Type</Form.Label>
                  }
                  open
                  value={type}
                  error={typeErr}
                  errorClass={classes.errorMsg}
                  onChange={(value) => {
                    setType(value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      typeErr: "",
                    }));
                  }}
                  options={resourcesOption}
                  defaultOptions
                />
              </Form.Group>
              {type?.value === "Document" && (
                <>
                  <Tooltip title="Click to view the document" placement="right">
                    <a href={file} target="_blank" rel="noreferrer">
                      <img src={pdfFile} alt="img" />
                    </a>
                  </Tooltip>
                  {!isFileUpdate && (
                    <Button
                      className={classes.change_img}
                      onClick={() => {
                        setIsFileUpdate(() => !isFileUpdate);
                      }}
                    >
                      Change File
                    </Button>
                  )}
                  {isFileUpdate && (
                    <Form.Group
                      controlId="formBasicTitle"
                      className={classes.formGroup}
                    >
                      <Form.Label className={classes.label}>
                        File(pdf)
                      </Form.Label>
                      <div
                        className={
                          fileError ? classes.inputFieldErr : classes.inputField
                        }
                      >
                        <InputBase
                          className={classes.input}
                          accept="application/pdf"
                          type="file"
                          placeholder="file"
                          onChange={onUploadFile}
                        />
                      </div>
                      {fileError ? (
                        <Typography className={classes.errorMsg}>
                          {fileError}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  )}
                </>
              )}
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Url</Form.Label>
                <div className={`${classes.inputField}`}>
                  <InputBase
                    className={classes.input}
                    value={url}
                    type="text"
                    placeholder="Enter Url"
                    onChange={(e) => {
                      setUrl(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        fileError: "",
                      }));
                    }}
                  />
                </div>
              </Form.Group>
              <Row>
                <Col
                  style={{
                    padding: "0 5px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={!preview ? image : preview}
                    style={{ width: "20%" }}
                    alt="std-img"
                  />
                  {!isImageUpdate && (
                    <Button
                      className={classes.change_img}
                      onClick={() => {
                        setIsImageUpdate(() => !isImageUpdate);
                      }}
                    >
                      Change Image
                    </Button>
                  )}
                </Col>
              </Row>
              {isImageUpdate && (
                <Form.Group
                  controlId="formBasicTitle"
                  className={classes.formGroup}
                >
                  <Form.Label className={classes.label}>Image</Form.Label>
                  <div
                    className={
                      imageErr ? classes.inputFieldErr : classes.inputField
                    }
                  >
                    <InputBase
                      className={classes.input}
                      type="file"
                      accept="image/*"
                      onChange={(e) => onUploadFileChange(e)}
                    />
                  </div>
                  {imageErr ? (
                    <Typography className={classes.errorMsg}>
                      {imageErr}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Form.Group>
              )}
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Keywords</Form.Label>

                <TagsInput
                  selectedTags={handleSelectedTags}
                  tags={keywords}
                  fullWidth
                  id="tags"
                  name="keywords"
                  placeholder="Add keywords"
                />
              </Form.Group>

              <Form.Group
                className={classes.formGroup}
                controlId="formBasicDesc"
              >
                <Form.Label className={classes.label}>Description</Form.Label>
                <MyEditor
                  className={descriptionErr ? "titleErr" : ""}
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  handlePastedText={handlePastedText}
                />
                {descriptionErr ? (
                  <Typography className={classes.errorMsg}>
                    {descriptionErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className={classes.saveBtn}>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    className={classes.addBtn}
                    // type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>{parse(`${description}`)}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditResources;
