import { Button } from "@mui/material";
import React from "react";
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  addBtn: {
    margin: "40px 0px",
    width: "100%",
    height: "56px",
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    color: "#FFFFFF !important",
    background: "var(--admin)",
    boxShadow: " 0px 14px 28px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "#FFF !important",
      color: "var(--admin) !important",
      border: "1px solid var(--admin)",
    },
  },
});

function LoginLoadingBtn() {
  const classes = useStyles();
  return (
    <>
      <Button className={classes.addBtn} disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </Button>
    </>
  );
}

export default LoginLoadingBtn;
