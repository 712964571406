import React from "react";
import NewsEventsList from "./NewsEventList";
import "./style.css";
import { Heading } from "styles/App.styles";
import { useNavigate } from "react-router-dom";

const NewsEvents = () => {
  let navigate = useNavigate();
  return (
    <>
      <div style={{ backgroundColor: "#3b3b3b ", padding: "50px 10px" }}>
        <div className="heading_news">
          <Heading>News & Events</Heading>
        </div>
        <div className="mx-5">
          <h6
            onClick={() => navigate("/news")}
            style={{
              textAlign: "right",
              cursor: "pointer",
              color: " var(--secondary)",
              padding: "0 35px",
            }}
          >
            View All
          </h6>
        </div>
        <div className="container-fluid">
          <NewsEventsList isLandingPage={true} />
        </div>
      </div>
    </>
  );
};

export default NewsEvents;
