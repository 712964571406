import { useState, useEffect, useMemo } from "react";
import { Typography, Box, Grid, Paper, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Col, Row } from "react-bootstrap";
import Loading from "components/atoms/Loading";
import "../components/Pages/pageStyle.css";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import CardTem from "components/atoms/Card";

const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const useStyles = makeStyles({
  btnBox: {
    margin: "2.5rem 0 1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
      justifyContent: "center",
    },
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginTop: "5px",
    color: "var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  paper: {
    width: "100%",
    height: "200px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    margin: "10px 0",
  },
  paperBox: {
    padding: "45px 0",
    height: "100%",
    position: "relative",
  },
  itemBox: {
    display: "flex",
    justifyContent: "center",
  },
  bottomBox: {
    position: "absolute",
    width: "100%",
    bottom: "40px",
  },
  count: {
    fontWeight: "700 !important",
    fontSize: "24px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #2C3935 !important",
  },
  subtitlePaper: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #7A8883 !important",
  },
  calenderBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statisticsBox: {
    background: "#FFFFFF",
    margin: "15px 7px",
    padding: "20px 10px",
    height: "fir-content",
    borderRadius: "4px",
  },
  statisticsText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  row: {
    "@media (max-width: 600px)": {
      display: "block",
    },
    "& .row": {
      flex: "1 1 160px",
    },
  },
});

export default function Statistics() {
  const classes = useStyles();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [value, setValue] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState();
  const [programs, setPrograms] = useState();
  const [courses, setCourses] = useState();
  const [events, setEvents] = useState();

  useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        const { data } = await axiosPrivate.get("admin/stats");
        setStatistics(data);
        setPrograms(data?.programs);
        setCourses(data?.courses);
        setEvents(data?.events);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err?.response?.data?.message ?? err?.message);
      }
    };
    getStatistics();
  }, []);

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/courses/${id}`);
  };
  const handleClickOpenDetailPrograms = (id) => {
    navigate(`/dashboard/programs/${id}`);
  };

  return (
    <>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <Box className={classes.btnBox}>
            <Box>
              <Typography className={classes.pageTitle}>Overview</Typography>
              <Typography className={classes.subtitle}>
                you can check here students & teachers
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Row className="card_row_statics">
                  <Col>
                    <Paper square className={classes.paper}>
                      <Box className={classes.paperBox}>
                        <Box className={classes.itemBox}>
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_742_5293)">
                              <path
                                d="M5 10V22.5"
                                stroke="#291672"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.46875 33.7504C9.71947 31.8321 11.429 30.2561 13.4426 29.1652C15.4561 28.0743 17.7099 27.5029 20 27.5029C22.2901 27.5029 24.5439 28.0743 26.5575 29.1652C28.571 30.2561 30.2805 31.8321 31.5313 33.7504"
                                stroke="#291672"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M35 10L20 15L5 10L20 5L35 10Z"
                                stroke="#291672"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M26.4531 12.8438C27.5998 14.0959 28.3573 15.6546 28.6335 17.3298C28.9096 19.0051 28.6925 20.7245 28.0084 22.2784C27.3243 23.8323 26.203 25.1537 24.781 26.0814C23.359 27.0091 21.6978 27.503 20 27.503C18.3022 27.503 16.641 27.0091 15.219 26.0814C13.797 25.1537 12.6757 23.8323 11.9916 22.2784C11.3076 20.7245 11.0904 19.0051 11.3665 17.3298C11.6427 15.6546 12.4002 14.0959 13.5469 12.8438"
                                stroke="#291672"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_742_5293">
                                <rect width="40" height="40" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </Box>
                        <Box className={classes.bottomBox}>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.count}>
                              {statistics?.totalStudents}
                            </Typography>
                          </Box>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.subtitlePaper}>
                              total students
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Col>
                  <Col>
                    <Paper square className={classes.paper}>
                      <Box className={classes.paperBox}>
                        <Box className={classes.itemBox}>
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.9998 16.9214C24.665 16.9214 28.4605 13.126 28.4605 8.46071C28.4606 3.79545 24.665 0 19.9998 0C15.3345 0 11.5391 3.79545 11.5391 8.46071C11.5391 13.1261 15.3345 16.9214 19.9998 16.9214ZM19.9998 2.0202C23.5512 2.0202 26.4403 4.90942 26.4403 8.46071C26.4403 12.012 23.5511 14.9012 19.9998 14.9012C16.4485 14.9012 13.5593 12.012 13.5593 8.46071C13.5593 4.90942 16.4485 2.0202 19.9998 2.0202Z"
                              fill="#291672"
                            />
                            <path
                              d="M34.1311 36.2593L31.3566 24.7498C31.0274 23.3838 29.816 22.4297 28.4106 22.4297C26.6482 22.4297 13.1648 22.4297 11.5895 22.4297C10.1843 22.4297 8.97288 23.3838 8.64351 24.7498L5.86897 36.2593C5.40972 38.1647 6.85267 39.9998 8.815 39.9998H31.1852C33.1452 39.9998 34.5912 38.1675 34.1311 36.2593ZM8.815 37.9796C8.16186 37.9796 7.67982 37.3688 7.83303 36.7329L10.6076 25.2232C10.7173 24.7678 11.1211 24.4499 11.5897 24.4499H16.1884L14.9145 33.4611C14.8768 33.7278 14.9471 33.9983 15.1098 34.2127L17.9663 37.9796H8.815ZM16.9739 33.3274L18.2289 24.45H21.7715L23.0264 33.3274L20.0001 37.3181L16.9739 33.3274ZM31.1852 37.9796H22.0339L24.8903 34.2129C25.053 33.9983 25.1233 33.7277 25.0856 33.4612L23.8117 24.45H28.4104C28.8789 24.45 29.2828 24.7681 29.3925 25.2234L32.1671 36.733C32.3203 37.3682 31.8392 37.9796 31.1852 37.9796Z"
                              fill="#291672"
                            />
                          </svg>
                        </Box>
                        <Box className={classes.bottomBox}>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.count}>
                              {statistics?.totalTeachers}
                            </Typography>
                          </Box>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.subtitlePaper}>
                              total Teachers
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Col>
                  <Col>
                    <Paper square className={classes.paper}>
                      <Box className={classes.paperBox}>
                        <Box className={classes.itemBox}>
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M36.6673 27.9002V7.78349C36.6673 5.78349 35.034 4.30015 33.0507 4.46682H32.9507C29.4507 4.76682 24.134 6.55015 21.1673 8.41682L20.884 8.60015C20.4007 8.90015 19.6007 8.90015 19.1173 8.60015L18.7007 8.35015C15.734 6.50015 10.434 4.73349 6.93398 4.45015C4.95065 4.28349 3.33398 5.78348 3.33398 7.76682V27.9002C3.33398 29.5002 4.63398 31.0002 6.23398 31.2002L6.71732 31.2668C10.334 31.7502 15.9173 33.5835 19.1173 35.3335L19.184 35.3668C19.634 35.6168 20.3507 35.6168 20.784 35.3668C23.984 33.6002 29.584 31.7502 33.2173 31.2668L33.7673 31.2002C35.3673 31.0002 36.6673 29.5002 36.6673 27.9002Z"
                              stroke="#291672"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20 9.15039V34.1504"
                              stroke="#291672"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.916 14.1504H9.16602"
                              stroke="#291672"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.166 19.1504H9.16602"
                              stroke="#291672"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Box>
                        <Box className={classes.bottomBox}>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.count}>
                              {statistics?.totalCourses}
                            </Typography>
                          </Box>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.subtitlePaper}>
                              total Courses
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Col>
                </Row>
              </Grid>
              {/*<Grid item xs={12} sm={4}>
                 <Box className={classes.calenderBox}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker
                        showToolbar={false}
                        value={value}
                        shouldDisableDate={isWeekend}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box> 
              </Grid>*/}
            </Grid>
          </Box>
          <Row className={classes.statisticsBox}>
            <Box>
              <Box sx={{ padding: "20px 10px" }}>
                <Typography
                  sx={{ marginBottom: "10px" }}
                  className={classes.statisticsText}
                >
                  Published Programs
                </Typography>
                <Box>
                  <Slider {...settings}>
                    {programs?.map((programsData, i) => (
                      <>
                        <CardTem
                          data={programsData}
                          handleClickOpenDetail={() =>
                            handleClickOpenDetailPrograms(programsData?.id)
                          }
                        />
                      </>
                    ))}
                  </Slider>
                </Box>
              </Box>
            </Box>
          </Row>
          <Row className={classes.statisticsBox}>
            <Box>
              <Box sx={{ padding: "20px 10px" }}>
                <Typography
                  sx={{ marginBottom: "10px" }}
                  className={classes.statisticsText}
                >
                  Published Course
                </Typography>
                <Box>
                  <Slider {...settings}>
                    {courses?.map((courseData, i) => (
                      <>
                        <CardTem
                          data={courseData}
                          handleClickOpenDetail={() =>
                            handleClickOpenDetail(courseData?.id)
                          }
                        />
                      </>
                    ))}
                  </Slider>
                </Box>
              </Box>
            </Box>
          </Row>
        </>
      )}
    </>
  );
}
