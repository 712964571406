import makeAxiosRequest from "../helpers/makeAxiosRequest";

const AuthService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  async login({ email, password }) {
    try {
      const response = await makeAxiosRequest(
        "POST",
        `${this._url}/auth/login`,
        { email, password }
      );
      return response;
    } catch (err) {
      if (navigator.onLine) {
        throw err;
      } else {
        throw "Network error!";
      }
    }
  },
  async getMe() {
    try {
      const response = await makeAxiosRequest(
        "GET",
        `${this._url}/admin_auth/me`
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  async forgetPassword({ email }) {
    try {
      const response = await makeAxiosRequest(
        "POST",
        `${this._url}/auth/forgotpassword`,
        { email }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  async resetPassword({ otp, password, email }) {
    try {
      const response = await makeAxiosRequest(
        "POST",
        `${this._url}/auth/resetpassword`,
        { otp, password, email }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
};

export default AuthService;
