import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  Grid,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { AuthContext } from "context/authContext";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { makeStyles } from "@mui/styles";
import { Form, Image } from "react-bootstrap";
import img1 from "../../Assets/Images/IRSA";
import LoginLoadingBtn from "components/atoms/LoginLoadingBtn";
import logo from "../../Assets/Images/home.svg";

const useStyles = makeStyles({
  gridContainer: {
    backgroundColor: " #f6f4fb !important",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },
  homeImg: {
    webkitFilter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3))",
    filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3))",
    transition: "transform .5s ease",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  form: {
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f6f4fb !important",

    "@media (max-width: 600px)": {
      height: "65vh",
    },
  },
  boxForm: {
    width: "75%",
  },
  homeBox: {
    backgroundColor: "#f6f4fb !important",
    padding: "20px",
    height: "20%",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  homeBtn: {
    fontWeight: "400 !important",
    fontSize: "24px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var( --admin) !important",
    cursor: "pointer",
  },
  formHeading: {
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--heading-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  formSubtitle: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    color: "var(--text-color) !important",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  inputDiv: {
    margin: "10px 0",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  submitBtn: {
    margin: "40px 0px",
    width: "100%",
    height: "56px",
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    color: "#FFFFFF",
    background: "var(--admin)",
    boxShadow: " 0px 14px 28px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "#FFF !important",
      color: "var(--admin) !important",
      border: "1px solid var(--admin)",
    },
  },
  updateLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "120%",
    textTransform: "capitalize",
    color: " var(--admin)",
    textDecoration: "underline !important",
  },
  sideBox: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--admin) !important",

    "@media (max-width: 600px)": {
      height: "fit-content",
      padding: "60px",
    },
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
});

export default function Login() {
  const { onLogin, error, setError, loading } = useContext(AuthContext);
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onLogin({
          email: values.email,
          password: values.password,
        });
        setStatus({ success: false });
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <>
          <Grid container className={classes.gridContainer}>
            <Grid item sm={7} xs={12}>
              <Box className={classes.homeBox}>
                <Link className={classes.homeBtn} to="/">
                  <img src={logo} alt="logo" className={classes.homeImg} />
                </Link>
              </Box>
              <Form noValidate onSubmit={handleSubmit} className={classes.form}>
                <Box className={classes.boxForm}>
                  <Typography variant="h3" className={classes.formHeading}>
                    Welcome Back
                  </Typography>
                  <Typography
                    variant="subtitle"
                    className={classes.formSubtitle}
                  >
                    We keep your information save.
                  </Typography>
                  <div className={classes.inputDiv}>
                    <label className={classes.label}>Email</label>
                    <div
                      className={
                        touched.email && errors.email
                          ? `${classes.inputFieldErr}`
                          : `${classes.inputField}`
                      }
                    >
                      <svg
                        width="19"
                        height="17"
                        viewBox="0 0 19 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.8831 0.666992C16.6581 0.666992 18.7506 2.93116 18.7506 5.93366V11.657C18.7506 13.1937 18.2073 14.582 17.2198 15.567C16.334 16.4495 15.1848 16.917 13.8965 16.917H5.68565C4.39982 16.917 3.25148 16.4503 2.36482 15.567C1.37732 14.582 0.833984 13.1937 0.833984 11.657V5.93366C0.833984 2.93116 2.92648 0.666992 5.70148 0.666992H13.8831ZM13.8831 1.91699H5.70148C3.60565 1.91699 2.08398 3.60616 2.08398 5.93366V11.657C2.08398 12.8595 2.49732 13.9337 3.24732 14.6812C3.89398 15.327 4.73815 15.667 5.68815 15.667H13.8831C13.8848 15.6653 13.8915 15.667 13.8965 15.667C14.8473 15.667 15.6906 15.327 16.3373 14.6812C17.0881 13.9337 17.5006 12.8595 17.5006 11.657V5.93366C17.5006 3.60616 15.979 1.91699 13.8831 1.91699ZM15.1965 5.77433C15.414 6.04183 15.3731 6.43516 15.1056 6.65349L11.4023 9.66349C10.934 10.0352 10.374 10.221 9.81482 10.221C9.25732 10.221 8.70148 10.0368 8.23648 9.66849L4.49898 6.65516C4.22982 6.43849 4.18815 6.04433 4.40398 5.77599C4.62148 5.50849 5.01482 5.46599 5.28315 5.68183L9.01732 8.69182C9.48648 9.06349 10.1473 9.06349 10.6198 8.68849L14.3165 5.68349C14.5848 5.46433 14.9781 5.50599 15.1965 5.77433Z"
                          fill="var(--text-color)"
                        />
                      </svg>
                      <InputBase
                        id="email-login"
                        type="email"
                        name="email"
                        value={values.email}
                        placeholder="example@gmail.com"
                        fullWidth
                        autoComplete="off"
                        className={classes.input}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </div>
                  <div className={classes.inputDiv}>
                    <label className={classes.label}>Password</label>
                    <div
                      className={
                        touched.password && errors.password
                          ? `${classes.inputFieldErr}`
                          : `${classes.inputField}`
                      }
                    >
                      <svg
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.31307 0.668464C9.7464 0.668464 11.7281 2.65013 11.7281 5.08596L11.728 6.3998C13.2073 6.91464 14.2715 8.32297 14.2715 9.9758V13.55C14.2715 15.6375 12.574 17.335 10.4865 17.335H4.11898C2.03148 17.335 0.333984 15.6375 0.333984 13.55V9.9758C0.333984 8.32328 1.39775 6.91517 2.87666 6.40009L2.87723 5.08596C2.88223 3.88596 3.3464 2.77846 4.1839 1.94846C5.02223 1.11763 6.1289 0.636798 7.31307 0.668464ZM10.4865 7.4408H4.11898C2.72065 7.4408 1.58398 8.57746 1.58398 9.9758V13.55C1.58398 14.9483 2.72065 16.085 4.11898 16.085H10.4865C11.884 16.085 13.0215 14.9483 13.0215 13.55V9.9758C13.0215 8.57746 11.884 7.4408 10.4865 7.4408ZM7.30257 10.2123C7.64757 10.2123 7.92757 10.4923 7.92757 10.8373V12.6881C7.92757 13.0331 7.64757 13.3131 7.30257 13.3131C6.95757 13.3131 6.67757 13.0331 6.67757 12.6881V10.8373C6.67757 10.4923 6.95757 10.2123 7.30257 10.2123ZM7.31057 1.91846H7.29723C6.45307 1.91846 5.66223 2.24346 5.06473 2.83596C4.46307 3.43096 4.13057 4.22513 4.12723 5.0718L4.12648 6.19033H10.4773L10.4781 5.08596C10.4781 3.3393 9.05723 1.91846 7.31057 1.91846Z"
                          fill="var(--text-color)"
                        />
                      </svg>

                      <InputBase
                        className={classes.input}
                        type={showPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="password"
                        placeholder="Enter Password"
                        fullWidth
                        autoComplete="off"
                        error={Boolean(touched.password && errors.password)}
                        value={values.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <BsEye
                                  style={{
                                    fontSize: "20px",
                                    color: "var(--text-color)",
                                  }}
                                />
                              ) : (
                                <BsEyeSlash
                                  style={{
                                    fontSize: "20px",
                                    color: "var(--text-color)",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </div>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <div className={classes.inputDiv}>
                    {loading ? (
                      <LoginLoadingBtn />
                    ) : (
                      <>
                        <Button
                          type="submit"
                          disableElevation
                          disabled={loading}
                          variant="contained"
                          className={classes.submitBtn}
                        >
                          LogIn
                        </Button>
                      </>
                    )}
                  </div>
                  {touched.password && error ? (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {error}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                  <div className={classes.updateLink}>
                    <Link to="/forget-password" className={classes.link}>
                      Forget Password?
                    </Link>
                  </div>
                </Box>
              </Form>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box className={classes.sideBox}>
                <Image src={img1} width="344" />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
}
