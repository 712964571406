import { useState, useEffect } from "react";
import { useCancellablePromise } from "../helpers/promiseHandler";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};
const AdminService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetAdmins({ page, pageSize, reload }) {
    const axiosPrivate = useAxiosPrivate();

    const [admins, setAdmins] = useState({
      admins: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdmins({ page, pageSize, axiosPrivate }))
        .then((res) => {
          setAdmins(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      admins_data: admins,
    };
  },
  async getAdmins({ page, pageSize, axiosPrivate }) {
    try {
      const { data } = await axiosPrivate.get(
        `${this._url}/admin_auth/getadmins?page=${page}&page_size=${pageSize}`
      );

      return { admins: data?.results, totalItems: data?.count };
    } catch (err) {
      if (err.response) {
        /* 
          The request was made and the server responded with a status code
          that falls out of the range of 2xx
        */
        throw err.response.data;
      } else if (err.request) {
        // Client never received a response, or request never left
        throw err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Error: ${err.message}`);
      }
    }
  },
};

export default AdminService;
