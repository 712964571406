import React, { useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import AddNewsEvents from "components/Forms/AddNewsEvents";
import NewsService from "services/news.service";
import Loading from "components/atoms/Loading";
import { Box, Grid } from "@mui/material";
import NewsCard from "components/atoms/NewsCard";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import EditNewsEvents from "components/Forms/EditNewsEvent";
import TablePagination from "components/atoms/Pagination";
import EditorModel from "components/atoms/EditorModel";
import BtnBox from "components/atoms/BtnBox";

const Newsevents = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [filter, setFilter] = useState({
    filterStatus: [],
  });
  const { filterStatus } = filter;
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, news_data } = NewsService.GetNews(
    page,
    pageSize,
    searchText,
    filterStatus,
    reload
  );
  const { totalItems, news } = useMemo(
    () => ({
      news: news_data?.news,
      totalItems: news_data.totalItems,
    }),
    [news_data]
  );

  const reversNews = [...news].reverse();

  const deleteNews = async (id, handleClose, reload) => {
    try {
      const { data } = await axiosPrivate.delete(`news/${id}`);
      toast.success(data);
      handleClose();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isReload) => {
    setOpen(false);
  };

  // delete news model

  const handleClickOpenDelete = ({ newsData }) => {
    setData(newsData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete news model

  // course details

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/newsevents/${id}`);
  };
  // end course details

  // edit news model

  const handleClickOpenEdit = ({ newsData }) => {
    setData(newsData);
    setOpenEdit(true);
  };

  const handleCloseEdit = (isReload) => {
    setOpenEdit(false);
  };
  // end edit news model

  return (
    <>
      <ToastContainer autoClose={3000} />
      <BtnBox
        handleClickOpen={handleClickOpen}
        title={"News"}
        subtitle={"You Have Added Following News"}
        btnText={"Add News"}
      />

      <EditorModel
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddNewsEvents
            handleClose={handleClose}
            toast={toast}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Add News and Events"
      />

      {/* edit news model */}

      <EditorModel
        openModel={openEdit}
        handleClose={handleCloseEdit}
        bodyContent={
          <EditNewsEvents
            handleClose={handleCloseEdit}
            toast={toast}
            news={data}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Edit News"
      />
      {/* end edit news model */}

      {/* delete news model */}

      <DeleteConfirmModel
        openDelete={openDelete}
        handleClose={handleCloseDelete}
        onClick={() => deleteNews(data?.id, handleCloseDelete)}
      />

      {/* end delete news model */}
      {loading === true ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
            >
              {reversNews?.map((newsData, i) => (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                    <NewsCard
                      data={newsData}
                      onClick={() => handleClickOpenEdit({ newsData })}
                      handleClickOpenDelete={() =>
                        handleClickOpenDelete({ newsData })
                      }
                      handleClickOpenDetail={() =>
                        handleClickOpenDetail(newsData?.id)
                      }
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
      {totalItems > 0 && (
        <div
          className="my-3"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TablePagination
            count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
            page={page}
            onChange={(e, v) =>
              setSearchQuery((prev) => ({ ...prev, page: v }))
            }
          />
        </div>
      )}
    </>
  );
};

export default Newsevents;
