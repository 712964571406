import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import "../Faculty/facultyStyle.css";
import { RiEqualizerFill } from "react-icons/ri";
import CloseIcon from "@mui/icons-material/Close";
import { getDateObject } from "helpers/common";
import { format } from "date-fns";
import TablePagination from "components/atoms/Pagination";
import FullScreenDialog from "components/atoms/DialogeBox";
import Loading from "components/atoms/Loading";
import parse from "html-react-parser";
import ResourcesService from "services/resources.service";

const useStyles = makeStyles({
  desktop_view: {
    "@media (max-width: 900px)": {
      display: "none !important",
    },
  },
  main_container: {
    "@media (max-width: 900px)": {
      justifyContent: "center !important",
    },
  },
  search_sidebar: {
    display: "none !important",

    "@media (max-width: 900px)": {
      padding: "10px 20px",
      display: "flex !important",
      justifyContent: "space-between",
    },
  },
  checkBoxHeading: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    display: "flex !important",
    alignItems: "center !important",
    textAlign: "center !important",
    textTransform: "capitalize",
    color: " #252827 !important",
  },
  checkBoxLabel: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    display: "flex !important",
    alignItems: "center !important",
    textAlign: "center !important",
    textTransform: "capitalize",
    color: "var(--text-color) !important",
    marginLeft: "20px",
    marginBottom: "0px !important",
  },

  top_box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "@media (max-width: 768px)": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  input_box: {
    display: "flex",
    alignItems: "center",

    "@media (max-width: 550px)": {
      display: "block !important",
    },
  },
  formGroup: {
    display: "flex",
    alignItems: "center",
  },
  search_bt: {
    width: "112px",
    height: "48px",
    backgroundColor: "#E24F57 !important",
    color: "#fff !important",
    borderRadius: "4px !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
    marginLeft: "10px !important",

    "@media (max-width: 550px)": {
      width: "100%",
      margin: "0 !important",
    },
  },
  search_felid: {
    width: "352px",
    height: "48px",
    background: "#F3F3F3",
    borderRadius: "4px",
    border: "none !important",
    padding: "20px",
    outline: "none",

    "@media (max-width: 768px)": {
      width: "270px !important",
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px auto !important",
    },
  },
  formControl: {
    display: "flex !important",
  },
  total_result: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120%",
    textAlign: "center",
    margin: "0px",
    textTransform: "capitalize",
    color: "var(--text-color)  !important",

    "@media (max-width: 900px)": {
      display: "none !important",
    },
  },
  total_result_mobile: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120%",
    textAlign: "center",
    margin: "0px",
    textTransform: "capitalize",
    color: "var(--text-color)  !important",
    display: "none",

    "@media (max-width: 900px)": {
      display: "block !important",
    },
  },
  card: {
    width: "60%",
    height: "fit-content",
    background: "transparent !important",
    boxShadow: "none !important",
    borderRadius: "0 !important",
    border: "none !important",
    marginBottom: "10px",
    cursor: "pointer",

    "@media (max-width: 420px)": {
      width: "250px",
      margin: "20px auto",
    },
    "@media (max-width: 900px)": {
      margin: "20px auto !important",
    },
  },
  card_img: {
    width: "112px",
    height: "112px",
    borderRadius: "50%",
  },
  image_box: {
    display: "flex",
    justifyContent: "center",
  },
  date_posted: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120%",
    textTransform: "capitalize",
    color: " var(--body-text-color) !important",
    margin: "5px 0 !important",
  },
  heading: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120%",
    textTransform: "capitalize",
    color: " #252827 !important",
    margin: "5px 0 !important",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:hover": {
      color: "var(--primary) !important",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  subHeading: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120%",
    textTransform: "capitalize",
    color: "var(--body-text-color)  !important",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  keywords: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120%",
    textTransform: "capitalize",
    color: "var(--body-text-color)  !important",
    display: "flex",
  },
});

const ResourcesContent = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    filterStatus: [],
    filterPrograms: [],
  });
  const { filterStatus, filterPrograms } = filter;
  const [libraryContent, setLibraryContent] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, resources_data } = ResourcesService.GetResourcesLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterPrograms,
    reload,
  });

  const { totalItems, resources } = useMemo(
    () => ({
      resources: resources_data?.resources,
      totalItems: resources_data.totalItems,
    }),
    [resources_data]
  );

  const ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const onSearchChange = (e) => {
    setSearchQuery((ev) => ({
      ...ev,
      searchText: e.target.value,
    }));
    if (!e.target.value) {
      setReload(() => !reload);
    }
  };

  const handleClickOpen = (libraryData, i) => {
    setOpen(true);
    setLibraryContent(libraryData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setFilter({
        filterStatus: [...filterStatus, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setFilter({
        filterStatus: filterStatus.filter((e) => e !== value),
      });
    }
  };

  const handleChangeProgram = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setFilter({
        filterPrograms: [...filterPrograms, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setFilter({
        filterPrograms: filterPrograms.filter((e) => e !== value),
      });
    }
  };

  return (
    <>
      <Container style={{ padding: "60px 0px" }}>
        <FullScreenDialog
          open={open}
          handleClose={handleClose}
          libraryContent={libraryContent}
        />
        <Box className={classes.search_sidebar}>
          <Button onClick={ToggleSidebar}>
            <RiEqualizerFill
              color="var(--primary)"
              style={{ marginRight: "5px" }}
            />
            <strong style={{ color: "var(--primary)" }}>Filter</strong>
          </Button>
          <p className={classes.total_result_mobile}>{totalItems} results</p>
          <Box className={isOpen === true ? "active_sidebar" : "sidebar"}>
            <Box className="sd-header">
              <h4 className="mb-0">Search Resources</h4>
              <Button onClick={ToggleSidebar}>
                <CloseIcon color="var(--primary)" />
              </Button>
            </Box>
            <Box className="sd-body">
              <Box sx={{ margin: "10px 0px" }}>
                <Typography variant="h5" className={classes.checkBoxHeading}>
                  Content Type
                </Typography>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Video"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Video
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Audio"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Audio
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Document"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Document
                    </Form.Label>
                  </Form.Group>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={2} className={classes.main_container}>
            <Grid item md={4} className={classes.desktop_view}>
              <Box sx={{ margin: "10px 0px" }}>
                <Typography variant="h5" className={classes.checkBoxHeading}>
                  Content Type
                </Typography>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Video"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Video
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Audio"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Audio
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Document"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Document
                    </Form.Label>
                  </Form.Group>
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} sx={{ paddingLeft: "0px" }}>
              <Box className={classes.top_box}>
                <Box className={classes.input_box}>
                  <input
                    id="search-bar"
                    className={classes.search_felid}
                    type="search"
                    placeholder="Search library"
                    onChange={(e) => onSearchChange(e)}
                  />
                  <IconButton
                    className={classes.search_bt}
                    type="submit"
                    aria-label="search"
                    onClick={() => searchText && setReload(() => !reload)}
                  >
                    Search
                  </IconButton>
                </Box>
                <Box>
                  <p className={classes.total_result}>{totalItems} results</p>
                </Box>
              </Box>
              <Box sx={{ marginTop: "40px" }}>
                <Row className="card_row">
                  <Col style={{ padding: "0" }}>
                    {loading === true ? (
                      <div>
                        <Loading />
                      </div>
                    ) : totalItems > 0 ? (
                      resources?.map((libraryData, i) => (
                        <Card
                          className={classes.card}
                          key={i}
                          onClick={() => handleClickOpen(libraryData, i)}
                        >
                          <Typography
                            variant="subtitle1"
                            className={classes.date_posted}
                          >
                            {format(
                              getDateObject(libraryData?.createdAt),
                              "MM/dd/yyyy"
                            )}
                          </Typography>
                          <Typography
                            variant="h5"
                            component="div"
                            className={classes.heading}
                          >
                            {libraryData?.title}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            color="text.secondary"
                            className={classes.subHeading}
                          >
                            {parse(`${libraryData?.description}`)}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5, display: "flex" }}
                            color="text.secondary"
                            className={classes.keywords}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <strong>Keyword:</strong>{" "}
                              {libraryData?.keywordss?.map(
                                (search_keywords, i) => (
                                  <Typography
                                    key={i}
                                    sx={{ mb: 1.5, margin: "0 10px" }}
                                    color="text.secondary"
                                    className={classes.subHeading}
                                  >
                                    {search_keywords}
                                  </Typography>
                                )
                              )}
                            </Box>
                          </Typography>
                        </Card>
                      ))
                    ) : (
                      <h2 className="text-center">No Record found</h2>
                    )}
                  </Col>
                  {/* <Col style={{ padding: "0" }}>
                    <Card className={classes.card}>
                      <Box className={classes.image_box}>
                        <Image
                          className={classes.card_img}
                          src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                        />
                      </Box>
                      <Typography
                        variant="h5"
                        component="div"
                        className={classes.heading}
                      >
                        gvvds vsd sd
                      </Typography>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        className={classes.subHeading}
                      >
                        sd sd s ds s
                      </Typography>
                      {/* <Typography
                            sx={{ mb: 1.5 }}
                            color="text.secondary"
                            className={classes.subHeading}
                          >
                            HEC approved supervisor
                          </Typography>
                    </Card>
                  </Col> */}
                  {totalItems > 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <TablePagination
                        count={Math.ceil(
                          parseInt(totalItems) / parseInt(pageSize)
                        )}
                        page={page}
                        onChange={(e, v) =>
                          setSearchQuery((prev) => ({ ...prev, page: v }))
                        }
                      />
                    </Box>
                  )}
                </Row>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ResourcesContent;
