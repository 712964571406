import BackToTop from "components/atoms/BackToTop/BackToTop";
import Footer from "components/Layout/Footer/Footer";
import LibraryContent from "components/Layout/Library/LibraryContent";
import LibraryMainSec from "components/Layout/Library/LibraryMainSec";
import Navbar from "components/Layout/Navbar";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect, useMemo } from "react";

const LibraryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <TopNav /> */}
      <Navbar />
      <LibraryMainSec />
      <LibraryContent />
      <BackToTop />
      <Footer />
    </>
  );
};

export default LibraryPage;
