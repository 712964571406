import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Model from "./Model";

function ModalContainer({
  btnComponent,
  content,
  title,
  xl,
  lg,
  sm,
  isClosable,
  onModalClose = () => {},
  isOpen,
  imgPreview,
  width,
  helpModal,
}) {
  const [isVisible, setIsVisible] = useState(!!isOpen);
  const showModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };
  useEffect(() => {
    if (!isVisible) {
      onModalClose();
    }
  }, [isVisible]);

  return (
    <>
      {btnComponent && btnComponent({ onClick: showModal })}
      <Model
        openModel={isVisible}
        handleClose={handleCancel}
        bodyContent={content({ onClose: handleCancel })}
        title={title}
        width={width}
      />
    </>
  );
}

export default ModalContainer;
