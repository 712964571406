import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./HeroSection.css";
import backgroundVideo from "../../../Assets/Video/Background.mp4";
import logo from "../../../Assets/Images/LogoLight.png";

const HeroSection = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <>
      <div className="homeContent ">
        <video
          className="vidBox mobile-hidden"
          autoPlay
          loop
          muted
          tabIndex="-1"
        >
          <source type="video/mp4" src={backgroundVideo} />
        </video>
        <div className="mobileImg desktop-hidden tablet-hidden"></div>
        <header aria-label="Site Header">
          <div className="fadeBox" style={{ transform: "translateY(0px)" }}>
            <div className="blackBox">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img loading="eager" width="150px" src={logo} alt="" />
              </div>

              <nav aria-label="Site Utility Navigation" className="heroCard">
                <div className="mainButton-box mainButton-box-first">
                  <Link to="/about">
                    <div className="mainButton pause-on-hover">
                      <span className="mobile-text-change desktop-hidden tablet-hidden white alpha normal">
                        About
                      </span>
                      <span className="mobile-hidden white eta normal">
                        About
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
              <nav aria-label="Site Utility Navigation" className="heroCard">
                <div className="mainButton-box">
                  <Link to="/training-programs">
                    <div className="mainButton pause-on-hover">
                      <span className="mobile-text-change desktop-hidden tablet-hidden white alpha normal">
                        Training Programs
                      </span>
                      <span className="mobile-hidden white eta normal">
                        Training Programs
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
              <nav aria-label="Site Utility Navigation" className="heroCard">
                <div className="mainButton-box">
                  <Link to="/faculty">
                    <div className="mainButton pause-on-hover">
                      <span className="mobile-text-change desktop-hidden tablet-hidden white alpha normal">
                        Faculty
                      </span>
                      <span className="mobile-hidden white eta normal">
                        Faculty
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
              <nav aria-label="Site Utility Navigation" className="heroCard">
                <div className="mainButton-box">
                  <Link to="/login">
                    <div className="mainButton pause-on-hover">
                      <span className="mobile-text-change desktop-hidden tablet-hidden white alpha normal">
                        LMS
                      </span>
                      <span className="mobile-hidden white eta normal">
                        LMS
                      </span>
                    </div>
                  </Link>
                </div>
              </nav>
              {/* <div className="searchWrapper">
                <div className="mobile-searchWrapper">
                  <div className="searchBox">
                    <input
                      style={{ color: "black" }}
                      type="search"
                      placeholder="Search"
                      className="pause-on-hover mobile-hidden universal-site-search-query eta normal field mainSearchField mainSearchField-vid"
                      aria-label="search input"
                    />
                    <input
                      style={{ color: "black" }}
                      type="search"
                      placeholder="Search"
                      className="pause-on-hover mobile-text-change desktop-hidden tablet-hidden universal-site-search-query alpha normal field mainSearchField mainSearchField-vid"
                      aria-label="search input"
                    />
                    <div className="pause-on-hover universal-site-search-button mainSearchBtn btn-submit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="search-icon-svg"
                        viewBox="-20 -50 220 220"
                        version="1.1"
                        focusable="false"
                      >
                        <path d="M137.686,11.342 C137.686,-15.909 115.595,-38 88.344,-38 C61.09,-38 39,-15.909 39,11.342 C39,36.724 58.168,57.607 82.818,60.355 L82.818,72.838 C79.304,74.521 76.898,77.714 76.898,81.419 L76.898,128.148 C76.898,133.588 82.023,138 88.345,138 C94.667,138 99.789,133.589 99.789,128.148 L99.789,81.419 C99.789,77.711 97.382,74.521 93.869,72.838 L93.869,60.354 C118.515,57.606 137.686,36.726 137.686,11.342 Z M56.032,11.225 C56.032,-6.759 70.606,-21.338 88.589,-21.338 C106.572,-21.338 121.142,-6.759 121.142,11.225 C121.142,29.205 106.572,43.78 88.589,43.78 C70.606,43.78 56.032,29.205 56.032,11.225 Z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div
              onClick={() => {
                !showMobileMenu
                  ? setShowMobileMenu(true)
                  : setShowMobileMenu(false);
              }}
              id="mobileMenu"
              className="mobileMenu-btn-wrapper"
              style={{ marginBottom: "9%" }}
            >
              <div
                desktop-hidden
                tablet-hidden
                change-btn
                className={
                  !showMobileMenu
                    ? "mobileMenu-btn desktop-hidden tablet-hidden"
                    : "mobileMenu-btn desktop-hidden tablet-hidden change-btn"
                }
              >
                <div className="hamBox">
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
                <div
                  className="mobile-text-change menuText alpha normal"
                  tabIndex="0"
                >
                  Menu
                </div>
              </div>
            </div>
            <div
              className={
                !showMobileMenu
                  ? "mobile-offNav desktop-hidden tablet-hidden"
                  : "mobile-offNav desktop-hidden tablet-hidden show-mobile-offNav"
              }
            >
              <ul className="dropList eta normal unstyled">
                <li>
                  <Link to="/" className="offNav-a">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="offNav-a">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/training-programs" className="offNav-a">
                    Training Programs
                  </Link>
                </li>
                <li>
                  <Link to="/faculty" className="offNav-a">
                    Faculty
                  </Link>
                </li>
                <li>
                  <Link to="/login" className="offNav-a">
                    LMS
                  </Link>
                </li>
                <li>
                  <Link to="/campus-culture" className="offNav-a" tabIndex="0">
                    Campus Culture
                  </Link>
                </li>
                <li>
                  <Link to="/library" className="offNav-a">
                    Library
                  </Link>
                </li>
                <li>
                  <Link to="/news" className="offNav-a">
                    News &amp; Events
                  </Link>
                </li>
                <li>
                  <Link to="/stories" className="offNav-a" tabIndex="0">
                    Stories
                  </Link>
                </li>
              </ul>
              <div
                className="mobileMenu-close"
                onClick={() => setShowMobileMenu(false)}
              >
                <div className="mobileMenu-btn mobileMenu-btn-close">
                  <div className="hamBox">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                  </div>
                  <div
                    className="mobile-text-change menuText alpha white normal"
                    tabIndex="-1"
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default HeroSection;
