import BackToTop from "components/atoms/BackToTop/BackToTop";
import CampusTour from "components/Layout/AboutPage/CampusTour";
import Details from "components/Layout/AboutPage/Details";
import HeroSection from "components/Layout/AboutPage/HeroSection";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect } from "react";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <TopNav /> */}
      <Navbar />
      <HeroSection />
      <Details />
      {/* <ProgramsCard /> */}
      {/* <CampusTour /> */}
      <BackToTop />
      <Footer />
    </>
  );
};

export default AboutPage;
