export const classBatchOptions = [
  { value: "Fall", label: "Fall" },
  { value: "Spring", label: "Spring" },
  { value: "Summer", label: "Summer" },
];
export const contentTypeOption = [
  { value: "video", label: "Video" },
  { value: "document", label: "Document" },
  { value: "assignment", label: "Assignment" },
];

export const classBatchYearOptions = Array.from(
  new Array(40),
  (val, index) => ({
    value: (index + 2022).toString(),
    label: (index + 2022).toString(),
  })
);

export const libraryTypeOptions = [
  { value: "Book", label: "Book" },
  { value: "Magazines", label: "Magazines" },
  { value: "Multimedia", label: "Multimedia" },
  { value: "Others", label: "Others" },
];
export const publicationOptions = [
  { value: "Book", label: "Book" },
  { value: "Magazines", label: "Magazines" },
  { value: "Multimedia", label: "Multimedia" },
  { value: "Others", label: "Others" },
];
export const resourcesOption = [
  { value: "Video", label: "Video" },
  { value: "Audio", label: "Audio" },
  { value: "Document", label: "Document" },
];
