import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import parse from "html-react-parser";
import useAuth from "hooks/useAuth";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    margin: "10px",
    padding: "10px",
    maxWidth: "341px",
    height: "358px",
    background: (props) =>
      props?.role === "admin" ||
      props.location.pathname === "/dashboard/programs" ||
      props.location.pathname === "/dashboard/courses"
        ? "#fff"
        : "#f6f4fb",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  cardImage: {
    maxWidth: "317px",
    height: "162px",
    filter: "drop-shadow(0px 8px 21px rgba(0, 0, 0, 0.06))",
    borderRadius: "4px",
  },
  titleBox: {
    display: (props) =>
      props?.role === "admin" ||
      props.location.pathname === "/dashboard/programs" ||
      props.location.pathname === "/dashboard/courses"
        ? "flex"
        : "block",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #252827 !important",
    display: "-webkit-box",
    lineClamp: "2",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
    },
  },
  editBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--text-color) !important",
    minWidth: "fit-content!important",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
    },
  },
  deleteBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #E24F57 !important",
    minWidth: "fit-content!important",
  },
  listBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px 5px",
  },
  listBoxCourse: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
  listBoxCorse: {
    display: "flex",
    alignItems: "center",
    margin: "10px 5px",
  },

  listText: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #A6A9A8; !important",
    marginLeft: "10px",
  },
  desText: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    color: " var(--link-text-color) !important",
    display: "-webkit-box",
    lineClamp: "2",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "15px",
  },
});

const CardTem = ({
  data,
  onClick,
  handleClickOpenDelete,
  handleClickOpenDetail,
}) => {
  const { user } = useAuth();
  let location = useLocation();
  const classes = useStyles({ user, location });

  return (
    <>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardImage}
          component="img"
          height="140"
          image={
            data.image?.startsWith("/")
              ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${data.image}`
              : data.image
          }
          alt="img"
        />
        <CardContent>
          <Box className={classes.titleBox}>
            <Box
              sx={{
                width:
                  (user?.role === "admin" &&
                    location.pathname === "/dashboard/programs") ||
                  (user?.role === "admin" &&
                    location.pathname === "/dashboard/courses")
                    ? " 60%"
                    : "100%",
              }}
            >
              <Typography
                onClick={() => handleClickOpenDetail()}
                gutterBottom
                variant="h5"
                component="div"
                className={classes.cardTitle}
              >
                {data?.title}
              </Typography>
            </Box>
            {(user?.role === "admin" &&
              location.pathname === "/dashboard/programs") ||
            (user?.role === "admin" &&
              location.pathname === "/dashboard/courses") ? (
              <Box>
                <Button className={classes.editBtn} onClick={() => onClick()}>
                  Edit
                </Button>
                <Button
                  className={classes.deleteBtn}
                  onClick={() => handleClickOpenDelete()}
                >
                  Remove
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box>
            {location.pathname === "/dashboard/programs" ? (
              <Box className={classes.listBox}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3337 13.9501V3.89174C18.3337 2.89174 17.517 2.15008 16.5253 2.23341H16.4753C14.7253 2.38341 12.067 3.27508 10.5837 4.20841L10.442 4.30008C10.2003 4.45008 9.80033 4.45008 9.55866 4.30008L9.35033 4.17508C7.86699 3.25008 5.21699 2.36674 3.46699 2.22508C2.47533 2.14174 1.66699 2.89174 1.66699 3.88341V13.9501C1.66699 14.7501 2.31699 15.5001 3.11699 15.6001L3.35866 15.6334C5.16699 15.8751 7.95866 16.7917 9.55866 17.6667L9.59199 17.6834C9.81699 17.8084 10.1753 17.8084 10.392 17.6834C11.992 16.8001 14.792 15.8751 16.6087 15.6334L16.8837 15.6001C17.6837 15.5001 18.3337 14.7501 18.3337 13.9501Z"
                    stroke="var(--icons-color)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 4.5752V17.0752"
                    stroke="var(--icons-color)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.45801 7.0752H4.58301"
                    stroke="var(--icons-color)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.08301 9.5752H4.58301"
                    stroke="var(--icons-color)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Typography className={classes.listText}>
                  {data?.course_count} Courses
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {location.pathname === "/dashboard/courses" ? (
              <>
                {" "}
                <Box sx={{ display: "flex" }}>
                  <Box className={classes.listBoxCourse}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3337 8.33366V12.5003C18.3337 16.667 16.667 18.3337 12.5003 18.3337H7.50033C3.33366 18.3337 1.66699 16.667 1.66699 12.5003V7.50033C1.66699 3.33366 3.33366 1.66699 7.50033 1.66699H11.667"
                        stroke="#D5DBD9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.3337 8.33366H15.0003C12.5003 8.33366 11.667 7.50033 11.667 5.00033V1.66699L18.3337 8.33366Z"
                        stroke="#D5DBD9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Typography className={classes.listText}>
                      {data?.course_content?.document_count
                        ? data?.course_content?.document_count
                        : "0"}{" "}
                      Documents
                    </Typography>
                  </Box>
                  <Box className={classes.listBoxCourse}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.97493 18.3337C14.5773 18.3337 18.3083 14.6027 18.3083 10.0003C18.3083 5.39795 14.5773 1.66699 9.97493 1.66699C5.37256 1.66699 1.6416 5.39795 1.6416 10.0003C1.6416 14.6027 5.37256 18.3337 9.97493 18.3337Z"
                        stroke="#D5DBD9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.2832 10.1912V8.79953C7.2832 7.0662 8.5082 6.35787 10.0082 7.22453L11.2165 7.92453L12.4249 8.62453C13.9249 9.4912 13.9249 10.9079 12.4249 11.7745L11.2165 12.4745L10.0082 13.1745C8.5082 14.0412 7.2832 13.3329 7.2832 11.5995V10.1912Z"
                        stroke="#D5DBD9"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Typography className={classes.listText}>
                      {data?.course_content?.video_count
                        ? data?.course_content?.video_count
                        : "0"}{" "}
                      Tutorials
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.listBoxCourse}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.95596 1.66699H11.0439C12.0363 1.66699 12.8486 2.43796 12.9145 3.41362L12.9189 3.54199L12.9188 3.75033H13.9541C15.4499 3.75033 16.6625 4.96289 16.6625 6.45866V16.4625C16.6625 17.498 15.823 18.3375 14.7875 18.3375H5.20801C4.17247 18.3375 3.33301 17.498 3.33301 16.4625V6.45866C3.33301 4.96289 4.54557 3.75033 6.04134 3.75033H7.08051L7.08096 3.54199C7.08096 2.54961 7.85193 1.73729 8.82759 1.67132L8.95596 1.66699H11.0439H8.95596ZM13.9541 5.00033H6.04134C5.23593 5.00033 4.58301 5.65324 4.58301 6.45866V16.4625C4.58301 16.8077 4.86283 17.0875 5.20801 17.0875H14.7875C15.1327 17.0875 15.4125 16.8077 15.4125 16.4625V6.45866C15.4125 5.65324 14.7596 5.00033 13.9541 5.00033ZM12.7085 11.2503C13.5139 11.2503 14.1668 11.9032 14.1668 12.7087V14.3729C14.1668 15.1783 13.5139 15.8312 12.7085 15.8312H7.29324C6.48783 15.8312 5.83491 15.1783 5.83491 14.3729V12.7087C5.83491 11.9032 6.48783 11.2503 7.29324 11.2503H12.7085ZM12.7085 12.5003H7.29324C7.17818 12.5003 7.08491 12.5936 7.08491 12.7087V14.3729C7.08491 14.488 7.17818 14.5812 7.29324 14.5812H12.7085C12.8236 14.5812 12.9168 14.488 12.9168 14.3729V12.7087C12.9168 12.5936 12.8236 12.5003 12.7085 12.5003ZM8.95801 7.91699H11.0413C11.3865 7.91699 11.6663 8.19681 11.6663 8.54199C11.6663 8.85841 11.4312 9.1199 11.1262 9.16129L11.0413 9.16699H8.95801C8.61283 9.16699 8.33301 8.88717 8.33301 8.54199C8.33301 8.22558 8.56814 7.96408 8.8732 7.9227L8.95801 7.91699H11.0413H8.95801ZM11.0439 2.91699H8.95596C8.63955 2.91699 8.37805 3.15212 8.33666 3.45718L8.33096 3.54199L8.33051 3.75033H11.6689V3.54199C11.6689 3.22558 11.4337 2.96408 11.1287 2.9227L11.0439 2.91699Z"
                      fill="#D5DBD9"
                    />
                  </svg>
                  <Typography className={classes.listText}>
                    {data?.course_content?.assignment_count
                      ? data?.course_content?.assignment_count
                      : "0"}{" "}
                    Assignments
                  </Typography>
                </Box>
              </>
            ) : (
              ""
            )}
            <Box>
              <Typography className={classes.desText}>
                {parse(`${data?.description}`)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CardTem;
