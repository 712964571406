import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Programs from "./pages/programs";
import NoMatch from "./pages/no-match";
import LandingPage from "./components/Pages/LandingPage";
import AboutPage from "./components/Pages/AboutPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Courses from "./pages/courses";
import Student from "pages/student";
import Instructor from "pages/instructor";
import Admin from "pages/admin";
import { AuthContext } from "./context/authContext";
import AdminRoute from "./helpers/adminRoute";
import StudentRoute from "./helpers/studentRoute";
import TeacherRoute from "./helpers/teacherRoute";
import PersistLogin from "components/PersistLogin";
import PublicRoute from "./helpers/publicRoute";
import CourseContent from "pages/course-content";
import Newsevents from "pages/newsevents";
import TeacherCourses from "pages/teacher-courses";
import TeacherPrograms from "pages/teacher-programs";
import SingleNews from "pages/single-news";
import AcademicProgramsPage from "components/Pages/AcademicProgramsPage";
import FacultyPage from "components/Pages/FacultyPage";
import Signin from "pages/login";
import ForgetPassword from "components/Authentication/ForgetPassword/ForgetPassword";
// import AddNewAdmin from "pages/add-admin";
import LibraryPage from "components/Pages/LibraryPage";
import NewsPage from "components/Pages/NewsPage";
import CampusCulture from "components/Pages/CampusCulture";
import StoriesPage from "components/Pages/StoriesPage";
import AuthResetPassword from "components/Auth/AuthResetPassword";
import StudentProfile from "components/Details/StudentDetails";
import TeacherDetails from "components/Details/InstructorDetails";
import AdminDetails from "components/Details/AdminDetails";
import Library from "pages/library";
import ProgramsDetails from "pages/programs-details";
import StudentStories from "pages/stories";
import Classes from "pages/classes";
import Statistics from "pages/statistics";
import ClassDetails from "pages/classDetails";
import Home from "pages/StudentsPages/Home";
import StudentClasses from "pages/StudentsPages/StudentClasses";
import StudentClassDetails from "pages/StudentsPages/studentClassDetails";
import MyProfile from "pages/StudentsPages/profile";
import ProfileSetting from "pages/StudentsPages/setting";
import TeacherProfile from "pages/TeacherPages.jsx/profile";
import TeacherClasses from "pages/TeacherPages.jsx/TeacherClasses";
import TeacherClassDetails from "pages/TeacherPages.jsx/TeacherClassDetails";
import TeacherHome from "pages/TeacherPages.jsx/Home";
import ResourcesPage from "components/Pages/ResourcesPage";
import PublicationPage from "components/Pages/PublicationPage";
import Publication from "pages/publication";
import Resources from "pages/resources";
import Profile from "pages/adminProfile";
import LibraryDetails from "pages/library-details";
import Chat from "pages/Chat";
import ChatRouteComp from "pages/ChatComponents/ChatRouteComp";
import StudentList from "pages/Attendance/StudentList";
import Attendance from "pages/Attendance/attendance";
import NewsDetails from "components/Layout/NewsPages/NewsDetals";
import StoriesDetailsPage from "components/Layout/Stories/StoriesDetailsPage";

export default function Router() {
  const { isLoggedIn, user } = useContext(AuthContext);

  return (
    <>
      <Routes>
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="reset-password" element={<AuthResetPassword />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="training-programs" element={<AcademicProgramsPage />} />
        <Route path="faculty" element={<FacultyPage />} />
        <Route path="library" element={<LibraryPage />} />
        <Route path="news" element={<NewsPage />} />
        <Route path="campus-culture" element={<CampusCulture />} />
        <Route path="resources" element={<ResourcesPage />} />
        <Route path="publication" element={<PublicationPage />} />
        <Route path="stories" element={<StoriesPage />} />
        <Route path="stories/:id" element={<StoriesDetailsPage />} />
        <Route path="news/:id" element={<NewsDetails />} />
        <Route element={<PersistLogin />}>
          <Route
            path="login"
            element={
              <PublicRoute isLoggedIn={isLoggedIn} user={user}>
                <Signin />
              </PublicRoute>
            }
          />

          <Route index element={<LandingPage />} />
          <Route
            path="dashboard"
            element={
              <AdminRoute isLoggedIn={isLoggedIn} user={user}>
                <Dashboard />
              </AdminRoute>
            }
          >
            <Route path="statistics" element={<Statistics />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="attendance/:id" element={<StudentList />} />
            <Route path="admin" element={<Admin />} />
            <Route path="admin/:id" element={<AdminDetails />} />
            <Route path="programs" element={<Programs />} />
            <Route path="programs/:id" element={<ProgramsDetails />} />
            <Route path="courses" element={<Courses />} />
            <Route path="courses/:id" element={<CourseContent />} />
            <Route path="classes" element={<Classes />} />
            <Route path="classes/:id" element={<ClassDetails />} />
            <Route path="student" element={<Student />} />
            <Route path="student/:id" element={<StudentProfile />} />
            <Route path="instructor" element={<Instructor />} />
            <Route path="instructor/:id" element={<TeacherDetails />} />
            <Route path="newsevents" element={<Newsevents />} />
            <Route path="newsevents/:id" element={<SingleNews />} />
            <Route path="library" element={<Library />} />
            <Route path="library/:id" element={<LibraryDetails />} />
            <Route path="stories" element={<StudentStories />} />
            <Route path="publication" element={<Publication />} />
            <Route path="resources" element={<Resources />} />
            <Route path="inbox" element={<Chat />} />
            <Route path="inbox/:id" element={<ChatRouteComp />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route
            path="student"
            element={
              <StudentRoute isLoggedIn={isLoggedIn} user={user}>
                <Dashboard />
              </StudentRoute>
            }
          >
            <Route path="home" element={<Home />} />
            <Route path="classes" element={<StudentClasses />} />
            <Route path="classes/:id" element={<StudentClassDetails />} />
            <Route path="courses/:id" element={<CourseContent />} />
            <Route path="programs/:id" element={<ProgramsDetails />} />
            <Route path="inbox" element={<Chat />} />
            <Route path="inbox/:id" element={<ChatRouteComp />} />
            <Route path="profile" element={<MyProfile />} />
            <Route path="setting" element={<ProfileSetting />} />
          </Route>
          <Route
            path="teacher"
            element={
              <TeacherRoute isLoggedIn={isLoggedIn} user={user}>
                <Dashboard />
              </TeacherRoute>
            }
          >
            <Route path="home" element={<TeacherHome />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="attendance/:id" element={<StudentList />} />
            <Route path="classes" element={<TeacherClasses />} />
            <Route path="classes/:id" element={<TeacherClassDetails />} />
            <Route path="courses" element={<TeacherCourses />} />
            <Route path="courses/:id" element={<CourseContent />} />
            <Route path="programs" element={<TeacherPrograms />} />
            <Route path="programs/:id" element={<ProgramsDetails />} />
            <Route path="inbox" element={<Chat />} />
            <Route path="inbox/:id" element={<ChatRouteComp />} />
            <Route path="profile" element={<TeacherProfile />} />
          </Route>
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
