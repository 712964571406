import * as React from "react";
import Slider1 from "../../Assets/Images/StoriesSlider/1.jpg";
import Slider2 from "../../Assets/Images/StoriesSlider/2.JPG";
import Slider3 from "../../Assets/Images/StoriesSlider/3.jpg";
import Slider4 from "../../Assets/Images/StoriesSlider/4.JPG";
import Slider5 from "../../Assets/Images/StoriesSlider/5.jpg";
import Slider6 from "../../Assets/Images/StoriesSlider/6.JPG";
import Slider7 from "../../Assets/Images/StoriesSlider/7.jpg";
import { Carousel } from "react-bootstrap";
import { useState } from "react";

export default function StoriesManSec() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider3} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider4} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider5} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider6} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider7} alt="Second slide" />
        </Carousel.Item>
      </Carousel>
    </React.Fragment>
  );
}
