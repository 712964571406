import Image from "react-bootstrap/Image";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../Assets/Images/IRSA";
import "./style.css";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  login_btn: {
    width: "112px",
    height: "48px",
    backgroundColor: "#E24F57 !important",
    color: "#fff !important",
    borderRadius: "4px !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
    marginLeft: "10px !important",
    textAlign: "center",
    padding: "10px",

    "@media (max-width: 992px)": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      margin: "0 !important",
    },
  },
});
function TopNav() {
  const classes = useStyles();
  return (
    <Navbar collapseOnSelect expand="lg" style={{ background: "#fff" }}>
      <Container className="main_Container">
        <Navbar.Brand href="/">
          <Image src={logo} width="100px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="responsive-navmenu"
          style={{ justifyContent: "end" }}
        >
          <Nav className="me-auto nav_links">
            <Link className="navigation-link" to="/about">
              Home
            </Link>
            <Link className="navigation-link" to="/about">
              About
            </Link>
            <Link className="navigation-link" to="/training-programs">
              Training Programs
            </Link>
            <Link className="navigation-link" to="/faculty">
              Faculty
            </Link>
            <Link className="navigation-link" to="/login">
              LMS
            </Link>
            <Link className="navigation-link" to="/campus-culture">
              Campus and Culture
            </Link>
            <Link className="navigation-link" to="/library">
              Library
            </Link>
            <Link className="navigation-link" to="/news">
              News and Events
            </Link>
            <Link className="navigation-link" to="/stories">
              Stories
            </Link>
            <Link className={classes.login_btn} to="/login">
              Login
            </Link>
          </Nav>
          <Nav className="responsive-navSearch">
            <input
              type="text"
              className="search-click mobile-screen"
              name=""
              placeholder="search here..."
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
      {/* <div className="search_Con_open">
        <input
          type="text"
          className="search-open full-screen"
          name=""
          placeholder="search here..."
        />
      </div> */}
    </Navbar>
  );
}

export default TopNav;
