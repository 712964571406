import Loading from "components/atoms/Loading";
import React from "react";
import { Container } from "react-bootstrap";
import "./LAtCampus.css";

const LAtCampus = ({ loading, details, onClick }) => {
  return (
    <>
      <div
        style={{
          margin: "50px 120px",
          background: "#E7FFFB",
          padding: "20px",
          borderRadius: "15px",
        }}
      >
        <div className="imagesBox">
          {loading ? (
            <div style={{ width: "100%" }}>
              <Loading />
            </div>
          ) : (
            details?.all_images?.map((imgData, i) => (
              <>
                <div className="mx-2 my-3">
                  <img
                    className="galleryImage"
                    key={i}
                    src={
                      imgData?.image?.startsWith("/")
                        ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${imgData?.image}`
                        : imgData?.image
                    }
                    alt="img"
                    width={200}
                    height={150}
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    onClick={() => onClick(i)}
                  />
                </div>
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default LAtCampus;
