import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setUser } = useAuth();

  const logout = async () => {
    setUser({});
    try {
      await axios.post(`auth/logout`, {
        refresh: localStorage.getItem("refresh"),
      });
      localStorage.removeItem("route");
    } catch (err) {
      localStorage.removeItem("route");
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
