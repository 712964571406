import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import "../../../news-details.css";
import { format } from "date-fns";
import { getDateObject } from "helpers/common";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../../pages/css/style.css";
import img1 from "../../Assets/Images/culture.jpg";
import BackToTop from "components/atoms/BackToTop/BackToTop";
import Loading from "components/atoms/Loading";
import NewsService from "services/news.service";
import { useMemo } from "react";
import makeAxiosRequest from "helpers/makeAxiosRequest";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "left",
  },
  backBtn: {
    cursor: "pointer",
    color: "#252827 !important",
  },

  details: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "var(--link-text-color) !important",
    margin: "10px 0",
    letterSpacing: "1px",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  playIcon: { fontSize: "30px", color: "#000", cursor: "pointer" },
  videoTitle: {
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#000 !important",
    margin: "10px 0",
    letterSpacing: "1px",
  },
  listUl: {
    background: "#fff",
    margin: "10px 0",
    borderRadius: "4px",
  },
  tabBtn: {
    width: "140px",
    height: "40px",
    border: "0.75px solid var(--icons-color)",
    color: "var(--icons-color)",
    borderRadius: "4px",
    margin: "0 10px",
    "& .Mui-selected": {
      color: "#000 !important",
    },
  },
  fab: {
    background: "var(--admin)",
    color: "#fff",
    position: "fixed",
    bottom: 40,
    right: 40,
  },
  iconBtn: {
    color: "var(--link-text-color)",
    width: "fit-content",
    margin: "0 5px",
    cursor: "pointer",
  },
});

export default function NewsDetails() {
  const { id } = useParams();
  let navigate = useNavigate();
  const classes = useStyles();
  const [newsLoading, setNewsLoading] = useState("");
  const [details, setDetails] = useState("");
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({
    filterStatus: [],
  });
  const { filterStatus } = filter;
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, news_data } = NewsService.GetNewsLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    reload,
  });

  const { totalItems, news } = useMemo(
    () => ({
      news: news_data?.news,
      totalItems: news_data.totalItems,
    }),
    [news_data]
  );
  useEffect(() => {
    async function getNewsDetails() {
      setNewsLoading(true);
      try {
        const response = await makeAxiosRequest(
          "GET",
          `${process.env.REACT_APP_IRSA_BACKEND_API}/news/p/getall/${id}`
        );
        setDetails(response);
        setNewsLoading(false);
      } catch (err) {
        if (err?.response) {
        } else if (err?.request) {
          console.log(err?.request);
        } else {
          console.log(err?.message);
        }
      }
    }
    getNewsDetails();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickOpenDetail = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <>
      <Box>
        <Box className={classes.btnBox}>
          <ArrowBackIcon
            className={classes.backBtn}
            onClick={() => navigate(-1)}
          />
        </Box>
        {!localStorage.getItem("route") ? (
          <div>
            <div className="topNav">
              <div className="col-lg-9">
                <nav className="navbar navbar-expand-sm bg-dark p-0">
                  <ul className="navbar-nav ml-n2 ">
                    <li className="nav-item border-right border-secondary">
                      <p className="nav-link linkColor small m-0">
                        {format(
                          getDateObject(details?.created_at),
                          "dd/MM/yyyy"
                        )}
                      </p>
                    </li>
                    <li className="nav-item border-right">
                      <Link className="nav-link linkColor small" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link linkColor small" to="/login">
                        Login
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-3 text-right d-none d-md-block">
                <nav
                  className="navbar navbar-expand-sm bg-dark p-0"
                  style={{ justifyContent: "end" }}
                >
                  <ul className="navbar-nav ml-auto mr-n2">
                    <li className="nav-item">
                      <a
                        className="nav-link linkColor"
                        href="https://www.facebook.com/irsacademy.pk"
                      >
                        <small className="fab fa-facebook-f"></small>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link linkColor"
                        href="https://www.youtube.com/channel/UCLmZtzhwr2QGQTlQbPZBlKA"
                      >
                        <small className="fab fa-youtube"></small>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="mt-5 pt-3">
          {newsLoading ? (
            <Loading />
          ) : (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="position-relative mb-3">
                      <img
                        className="img-fluid w-100"
                        src={
                          details?.image?.startsWith("/")
                            ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${details?.image}`
                            : details?.image ?? img1
                        }
                        style={{ objectFit: "cover" }}
                        alt="img"
                      />
                      <div className="bg-white border border-top-0 p-4">
                        <div className="mb-3 d-flex">
                          {/* <p
                            className="badge badge-primary text-uppercase p-2 mr-2"
                            style={{ marginRight: "10px" }}
                          >
                            Business
                          </p> */}
                          <p
                            className="linkColor"
                            style={{ width: "fit-content", margin: "0" }}
                          >
                            {format(
                              getDateObject(details?.created_at),
                              "dd/MM/yyyy"
                            )}
                          </p>
                        </div>
                        <h1
                          className="mb-3 text-uppercase"
                          style={{
                            fontWeight: "700",
                            color: "#31404B",
                            display: "-webkit-box",
                            lineClamp: "2",
                            boxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {details?.title}
                        </h1>
                        <p>{parse(`${details?.description}`)}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="mb-3">
                      <div className="section-title mb-0">
                        <h4
                          className="m-0 text-uppercase"
                          style={{ fontWeight: "700" }}
                        >
                          Follow Us
                        </h4>
                      </div>
                      <div className="bg-white border border-top-0 p-3 d-flex">
                        <a
                          href="https://www.facebook.com/irsacademy.pk"
                          className="d-block text-white text-decoration-none m-1"
                          style={{
                            background: "#39569e",
                            width: "fit-content",
                          }}
                        >
                          <i
                            className="fab fa-facebook-f text-center py-4 mr-3"
                            style={{
                              width: "65px",
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          ></i>
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCLmZtzhwr2QGQTlQbPZBlKA"
                          className="d-block text-white text-decoration-none m-1"
                          style={{
                            background: "#dc472e",
                            width: "fit-content",
                          }}
                        >
                          <i
                            className="fab fa-youtube text-center py-4 mr-3"
                            style={{
                              width: "65px",
                              background: "rgba(0, 0, 0, 0.2)",
                            }}
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div className="mb-3 mobileViewCards">
                      <div className="section-title mb-0">
                        <h4
                          className="m-0 text-uppercase"
                          style={{ fontWeight: "700" }}
                        >
                          Other News
                        </h4>
                      </div>
                      <div className="bg-white border border-top-0 p-3">
                        {news?.map((newsData, i) => (
                          <div
                            key={newsData?.id}
                            className="d-flex align-items-center bg-white mb-3"
                            style={{ height: "110px", cursor: "pointer" }}
                          >
                            <img
                              className=""
                              src={newsData?.image}
                              alt=""
                              style={{
                                width: "120px",
                                height: " 100%",
                                objectFit: "cover",
                              }}
                            />
                            <div className="w-100 h-100 p-3 d-flex flex-column justify-content-center border border-left-0">
                              <div className="mb-2 d-flex">
                                {/* <p
                                  className="badge badge-primary text-uppercase p-1 mb-0"
                                  style={{
                                    marginRight: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  Business
                                </p> */}
                                <p
                                  className="linkColor"
                                  style={{
                                    width: "fit-content",
                                    margin: "0",
                                  }}
                                >
                                  <small>
                                    {format(
                                      getDateObject(newsData?.created_at),
                                      "dd/MM/yyyy"
                                    )}
                                  </small>
                                </p>
                              </div>
                              <p
                                className="h6 m-0 text-secondary text-uppercase font-weight-bold otherNewsTitle"
                                style={{
                                  display: "-webkit-box",
                                  lineClamp: "2",
                                  boxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                onClick={() =>
                                  handleClickOpenDetail(newsData?.id)
                                }
                              >
                                {newsData?.title}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="section-title mb-0">
                        <h4 className="m-0 text-uppercase font-weight-bold">
                          Tags
                        </h4>
                      </div>
                      <div className="bg-white border border-top-0 p-3">
                        <div className="d-flex flex-wrap m-n1">
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Politics
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Business
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Corporate
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Business
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Health
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Education
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Science
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Business
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Foods
                          </p>
                          <p className="btn btn-sm btn-outline-secondary m-1">
                            Travel
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <BackToTop />
      </Box>
    </>
  );
}
