import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS } from "./event-utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import useAxiosPrivate from "hooks/useAxiosPrivate";

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  backBtn: {
    cursor: "pointer",
    color: "#252827 !important",
  },
});

const AttendanceCalender = ({
  myFunc,
  classId,
  setStateToOpenList,
  setStateToOpenClassPage,
  handleEditState,
}) => {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);

  console.log("currentEvents", currentEvents);

  const handleDateSelect = (selectInfo) => {
    let dataType = currentEvents.find((m) => m.start === selectInfo?.startStr);
    myFunc(selectInfo?.start);
    setStateToOpenList();
    handleEditState(dataType ? true : false);
  };

  React.useEffect(() => {
    async function getTeacherDetails() {
      try {
        const { data } = await axiosPrivate.get(`attendance/stats/${classId}`);
        setCurrentEvents(data);
      } catch (err) {
        console.log(
          "_____________*",
          err?.response?.data?.message ?? err?.message
        );
      }
    }
    getTeacherDetails();
  }, []);

  return (
    <>
      <Box className={classes.btnBox}>
        <ArrowBackIcon
          className={classes.backBtn}
          onClick={setStateToOpenClassPage}
        />
      </Box>
      <div className="demo-app">
        <div className="demo-app-main">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            events={currentEvents}
            // eventClick={handleEventClick}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
        </div>
      </div>
    </>
  );
};

export default AttendanceCalender;
