import * as React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { debounce } from "lodash";
import { Typography } from "@mui/material";

export default function SelectAsync({
  options,
  label,
  async,
  error,
  errorClass,
  ...props
}) {
  const debouncedRef = React.useRef(0);
  const loadOptions = async (__) => {
    const _options = await new Promise((resolve) => {
      debounce((value) => {
        debouncedRef.current += 1;
        const LocalRef = debouncedRef.current;
        setTimeout(() => {
          if (LocalRef === debouncedRef.current) {
            props.loadOptions(value).then((response) => {
              resolve(response);
            });
          }
        }, 300);
      }, 300)(__);
    });
    return _options;
  };
  return (
    <>
      {label && label}
      {async ? (
        <AsyncSelect
          {...props}
          async={async}
          options={options}
          loadOptions={loadOptions}
          onChange={(value) => {
            props?.onChange?.(value);
          }}
        />
      ) : (
        <Select
          {...props}
          options={options}
          onChange={(value) => {
            props?.onChange?.(value);
          }}
        />
      )}
      {error && <Typography className={errorClass}>{error}</Typography>}
    </>
  );
}
