import LoadingBtn from "components/atoms/LoadingBtn";
import React, { useState } from "react";
import { Form, Container } from "react-bootstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./forms.css";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, Button, MenuItem, Select } from "@mui/material";
import { contentTypeOption } from "docs/data";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldDes: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.descriptionErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "fit-content",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  select: {
    border: "none",
    backgroundColor: "var(--input-background-color)",
    padding: "0",
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const ContentByTeacher = ({ handleClose, id, toast, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    titleError: "",
    typeError: "",
    urlError: "",
    fileError: "",
  });
  const [file, setFile] = useState("");
  const { titleError, typeError, urlError, fileError } = validationErrors;

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleError: "Please provide title",
      }));
    }
    if (!type) {
      setValidationErrors((prev) => ({
        ...prev,
        typeError: "Please select Type",
      }));
    }
    if (type === "VIDEO" && !url) {
      setValidationErrors((prev) => ({
        ...prev,
        urlError: "Please provide url",
      }));
    }
    if (type === "ASSIGNMENT" && !file) {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "Please provide file",
      }));
    }
    if (type === "DOCUMENT" && !file) {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "Please provide file",
      }));
    }
    if (type === "VIDEO" && !url) {
      return false;
    }

    if (type === "ASSIGNMENT" && !file) {
      return false;
    }
    if (type === "DOCUMENT" && !file) {
      return false;
    }
    if (!type || !title) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("type", type);
        bodyFormData.append("url", url);
        bodyFormData.append("file", file);
        bodyFormData.append("course", id);
        const res = await axiosPrivate({
          method: "post",
          url: "course_content/tchr/create",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        console.log({ err: err.message });
        toast.error(err.message);
        setLoading(false);
        if (err?.response) {
        } else if (err?.request) {
          toast.error(err.request);
        } else {
          toast.error(err.message);
        }
      }
    } else {
      console.log("error");
    }
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files[0].type !== "application/pdf") {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "Only pdf files are allowed",
      }));
      setFile("");
      return;
    }
    if (target.files < 1 || !target.validity.valid) {
      return;
    }

    setValidationErrors((prev) => ({
      ...prev,
      fileError: "",
    }));
    setFile(target.files[0]);
  };

  return (
    <>
      <Container>
        <Form>
          <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
            <Form.Label className={classes.label}>Title</Form.Label>
            <div
              className={
                titleError
                  ? `${classes.inputFieldErr}`
                  : `${classes.inputField}`
              }
            >
              <InputBase
                className={classes.input}
                type="text"
                placeholder="Enter title"
                onChange={(e) => {
                  setTitle(e.target.value);
                  setValidationErrors((prev) => ({ ...prev, titleError: "" }));
                }}
              />
            </div>
            {titleError ? (
              <Typography className={classes.errorMsg}>{titleError}</Typography>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
            <Form.Label className={classes.label}>Type</Form.Label>
            <Select
              className={
                typeError ? `${classes.inputFieldErr}` : `${classes.inputField}`
              }
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                setValidationErrors((prev) => ({ ...prev, typeError: "" }));
              }}
            >
              <MenuItem value="VIDEO">Video</MenuItem>
              <MenuItem value="ASSIGNMENT">Assignment</MenuItem>
              <MenuItem value="DOCUMENT">Document</MenuItem>
            </Select>
            {typeError ? (
              <Typography className={classes.errorMsg}>{typeError}</Typography>
            ) : (
              ""
            )}
          </Form.Group>
          {type === "VIDEO" && (
            <Form.Group
              controlId="formBasicTitle"
              className={classes.formGroup}
            >
              <Form.Label className={classes.label}>Url</Form.Label>
              <div
                className={
                  urlError
                    ? `${classes.inputFieldErr}`
                    : `${classes.inputField}`
                }
              >
                <InputBase
                  className={classes.input}
                  type="text"
                  placeholder="Enter Url"
                  onChange={(e) => {
                    setUrl(e.target.value);
                    setValidationErrors((prev) => ({ ...prev, urlError: "" }));
                  }}
                />
              </div>
              {urlError ? (
                <Typography className={classes.errorMsg}>{urlError}</Typography>
              ) : (
                ""
              )}
            </Form.Group>
          )}
          {(type === "ASSIGNMENT" || type === "DOCUMENT") && (
            <Form.Group
              className={classes.formGroup}
              controlId="formBasicTitle"
            >
              <Form.Label className={classes.label}>File(pdf)</Form.Label>
              <div
                className={
                  fileError
                    ? `${classes.inputFieldErr}`
                    : `${classes.inputField}`
                }
              >
                <InputBase
                  className={classes.input}
                  type="file"
                  placeholder="file"
                  onChange={onUploadFileChange}
                />
              </div>
              {fileError ? (
                <Typography className={classes.errorMsg}>
                  {fileError}
                </Typography>
              ) : (
                ""
              )}
            </Form.Group>
          )}
          <Form.Group className={classes.saveBtn}>
            {loading ? (
              <LoadingBtn />
            ) : (
              <Button className={classes.addBtn} onClick={handleSubmit}>
                Add
              </Button>
            )}
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};

export default ContentByTeacher;
