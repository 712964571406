import Loading from "components/atoms/Loading";
import { useMemo, useState } from "react";
import ProgramService from "services/programs.service";
import { getDateObject } from "../helpers/common";
import { format } from "date-fns";
import Table from "components/atoms/Table";
import TablePagination from "components/atoms/Pagination";

export default function TeacherPrograms() {
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 6,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize } = searchQuery;
  const { loading, programs_data } = ProgramService.GetProgramsByTeacher(
    page,
    pageSize
  );
  const columnNames = ["Date", "Title", "Description"];
  const { totalItems, programs } = useMemo(
    () => ({
      programs: programs_data?.programs?.map((n) => [
        format(getDateObject(n.createdAt), "MM/dd/yyyy"),
        n?.title,
        n?.description,
      ]),
      totalItems: programs_data.totalItems,
    }),
    [programs_data]
  );
  return (
    <>
      {loading === true ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <>
          <Table columns={columnNames} rows={programs} />
          <div className="my-3">
            <TablePagination
              count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
              page={page}
              onChange={(e, v) =>
                setSearchQuery((prev) => ({ ...prev, page: v }))
              }
            />
          </div>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
    </>
  );
}
