import React, { useMemo, useState } from "react";
import ProgramService from "../services/programs.service";
import { ToastContainer, toast } from "react-toastify";
import TablePagination from "components/atoms/Pagination";
import EditProgram from "components/Forms/EditProgram";
import Loading from "components/atoms/Loading";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Box, Grid } from "@mui/material";
import CardTem from "components/atoms/Card";
import { useNavigate } from "react-router-dom";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import AddProgram from "components/Forms/AddProgram";
import EditorModel from "components/atoms/EditorModel";
import BtnBox from "components/atoms/BtnBox";

export default function Programs() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 9,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });

  const { page, pageSize } = searchQuery;
  const { loading, programs_data } = ProgramService.GetPrograms({
    page,
    pageSize,
    reload,
  });

  const { totalItems, programs } = useMemo(
    () => ({
      programs: programs_data?.programs,
      totalItems: programs_data.totalItems,
    }),
    [programs_data]
  );

  const deleteProgram = async (id, handleCloseDelete) => {
    try {
      const { data } = await axiosPrivate.delete(`programs/${id}`);
      toast.success(data);
      handleCloseDelete();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response) {
      } else if (err?.request) {
        toast.error(err.request);
      } else {
        toast.error(err.message);
      }
    }
  };

  const reversPrograms = [...programs].reverse();

  // add program model

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = (isReload) => {
    setOpenAdd(false);
    // isReload && setReload(() => !reload);
  };
  // end add program model

  // edit program model

  const handleClickOpen = ({ programData }) => {
    setData(programData);
    setOpen(true);
  };

  const handleClose = (isReload) => {
    setOpen(false);
    // isReload && setReload(() => !reload);
  };
  // end edit program model

  // program details model

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/programs/${id}`);
  };
  // end program details model

  // delete program model

  const handleClickOpenDelete = ({ programData }) => {
    setData(programData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete program model

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Box sx={{ position: "relative", height: "100%" }}>
        <BtnBox
          handleClickOpen={handleOpenAdd}
          title={"Published Programs"}
          subtitle={"You Have Published Following Programs"}
          btnText={"Add Program"}
        />
        {/* delete program model */}

        <DeleteConfirmModel
          openDelete={openDelete}
          handleClose={handleCloseDelete}
          onClick={() => deleteProgram(data?.id, handleCloseDelete)}
        />
        {/* end delete program model */}

        {/* add program model */}

        <EditorModel
          openModel={openAdd}
          handleClose={handleCloseAdd}
          bodyContent={
            <AddProgram
              handleClose={handleCloseAdd}
              toast={toast}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Published New Program"
        />
        {/* add edit program model */}

        {/* edit program model */}

        <EditorModel
          openModel={open}
          handleClose={handleClose}
          bodyContent={
            <EditProgram
              handleClose={handleClose}
              toast={toast}
              program={data}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Edit Program"
        />
        {/* end edit program model */}

        {loading === true ? (
          <div>
            <Loading />
          </div>
        ) : totalItems > 0 ? (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              >
                {reversPrograms?.map((programData, i) => (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                      <CardTem
                        data={programData}
                        onClick={() => handleClickOpen({ programData })}
                        handleClickOpenDelete={() =>
                          handleClickOpenDelete({ programData })
                        }
                        handleClickOpenDetail={() =>
                          handleClickOpenDetail(programData?.id)
                        }
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <h2 className="text-center">No Record found</h2>
        )}
        {totalItems > 0 && (
          <div
            className="my-3"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TablePagination
              count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
              page={page}
              onChange={(e, v) =>
                setSearchQuery((prev) => ({ ...prev, page: v }))
              }
            />
          </div>
        )}
      </Box>
    </>
  );
}
