import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const ProgramService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetPrograms({ page, pageSize, reload, searchText }) {
    const axiosPrivate = useAxiosPrivate();

    const [programs, setPrograms] = useState({
      programs: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getPrograms({ page, pageSize, axiosPrivate, searchText })
      )
        .then((res) => {
          setPrograms(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, reload, searchText]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      programs_data: programs,
    };
  },
  GetProgramsLandingPage({ page, pageSize, reload }) {
    const [programs, setPrograms] = useState({
      programs: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getProgramsLandingPage({ page, pageSize }))
        .then((res) => {
          setPrograms(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      programs_data: programs,
    };
  },
  async getPrograms({ page, pageSize, axiosPrivate, searchText }) {
    try {
      const { data } = await axiosPrivate.get(
        `${this._url}/programs?page=${page}&page_size=${pageSize}`
      );

      return { programs: data?.results, totalItems: data?.count };
    } catch (err) {
      if (err.response) {
        /* 
          The request was made and the server responded with a status code
          that falls out of the range of 2xx
        */
        throw err.response.data;
      } else if (err.request) {
        // Client never received a response, or request never left
        throw err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Error: ${err.message}`);
      }
    }
  },
  async getProgramsLandingPage({ page, pageSize }) {
    try {
      const response = await makeAxiosRequest(
        "GET",
        `${this._url}/programs/p/getall/?page=${page}&page_size=${pageSize}`
      );
      return { programs: response?.results, totalItems: response?.count };
    } catch (err) {
      throw err;
    }
  },
  async addProgram(payload) {
    let response = await makeAxiosRequest(
      "POST",
      `${this._url}/programs`,
      payload
    );
    if (response?.error) {
      throw Error(response.message ?? "Something went wrong");
    }

    return response;
  },

  async searchPrograms({ page, pageSize, searchText, axiosPrivate }) {
    try {
      const response = await axiosPrivate.get(
        `${this._url}/programs?page=${page}&page_size=${pageSize}&searchText=${searchText}`
      );

      return {
        programs: response?.data?.results,
        totalItems: response?.data?.count,
      };
    } catch (err) {
      throw new Error(err?.response?.data?.message ?? err?.message);
    }
  },

  // get programs by teacher

  GetProgramsByTeacher(page, pageSize, reload) {
    const axiosPrivate = useAxiosPrivate();

    const [programs, setPrograms] = useState({
      programs: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getProgramsByTeacher(page, pageSize, axiosPrivate)
      )
        .then((res) => {
          setPrograms(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      programs_data: programs,
    };
  },
  async getProgramsByTeacher(page, pageSize, axiosPrivate) {
    try {
      const { data } = await axiosPrivate.get(
        `programs/tchr/getAll?page=${page}&itemsPerPage=${pageSize}`
      );

      return { programs: data, totalItems: data?.length };
    } catch (err) {
      if (err.response) {
        /* 
          The request was made and the server responded with a status code
          that falls out of the range of 2xx
        */
        throw err.response.data;
      } else if (err.request) {
        // Client never received a response, or request never left
        throw err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Error: ${err.message}`);
      }
    }
  },
};

export default ProgramService;
