import { Button, InputBase, Typography } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./forms.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.firstNameErr &&
      props.lastNameErr &&
      props.emailErr &&
      props.passwordErr &&
      props.confirmPasswordErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const AddAdmin = ({ handleClose, toast, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    cnic: "",
    image: null,
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstNameErr: "",
    lastNameErr: "",
    emailErr: "",
    passwordErr: "",
    confirmPasswordErr: "",
    phoneNumberErr: "",
    cnicErr: "",
    imageErr: "",
  });

  const [loading, setLoading] = useState(false);

  const { firstName, lastName, email, phoneNumber, cnic, image } = state;

  const {
    firstNameErr,
    lastNameErr,
    emailErr,
    passwordErr,
    confirmPasswordErr,
    phoneNumberErr,
    cnicErr,
    imageErr,
  } = errors;

  const classes = useStyles({
    firstNameErr,
    lastNameErr,
    emailErr,
    passwordErr,
    confirmPasswordErr,
  });
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [cropImage, setCropImage] = useState(null);

  console.log(";;;;", JSON.stringify(image));

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
    setState((prev) => ({
      ...prev,
      image: file,
    }));
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = cropImage.naturalWidth / cropImage.width;
    const scaleY = cropImage.naturalHeight / cropImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      cropImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setState((prev) => ({
      ...prev,
      image: base64Image,
    }));
  };

  const validate = () => {
    if (!firstName) {
      setErrors((prev) => ({
        ...prev,
        firstNameErr: "Please provide first name",
      }));
    }
    if (!lastName) {
      setErrors((prev) => ({
        ...prev,
        lastNameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!phoneNumber) {
      setErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Please provide phone number",
      }));
    }
    if (phoneNumber.length < 11 || phoneNumber.length > 11) {
      setErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Phone Number must be atleast 11 characters ",
      }));
    }
    if (!cnic) {
      setErrors((prev) => ({
        ...prev,
        cnicErr: "Please provide cnic",
      }));
    }
    if (cnic.length < 13 || cnic.length > 13) {
      setErrors((prev) => ({
        ...prev,
        cnicErr: "CNIC must be atleast 13 characters ",
      }));
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      phoneNumber.length < 11 ||
      phoneNumber.length > 11 ||
      cnic.length < 13 ||
      cnic.length > 13 ||
      !cnic
    ) {
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", firstName);
        bodyFormData.append("lastname", lastName);
        bodyFormData.append("email", email);
        bodyFormData.append("phone_number", phoneNumber);
        bodyFormData.append("cnic", cnic);
        bodyFormData.append("image", JSON.stringify(image));
        const res = await axiosPrivate({
          method: "post",
          url: "admin_auth/register",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.code === 400) {
          toast.error("Validation error occurred");
        } else {
          toast.error(err?.response?.data?.message ?? err?.message);
        }
      }
    }
  };

  const handleChangePhone = (e) => {
    const limit = 11;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState((prev) => ({
        ...prev,
        phoneNumber: e.target.value.slice(0, limit),
      }));
      setErrors((prev) => ({
        ...prev,
        phoneNumberErr: "",
      }));
    }
  };
  const handleChangeCNIC = (e) => {
    const limit = 13;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState((prev) => ({
        ...prev,
        cnic: e.target.value.slice(0, limit),
      }));
      setErrors((prev) => ({
        ...prev,
        cnicErr: "",
      }));
    }
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    fileToBase64(file, (err, result) => {
      if (result) {
        setState((prev) => ({
          ...prev,
          image: result,
        }));
        setErrors((prev) => ({
          ...prev,
          imageErr: "",
        }));
      }
    });
    setSrc(URL.createObjectURL(file));
    setErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
  };

  // const onUploadFileChange = (e) => {
  //   if (e.target.files.length === 0 || !e.target.validity.valid) {
  //     return false;
  //   }
  //   const file = e.target.files[0];
  //   if (!/^image\//.test(file.type)) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       imageErr: `File ${file.name} is not an image.`,
  //     }));
  //     return false;
  //   }
  //   if (file.size > 1000000) {
  //     fileToBase64(e.target.files[0], (err, result) => {
  //       if (result) {
  //         setState((prev) => ({
  //           ...prev,
  //           image: result,
  //         }));
  //       }
  //     });
  //     setErrors((prev) => ({
  //       ...prev,
  //       imageErr: `Maximum file size 1MB`,
  //     }));
  //     return false;
  //   }
  //   setErrors((prev) => ({
  //     ...prev,
  //     imageErr: "",
  //   }));
  // };

  return (
    <>
      <Form>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>First Name</Form.Label>
          <div
            className={
              firstNameErr
                ? `${classes.inputFieldErr}`
                : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="text"
              name="firstName"
              value={firstName}
              placeholder="Enter first name"
              fullWidth
              onChange={(e) => {
                if (
                  !/^[a-z A-Z ]*$/.test(e.target.value) ||
                  e.target.value.length < 3
                ) {
                  setErrors((prev) => ({
                    ...prev,
                    firstNameErr: !e.target.value
                      ? "First name can't be empty"
                      : e.target.value.length < 3
                      ? "First name must be Minimum 2 characters long"
                      : "Only alphabets are allowed",
                  }));
                  setState((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }));
                } else {
                  setState((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }));
                  setErrors((prev) => ({
                    ...prev,
                    firstNameErr: "",
                  }));
                }
              }}
            />
          </div>
          {firstNameErr ? (
            <Typography className={classes.errorMsg}>{firstNameErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>Last Name</Form.Label>
          <div
            className={
              lastNameErr ? `${classes.inputFieldErr}` : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="text"
              name="lastName"
              value={lastName}
              placeholder="Enter last name"
              fullWidth
              onChange={(e) => {
                if (
                  !/^[a-z A-Z ]*$/.test(e.target.value) ||
                  e.target.value.length < 3
                ) {
                  setErrors((prev) => ({
                    ...prev,
                    lastNameErr: !e.target.value
                      ? "Last name can't be empty"
                      : e.target.value.length < 3
                      ? "Last name must be Minimum 2 characters long"
                      : "Only alphabets are allowed",
                  }));
                  setState((prev) => ({ ...prev, lastName: e.target.value }));
                } else {
                  setState((prev) => ({ ...prev, lastName: e.target.value }));
                  setErrors((prev) => ({
                    ...prev,
                    lastNameErr: "",
                  }));
                }
              }}
            />
          </div>
          {lastNameErr ? (
            <Typography className={classes.errorMsg}>{lastNameErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>Email</Form.Label>
          <div
            className={
              emailErr ? `${classes.inputFieldErr}` : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="email"
              name="email"
              value={email}
              placeholder="example@gmail.com"
              fullWidth
              onChange={(e) => {
                if (
                  !/^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(
                    e.target.value
                  )
                ) {
                  setErrors((prev) => ({
                    ...prev,
                    emailErr: e.target.value
                      ? "Invalid email address"
                      : "Email can't be empty",
                  }));
                  setState((prev) => ({ ...prev, email: e.target.value }));
                } else {
                  setState((prev) => ({ ...prev, email: e.target.value }));
                  setErrors((prev) => ({ ...prev, emailErr: "" }));
                }
              }}
            />
          </div>
          {emailErr ? (
            <Typography className={classes.errorMsg}>{emailErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>Phone Number</Form.Label>
          <div
            className={
              phoneNumberErr
                ? `${classes.inputFieldErr}`
                : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={handleChangePhone}
            />
          </div>
          {phoneNumberErr ? (
            <Typography className={classes.errorMsg}>
              {phoneNumberErr}
            </Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>CNIC</Form.Label>
          <div
            className={
              cnicErr ? `${classes.inputFieldErr}` : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="text"
              placeholder="Enter cnic"
              value={cnic}
              onChange={handleChangeCNIC}
            />
          </div>
          {cnicErr ? (
            <Typography className={classes.errorMsg}>{cnicErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>Image</Form.Label>
          <div
            className={
              imageErr ? `${classes.inputFieldErr}` : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="file"
              placeholder="Enter image"
              accept="image/*"
              onChange={(e) => onUploadFileChange(e)}
            />
          </div>
          {imageErr ? (
            <Typography className={classes.errorMsg}>{imageErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className={classes.saveBtn}>
          {loading ? (
            <LoadingBtn />
          ) : (
            <Button className={classes.addBtn} onClick={onSubmit}>
              Add
            </Button>
          )}
        </Form.Group>
      </Form>
      {src && (
        <div>
          <ReactCrop
            src={src}
            onImageLoaded={setCropImage}
            crop={crop}
            onChange={setCrop}
          />
          <br />
          <button className={classes.addBtn} onClick={cropImageNow}>
            Crop
          </button>
          <br />
          <br />
        </div>
      )}
      {image && <img style={{ width: "500px" }} src={image} alt="CropImg" />}
    </>
  );
};

export default AddAdmin;
