import { Box, Typography } from "@mui/material";
import React from "react";

const StoriesDetail = () => {
  return (
    <>
      <Box sx={{ width: "100%", background: "#E7FFFB", padding: "50px" }}>
        {/* <Typography
          variant="h4"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: " 120%",
            textTransform: "capitalize",
            color: "var(--primary)",
            margin: "10px 0",
          }}
        >
          About Us
        </Typography> */}
        <Box>
          <Typography
            sx={{
              margin: "0",
              textAlign: "justify",
            }}
          >
            Inland Revenue Service Academy (IRSA) has a multidimensional role of
            imparting skills and knowledge to various participants round the
            clock in a year. It is a multiplex of teaching, socialization, and
            vibrant cultural activities necessary to foment the zeal of
            participants hailing from all nooks and corners of the county. It
            prides itself on providing top-notch training and experience-based
            learning to various flagship courses. It is responsible for
            conducting STP, DST, and IST. It has a multipurpose campus that
            contains hostel, transport, auditorium, conference rooms, gymnasium,
            and messing facilities.
            <br /> <br />
            Training facilities are augmented by drawing the best officers from
            field formations to apprise the trainee officers regarding the
            latest tax laws and the revenue generation efforts of Federal Board
            of Revenue. The STP program undergoes a robust training session and
            contains a mix of extra-curricular activities which range from field
            visits, excursion trips, mess nights and welcome visits of officers
            in the promotion zone and young probationary officers of CTP. During
            the last year, an excursion trip was undertaken to Kartarpur to
            visit the holy place. Later, during the same visit an export
            facility was visited to apprise the probationary officers about the
            potential for commerce and business. A Country Study Trip to
            Bahawalpur District was planned which gave trainee officers the
            opportunity to learn about the unique culture and economy of the
            place. <br />
            <br />
            The DST chapter is responsible for the training of senior Deputy
            Commissioners who are in the promotion zone for a one-month course
            mandated by NIMS institute. Review and analysis of presentations,
            case studies, and lecture participation are one of the key features
            of the DST course since it prepares the attendees for their next
            assignment as additional commissioner. Resource persons are
            meticulously handpicked to provide an in-depth experience of the
            latest trends and pitfalls in taxation issues.
            <br /> IRSA has a goal to provide unflinching support to field
            formations to boost the capacity development of support staff posted
            therein. The IST chapter looks after the training and refresher
            courses of staff to impart the latest knowledge on the practice of
            tax laws. During the last year of 2022, 8 modules for newly
            recruited Inspectors of Inland Revenue, and 5 modules for support
            staff including IROs/IRAOs, AD-MIS were conducted.
            <br />
            <br /> The campus facilities are used for multiple purposes which
            include hosting training programs for international organization. A
            three-day workshop was organized by REMIT under the DFID (UKaid) at
            the premises of the academy for the purpose of empowerment of woman
            across different cross sections of the society. Similarly, another
            training activity was organized by the HMRC-UK under the aegis of
            OECD for imparting training on Criminal Investigation at the
            premises of the academy and the participants included officers from
            Intelligence and Investigation wing of FBR.
            <br /> <br />
            Mess nights have remained a regular feature of the welcome tradition
            of the academy to encourage the participation of officers nominated
            for MCMC promotional course and probationary officers of 50th CTP
            for inter-cultural mingling and participation. In this regard, the
            probationary officers of 49th STP collaborated with the MCMC
            participants to arrange a ghazal night, a welcome dinner for the
            50th CTP. On one such occasion, Member (IT) was invited to the
            academy as guest of honour for the laptop and Ipad distribution
            ceremony.
          </Typography>
          <Box></Box>
        </Box>
      </Box>
    </>
  );
};

export default StoriesDetail;
