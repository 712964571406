import BackToTop from "components/atoms/BackToTop/BackToTop";
import FacultyMainSec from "components/Layout/Faculty/FacultyMainSec";
import FacultyMembers from "components/Layout/Faculty/FacultyMembers";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect, useMemo, useState } from "react";
import ProgramService from "services/programs.service";

const FacultyPage = () => {
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: "",
  });

  const { page, pageSize } = searchQuery;
  const { programs_data } = ProgramService.GetProgramsLandingPage({
    page,
    pageSize,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { totalItems, programs } = useMemo(
    () => ({
      programs: programs_data?.programs,
      totalItems: programs_data.totalItems,
    }),
    [programs_data]
  );
  return (
    <>
      {/* <TopNav /> */}
      <Navbar />
      {/* <FacultyMainSec /> */}
      <FacultyMembers programs={programs} />
      <BackToTop />
      <Footer />
    </>
  );
};

export default FacultyPage;
