import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { Form, Row, Col } from "react-bootstrap";
import LoadingBtn from "components/atoms/LoadingBtn";
import { useParams } from "react-router-dom";
import { Button, InputBase, Typography, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "components/atoms/Loading";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  backBtn: {
    cursor: "pointer",
    color: "#252827 !important",
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    marginLeft: "20px",
    "@media (max-width: 600px)": {
      margin: "0",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  formGroupBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
    },
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  editIcon: {
    marginLeft: "10px",
    color: "#252827 !important",
    cursor: "pointer",
  },
  change_img: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "transparent",
    border: "1px solid var(--admin)",
    color: "var(--admin) !important",
    margin: "10px auto",
    transition: "0.3s",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  UserImage: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    display: "flex",
    margin: "0 auto",
  },
  fieldRow: {
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  fieldCol: {
    padding: "0 5px",
  },
});

const TeacherDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  let navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    // cnic: "",
    designation: "",
    qualification: "",
    image: null,
  });

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    // cnic,
    designation,
    qualification,
    image,
  } = state;

  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    firstNameErr: "",
    lNameErr: "",
    emailErr: "",
    phoneNumberErr: "",
    cnicErr: "",
    designationErr: "",
    qualificationErr: "",
    imageErr: "",
  });

  const {
    firstNameErr,
    lNameErr,
    emailErr,
    phoneNumberErr,
    cnicErr,
    designationErr,
    qualificationErr,
    imageErr,
  } = validationErrors;

  useEffect(() => {
    async function getTeacherDetails() {
      try {
        setLoading(true);
        const { data } = await axiosPrivate.get(`tchr_auth/profile/${id}`);
        setState((prev) => ({
          ...prev,
          firstName: data?.firstname,
          lastName: data?.lastname,
          email: data?.email,
          phoneNumber: data?.phone_number,
          // cnic: data?.cnic,
          designation: data?.designation,
          qualification: data?.qualification,
          image: data?.image?.startsWith("/")
            ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${data?.image}`
            : data?.image,
        }));

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
    getTeacherDetails();
  }, []);

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [cropImage, setCropImage] = useState(null);
  const [output, setOutput] = useState(null);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
    setState((prev) => ({
      ...prev,
      image: file,
    }));
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = cropImage.naturalWidth / cropImage.width;
    const scaleY = cropImage.naturalHeight / cropImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      cropImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setState((prev) => ({
      ...prev,
      image: base64Image,
    }));
    // // Converting to base64
    // const base64Image = canvas.toDataURL("image/jpeg");
    // setOutput(base64Image);
  };

  const validate = () => {
    if (!firstName) {
      setValidationErrors((prev) => ({
        ...prev,
        firstNameErr: "Please provide first name",
      }));
    }
    if (!lastName) {
      setValidationErrors((prev) => ({
        ...prev,
        lNameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setValidationErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!phoneNumber) {
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Please provide phone number",
      }));
    }
    if (phoneNumber.length < 11 || phoneNumber.length > 11) {
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Phone Number must be atleast 11 characters ",
      }));
    }
    // if (!cnic) {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     cnicErr: "Please provide cnic",
    //   }));
    // }
    // if (cnic.length < 13 || cnic.length > 13) {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     cnicErr: "CNIC must be atleast 13 characters ",
    //   }));
    // }
    if (!designation) {
      setValidationErrors((prev) => ({
        ...prev,
        designationErr: "Please provide designation",
      }));
    }
    if (!qualification) {
      setValidationErrors((prev) => ({
        ...prev,
        qualificationErr: "Please provide designation",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      phoneNumber.length < 11 ||
      phoneNumber.length > 11 ||
      // cnic.length < 13 ||
      // cnic.length > 13 ||
      // !cnic ||
      !designation ||
      !qualification ||
      image === null
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", firstName);
        bodyFormData.append("lastname", lastName);
        bodyFormData.append("phone_number", phoneNumber);
        // bodyFormData.append("cnic", cnic);
        bodyFormData.append("designation", designation);
        bodyFormData.append("qualification", qualification);
        isImageUpdate && bodyFormData.append("image", image);
        const res = await axiosPrivate({
          method: "put",
          url: `tchr_auth/profile/edit/${id}`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        navigate("/dashboard/instructor", { replace: true });
      } catch (err) {
        toast.error(err.response.data.message);
        setLoading(false);
        if (err?.response?.data?.code === 400) {
          toast.error("Validation error occurred");
        } else {
          toast.error(err?.response?.data?.message ?? err?.message);
        }
      }
    }
  };

  const handleChangePhone = (e) => {
    const limit = 11;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState((prev) => ({
        ...prev,
        phoneNumber: e.target.value.slice(0, limit),
      }));
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "",
      }));
    }
  };
  // const handleChangeCNIC = (e) => {
  //   const limit = 13;
  //   const re = /^[0-9\b]+$/;
  //   if (e.target.value === "" || re.test(e.target.value)) {
  //     setState((prev) => ({
  //       ...prev,
  //       cnic: e.target.value.slice(0, limit),
  //     }));
  //     setValidationErrors((prev) => ({
  //       ...prev,
  //       cnicErr: "",
  //     }));
  //   }
  // };

  // const onUploadFileChange = (e) => {
  //   if (e.target.files.length === 0 || !e.target.validity.valid) {
  //     return false;
  //   }
  //   const file = e.target.files[0];
  //   if (!/^image\//.test(file.type)) {
  //     setValidationErrors((prev) => ({
  //       ...prev,
  //       imageErr: `File ${file.name} is not an image.`,
  //     }));
  //     return false;
  //   }
  //   if (file.size > 1000000) {
  //     setValidationErrors((prev) => ({
  //       ...prev,
  //       imageErr: `Maximum file size 1MB`,
  //     }));
  //     return false;
  //   }
  //   setValidationErrors((prev) => ({
  //     ...prev,
  //     imageErr: "",
  //   }));
  //   setState((prev) => ({
  //     ...prev,
  //     image: file,
  //   }));
  // };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    fileToBase64(file, (err, result) => {
      if (result) {
        setState((prev) => ({
          ...prev,
          image: result,
        }));
        setValidationErrors((prev) => ({
          ...prev,
          imageErr: "",
        }));
      }
    });
    setSrc(URL.createObjectURL(file));
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
  };

  const handleEdit = () => {
    setCheck((prevCheck) => !prevCheck);
    setValidationErrors((prev) => ({
      ...prev,
      firstNameErr: "",
      lNameErr: "",
      emailErr: "",
      phoneNumberErr: "",
      cnicErr: "",
      designationErr: "",
      qualificationErr: "",
      imageErr: "",
    }));
  };
  return (
    <>
      <Box>
        <Box className={classes.btnBox}>
          <ArrowBackIcon
            className={classes.backBtn}
            onClick={() => navigate(-1)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <Typography className={classes.pageTitle}>
              Teacher Information
            </Typography>
            <EditIcon className={classes.editIcon} onClick={handleEdit} />
          </Box>
        </Box>
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Form>
                  <Row>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          First Name
                        </Form.Label>
                        <div
                          className={
                            firstNameErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="text"
                            disabled={check}
                            value={firstName}
                            placeholder="Enter first name"
                            onChange={(e) => {
                              if (
                                !/^[a-z A-Z]*$/.test(e.target.value) ||
                                e.target.value.length < 3
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  firstNameErr: !e.target.value
                                    ? "First name can't be empty"
                                    : e.target.value.length < 3
                                    ? "First name must be Minimum 2 characters long"
                                    : "Only alphabets are allowed",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  firstName: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  firstName: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  firstNameErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {firstNameErr ? (
                          <Typography className={classes.errorMsg}>
                            {firstNameErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          Last Name
                        </Form.Label>
                        <div
                          className={
                            lNameErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="text"
                            value={lastName}
                            disabled={check}
                            placeholder="Enter last name"
                            onChange={(e) => {
                              if (
                                !/^[a-z A-Z ]*$/.test(e.target.value) ||
                                e.target.value.length < 3
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  lNameErr: !e.target.value
                                    ? "Last name can't be empty"
                                    : e.target.value.length < 3
                                    ? "Last name must be Minimum 2 characters long"
                                    : "Only alphabets are allowed",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  lastName: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  lastName: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  lNameErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {lNameErr ? (
                          <Typography className={classes.errorMsg}>
                            {lNameErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={classes.fieldRow}>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>Email</Form.Label>
                        <div
                          className={
                            emailErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="email"
                            value={email}
                            disabled
                            placeholder="Enter email"
                            onChange={(e) => {
                              if (
                                !/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(
                                  e.target.value
                                )
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  emailErr: e.target.value
                                    ? "Invalid email address"
                                    : "Email can't be empty",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  emailErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {emailErr ? (
                          <Typography className={classes.errorMsg}>
                            {emailErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          Phone Number
                        </Form.Label>
                        <div
                          className={
                            phoneNumberErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="number"
                            disabled={check}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={handleChangePhone}
                          />
                        </div>
                        {phoneNumberErr ? (
                          <Typography className={classes.errorMsg}>
                            {phoneNumberErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={classes.fieldRow}>
                    {/* <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>CNIC</Form.Label>
                        <div
                          className={
                            cnicErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="number"
                            disabled={check}
                            placeholder="Enter CNIC"
                            value={cnic}
                            onChange={handleChangeCNIC}
                          />
                        </div>
                        {cnicErr ? (
                          <Typography className={classes.errorMsg}>
                            {cnicErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col> */}
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          Designation
                        </Form.Label>
                        <div
                          className={
                            designationErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="text"
                            disabled={check}
                            value={designation}
                            placeholder="Enter designation"
                            onChange={(e) => {
                              if (
                                !/^[a-zA-Z ]*$/.test(e.target.value) ||
                                e.target.value.length < 3
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  designationErr: !e.target.value
                                    ? "Designation can't be empty"
                                    : e.target.value.length < 6
                                    ? "Designation must be Minimum 5 characters long"
                                    : "Only alphabets are allowed",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  designation: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  designation: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  designationErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {designationErr ? (
                          <Typography className={classes.errorMsg}>
                            {designationErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          Qualification
                        </Form.Label>
                        <div
                          className={
                            qualificationErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="text"
                            disabled={check}
                            value={qualification}
                            placeholder="Enter designation"
                            onChange={(e) => {
                              if (
                                !/^[a-zA-Z ]*$/.test(e.target.value) ||
                                e.target.value.length < 6
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  qualificationErr: !e.target.value
                                    ? "Qualification can't be empty"
                                    : e.target.value.length < 6
                                    ? "Qualification must be Minimum 5 characters long"
                                    : "Only alphabets are allowed",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  qualification: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  qualification: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  qualificationErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {qualificationErr ? (
                          <Typography className={classes.errorMsg}>
                            {qualificationErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className={classes.fieldCol}>
                      {isImageUpdate && (
                        <Form.Group
                          controlId="formBasicTitle"
                          className={classes.formGroup}
                        >
                          <Form.Label className={classes.label}>
                            Image
                          </Form.Label>
                          <div
                            className={
                              imageErr
                                ? `${classes.inputFieldErr}`
                                : `${classes.inputField}`
                            }
                          >
                            <InputBase
                              className={classes.input}
                              type="file"
                              placeholder="Enter image"
                              accept="image/*"
                              onChange={(e) => onUploadFileChange(e)}
                            />
                          </div>
                          {imageErr ? (
                            <Typography className={classes.errorMsg}>
                              {imageErr}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Grid>

              <Grid item xs={12} md={5}>
                <Form.Group
                  controlId="formBasicTitle"
                  className={classes.formGroupImage}
                >
                  {!isImageUpdate ? (
                    <img src={image} className={classes.UserImage} alt="img" />
                  ) : (
                    src && (
                      <div>
                        <ReactCrop
                          src={src}
                          onImageLoaded={setCropImage}
                          crop={crop}
                          onChange={setCrop}
                        />
                        <br />
                        <button
                          className={classes.addBtn}
                          onClick={cropImageNow}
                        >
                          Crop
                        </button>
                        <br />
                        <br />
                      </div>
                    )
                  )}

                  {/* {<img src={image} className={classes.UserImage} alt="img" />} */}
                  {!isImageUpdate && (
                    <Button
                      className={classes.change_img}
                      disabled={check}
                      onClick={() => {
                        setIsImageUpdate(() => !isImageUpdate);
                        setState((prev) => ({
                          ...prev,
                          image: null,
                        }));
                      }}
                    >
                      Change Image
                    </Button>
                  )}
                </Form.Group>
              </Grid>
            </Grid>
            <Box>
              <Form.Group className={classes.formGroupBtn}>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    className={classes.addBtn}
                    type="submit"
                    disabled={check}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                )}
              </Form.Group>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TeacherDetails;
