let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    title: "Total Present",
    start: "2022-10-05",
  },
  {
    title: "Total Absent",
    start: "2022-09-05",
  },
  {
    id: createEventId(),
    title: "Total Present",
    start: "2022-10-07",
  },
  {
    id: createEventId(),
    title: "Total Absent",
    start: "2022-10-07",
  },
  {
    id: createEventId(),
    title: "Total Present",
    start: "2022-10-09",
  },
  {
    id: createEventId(),
    title: "Total Absent",
    start: "2022-09-03",
  },
  {
    id: createEventId(),
    title: "Total Present",
    start: "2022-10-01",
  },
  {
    id: createEventId(),
    title: "Total Absent",
    start: "2022-10-23",
  },
];

export function createEventId() {
  return String(eventGuid++);
}
