import React from "react";
import { Container } from "react-bootstrap";
import "./style.css";
import backgroundImg from "../../../Assets/Images/irsaLibraryHomepage.png";
import { RiShareCircleLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const LibraryBlooming = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div className="text-center">
              <Link className="Library-btn" to="/library">
                <Typography style={{ fontSize: "24px" }}>
                  IRSA Library
                  {/* Baker Library | Bloomberg Center */}
                </Typography>
                <RiShareCircleLine
                  style={{ marginLeft: "15px", fontSize: "24px" }}
                />
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default LibraryBlooming;
