import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Box, Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Form } from "react-bootstrap";
import Loading from "components/atoms/Loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  backBtn: {
    cursor: "pointer",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
});

const options = [
  { id: 1, label: "Present", value: "Present", attendance_type: true },
  {
    id: 2,
    label: "Absent",
    value: "Absent",
  },
];

const Actions = ({ index, receivedData }) => {
  const handleChange = (e) => {
    receivedData(e.target.value, index?.id);
  };

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            checked={index?.attendance_type === "Present"}
            value="Present"
            control={<Radio />}
            label="Present"
            onChange={handleChange}
          />
          <FormControlLabel
            checked={index?.attendance_type === "Absent"}
            value="Absent"
            control={<Radio />}
            label="Absent"
            onChange={handleChange}
          />
          {/* {options.map((option, i) => (
            <FormControlLabel
              key={i}
              // checked={index?.attendance_type === option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              onChange={handleChange}
            />
          ))} */}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default function StudentList({
  classId,
  selectDate,
  setStateToOpenList,
  setStateToOpen,
  handle,
  data,
}) {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);

  React.useEffect(() => {
    async function getStudentsList() {
      setLoading(true);
      try {
        setLoading(true);
        const { data } = await axiosPrivate.get(
          `studentinclass/${classId}?date=${selectDate}`
        );
        setStudents(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(
          "_____________*",
          err?.response?.data?.message ?? err?.message
        );
      }
    }
    getStudentsList();
  }, []);

  const receivedData = (type, id) => {
    const newState = students.map((obj) => {
      if (obj.id === id) {
        return { ...obj, attendance_type: type };
      }

      return obj;
    });
    setStudents(newState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosPrivate.post(`/attendance/${classId}`, {
        date: selectDate,
        students: students,
      });
      toast.success(data);
      setStateToOpen(classId);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosPrivate.put(`/attendance/edit/${classId}`, {
        date: selectDate,
        students: students,
      });
      toast.success(data);
      setStateToOpen(classId);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  const backFuc = () => {
    setStateToOpen(classId);
  };

  const columns = [
    { field: "roll_number", headerName: "Roll Number", flex: 0.5 },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 0.5,
      valueGetter: (params) =>
        `${params.row.firstname || ""} ${params.row.lastname || ""}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Actions
              index={params?.row}
              classId={classId}
              students={students}
              receivedData={receivedData}
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <ToastContainer autoClose={2000} />
      <Box className={classes.btnBox}>
        <ArrowBackIcon className={classes.backBtn} onClick={backFuc} />
      </Box>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : students?.length > 0 ? (
        <>
          <div
            style={{
              width: "100%",
              background: "#FBFBFB",
              borderRadius: "4px",
              marginTop: "20px",
            }}
          >
            <DataGrid
              autoHeight
              rows={students}
              columns={columns}
              getRowId={(students) => students.id}
              pageSize={5}
              rowsPerPageOptions={[5]}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </div>
          <Form.Group className={classes.saveBtn}>
            <Button
              className={classes.addBtn}
              onClick={!handle ? handleSubmit : handleSubmitEdit}
            >
              Save Attendance
            </Button>
          </Form.Group>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
    </>
  );
}
