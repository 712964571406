import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useMemo, useState } from "react";
import ChatContainer from "./ChatComponents/ChatContainer";
import Contacts from "./ChatComponents/Contacts";
import Welcome from "./ChatComponents/Welcome";
import styled from "styled-components";
import ClassesService from "services/classes.service";

const Chat = () => {
  const axiosPrivate = useAxiosPrivate();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const { user } = useAuth();
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 1000,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;

  const { loading, classes_data } = ClassesService.GetClasses(
    page,
    pageSize,
    searchText,
    reload
  );

  const { totalItems, classesData } = useMemo(
    () => ({
      classesData: classes_data?.classes,
      totalItems: classes_data?.totalItems,
    }),
    [classes_data]
  );

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("alluserchat/", {
        withCredentials: true,
      });
      setContacts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    !user.role === "admin" && fetchData();
  }, []);

  const handleChatChange = (chat, search) => {
    setCurrentChat(chat);
    console.log("search", search);
  };
  const handleSearch = (search) => {
    setSearchQuery((ev) => ({
      ...ev,
      searchText: search,
    }));
  };

  return (
    <>
      <Container>
        <div className="custom">
          <Contacts
            contacts={contacts}
            classesData={classesData}
            user={user}
            changeChat={handleChatChange}
            handleSearch={handleSearch}
          />

          {currentChat === undefined ? (
            <Welcome user={user} />
          ) : (
            <ChatContainer currentChat={currentChat} user={user} />
          )}
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  .custom {
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    @media screen and (max-width: 600px) {
      grid-template-columns: 100%;
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
  }
`;

export default Chat;
