import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const NewsService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetNews(page, pageSize, searchText, filterStatus, filterCategory, reload) {
    const axiosPrivate = useAxiosPrivate();
    const [news, setNews] = useState({
      news: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getNews(page, pageSize, searchText, axiosPrivate, filterCategory)
      )
        .then((res) => {
          setNews(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterCategory, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      news_data: news,
    };
  },
  GetNewsLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterCategory,
    reload,
  }) {
    const axiosPrivate = useAxiosPrivate();
    const [news, setNews] = useState({
      news: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getNewsLandingPage({
          page,
          pageSize,
          searchText,
          axiosPrivate,
          filterStatus,
        })
      )
        .then((res) => {
          setNews(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterCategory, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      news_data: news,
    };
  },
  async getNews(page, pageSize, searchText, axiosPrivate, filterCategory) {
    const response = await axiosPrivate.get(
      `${this._url}/news?page=${page}&page_size=${pageSize}`
    );
    return { news: response?.data?.results, totalItems: response?.data?.count };
  },
  async getNewsLandingPage({ page, pageSize, searchText, filterStatus }) {
    try {
      const response = await makeAxiosRequest(
        "GET",
        `${this._url}/news/p/getall/?page=${page}&page_size=${pageSize}&search=${searchText}&filterStatus=${filterStatus}`
      );
      return { news: response?.results, totalItems: response?.count };
    } catch (err) {
      throw err;
    }
  },
  async addNews(payload) {
    let response = await makeAxiosRequest(
      "POST",
      `${this._url}/news/`,
      payload
    );
    if (response?.error) {
      throw Error(response.message ?? "Something went wrong");
    }

    return response;
  },
  async deleteNews({ id }) {
    let response = await makeAxiosRequest("DELETE", `${this._url}/news/${id}`);
    if (response?.error) {
      throw Error(response.message ?? "Something went wrong");
    }

    return response;
  },
};

export default NewsService;
