import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  modelTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  agreeBtn: {
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "10px 5px",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  backBtn: {
    height: "56px",
    width: "191px",
    margin: "10px 5px",
    background: "#fff",
    border: "1px solid var(--admin)",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "var(--admin) !important",
    "&:hover": {
      color: "#fff !important",
      background: "var(--admin)",
    },
  },
});

export default function DeleteConfirmModel({
  openDelete,
  handleClose,
  onClick,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modelTitle}
        >
          {"Delete Confirmation?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.backBtn} onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={() => onClick()} className={classes.agreeBtn}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
