import React, { useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import Loading from "components/atoms/Loading";
import { Box, Grid } from "@mui/material";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import TablePagination from "components/atoms/Pagination";
import EditorModel from "components/atoms/EditorModel";
import BtnBox from "components/atoms/BtnBox";
import AddStories from "components/Forms/AddStories";
import StoriesService from "services/studentStories.service";
import StoryCard from "components/atoms/StoryCard";
import Model from "components/atoms/Model";
import AddStoryGallery from "components/Forms/AddStoryGallery";
import EditStory from "components/Forms/EditStory";

const StudentStories = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAddGallery, setOpenAddGallery] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [filter, setFilter] = useState({
    filterStatus: [],
  });
  const { filterStatus } = filter;
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 15,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, stories_data } = StoriesService.GetStories(
    page,
    pageSize,
    searchText,
    filterStatus,
    reload
  );
  const { totalItems, stories } = useMemo(
    () => ({
      stories: stories_data?.stories,
      totalItems: stories_data.totalItems,
    }),
    [stories_data]
  );

  const deleteStory = async (id, handleClose, reload) => {
    try {
      const { data } = await axiosPrivate.delete(`story/delete/${id}`);
      toast.success("Story Deleted Successfully");
      handleClose();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };
  const deleteGalleryImage = async (storyId, imageId, handleClose) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("story_id", storyId);
      bodyFormData.append("image_id", imageId);
      const res = await axiosPrivate({
        method: "delete",
        url: "story/delete_image",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Image Deleted Successfully");
      handleClose();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isReload) => {
    setOpen(false);
  };

  // delete stories model

  const handleClickOpenDelete = ({ storyData }) => {
    setData(storyData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete stories model

  // course details

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/newsevents/${id}`);
  };
  // end course details

  // edit stories model

  const handleClickOpenEdit = ({ storyData }) => {
    setData(storyData);
    setOpenEdit(true);
  };

  const handleCloseEdit = (isReload) => {
    setOpenEdit(false);
  };
  // end edit stories model

  // add gallery model

  const handleClickOpenAddGallery = ({ storyData }) => {
    setData(storyData);
    setOpenAddGallery(true);
  };

  const handleCloseAddGallery = (isReload) => {
    setOpenAddGallery(false);
  };
  // end add gallery model

  return (
    <>
      <ToastContainer autoClose={3000} />
      <BtnBox
        handleClickOpen={handleClickOpen}
        title={"Stories"}
        subtitle={"You Have Added Following Stories"}
        btnText={"Add Stories"}
      />

      <EditorModel
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddStories
            handleClose={handleClose}
            toast={toast}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Add Stories"
      />

      {/* edit stories model */}

      <EditorModel
        openModel={openEdit}
        handleClose={handleCloseEdit}
        bodyContent={
          <EditStory
            handleClose={handleCloseEdit}
            toast={toast}
            stories={data}
            onClick={deleteGalleryImage}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Edit Stories"
      />
      {/* end edit stories model */}

      {/* add gallery model */}

      <Model
        openModel={openAddGallery}
        handleClose={handleCloseAddGallery}
        bodyContent={
          <AddStoryGallery
            handleClose={handleCloseAddGallery}
            toast={toast}
            data={data}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Add Gallery"
      />
      {/* end add gallery model */}

      {/* delete stories model */}

      <DeleteConfirmModel
        openDelete={openDelete}
        handleClose={handleCloseDelete}
        onClick={() => deleteStory(data?.id, handleCloseDelete)}
      />

      {/* end delete stories model */}
      {loading === true ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
            >
              {stories?.map((storyData, i) => (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                    <StoryCard
                      data={storyData}
                      onClick={() => handleClickOpenEdit({ storyData })}
                      handleClickOpenAddGallery={() =>
                        handleClickOpenAddGallery({ storyData })
                      }
                      handleClickOpenDelete={() =>
                        handleClickOpenDelete({ storyData })
                      }
                      // handleClickOpenDetail={() =>
                      //   handleClickOpenDetail(storyData?.id)
                      // }
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
      {totalItems > 0 && (
        <div
          className="my-3"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TablePagination
            count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
            page={page}
            onChange={(e, v) =>
              setSearchQuery((prev) => ({ ...prev, page: v }))
            }
          />
        </div>
      )}
    </>
  );
};

export default StudentStories;
