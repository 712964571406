import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AddIcon from "@mui/icons-material/Add";
import Model from "components/atoms/Model";
import { toast, ToastContainer } from "react-toastify";
import ContentByTeacher from "components/Forms/ContentByTeacher";
import Loading from "components/atoms/Loading";
import useAuth from "hooks/useAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import ModalVideo from "react-modal-video";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import parse from "html-react-parser";
import { Col, Row } from "react-bootstrap";
import "./stylePage.css";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "left",
  },
  backBtn: {
    cursor: "pointer",
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  contentBox: {
    width: "100%",
    height: "636px",
    overflowY: "scroll",
    background: "#FFFFFF",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    padding: "20px",
    margin: "25px 0",
  },
  dataBox: {
    width: "100%",
    height: "636px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  details: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: "var(--link-text-color) !important",
    margin: "10px 0",
    letterSpacing: "1px",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  playIcon: { fontSize: "30px", color: "#000", cursor: "pointer" },
  videoTitle: {
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#000 !important",
    margin: "10px 0",
    letterSpacing: "1px",
  },
  listUl: {
    margin: "10px 0",
    borderRadius: "4px",
  },
  tabBtn: {
    width: "140px",
    height: "40px",
    border: "0.75px solid var(--icons-color)",
    color: "var(--icons-color)",
    borderRadius: "4px",
    margin: "5px 10px",
    "& .Mui-selected": {
      color: "#000 !important",
    },
    "@media (max-width: 600px)": {
      margin: "5px 10px",
    },
  },
  fab: {
    background: "var(--admin)",
    color: "#fff",
    position: "fixed",
    bottom: 40,
    right: 40,
  },
  iconBtn: {
    color: "var(--link-text-color)",
    width: "fit-content",
    margin: "0 5px",
    cursor: "pointer",
  },
  programsCard: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse !important",
    },
  },
  programsImage: {
    width: "30%",
    "@media (max-width: 600px)": {
      width: "100% !important",
    },
  },
  dataList: {
    height: "80px",
    background: "#fff",
    margin: "5px 0",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CourseContent() {
  const { user } = useAuth();
  const { id } = useParams();
  let navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [courseContent, setCourseContent] = useState([]);
  const [reload, setReload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [courseDetails, setCourseDetails] = useState("");

  useEffect(() => {
    async function getCourseContent() {
      let response;
      setLoading(true);
      try {
        const { data } = await axiosPrivate.get(
          `course_content/getbycourseid/${id}`
        );
        response = data;
        setCourseContent(response);
        setCourseDetails(response?.course);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err?.response?.data?.message ?? err?.message);
      }
    }
    getCourseContent();
  }, [reload]);

  const deleteCourseContent = async (id, handleCloseDelete) => {
    try {
      let response;
      const { data } = await axiosPrivate.delete(`course_content/${id}`);
      response = data;
      toast.success(response?.message);
      handleCloseDelete();
      setReload(() => !reload);
    } catch (err) {
      if (err?.response) {
        toast.error(err?.response?.data?.message ?? err?.message);
      } else if (err?.request) {
        toast.error("Seems network error!");
      } else {
        toast.error(err?.message);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModel = (url) => {
    setUrl(url);
    setIsOpen(true);
  };

  // delete course model

  const handleClickOpenDelete = ({ content }) => {
    setData(content);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete course model

  // edit course content model

  // const handleOpenEdit = ({ content }) => {
  //   setData(content);
  //   setOpenEdit(true);
  // };

  // const handleCloseEdit = (isReload) => {
  //   setOpenEdit(false);
  // };
  // end edit course content model

  return (
    <>
      <ToastContainer autoClose={2000} />
      <Box>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId={url?.split("=")[1]}
          onClose={() => setIsOpen(false)}
        />
        {(user?.role === "admin" || user?.role === "teacher") && (
          <Tooltip title="Add" aria-label="add" onClick={() => setOpen(true)}>
            <Fab className={classes.fab} onClick={handleClickOpen}>
              <AddIcon />
            </Fab>
          </Tooltip>
        )}

        {/* edit course model */}
        {/* <Model
          openModel={openEdit}
          handleClose={handleCloseEdit}
          bodyContent={
            <EditCourseContent
              handleClose={handleCloseEdit}
              toast={toast}
              content={data}
              userRole={user.role}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Edit Course Content"
        /> */}
        {/* end edit course model */}

        {/* add course content */}
        <Model
          openModel={open}
          handleClose={handleClose}
          bodyContent={
            <ContentByTeacher
              id={id}
              handleClose={handleClose}
              toast={toast}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Add Course Content"
        />
        {/* end add course content */}

        {/* delete course model */}

        <DeleteConfirmModel
          openDelete={openDelete}
          handleClose={handleCloseDelete}
          onClick={() => deleteCourseContent(data?.id, handleCloseDelete)}
        />
        {/* end delete course model */}

        <Box className={classes.btnBox}>
          <ArrowBackIcon
            className={classes.backBtn}
            onClick={() => navigate(-1)}
          />
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item sm={12} md={6}>
                  <Box>
                    <Box>
                      <Typography className={classes.pageTitle}>
                        {courseContent?.course?.title}
                      </Typography>
                    </Box>
                    <Box className={classes.contentBox}>
                      <Typography className={classes.details}>
                        {parse(`${courseContent?.course?.description}`)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Box sx={{ width: "100%" }}>
                    <Box>
                      <Tabs
                        TabIndicatorProps={{ sx: { display: "none" } }}
                        sx={{
                          "& .MuiTabs-flexContainer": {
                            flexWrap: "wrap",
                          },
                        }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        centered
                      >
                        <Tab
                          className={classes.tabBtn}
                          label="Documents"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className={classes.tabBtn}
                          label="Assignments"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className={classes.tabBtn}
                          label="Videos"
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </Box>
                    <Box className={classes.dataBox}>
                      {loading === true ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <>
                          <TabPanel value={value} index={0}>
                            {courseContent?.document?.type === "Document" &&
                            courseContent?.document?.result.length > 0 ? (
                              <section>
                                <List className={classes.listUl}>
                                  {courseContent?.document?.result?.map(
                                    (content, k) => (
                                      <ListItem
                                        key={k}
                                        className={classes.dataList}
                                      >
                                        <ListItemAvatar>
                                          <AssignmentIcon
                                            className={classes.playIcon}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          className={classes.videoTitle}
                                          primary={content?.title}
                                        />

                                        <Tooltip
                                          title="Download"
                                          placement="top"
                                        >
                                          <a href={content.file} download>
                                            <DownloadIcon
                                              className={classes.playIcon}
                                            />
                                          </a>
                                        </Tooltip>
                                        {(user?.role === "admin" ||
                                          user?.role === "teacher") && (
                                          <Tooltip
                                            title="Delete"
                                            placement="top"
                                          >
                                            <DeleteIcon
                                              className={classes.iconBtn}
                                              onClick={() =>
                                                handleClickOpenDelete({
                                                  content,
                                                })
                                              }
                                            />
                                          </Tooltip>
                                        )}
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </section>
                            ) : (
                              <h2
                                className="text-center justify-content-center w-100"
                                style={{ color: "var(--icons-color)" }}
                              >
                                No Document
                              </h2>
                            )}
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            {courseContent?.assignment?.type === "Assignment" &&
                            courseContent?.assignment?.result.length > 0 ? (
                              <section>
                                <List className={classes.listUl}>
                                  {courseContent?.assignment?.result?.map(
                                    (content, j) => (
                                      <ListItem
                                        key={j}
                                        className={classes.dataList}
                                      >
                                        <ListItemAvatar>
                                          <AssignmentIcon
                                            className={classes.playIcon}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          className={classes.videoTitle}
                                          primary={content?.title}
                                        />
                                        <Tooltip
                                          title="Download"
                                          placement="top"
                                        >
                                          <a href={content.url} download>
                                            <DownloadIcon
                                              className={classes.playIcon}
                                            />
                                          </a>
                                        </Tooltip>
                                        {(user?.role === "admin" ||
                                          user?.role === "teacher") && (
                                          <Tooltip
                                            title="Delete"
                                            placement="top"
                                          >
                                            <DeleteIcon
                                              className={classes.iconBtn}
                                              onClick={() =>
                                                handleClickOpenDelete({
                                                  content,
                                                })
                                              }
                                            />
                                          </Tooltip>
                                        )}
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </section>
                            ) : (
                              <h2
                                className="text-center justify-content-center w-100"
                                style={{ color: "var(--icons-color)" }}
                              >
                                No Assignments
                              </h2>
                            )}
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            {courseContent?.video?.type === "Video" &&
                            courseContent?.video?.result.length > 0 ? (
                              <section>
                                <List className={classes.listUl}>
                                  {courseContent?.video?.result?.map(
                                    (content, key) => (
                                      <ListItem
                                        key={key}
                                        className={classes.dataList}
                                      >
                                        <ListItemAvatar>
                                          <PlayCircleIcon
                                            onClick={() =>
                                              handleOpenModel(content?.url)
                                            }
                                            className={classes.playIcon}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          className={classes.videoTitle}
                                          primary={content?.title}
                                        />
                                        {(user?.role === "admin" ||
                                          user?.role === "teacher") && (
                                          <>
                                            {/* <EditIcon
                                              className={classes.iconBtn}
                                              onClick={() =>
                                                handleOpenEdit({ content })
                                              }
                                            /> */}
                                            <DeleteIcon
                                              className={classes.iconBtn}
                                              onClick={() =>
                                                handleClickOpenDelete({
                                                  content,
                                                })
                                              }
                                            />
                                          </>
                                        )}
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </section>
                            ) : (
                              <h2
                                className="text-center justify-content-center w-100"
                                style={{ color: "var(--icons-color)" }}
                              >
                                No Video
                              </h2>
                            )}
                          </TabPanel>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Row>
              <Col>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Program
                </Typography>
                <Box className={classes.programBox}>
                  <Card className={classes.programsCard}>
                    <Box
                      sx={{
                        padding: "16px",
                      }}
                    >
                      <CardContent sx={{ padding: "0" }}>
                        <Typography
                          sx={{ cursor: "pointer" }}
                          component="div"
                          variant="h5"
                        >
                          {courseDetails?.program_details?.title}
                        </Typography>
                        <Box>
                          <Typography className={classes.programDesText}>
                            {courseDetails?.program_details?.description
                              ? parse(
                                  `${courseDetails?.program_details?.description}`
                                )
                              : ""}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Box>
                    <CardMedia
                      component="img"
                      className={classes.programsImage}
                      image={
                        courseDetails?.program_details?.image?.startsWith("/")
                          ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${courseDetails?.program_details?.image}`
                          : courseDetails?.program_details?.image
                      }
                      alt="Live from space album cover"
                    />
                  </Card>
                </Box>
              </Col>
            </Row>
          </>
        )}
      </Box>
    </>
  );
}
