import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundImage from "../../Assets/Images/Collage-2CampusCulture.png";

const useStyles = makeStyles({
  box: {
    padding: "0px",
    "@media (max-width: 768px)": {
      padding: "0",
    },
  },
  container: {
    padding: "0px !important",
    height: "150vh",
    // background: `url(${backgroundImage})`,
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // display: "flex !important",
    // justifyContent: "center",
    // alignItems: "center",
    // borderRadius: "4px",

    // "@media (max-width: 768px)": {
    //   height: "80vh",
    // },
  },
  textBox: {
    height: "172px",
    width: "558px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",

    "@media (max-width: 768px)": {
      height: "fit-content",
      padding: "10px",
      width: "100%",
    },
  },
  boxText: {
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textAlign: "center",
    color: "#FFFFFF !important",

    "@media (max-width: 768px)": {
      fontSize: "30px !important",
    },
  },
  btnBox: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "20px",
  },
  tourBtn: {
    width: "194px",
    height: "45px",
    background: "#E24F57 !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
    textTransform: "capitalize !important",
    color: "#FFFFFF !important",
    borderRadius: "0 !important",
  },
});

export default function DetailsCard({ text }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Box className={classes.box}>
        <Container maxWidth="100%" className={classes.container}>
          <img
            src={backgroundImage}
            alt="img"
            style={{ width: "100%", height: "100%" }}
          />
          s
          {/* <Box>
            <Box className={classes.textBox}>
              <Typography className={classes.boxText}>{text}</Typography>
            </Box>
          </Box> */}
        </Container>
      </Box>
    </React.Fragment>
  );
}
