import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./forms.css";
import LoadingBtn from "components/atoms/LoadingBtn";
import { Box, Button, InputBase, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import MyEditor from "components/atoms/TestEditor";
import { convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import parse from "html-react-parser";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.titleError
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  imageField: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.imageErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldDes: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.descriptionErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "fit-content",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const AddProgram = ({ handleClose, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    titleError: "",
    descriptionErr: "",
    imageErr: "",
  });
  const { titleError, descriptionErr, imageErr } = validationErrors;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // const [uploadedImages, setUploadedImages] = useState([]);

  // const _uploadImageCallBack = (file) => {
  //   // long story short, every time we upload an image, we
  //   // need to save it to the state so we can get it's data
  //   // later when we decide what to do with it.

  //   // Make sure you have a uploadImages: [] as your default state
  //   let uploadedImage = uploadedImages;

  //   const imageObject = {
  //     file: file,
  //     localSrc: URL.createObjectURL(file),
  //   };

  //   uploadedImage.push(imageObject);

  //   setUploadedImages(uploadedImage);

  //   // We need to return a promise with the image src
  //   // the img src we will use here will be what's needed
  //   // to preview it in the browser. This will be different than what
  //   // we will see in the index.md file we generate.
  //   return new Promise((resolve, reject) => {
  //     resolve({ data: { link: imageObject.localSrc } });
  //   });
  // };

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleError: "Please provide title",
      }));
    }
    if (!description) {
      setValidationErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (description.length < 8) {
      setValidationErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (!title || !description || description.length < 8 || image == null) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("description", description);
        bodyFormData.append("image", image);
        const res = await axiosPrivate({
          method: "post",
          url: "/programs",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success(res.data);
        setLoading(false);
        handleClose();
        reload("reload");
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.code === 400) {
          toast.error("Validation error occurred");
        } else {
          toast.error(err?.response?.data?.message ?? err?.message);
        }
      }
    }
  };
  const classes = useStyles({ titleError, descriptionErr, imageErr });

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    if (file.size > 10000000) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `Maximum file size 10MB`,
      }));
      return false;
    }
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
    setImage(e.target.files[0]);
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setValidationErrors((prev) => ({
      ...prev,
      descriptionErr: "",
    }));
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setDescription(currentContentAsHTML);
  };
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Form>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Title</Form.Label>
                <div className={classes.inputField}>
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        titleError: "",
                      }));
                    }}
                  />
                </div>
                {titleError ? (
                  <Typography className={classes.errorMsg}>
                    {titleError}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Image</Form.Label>
                <div className={classes.imageField}>
                  <InputBase
                    className={classes.input}
                    accept="image/*"
                    type="file"
                    onChange={(e) => onUploadFileChange(e)}
                  />
                </div>
                {imageErr ? (
                  <Typography className={classes.errorMsg}>
                    {imageErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                className={classes.formGroup}
                controlId="formBasicDesc"
              >
                <Form.Label className={classes.label}>
                  Program Description
                </Form.Label>
                <MyEditor
                  className={
                    descriptionErr
                      ? `${classes.inputFieldDesErr}`
                      : `${classes.inputFieldDes}`
                  }
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  // _uploadImageCallBack={_uploadImageCallBack}
                />
                {descriptionErr ? (
                  <Typography className={classes.errorMsg}>
                    {descriptionErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                {loading ? (
                  <>
                    <LoadingBtn />
                  </>
                ) : (
                  <>
                    <Button
                      className={classes.addBtn}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Publish
                    </Button>
                  </>
                )}
              </Form.Group>
            </Form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>{parse(`${description}`)}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddProgram;
