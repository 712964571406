import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import parse from "html-react-parser";
import TopNav from "../TopNavBar/TopNav";
import Navbar from "../Navbar";
import { Box, Typography } from "@mui/material";
import LAtCampus from "../LandingPage/LATCampus/LAtCampus";
import BackToTop from "components/atoms/BackToTop/BackToTop";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import makeAxiosRequest from "helpers/makeAxiosRequest";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Carousel from "react-elastic-carousel";

const useStyles = makeStyles({
  container: {
    padding: "0 !important",
    height: "120vh",
    // backgroundImage: `url(${backgroundImage})`,
    // objectFit: "scale-down",

    // backgroundPosition: "center",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // display: "flex !important",
    // justifyContent: "center",
    // alignItems: "end",

    // "@media (max-width: 768px)": {
    //   height: "80vh",
    // },
  },
  box: {
    height: "277px",
    background: "#252827",
    width: "640px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginBottom: "50px",

    "@media (max-width: 768px)": {
      height: "220px",
      width: "600px",
    },
  },
  text: {
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textAlign: "center",
    color: "#FFFFFF !important",

    "@media (max-width: 768px)": {
      fontSize: "30px !important",
    },
  },
});

const StoriesDetailsPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState("");
  const [details, setDetails] = useState("");
  const [show, setShow] = useState(false);
  const [imgData, setImgData] = useState(false);

  console.log("imgData", imgData);

  useEffect(() => {
    async function getNewsDetails() {
      setLoading(true);
      try {
        const response = await makeAxiosRequest(
          "GET",
          `${process.env.REACT_APP_IRSA_BACKEND_API}/stories/${id}`
        );
        setDetails(response);
        setLoading(false);
      } catch (err) {
        if (err?.response) {
        } else if (err?.request) {
          console.log(err?.request);
        } else {
          console.log(err?.message);
        }
      }
    }
    getNewsDetails();
  }, [id]);

  function handleShow(i) {
    setImgData(i);
    setShow(true);
  }

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Carousel itemsToShow={1} initialActiveIndex={imgData}>
            {details?.all_images?.map((imgDataSlide, i) => (
              <>
                <div className="mx-2 my-3">
                  <img
                    key={i}
                    src={
                      imgDataSlide?.image?.startsWith("/")
                        ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${imgDataSlide?.image}`
                        : imgDataSlide?.image
                    }
                    alt="img"
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </div>
              </>
            ))}
          </Carousel>
          {/* <img
            src={
              imgData && imgData?.image?.startsWith("/")
                ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${imgData?.image}`
                : imgData?.image
            }
            alt="img"
            style={{
              borderRadius: "10px",
              cursor: "pointer",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }} */}
          {/* /> */}
        </Modal.Body>
      </Modal>
      <TopNav />
      <Navbar />
      <CssBaseline />
      <Container maxWidth="100%" className={classes.container}>
        <img
          src={
            details?.main_image
              ? details?.main_image?.startsWith("/")
                ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${details?.main_image}`
                : details?.main_image
              : "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          }
          alt="img"
          style={{ width: "100%", height: "100%" }}
        />
      </Container>
      <Box sx={{ width: "100%", padding: "50px" }}>
        <Typography
          sx={{
            margin: "0",
            textAlign: "justify",
          }}
        >
          {parse(`${details?.description}`)}
        </Typography>
      </Box>
      <LAtCampus details={details} loading={loading} onClick={handleShow} />
      <BackToTop />
      <Footer />
    </>
  );
};

export default StoriesDetailsPage;
