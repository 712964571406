import BackToTop from "components/atoms/BackToTop/BackToTop";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import NewsContent from "components/Layout/NewsPages/NewsContent";
import NewsMainSec from "components/Layout/NewsPages/NewsMainSec";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect } from "react";

const NewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <TopNav /> */}
      <Navbar />
      {/* <NewsMainSec /> */}
      <NewsContent />
      <BackToTop />
      <Footer />
    </>
  );
};

export default NewsPage;
