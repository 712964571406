import { useState, createContext } from "react";
import AuthService from "../services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const AuthContext = createContext({});

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState({});
  const [teacherData, setTeacherData] = useState({});
  const [studentClasses, setStudentClasses] = useState({});
  const [teacherClasses, setTeacherClasses] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const from = location?.state?.from?.pathname;

  const onLogin = (props) => {
    setLoading(true);
    AuthService.login(props)
      .then((res) => {
        setLoading(false);
        setUser(res);
        setUserData(res?.student);
        setTeacherData(res?.teacher);
        setStudentClasses(res?.student?.classes);
        setTeacherClasses(res?.teacher?.classes);
        setError("");
        setIsLoggedIn(true);
        localStorage.setItem("route", `${res?.role}_auth`);
        localStorage.setItem("refresh", `${res?.refresh}`);
        localStorage.setItem("role", `${res?.role}`);
        localStorage.setItem("isSuperAdmin", `${res?.isSuperAdmin}`);
        localStorage.setItem("user", `${res?.user}`);
        if (res?.role === "admin")
          navigate(from ? from : "/dashboard/statistics", { replace: true });
        if (res?.role === "student") navigate(from ? from : "/student/home");
        if (res?.role === "teacher") navigate(from ? from : "/teacher/home");
        toast.success("You logged in in successfully", {
          autoClose: 2000,
          className: "primaryColor",
          position: "top-right",
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        setIsLoggedIn(false);
        localStorage.removeItem("route");
      });
  };
  const forgetPass = (props) => {
    AuthService.forgetPassword(props)
      .then((res) => {})
      .catch((err) => {
        setIsLoggedIn(false);
        localStorage.removeItem("route");
      });
  };
  const resetPass = (props) => {
    AuthService.resetPassword(props)
      .then((res) => {
        navigate("/login", { replace: true });
        toast.success(res, {
          autoClose: 2000,
          className: "primaryColor",
          position: "top-center",
        });
      })
      .catch((err) => {
        setIsLoggedIn(false);
        toast.error(err);
        localStorage.removeItem("route");
      });
  };

  const refresh = () => {
    AuthService.getMe()
      .then((res) => {
        setUser(res);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        setIsLoggedIn(false);
        localStorage.removeItem("route");
      });
  };

  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogin,
        refresh,
        forgetPass,
        resetPass,
        isLoggedIn,
        user,
        setUser,
        error,
        setError,
        studentClasses,
        userData,
        teacherData,
        teacherClasses,
        loading,
      }}
    >
      {props.children}
      <ToastContainer />
    </AuthContext.Provider>
  );
};
