import { Navigate, useLocation } from "react-router-dom";
const StudentRoute = ({ isLoggedIn, user, children }) => {
  const location = useLocation();
  if (isLoggedIn && user.role === "student") {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default StudentRoute;
