import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    padding: "30px !important",
    height: "90vh",
    backgroundImage:
      "url(https://images.unsplash.com/20/cambridge.JPG?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1147&q=80)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "end",

    "@media (max-width: 768px)": {
      height: "80vh",
    },
  },
  textBox: {
    height: "172px",
    background: "var(--primary)",
    width: "558px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",

    "@media (max-width: 768px)": {
      height: "fit-content",
      padding: "10px",
      width: "100%",
    },
  },
  boxText: {
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textAlign: "center",
    color: "#FFFFFF !important",

    "@media (max-width: 768px)": {
      fontSize: "30px !important",
    },
  },
  btnBox: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "20px",
  },
  tourBtn: {
    width: "194px",
    height: "45px",
    background: "#E24F57 !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
    textTransform: "capitalize !important",
    color: "#FFFFFF !important",
    borderRadius: "0 !important",
  },
});

export default function CampusTour() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="100%" className={classes.container}>
        {/* <Box>
          <Box className={classes.textBox}>
            <Typography className={classes.boxText}>
              Our residential campus is designed to develop skills and build
              relationship.
            </Typography>
          </Box>
          <Box className={classes.btnBox}>
            <Button className={classes.tourBtn}>take the online tour</Button>
          </Box>
        </Box> */}
      </Container>
    </React.Fragment>
  );
}
