import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};
const CoursesService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetCourses({ page, pageSize, reload, searchText }) {
    const axiosPrivate = useAxiosPrivate();
    const [courses, setCourses] = useState({
      courses: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getCourses({ page, pageSize, axiosPrivate, searchText })
      )
        .then((res) => {
          setCourses(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, reload, searchText]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      courses_data: courses,
    };
  },
  async getCourses({ page, pageSize, axiosPrivate, searchText }) {
    const { data } = await axiosPrivate.get(
      `${this._url}/courses?page=${page}&page_size=${pageSize}`
    );

    return { courses: data?.results, totalItems: data?.count };
  },
  async addCourse(payload) {
    try {
      let response = await makeAxiosRequest(
        "POST",
        `${this._url}/courses`,
        payload
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  /**
   * Get Courses in which student enrolled
   */
  GetCoursesByStudent(page, pageSize) {
    const axiosPrivate = useAxiosPrivate();
    const [courses, setCourses] = useState({
      courses: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCoursesByStudent(page, pageSize, axiosPrivate))
        .then((res) => {
          setCourses(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      courses_data: courses,
    };
  },
  async getCoursesByStudent(page, pageSize, axiosPrivate) {
    const { data } = await axiosPrivate.get(
      `courses/std/getall?page=${page}&itemsPerPage=${pageSize}`
    );
    return { courses: data?.items, totalItems: data?.totalItems };
  },

  /**
   * Get Courses by teacher
   */
  GetCoursesByTeacher(page, pageSize, reload) {
    const axiosPrivate = useAxiosPrivate();
    const [courses, setCourses] = useState({
      courses: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getCoursesByTeacher(page, pageSize, axiosPrivate))
        .then((res) => {
          setCourses(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      courses_data: courses,
    };
  },
  async getCoursesByTeacher(page, pageSize, axiosPrivate) {
    const { data } = await axiosPrivate.get(
      `courses/tchr/getall?page=${page}&itemsPerPage=${pageSize}`
    );

    return { courses: data?.items, totalItems: data?.totalItems };
  },

  async searchCourses({ page, pageSize, searchText, axiosPrivate }) {
    try {
      const response = await axiosPrivate.get(
        `${this._url}/courses?page=${page}&page_size=${pageSize}&searchText=${searchText}`
      );

      return {
        courses: response?.data?.results,
        totalItems: response?.data?.count,
      };
    } catch (err) {
      throw new Error(err?.response?.data?.message ?? err?.message);
    }
  },
};

export default CoursesService;
