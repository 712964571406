import React, { useMemo, useState } from "react";
import CoursesService from "../services/courses.service";
import Loading from "components/atoms/Loading";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import CardTem from "components/atoms/Card";
import TablePagination from "components/atoms/Pagination";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
      justifyContent: "center",
    },
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginTop: "5px",
    color: "var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "10px 0",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
    "@media (max-width: 600px)": {
      margin: "10px auto",
    },
  },
});

export default function TeacherCourses() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [clickedIndex, setClickedIndex] = useState({});
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    filterText: "",
  });
  const { page, pageSize } = searchQuery;
  const { loading, courses_data } = CoursesService.GetCourses({
    page,
    pageSize,
    reload,
  });

  const { totalItems, courses } = useMemo(
    () => ({
      courses: courses_data?.courses,
      totalItems: courses_data.totalItems,
    }),
    [courses_data]
  );

  // const handleClick = (index, id) => () => {
  //   setClickedIndex((state) => ({
  //     ...state, // <-- copy previous state
  //     [index]: !state[index], // <-- update value by index key
  //   }));
  //   navigate(`/teacher/courses/${id}`);
  // };

  const handleClickOpenDetail = (id) => {
    navigate(`/teacher/courses/${id}`);
  };
  return (
    <>
      <Box sx={{ position: "relative", height: "100%" }}>
        <ToastContainer autoClose={3000} />
        <Box className={classes.btnBox}>
          <Box>
            <Typography className={classes.pageTitle}>
              Published Course
            </Typography>
          </Box>
        </Box>

        {loading === true ? (
          <div>
            <Loading />
          </div>
        ) : totalItems > 0 ? (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              >
                {courses?.map((courseData, i) => (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                      <CardTem
                        data={courseData}
                        handleClickOpenDetail={() =>
                          handleClickOpenDetail(courseData?.id)
                        }
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
            <div
              className="my-3"
              style={{
                position: "absolute",
                bottom: "0",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <TablePagination
                count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
                page={page}
                onChange={(e, v) =>
                  setSearchQuery((prev) => ({ ...prev, page: v }))
                }
              />
            </div>
          </>
        ) : (
          <h2 className="text-center">No Record found</h2>
        )}
      </Box>
    </>
  );
}
