import React, { useState } from "react";
import { Box, Button, InputBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const useStyles = makeStyles({
  messageInputBox: {
    display: "flex",
    alignItems: "center",
    height: "60px",
    padding: "10px",
    margin: "20px 10px 20px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#F1F1F1",
    borderRadius: "4px",
    width: "80%",
    "@media (max-width: 600px)": {
      height: "40px",
    },
  },
  messageInput: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  sendBtn: {
    width: "119px",
    height: "60px",
    background: "#18BBA2",
    boxShadow: "0px 23px 24px rgba(0, 0, 0, 0.06)",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#18BBA2",
    },
    "@media (max-width: 600px)": {
      height: "40px",
      width: "fit-content",
    },
  },
  btnText: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "120%",
    color: "#FFFFFF",
    marginRight: "5px",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
});

export default function ChatInput({ handleSendMsg, currentChat }) {
  const classes = useStyles();
  const [msg, setMsg] = useState("");

  const sendMsg = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  return (
    <Container>
      <form className="input-container" onSubmit={(e) => sendMsg(e)}>
        <Box className={classes.messageInputBox}>
          <InputBase
            placeholder="Type your message here"
            className={classes.messageInput}
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          />
        </Box>
        <Button
          className={classes.sendBtn}
          type="submit"
          disabled={currentChat?.status === "inactive"}
        >
          <Typography className={classes.btnText}>Send</Typography>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.50978 4.22965L18.0698 8.50965C21.9098 10.4296 21.9098 13.5696 18.0698 15.4896L9.50978 19.7696C3.74978 22.6496 1.39978 20.2896 4.27978 14.5396L5.14978 12.8096C5.36978 12.3696 5.36978 11.6396 5.14978 11.1996L4.27978 9.45965C1.39978 3.70965 3.75978 1.34965 9.50978 4.22965Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.43994 12H10.8399"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </form>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  grid-template-columns: 5% 95%;
  padding: 0 2rem;
  background-color: var(--input-background-color);
  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    padding: 0 1rem;
    gap: 1rem;
  }
  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
