import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useState } from "react";
import { Form, Container } from "react-bootstrap";
import "./forms.css";
import { EditorState, Modifier } from "draft-js";
import { convertToHTML } from "draft-convert";
import MyEditor from "components/atoms/TestEditor";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, Button, Grid, Box } from "@mui/material";
import TagsInput from "./TagsInput";
import Select from "components/atoms/Select";
import { resourcesOption } from "docs/data";
import parse from "html-react-parser";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#FFFFFF",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const AddResources = ({ handleClose, toast, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    titleErr: "",
    descriptionErr: "",
    typeErr: "",
    fileError: "",
    imageErr: "",
  });
  const { titleErr, descriptionErr, typeErr, urlError, fileError, imageErr } =
    validationErrors;

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleErr: "Please provide title",
      }));
    }
    if (!description) {
      setValidationErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (description.length < 8) {
      setValidationErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (!type?.value) {
      setValidationErrors((prev) => ({
        ...prev,
        typeErr: "Please provide type",
      }));
    }

    if (!file && !url) {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "Please provide file or enter url below",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }

    if (
      !title ||
      !description ||
      description.length < 8 ||
      !type?.value ||
      image === null ||
      (!url && !file)
    ) {
      return false;
    }
    return true;
  };

  console.log("type", type);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("description", description);
        url && bodyFormData.append("url", url);
        bodyFormData.append("type", type?.value);
        bodyFormData.append("image", image);
        !url && bodyFormData.append("document", file);
        keywords && bodyFormData.append("keywords", JSON.stringify(keywords));
        const res = await axiosPrivate({
          method: "post",
          url: "/resource",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    } else {
      console.log("error");
    }
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    if (file.size > 10000000) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `Maximum file size 10MB`,
      }));
      return false;
    }
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));

    setImage(e.target.files[0]);
  };

  const handlePastedText = (text, html = "") => {
    // if they try to paste something they shouldn't let's handle it
    if (text.indexOf("text that should not be pasted") != -1) {
      // we'll add a message for the offending user to the editor state
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "nice try, chump!"
      );

      // update our state with the new editor content
      this.onChange(
        EditorState.push(editorState, newContent, "insert-characters")
      );
      return true;
    } else {
      return false;
    }
  };

  const onUploadFile = ({ target }) => {
    if (target.files[0].type !== "application/pdf") {
      setValidationErrors((prev) => ({
        ...prev,
        fileError: "only pdf files are allowed",
      }));
      setFile("");
      return;
    }

    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    setValidationErrors((prev) => ({
      ...prev,
      fileError: "",
    }));
    setFile(target.files[0]);
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setValidationErrors((prev) => ({
      ...prev,
      descriptionErr: "",
    }));
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setDescription(currentContentAsHTML);
  };
  function handleSelectedTags(items) {
    setKeywords(items);
  }

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Form>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Title</Form.Label>
                <div
                  className={
                    titleErr ? classes.inputFieldErr : classes.inputField
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        titleErr: "",
                      }));
                    }}
                  />
                </div>
                {titleErr ? (
                  <Typography className={classes.errorMsg}>
                    {titleErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Select
                  label={
                    <Form.Label className={classes.label}>Type</Form.Label>
                  }
                  open
                  value={type}
                  error={typeErr}
                  errorClass={classes.errorMsg}
                  onChange={(value) => {
                    setType(value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      typeErr: "",
                    }));
                  }}
                  options={resourcesOption}
                  defaultOptions
                />
              </Form.Group>
              {type?.value === "Document" && (
                <Form.Group
                  controlId="formBasicTitle"
                  className={classes.formGroup}
                >
                  <Form.Label className={classes.label}>File(pdf)</Form.Label>
                  <div
                    className={
                      fileError ? classes.inputFieldErr : classes.inputField
                    }
                  >
                    <InputBase
                      className={classes.input}
                      accept="application/pdf"
                      type="file"
                      placeholder="file"
                      onChange={onUploadFile}
                    />
                  </div>
                  {fileError ? (
                    <Typography className={classes.errorMsg}>
                      {fileError}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Form.Group>
              )}
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Url</Form.Label>
                <div
                  className={
                    urlError
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter Url"
                    onChange={(e) => {
                      setUrl(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        fileError: "",
                      }));
                    }}
                  />
                </div>
                {urlError ? (
                  <Typography className={classes.errorMsg}>
                    {urlError}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Image</Form.Label>
                <div
                  className={
                    imageErr ? classes.inputFieldErr : classes.inputField
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="file"
                    accept="image/*"
                    onChange={(e) => onUploadFileChange(e)}
                  />
                </div>
                {imageErr ? (
                  <Typography className={classes.errorMsg}>
                    {imageErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Keywords</Form.Label>
                <TagsInput
                  selectedTags={handleSelectedTags}
                  fullWidth
                  tags={keywords}
                  id="tags"
                  name="Keywords"
                  placeholder="Add keywords"
                />
              </Form.Group>

              <Form.Group
                className={classes.formGroup}
                controlId="formBasicDesc"
              >
                <Form.Label className={classes.label}>Description</Form.Label>
                <MyEditor
                  className={descriptionErr ? "titleErr" : ""}
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  handlePastedText={handlePastedText}
                />
                {descriptionErr ? (
                  <Typography className={classes.errorMsg}>
                    {descriptionErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group style={{ display: "flex", justifyContent: "end" }}>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button className={classes.addBtn} onClick={handleSubmit}>
                    Add
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>{parse(`${description}`)}</Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AddResources;
