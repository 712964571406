import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import profileImage from "../Assets/Images/profile.png";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    position: "relative",
    width: "100%",
    height: "215px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    "@media (max-width: 600px)": {
      height: "fit-content",
      padding: "20px 0",
    },
  },
  upperBox: {
    display: "flex",
    height: "100%",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  cardImage: {
    width: "100px",
    height: "100px",
    filter: "drop-shadow(0px 8px 21px rgba(0, 0, 0, 0.06))",
    borderRadius: "50%",
    margin: "auto 20px",
    "@media (max-width: 600px)": {
      margin: "auto",
    },
  },
  titleBox: {
    height: "100%",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
  cardTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  designation: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: " var(--body-text-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  study: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  viewProfile: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--primary) !important",
    padding: "0",
    marginTop: "10px",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
      background: "transparent",
    },
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
      margin: "10px auto",
    },
  },
  editBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--text-color) !important",
    minWidth: "fit-content!important",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
    },
  },
  deleteBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #E24F57 !important",
    minWidth: "fit-content!important",
  },
  removeBtnBox: {
    position: "absolute",
    bottom: "0",
    display: "flex",
    justifyContent: "end",
    padding: "0 10px",
    width: "100%",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      paddingBottom: "0 !important",
    },
  },
  cardContent: {
    padding: "16px 10px",
    width: "100%",
    "@media (max-width: 600px)": {
      paddingBottom: "0 !important",
    },
  },
});
const ProfileCard = ({
  data,
  handleClickOpenDelete,
  handleClickOpenDetail,
}) => {
  const classes = useStyles();
  const Location = useLocation();
  return (
    <div>
      <Card className={classes.card}>
        <Box className={classes.upperBox}>
          <CardMedia
            className={classes.cardImage}
            component="img"
            image={
              data?.image?.startsWith("/")
                ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${data?.image}`
                : data?.image ?? profileImage
            }
            alt="img"
          />
          <CardContent className={classes.cardContent}>
            <Box className={classes.titleBox}>
              <Box>
                {Location.pathname === "/dashboard/admin" ? (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.cardTitle}
                    >
                      {data?.firstname + " " + data?.lastname}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.cardTitle}
                    >
                      {data?.username}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.designation}
                    >
                      {data?.email}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {Location.pathname === "/dashboard/instructor" ? (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.cardTitle}
                    >
                      {data?.firstname + " " + data?.lastname}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.designation}
                    >
                      {data?.email}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.study}
                    >
                      {data?.designation}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {Location.pathname === "/dashboard/student" ? (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.cardTitle}
                    >
                      {data?.firstname + " " + data?.lastname}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.designation}
                    >
                      {data?.rollNumber}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={classes.study}
                    >
                      {data?.degree}
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                <Button
                  className={classes.viewProfile}
                  onClick={handleClickOpenDetail}
                >
                  View Profile
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Box>
        <Box className={classes.removeBtnBox}>
          <Button className={classes.deleteBtn} onClick={handleClickOpenDelete}>
            Remove
          </Button>
        </Box>
      </Card>
    </div>
  );
};

export default ProfileCard;
