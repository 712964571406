import { ToastContainer, toast } from "react-toastify";
import Model from "components/atoms/Model";
import { useMemo, useState } from "react";
import Loading from "components/atoms/Loading";
import {
  Box,
  Button,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddClasses from "components/Forms/AddClasses";
import ClassesService from "services/classes.service";
import {
  DataGrid,
  gridClasses,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import { getDateObject } from "../helpers/common";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import EditClasses from "components/Forms/EditClasses";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import BtnBox from "components/atoms/BtnBox";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import { useStyles } from "components/Forms/utils";
import { makeStyles } from "@mui/styles";

const useStylesDialogue = makeStyles({
  modelTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  agreeBtn: {
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "10px 5px",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  backBtn: {
    height: "56px",
    width: "191px",
    margin: "10px 5px",
    background: "#fff",
    border: "1px solid var(--admin)",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "var(--admin) !important",
    "&:hover": {
      color: "#fff !important",
      background: "var(--admin)",
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const ClassDetails = ({ index }) => {
  const navigate = useNavigate();

  const handleClickOpenDetail = () => {
    navigate(`/dashboard/classes/${index?.id}`);
  };

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          <div onClick={handleClickOpenDetail}>{index?.title}</div>
        </>
      }
    />
  );
};
const Actions = ({ index, reload }) => {
  const classes = useStylesDialogue();
  const axiosPrivate = useAxiosPrivate();
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = (isReload) => {
    setOpenEdit(false);
  };

  const changeStatus = async (handleCloseDelete) => {
    try {
      const { data } = await axiosPrivate.post(
        `/irsa_classes/changestatus/${deleteId}`,
        {
          status: index?.status === "active" ? "inactive" : "active",
        }
      );
      toast.success(data);
      handleCloseDelete();
      reload("reload");
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response) {
      } else if (err?.request) {
        toast.error(err.request);
      } else {
        toast.error(err.message);
      }
    }
  };

  // delete student model

  const handleClickOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete student model

  return (
    <>
      <Model
        openModel={openEdit}
        handleClose={handleCloseEdit}
        bodyContent={
          <EditClasses
            handleClose={handleCloseEdit}
            toast={toast}
            classesData={index}
            reload={reload}
          />
        }
        title="Edit Class"
      />

      {/* delete course model */}

      <Dialog
        // fullScreen={fullScreen}
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={classes.modelTitle}
        >
          {"Status Confirmation?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.backBtn} onClick={handleCloseDelete}>
            Disagree
          </Button>
          <Button
            onClick={() => changeStatus(handleCloseDelete)}
            className={classes.agreeBtn}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* end delete course model */}

      <Box>
        <EditIcon onClick={handleClickOpenEdit} />
      </Box>
      <Box>
        <Button onClick={() => handleClickOpenDelete(index?.id)}>
          {loading ? (
            <CircularProgress />
          ) : index?.status === "active" ? (
            "Inactive"
          ) : (
            "Active"
          )}
        </Button>
      </Box>
    </>
  );
};

function getFullName(params) {
  return `${params?.row?.teacher_details?.firstname || ""} ${
    params?.row?.teacher_details?.lastname || ""
  }`;
}

function setFullName(params) {
  const [firstname, lastname] = params.row.toString().split(" ");
  return { ...params.row, firstname, lastname };
}

function parseFullName(value) {
  return String(value)
    .split(" ")
    .map((str) => (str?.length > 0 ? str[0].toUpperCase() + str.slice(1) : ""))
    .join(" ");
}

const Classes = () => {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 100,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize } = searchQuery;

  const { loading, classes_data } = ClassesService.GetClasses(
    page,
    pageSize,
    reload
  );

  const { totalItems, classesData } = useMemo(
    () => ({
      classesData: classes_data?.classes,
      totalItems: classes_data?.totalItems,
    }),
    [classes_data]
  );

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <ClassDetails index={params?.row} />
          </div>
        );
      },
    },
    {
      field: "batch",
      width: 100,
      headerName: "Batch",
    },
    {
      field: "year",
      width: 100,
      headerName: "Year",
    },
    {
      field: "startDate",
      type: "date",
      width: 100,
      headerName: "Start Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "endDate",
      type: "date",
      width: 100,
      headerName: "End Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "course",
      headerName: "Course",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              {params?.row?.course_details?.title}
            </div>
          </>
        );
      },
    },
    {
      field: "fullName",
      type: "string",
      width: 200,
      headerName: "Teacher Name",
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Actions
              index={params?.row}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          </div>
        );
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isReload) => {
    setOpen(false);
  };
  return (
    <>
      <ToastContainer autoClose={2000} />
      <BtnBox
        handleClickOpen={handleClickOpen}
        title={"Classes"}
        subtitle={"You Have Added Following Class"}
        btnText={"Add Class"}
      />
      <Model
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddClasses
            handleClose={handleClose}
            toast={toast}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Add Class"
      />

      {loading ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <>
          <div
            style={{
              width: "100%",
              background: "#FBFBFB",
              borderRadius: "4px",
            }}
          >
            <StripedDataGrid
              sx={{
                boxShadow: 2,
                color: "rgba(0, 0, 0, 0.87)",
                "& .MuiDataGrid-cell:hover": {
                  color: "var(--admin)",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              autoHeight
              pageSize={25}
              rowsPerPageOptions={[5]}
              components={{
                Pagination: CustomPagination,
              }}
              rows={classesData}
              getRowId={(classesData) => classesData.id}
              columns={columns}
              ColumnWidth="*"
            />
          </div>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
    </>
  );
};

export default Classes;
