import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./forms.css";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import MyEditor from "components/atoms/TestEditor";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, Button, Grid, Box } from "@mui/material";
import parse from "html-react-parser";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#FFFFFF",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const AddNewsEvents = ({ handleClose, toast, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    titleErr: "",
    contentErr: "",
    imageErr: "",
  });
  const { titleErr, contentErr, imageErr } = validationErrors;

  console.log("yoyo honey singh", content.length);

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleErr: "Please provide title",
      }));
    }
    if (!content) {
      setValidationErrors((prev) => ({
        ...prev,
        contentErr: "Please provide description",
      }));
    }
    if (content.length < 8) {
      setValidationErrors((prev) => ({
        ...prev,
        contentErr: "Please provide description",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (!title || !content || image === null || content.length < 8) {
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("description", content);
        bodyFormData.append("image", image);
        const res = await axiosPrivate({
          method: "post",
          url: "/news",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
      console.log("Validated");
    } else {
      console.log("error");
    }
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    if (file.size > 10000000) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `Maximum file size 10MB`,
      }));
      return false;
    }
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
    setImage(e.target.files[0]);
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setValidationErrors((prev) => ({
      ...prev,
      contentErr: "",
    }));
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setContent(currentContentAsHTML);
  };

  // const handlePastedFiles = (files) => {
  //   const formData = new FormData();
  //   formData.append("file", files[0]);
  //   fetch("/api/uploads", { method: "POST", body: formData })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data.file) {
  //         setEditorState(insertImage(data.file)); //created below
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const insertImage = (url) => {
  //   const contentState = editorState.getCurrentContent();
  //   const contentStateWithEntity = contentState.createEntity(
  //     "image",
  //     "IMMUTABLE",
  //     { src: url }
  //   );
  //   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  //   const newEditorState = EditorState.set(editorState, {
  //     currentContent: contentStateWithEntity,
  //   });
  //   return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  // };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Form>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Title</Form.Label>
                <div
                  className={
                    titleErr ? classes.inputFieldErr : classes.inputField
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        titleErr: "",
                      }));
                    }}
                  />
                </div>
                {titleErr ? (
                  <Typography className={classes.errorMsg}>
                    {titleErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Image</Form.Label>
                <div
                  className={
                    imageErr ? classes.inputFieldErr : classes.inputField
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="file"
                    accept="image/*"
                    onChange={(e) => onUploadFileChange(e)}
                  />
                </div>
                {imageErr ? (
                  <Typography className={classes.errorMsg}>
                    {imageErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group
                className={classes.formGroup}
                controlId="formBasicDesc"
              >
                <Form.Label className={classes.label}>Content</Form.Label>
                <MyEditor
                  className={contentErr ? "titleErr" : ""}
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  // handlePastedFiles={handlePastedFiles}
                />
                {contentErr ? (
                  <Typography className={classes.errorMsg}>
                    {contentErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className={classes.saveBtn}>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    className={classes.addBtn}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>{parse(`${content}`)}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddNewsEvents;
