import React, { useMemo, useState } from "react";
import Model from "components/atoms/Model";
import AddInstructor from "components/Forms/AddInstructor";
import TablePagination from "components/atoms/Pagination";
import InstructorService from "services/instructor.service";
import Loading from "components/atoms/Loading";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Box, Grid } from "@mui/material";
import ProfileCard from "components/atoms/ProfileCard";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import { useNavigate } from "react-router-dom";
import BtnBox from "components/atoms/BtnBox";

export default function Instructor() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 9,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });

  const { page, pageSize } = searchQuery;
  const { loading, instructor_data } = InstructorService.GetInstructor({
    page,
    pageSize,
    reload,
  });

  const deleteInstructor = async (id, onClose) => {
    try {
      const { data } = await axiosPrivate.delete(`teachers/adr/${id}`);
      toast.success(data);
      onClose();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response) {
        /*
          The request was made and the server responded with a status code
          that falls out of the range of 2xx
              */
        toast.error(err?.response?.data?.message);
      } else if (err?.request) {
        // Client never received a response, or request never left
        toast.error(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // throw new Error(`Error: ${err.message}`);
        toast.error(err.message);
      }
    }
  };

  const { totalItems, instructor } = useMemo(
    () => ({
      instructor: instructor_data?.instructor,
      totalItems: instructor_data.totalItems,
    }),
    [instructor_data]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // delete teacher model

  const handleClickOpenDelete = ({ instructorData }) => {
    setData(instructorData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete teacher model

  // teacher details

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/instructor/${id}`);
  };
  // end teacher details

  return (
    <>
      <Box sx={{ position: "relative", height: "100%" }}>
        <ToastContainer autoClose={3000} />
        <BtnBox
          handleClickOpen={handleClickOpen}
          title={"Teacher"}
          subtitle={"You Have Added Following Teacher"}
          btnText={"Add Teacher"}
        />

        <Model
          openModel={open}
          handleClose={handleClose}
          title="Add Teacher"
          bodyContent={
            <AddInstructor
              handleClose={handleClose}
              toast={toast}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
        />

        {/* delete course model */}

        <DeleteConfirmModel
          openDelete={openDelete}
          handleClose={handleCloseDelete}
          onClick={() => deleteInstructor(data?.id, handleCloseDelete)}
        />
        {/* end delete course model */}

        {loading === true ? (
          <div>
            <Loading />
          </div>
        ) : totalItems > 0 ? (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 12, sm: 6, md: 8, lg: 12 }}
              >
                {instructor?.map((instructorData, i) => (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
                      <ProfileCard
                        data={instructorData}
                        handleClickOpenDelete={() =>
                          handleClickOpenDelete({ instructorData })
                        }
                        handleClickOpenDetail={() =>
                          handleClickOpenDetail(instructorData?.id)
                        }
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <h2 className="text-center">No Record found</h2>
        )}
        {totalItems > 0 && (
          <div
            className="my-3"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TablePagination
              count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
              page={page}
              onChange={(e, v) => {
                setSearchQuery((prev) => ({ ...prev, page: v }));
              }}
            />
          </div>
        )}
      </Box>
    </>
  );
}
