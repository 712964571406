import { Navigate, useLocation } from "react-router-dom";
const PrivateRoute = ({ isLoggedIn, user, children }) => {
  const location = useLocation();
  if (isLoggedIn && user.role === "admin") {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
