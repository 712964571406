import BackToTop from "components/atoms/BackToTop/BackToTop";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import StoriesContent from "components/Layout/Stories/StoriesContent";
import StoriesDetail from "components/Layout/Stories/StoriesDetail";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect } from "react";
import StoriesManSec from "../Layout/Stories/StoriesManSec";

const StoriesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <TopNav /> */}
      <Navbar />
      <StoriesManSec />
      <StoriesDetail />
      <StoriesContent />
      <BackToTop />
      <Footer />
    </>
  );
};

export default StoriesPage;
