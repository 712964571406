/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.css";
import logo from "../../Assets/Images/IRSA";
import { FaFax } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div style={{ padding: "0 30px" }}>
        <div className="innerDiv">
          <div className="innerDItem">
            <div className="imageDiv">
              <img src={logo} width="150px" alt="logo" />
            </div>
            <p style={{ color: "#fff", fontWeight: "400", marginTop: "30px" }}>
              Learn, Discern and Govern
            </p>
          </div>
          <div className="innerDItem middleItem">
            <h6 className="follow text-white">FOLLOW US</h6>
            <div>
              <a
                href="https://www.facebook.com/irsacademy.pk"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="iconPath"
                    d="M22 1.1547C23.2376 0.440169 24.7624 0.440169 26 1.1547L42.7846 10.8453C44.0222 11.5598 44.7846 12.8803 44.7846 14.3094V33.6906C44.7846 35.1197 44.0222 36.4402 42.7846 37.1547L26 46.8453C24.7624 47.5598 23.2376 47.5598 22 46.8453L5.21539 37.1547C3.97779 36.4402 3.21539 35.1197 3.21539 33.6906V14.3094C3.21539 12.8803 3.97779 11.5598 5.21539 10.8453L22 1.1547Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M30 14H27C25.6739 14 24.4021 14.5268 23.4645 15.4645C22.5268 16.4021 22 17.6739 22 19V22H19V26H22V34H26V26H29L30 22H26V19C26 18.7348 26.1054 18.4804 26.2929 18.2929C26.4804 18.1054 26.7348 18 27 18H30V14Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCLmZtzhwr2QGQTlQbPZBlKA"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="iconPath"
                    d="M22 1.1547C23.2376 0.440169 24.7624 0.440169 26 1.1547L42.7846 10.8453C44.0222 11.5598 44.7846 12.8803 44.7846 14.3094V33.6906C44.7846 35.1197 44.0222 36.4402 42.7846 37.1547L26 46.8453C24.7624 47.5598 23.2376 47.5598 22 46.8453L5.21539 37.1547C3.97779 36.4402 3.21539 35.1197 3.21539 33.6906V14.3094C3.21539 12.8803 3.97779 11.5598 5.21539 10.8453L22 1.1547Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M34.5396 18.42C34.4208 17.9454 34.1789 17.5106 33.8382 17.1594C33.4976 16.8082 33.0703 16.5532 32.5996 16.42C30.8796 16 23.9996 16 23.9996 16C23.9996 16 17.1196 16 15.3996 16.46C14.9288 16.5932 14.5016 16.8482 14.1609 17.1994C13.8203 17.5506 13.5784 17.9854 13.4596 18.46C13.1448 20.2056 12.9908 21.9763 12.9996 23.75C12.9884 25.537 13.1424 27.3213 13.4596 29.08C13.5906 29.5398 13.8379 29.9581 14.1777 30.2945C14.5176 30.6308 14.9384 30.8738 15.3996 31C17.1196 31.46 23.9996 31.46 23.9996 31.46C23.9996 31.46 30.8796 31.46 32.5996 31C33.0703 30.8668 33.4976 30.6118 33.8382 30.2606C34.1789 29.9094 34.4208 29.4746 34.5396 29C34.852 27.2676 35.0059 25.5103 34.9996 23.75C35.0108 21.963 34.8568 20.1787 34.5396 18.42V18.42Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.75 27.0205L27.5 23.7505L21.75 20.4805V27.0205Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="innerDItem">
            <div style={{ display: "inline-grid" }}>
              <p className="links">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0504 5C16.0272 5.19057 16.9248 5.66826 17.6285 6.37194C18.3322 7.07561 18.8099 7.97326 19.0004 8.95L15.0504 5ZM15.0504 1C17.0797 1.22544 18.972 2.13417 20.4167 3.57701C21.8613 5.01984 22.7724 6.91101 23.0004 8.94L15.0504 1ZM22.0004 16.92V19.92C22.0016 20.1985 21.9445 20.4742 21.8329 20.7293C21.7214 20.9845 21.5577 21.2136 21.3525 21.4019C21.1473 21.5901 20.905 21.7335 20.6412 21.8227C20.3773 21.9119 20.0978 21.9451 19.8204 21.92C16.7433 21.5856 13.7874 20.5341 11.1904 18.85C8.77425 17.3147 6.72576 15.2662 5.19042 12.85C3.5004 10.2412 2.44866 7.27099 2.12042 4.18C2.09543 3.90347 2.1283 3.62476 2.21692 3.36162C2.30555 3.09849 2.44799 2.85669 2.63519 2.65162C2.82238 2.44655 3.05023 2.28271 3.30421 2.17052C3.5582 2.05833 3.83276 2.00026 4.11042 2H7.11042C7.59573 1.99522 8.06621 2.16708 8.43418 2.48353C8.80215 2.79999 9.0425 3.23945 9.11042 3.72C9.23704 4.68007 9.47187 5.62273 9.81042 6.53C9.94497 6.88792 9.97408 7.27691 9.89433 7.65088C9.81457 8.02485 9.62928 8.36811 9.36042 8.64L8.09042 9.91C9.51398 12.4135 11.5869 14.4864 14.0904 15.91L15.3604 14.64C15.6323 14.3711 15.9756 14.1858 16.3495 14.1061C16.7235 14.0263 17.1125 14.0555 17.4704 14.19C18.3777 14.5286 19.3204 14.7634 20.2804 14.89C20.7662 14.9585 21.2098 15.2032 21.527 15.5775C21.8441 15.9518 22.0126 16.4296 22.0004 16.92Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <a href="tel:+92-42-99260346" className="linkDetails">
                  +92-42-99260346
                </a>
              </p>
              <p className="links">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <a
                  href="https://www.google.com/maps/place/Inland+Revenue+Service+Academy/@31.5219719,74.2943154,17z/data=!4m5!3m4!1s0x391903718ac06d7f:0xe58261acc4a27923!8m2!3d31.5219537!4d74.2944925"
                  target="_blank"
                  className="linkDetails"
                  rel="noreferrer"
                >
                  Sutluj Block, Allama Iqbal Town, lahore
                </a>
              </p>
              <p className="links">
                <FaFax style={{ width: "24", height: "24" }} />
                <a className="linkDetails" href="fax:+924299260346">
                  +92-42-99260346
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bottomFooter">
          <div
            style={{
              display: "flex",
              color: "#fff",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <small className="bottomLink">Privacy policy</small>
          </div>
          <div className="copyRight">
            <small className="text-center mt-5">
              &copy; IRSA {new Date().getFullYear()}. All rights reserved.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
