import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Image } from "react-bootstrap";
import image1 from "../../Assets/Images/Banner for short photo on campus and culture.jpg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  gridBox: {
    margin: "80px 0",
    "@media (max-width: 600px)": {
      margin: "0 0 40px 0",
      padding: "0 20px",
    },
  },
  mobileView: {
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse !important",
    },
  },
  imageBox: {
    width: "100%",
    height: "362px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    "@media (max-width: 600px)": {
      width: "100%",
      height: "200px",
    },
  },
  contentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    "@media (max-width: 600px)": {
      width: "100%",
      height: "200px",
    },
  },
  heading: {
    fontWeight: "700 !important",
    fontSize: "32px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "var(--primary) !important",
    marginBottom: "20px !important",
    "@media (max-width: 600px)": {
      fontSize: "28px !important",
    },
  },
  content: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "150% !important",
    textTransform: "capitalize",
    textAlign: "justify",
    textJustify: "inter-word",
    color: "var(--body-text-color) !important",
  },
});

const CampusCultureCard = () => {
  const classes = useStyles();
  return (
    <>
      <Container style={{ padding: "60px 0px" }}>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Hostel
                </Typography>
                <p className={classes.content}>
                  The IRS Academy boasts an impeccable and upgraded hostel
                  accommodation for its trainee officers and alumni. It
                  comprises an old hostel facility comprises 28 rooms
                  specifically reserved for STP (Specialized Training Program)
                  trainees, while the new hostel facility consists of 25 rooms
                  designated for the participants of in-service training. Both
                  hostels are equipped with the latest technology and amenities
                  to provide a comfortable and safe living environment for the
                  trainee officers. The rooms in both hostels are
                  well-furnished, providing each trainee with a comfortable bed,
                  a study desk, and a closet to store belongings. The rooms
                  contain air-condition facility, cold and warm water
                  arrangement. They are well-ventilated and receive adequate
                  natural light. Other facilities include a laundry room,
                  providing trainees with easy access to clean clothes. The
                  hostels are also equipped with 24/7 security to ensure the
                  safety and security of the trainee officers. Overall, the IRS
                  Academy's hostel provides a comfortable and safe living
                  environment, aimed at making the training experience of the
                  trainee officers as pleasant as possible.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Library
                </Typography>
                <p className={classes.content}>
                  The library of the IRS Academy is a vital resource for trainee
                  officers and faculty. It has a vast collection of tax-related
                  books, journals, and periodicals that cater to the needs of
                  students, researchers, and practitioners. The library has a
                  user-friendly interface and provides easy access to
                  information through its digital catalog. Trainees can use the
                  library's resources to develop their knowledge and skills in
                  tax administration. The library also has a study area, where
                  trainees can work individually or in groups. It provides a
                  peaceful and conducive environment for learning and research.
                  The library is staffed by trained librarians who provide
                  assistance in using its resources and answer any questions the
                  trainees may have. With its modern facilities and resources,
                  the library of the Academy is an essential tool for the
                  development of the tax profession.
                </p>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Mess
                </Typography>
                <p className={classes.content}>
                  The Academy mess provides nourishing and well-balanced meals
                  for the trainees. The mess is managed by professional cooks
                  who ensure that the food is hygienic and cooked to perfection.
                  The menu offers a variety of dishes to cater to the diverse
                  dietary requirements of the trainees. There are separate
                  dining areas for students and faculty, providing a comfortable
                  and relaxed atmosphere for everyone to enjoy their meals. The
                  mess operates on a set schedule, ensuring that trainee
                  officers get timely meals and don't miss out on any classes or
                  activities. The mess also provides tea and snacks at
                  designated times to cater to the needs of the trainees. The
                  aim of the mess is to provide a homely and welcoming
                  environment to the trainees and make sure they receive a
                  well-rounded diet during their stay at the Academy.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Sports Complex
                </Typography>
                <p className={classes.content}>
                  The sports complex of the IRS Academy boasts a range of
                  top-notch facilities designed to cater to the fitness and
                  recreational needs of trainee officers. The Academy features a
                  spacious and well-maintained playground, perfect for playing
                  Long Tennis. Additionally, there is a modern gymnasium
                  equipped with the latest fitness equipment, providing a
                  perfect environment for trainees to maintain their physical
                  fitness. The complex also has a swimming pool where trainees
                  can refresh themselves and improve their swimming skills.
                  There are also several indoor game rooms, including badminton,
                  table tennis and billiards, where trainees can engage in
                  friendly competitions and enhance their hand-eye coordination.
                  The sports complex is managed by experienced and well-trained
                  staff who are dedicated to ensuring that the facilities are
                  kept in good condition and are always accessible to trainees.
                  The sports complex operates on a flexible schedule, allowing
                  trainees to make the most of their free time and stay active,
                  even after a long day of training and studying. The sports
                  complex of the training school is an integral part of the
                  training experience, providing trainee officers with the
                  necessary resources and support to maintain their physical and
                  mental well-being, and to engage in fun, healthy activities
                  that promote team spirit and camaraderie.
                </p>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Transport
                </Typography>
                <p className={classes.content}>
                  IRS Academy has a whole range of fleet for the needs of its
                  trainee officers both for moving out of city and commute
                  within the city. Intra city travel for miscellaneous
                  engagements is allowed to the trainee officers of Specialized
                  Training Program. Small and medium-sized vehicles are used for
                  this purpose. However, for inland country study tours,
                  industry visits and field trips, the Academy has two coasters
                  and two hi-roofs.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Auditorium
                </Typography>
                <p className={classes.content}>
                  The Academy Auditorium is a modern and spacious facility
                  designed to host a variety of events and activities. The
                  auditorium is equipped with state-of-the-art sound and
                  lighting systems, comfortable seating arrangements, and a
                  stage suitable for presentations, performances, and lectures.
                  It is also equipped with a multimedia system that includes two
                  large projection screens and high-quality speakers. The
                  auditorium provides a comfortable and elegant setting for
                  students, faculty, and staff to come together for events such
                  as guest lectures, mess nights, and presentations.
                </p>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Computer Lab
                </Typography>
                <p className={classes.content}>
                  The Academy’s computer Lab is a modern and well-equipped
                  facility that provides students with access to the latest
                  technology and software. The lab features high-performance
                  computers, fast internet connectivity, and a wide range of
                  software applications for students to use. Whether students
                  are working on a class project, conducting research, or simply
                  practicing their computer skills, they will find everything
                  they need in this lab. The lab is staffed by knowledgeable
                  technicians who are available to assist students with any
                  technical issues or questions they may have. The computer lab
                  is an essential resource for students and is a key part of the
                  university's commitment to providing a comprehensive education
                  experience.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Orchard
                </Typography>
                <p className={classes.content}>
                  IRSA has a newly planted orchard that provides a peaceful and
                  scenic environment for the trainees. The orchard is filled
                  with a variety of fruit trees like mango, oranges, pears, and
                  peaches, providing fresh and healthy options for the trainees.
                  The orchard also serves as an outdoor recreational area for
                  physical activities and relaxation. The trainees can enjoy a
                  walk or jog amidst the greenery and enjoy the fresh air. The
                  orchard also provides a perfect backdrop for picnics and group
                  activities. Overall, the newly planted orchard at the training
                  academy is a great addition to the already existing
                  facilities, promoting a healthy and rejuvenating lifestyle for
                  the trainees.
                </p>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.gridBox}>
          <Grid container spacing={10} className={classes.mobileView}>
            <Grid item sm={6} xs={12}>
              <Box className={classes.imageBox}>
                <Image src={image1} className={classes.image} />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.contentBox}>
              <Box>
                <Typography variant="h4" className={classes.heading}>
                  Extra Curricular
                </Typography>
                <p className={classes.content}>
                  The Academy places a strong emphasis on offering a
                  well-rounded educational experience for their trainee
                  officers. To complement the formal training programs, a
                  variety of extra-curricular activities are organized to
                  enhance the physical, cultural, and intellectual development
                  of the trainees. These activities may include sports
                  tournaments, music and dance performances, debates and quiz
                  competitions, field trips, and volunteer work. The aim is to
                  provide an environment that fosters teamwork, creativity, and
                  leadership skills. Through these activities, trainees can also
                  build relationships with their peers and gain a better
                  appreciation for their country and its people. The
                  extra-curricular activities are an important aspect of the
                  training program and are essential to the overall growth and
                  development of the trainees as individuals.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default CampusCultureCard;
