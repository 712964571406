import { useState, useEffect } from "react";
import { useCancellablePromise } from "../helpers/promiseHandler";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const StudentService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetStudents(page, pageSize, reload) {
    const axiosPrivate = useAxiosPrivate();

    const [students, setStudents] = useState({
      students: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getStudents(page, pageSize, axiosPrivate))
        .then((res) => {
          setStudents(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      students_data: students,
    };
  },
  async getStudents(page, pageSize, axiosPrivate) {
    try {
      const { data } = await axiosPrivate.get(
        `${this._url}/students/adr?page=${page}&page_size=${pageSize}`
      );

      return { students: data?.results, totalItems: data?.count };
    } catch (err) {
      if (err.response) {
        /* 
          The request was made and the server responded with a status code
          that falls out of the range of 2xx
        */
        throw err.response.data;
      } else if (err.request) {
        // Client never received a response, or request never left
        throw err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Error: ${err.message}`);
      }
    }
  },
};

export default StudentService;
