import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Robot from "../../components/Assets/Images/robot.gif";

export default function Welcome() {
  const [userName, setUserName] = useState("");
  useEffect(() => {
    async function fetchData() {
      setUserName(
        await JSON.parse(localStorage.getItem("chat-app-user")).username
      );
    }
    userName && fetchData();
  }, []);

  return (
    <Container>
      <img src={Robot} alt="" />
      <h1 style={{ color: "#000", fontWeight: "700", fontSize: "2em" }}>
        Welcome, <span>{userName}!</span>
      </h1>
      <h3
        style={{
          color: "#000",
          textTransform: "none",
          fontWeight: "600",
          fontSize: "1.17em",
        }}
      >
        Please select a chat to start messaging.
      </h3>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #fff;
  flex-direction: column;
  border-radius: 4px;
  margin: 15px;
  height: 80vh;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 768px) {
    display: none;
  }
  img {
    height: 20rem;
  }
  span {
    color: #4e0eff;
  }
`;
