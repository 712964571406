import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./menu.css";

const NavMenu = () => {
  const [show, setShow] = useState(false);
  const [showStickyNav, setShowStickyNav] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, []);

  return (
    <>
      <div
        className={!show ? "fullHeader" : "fullHeader show-offNav-fullHeader"}
      >
        <div className={!show ? "offNav" : "offNav show-offNav"}>
          <ul className="dropList unstyled">
            <li>
              <Link to="/about" className="offNav-a">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="offNav-a">
                About
              </Link>
            </li>
            <li>
              <Link to="/training-programs" className="offNav-a">
                Training Programs
              </Link>
            </li>
            <li>
              <Link to="/faculty" className="offNav-a">
                Faculty
              </Link>
            </li>
            <li>
              <Link to="/login" className="offNav-a">
                LMS
              </Link>
            </li>
            <li>
              <Link to="/campus-culture" className="offNav-a" tabIndex="0">
                Campus Culture
              </Link>
            </li>
            <li>
              <Link to="/library" className="offNav-a">
                Library
              </Link>
            </li>
            <li>
              <Link to="/news" className="offNav-a">
                News &amp; Events
              </Link>
            </li>
            <li>
              <Link to="/stories" className="offNav-a" tabIndex="0">
                Stories
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <div
            className={
              !show
                ? "topHead-btn pause-on-hover"
                : "topHead-btn pause-on-hover change-btn"
            }
            onClick={() => {
              !show ? setShow(true) : setShow(false);
            }}
          >
            <div className="hamBox">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            <div className="menuText eta light" tabIndex="0">
              Menu
            </div>
          </div>
        </div>

        <div
          className={
            scroll
              ? "stickHead mobile-hidden sticky-appear"
              : "stickHead mobile-hidden"
          }
          tabIndex="-1"
        >
          <div
            className={
              showStickyNav && scroll
                ? "offNav-sticky show-offNav-sticky"
                : "offNav-sticky"
            }
            tabIndex="-1"
          >
            <ul className="dropList unstyled">
              <li>
                <Link to="/" className="offNav-a">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="offNav-a">
                  About
                </Link>
              </li>
              <li>
                <Link to="/training-programs" className="offNav-a">
                  Training Programs
                </Link>
              </li>
              <li>
                <Link to="/faculty" className="offNav-a">
                  Faculty
                </Link>
              </li>
              <li>
                <Link to="/login" className="offNav-a">
                  LMS
                </Link>
              </li>
              <li>
                <Link to="/campus-culture" className="offNav-a" tabIndex="0">
                  Campus Culture
                </Link>
              </li>
              <li>
                <Link to="/library" className="offNav-a">
                  Library
                </Link>
              </li>
              {/* <li>
                <Link to="/publication" className="offNav-a">
                  Publication
                </Link>
              </li>
              <li>
                <Link to="/resources" className="offNav-a">
                  Resources
                </Link>
              </li> */}

              <li>
                <Link to="/news" className="offNav-a">
                  News &amp; Events
                </Link>
              </li>
              <li>
                <Link to="/stories" className="offNav-a" tabIndex="0">
                  Stories
                </Link>
              </li>
            </ul>
          </div>
          <div className="stickHead-bg" tabIndex="-1">
            <div className="stickyBtn first-stickyBtn desktop-sticky-links">
              <Link to="/about" tabIndex="-1">
                <span className="white eta light">About</span>
              </Link>
            </div>
            <div className="stickyBtn desktop-sticky-links">
              <Link to="/training-programs">
                <span className="white eta light">Training Programs</span>
              </Link>
            </div>
            <div className="stickyBtn desktop-sticky-links">
              <Link to="/faculty">
                <span className="white eta light">Faculty</span>
              </Link>
            </div>
            <div className="stickyBtn desktop-sticky-links">
              <Link to="/login">
                <span className="white eta light">LMS</span>
              </Link>
            </div>
            <div className="stickyBtn first-stickyBtn tablet-sticky-links">
              <Link className="tablet-hidden" to="/about" tabIndex="-1">
                <span className="white eta light">About</span>
              </Link>
            </div>
            <div className="stickyBtn tablet-sticky-links">
              <Link to="/training-programs" className="tablet-hidden">
                <span className="white eta light">Training Programs</span>
              </Link>
            </div>
            <div className="stickyBtn tablet-sticky-links">
              <Link to="/faculty" className="tablet-hidden">
                <span className="white eta light">Faculty</span>
              </Link>
            </div>
            <div className="stickyBtn tablet-sticky-links">
              <Link to="/login" className="tablet-hidden">
                <span className="white eta light">LMS</span>
              </Link>
            </div>
            {/* <div className="stickySearch" tabIndex="-1">
              <input
                style={{ color: "black" }}
                type="search"
                placeholder="Search"
                className="universal-site-search-query eta light field mainSearchField mainSearchField-sticky"
                tabIndex="-1"
                aria-label="search input"
              />
              <div className="universal-site-search-button mainSearchBtn btn-submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="search-icon-svg"
                  viewBox="-20 -50 220 220"
                  version="1.1"
                  focusable="false"
                >
                  <path d="M137.686,11.342 C137.686,-15.909 115.595,-38 88.344,-38 C61.09,-38 39,-15.909 39,11.342 C39,36.724 58.168,57.607 82.818,60.355 L82.818,72.838 C79.304,74.521 76.898,77.714 76.898,81.419 L76.898,128.148 C76.898,133.588 82.023,138 88.345,138 C94.667,138 99.789,133.589 99.789,128.148 L99.789,81.419 C99.789,77.711 97.382,74.521 93.869,72.838 L93.869,60.354 C118.515,57.606 137.686,36.726 137.686,11.342 Z M56.032,11.225 C56.032,-6.759 70.606,-21.338 88.589,-21.338 C106.572,-21.338 121.142,-6.759 121.142,11.225 C121.142,29.205 106.572,43.78 88.589,43.78 C70.606,43.78 56.032,29.205 56.032,11.225 Z"></path>
                </svg>
              </div>
            </div> */}
            <div
              className={showStickyNav ? "stickyHam change-btn" : "stickyHam"}
              tabIndex="-1"
              onClick={() => {
                !showStickyNav
                  ? setShowStickyNav(true)
                  : setShowStickyNav(false);
              }}
            >
              <div className="hamBox">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
              <div className="menuText eta light" tabIndex="-1">
                Menu
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavMenu;
