import styled from "styled-components/macro";
import { ToastContainer } from "react-toastify";

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

export const Heading = styled.h1`
  color: var(--primary);
  font-weight: 700;
  letter-spacing: 1px;
`;

export const Subtitle = styled.p`
  color: var(--gray);
  font-size: 16px;
  width: 50%;
  margin: 0 auto;
`;

export const StyledToastContainer = styled(ToastContainer)`
  z-index: var(--z-90);

  .Toastify__toast {
    padding: 0;
    min-height: 0;
    border-radius: 8px;
    font-family: inherit;
  }
  .Toastify__toast--default {
    background: none;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`;
