import * as React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";

export default function TablePagination({ count, page, onChange }) {
  return (
    <Stack spacing={2}>
      <Pagination
        count={count}
        page={page}
        variant="outlined"
        showFirstButton
        showLastButton
        onChange={onChange}
      />
    </Stack>
  );
}
