import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Slider from "react-slick";
import CardTem from "components/atoms/Card";
import { useNavigate } from "react-router-dom";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const useStyles = makeStyles({
  container: {
    margin: "30px 10px",
  },
  headingText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "var(--primary) !important",
  },
  statisticsText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  text: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120% !important",
    color: "var(--body-text-color) !important",
    margin: "10px 0",
  },
  greetingBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "172px",
    display: "flex",
    alignItems: "center",
  },
  statsBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "100%",
    padding: "20px 10px",
  },
  calenderBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
  },
  statisticsBox: {
    background: "#FFFFFF",
    margin: "15px 7px",
    padding: "20px 10px",
    height: "fir-content",
    borderRadius: "4px",
  },
  assignmentsBox: {
    background: "#FFFFFF",
    height: "349px",
    borderRadius: "4px",
  },
  progressTitle: {
    fontWeight: "700 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #7A8883 !important",
    margin: "5px 0",
  },
  eventsTitle: {
    fontWeight: "700 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#2C3935 !important",
    margin: "5px 0",
  },
  eventsSubTitle: {
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#7A8883 !important",
    margin: "5px 0",
  },
});

const Home = () => {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState();
  const [courses, setCourses] = useState();
  const [events, setEvents] = useState();

  useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        const { data } = await axiosPrivate.get("student/stats");
        setPrograms(data?.programs);
        setCourses(data?.courses);
        setEvents(data?.events);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err?.response?.data?.message ?? err?.message);
      }
    };
    getStatistics();
  }, []);

  const getProfile = async () => {
    try {
      const response = await axiosPrivate.get(
        `${localStorage.getItem("route")}/me`,
        {
          withCredentials: true,
        }
      );
      setProfile(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getProfile();
  }, []);

  const handleClickOpenDetail = (id) => {
    navigate(`/student/courses/${id}`);
  };
  const handleClickOpenDetailPrograms = (id) => {
    navigate(`/student/programs/${id}`);
  };

  return (
    <>
      <Box className={classes.container}>
        <Row>
          <Col xs={12} sm={8}>
            <Box className={classes.greetingBox}>
              <Box sx={{ padding: "0 20px" }}>
                <Typography className={classes.headingText}>
                  greetings {profile?.studentuser?.firstname}!
                </Typography>
                <Typography className={classes.text}>
                  welcome to IRSA LMS which is Online Certificate courses, led
                  by award-winning faculty, to help you master essential
                  business concepts.
                </Typography>
              </Box>
              <Box sx={{ padding: "0 20px" }}>
                <svg
                  width="155"
                  height="119"
                  viewBox="0 0 155 119"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7317 44.3455L3.85227 59.4259C-14.0281 95.242 45.5954 109.248 64.8213 101.701C98.3353 88.545 96.6542 119.445 133.101 88.4287C139.59 82.9061 152.809 67.3864 154.749 52.7163C155.541 46.7302 147.33 35.7284 136.875 24.5219C118.959 5.31674 91.4779 -0.589257 65.8901 5.33512C43.5725 10.5024 24.3549 24.6052 12.7317 44.3455Z"
                    fill="#F4FFFD"
                  />
                  <path
                    d="M59.0396 82.6672C58.2811 82.6672 57.556 82.4324 56.9404 81.9849C55.8504 81.1932 55.2934 79.8255 55.5203 78.4974L56.8214 70.9227L51.3173 65.5582C50.3367 64.5982 49.9924 63.1956 50.4144 61.8977C50.8238 60.6252 51.9519 59.6716 53.2863 59.4717L60.8991 58.369L64.301 51.4765C64.8737 50.3167 66.1002 49.5947 67.4997 49.5947C68.8991 49.5947 70.124 50.3151 70.6968 51.4781L74.1002 58.369L81.7067 59.4717C83.0633 59.6732 84.1644 60.603 84.5865 61.8993C85.007 63.1972 84.6611 64.5983 83.6868 65.555L78.1779 70.9227L79.4774 78.4959C79.7059 79.8223 79.149 81.1916 78.0589 81.9834C76.9784 82.7656 75.4965 82.8703 74.3112 82.2626L67.4997 78.6783L60.6961 82.2578C60.1867 82.526 59.6139 82.6672 59.0396 82.6672ZM67.4997 51.9747C67.1173 51.9747 66.6365 52.1207 66.435 52.5301L62.7571 59.9826C62.5842 60.3349 62.2494 60.5776 61.8607 60.6332L53.6322 61.8263C53.1911 61.893 52.8151 62.2071 52.6786 62.6308C52.5374 63.0655 52.6532 63.5336 52.9801 63.8557L58.9301 69.6534C59.2109 69.9263 59.3379 70.3214 59.2728 70.7069L57.8654 78.8973C57.7909 79.3384 57.9749 79.7938 58.3367 80.0571C58.6984 80.3189 59.1951 80.357 59.5886 80.1492L66.9443 76.2793C67.2918 76.0968 67.7044 76.0968 68.0518 76.2793L75.4092 80.1492C75.7964 80.3475 76.2977 80.3158 76.6595 80.0556C77.0213 79.7938 77.2053 79.3384 77.1292 78.8989L75.7234 70.7069C75.6567 70.3214 75.7852 69.9263 76.0661 69.6534L82.0193 63.8525C82.3429 63.5352 82.4588 63.0655 82.3191 62.6324C82.1795 62.2024 81.8114 61.8914 81.3576 61.8248L73.1355 60.6316C72.7468 60.576 72.412 60.3333 72.239 59.981L68.5596 52.5285C68.3612 52.1207 67.8821 51.9747 67.4997 51.9747Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M67.4998 48.5034C65.8671 48.5034 64.5391 47.1753 64.5391 45.5442V43.3293C64.5391 41.6982 65.8671 40.3701 67.4998 40.3701C69.1309 40.3701 70.4589 41.6982 70.4589 43.3293V45.5442C70.4605 47.1753 69.1325 48.5034 67.4998 48.5034ZM67.4998 42.7485C67.1793 42.7485 66.9191 43.0087 66.9191 43.3277V45.5426C66.9191 45.8616 67.1793 46.1218 67.4998 46.1218C67.8187 46.1218 68.0789 45.8616 68.0789 45.5426V43.3277C68.0805 43.0087 67.8203 42.7485 67.4998 42.7485Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M67.4998 94.6505C65.8671 94.6505 64.5391 93.3208 64.5391 91.6882V89.4748C64.5391 87.8437 65.8671 86.5156 67.4998 86.5156C69.1309 86.5156 70.4589 87.8437 70.4589 89.4748V91.6882C70.4605 93.3208 69.1325 94.6505 67.4998 94.6505ZM67.4998 88.8956C67.1793 88.8956 66.9191 89.1558 66.9191 89.4748V91.6882C66.9191 92.0087 67.1793 92.2705 67.4998 92.2705C67.8187 92.2705 68.0789 92.0087 68.0789 91.6882V89.4748C68.0805 89.1558 67.8203 88.8956 67.4998 88.8956Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M83.0324 54.9368C82.2406 54.9368 81.4981 54.6306 80.9396 54.0705C79.7845 52.9154 79.7845 51.0384 80.9396 49.8833L82.504 48.3188C83.6179 47.2002 85.5742 47.1987 86.6928 48.3204C87.8431 49.4708 87.8431 51.3478 86.6928 52.5045L85.1252 54.0705C84.5667 54.6306 83.8225 54.9368 83.0324 54.9368ZM84.5968 49.8293C84.508 49.8293 84.335 49.8516 84.1891 49.9991L82.6214 51.5667C82.3945 51.7936 82.3945 52.1617 82.6214 52.3886C82.9181 52.6838 83.1466 52.6838 83.4433 52.3886L85.0078 50.8242C85.2331 50.5973 85.2331 50.2292 85.0078 50.0023C84.857 49.8516 84.6857 49.8293 84.5968 49.8293Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M50.4012 87.5663C49.611 87.5663 48.8668 87.2585 48.3083 86.6984C47.7482 86.1399 47.4404 85.3973 47.4404 84.6056C47.4404 83.8138 47.7482 83.0697 48.3083 82.5112L49.8696 80.9483C50.9914 79.8297 52.9414 79.8297 54.0584 80.9483C55.2119 82.1018 55.2119 83.9789 54.06 85.1371L52.4924 86.7016C51.9339 87.2585 51.1913 87.5663 50.4012 87.5663ZM51.9656 82.462C51.8752 82.462 51.7006 82.4842 51.5531 82.6318L49.9918 84.1946C49.8426 84.3438 49.8204 84.5152 49.8204 84.6056C49.8204 84.696 49.8426 84.8674 49.9902 85.015C50.2885 85.3132 50.5154 85.3132 50.8137 85.015L52.3781 83.4537C52.6034 83.2284 52.6034 82.8571 52.3781 82.6302C52.229 82.4826 52.056 82.462 51.9656 82.462Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M91.679 70.4693H89.4656C87.8329 70.4693 86.5049 69.1413 86.5049 67.5102C86.5065 65.8759 87.8361 64.5479 89.4656 64.5479H91.679C93.3117 64.5479 94.6397 65.8775 94.6397 67.5102C94.6413 68.2987 94.3351 69.0429 93.7766 69.5998C93.2165 70.1615 92.4723 70.4693 91.679 70.4693ZM89.4656 66.9279C89.1451 66.9279 88.8849 67.1897 88.8849 67.5117C88.8849 67.8291 89.1451 68.0893 89.4656 68.0893H91.679C91.8361 68.0893 91.9821 68.029 92.0931 67.9195C92.1566 67.8561 92.2597 67.7196 92.2597 67.5117C92.2597 67.1897 91.9995 66.9279 91.679 66.9279H89.4656Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M45.5319 70.4693H43.3201C41.6874 70.4693 40.3594 69.1413 40.3594 67.5102C40.3578 66.7216 40.6656 65.9759 41.2241 65.4173C41.7842 64.8557 42.5284 64.5479 43.3201 64.5479H45.5319C47.1662 64.5479 48.4942 65.8775 48.4942 67.5102C48.4926 69.1428 47.163 70.4693 45.5319 70.4693ZM43.3201 66.9279C43.1107 66.9279 42.9726 67.0342 42.9092 67.0976C42.8457 67.1611 42.7394 67.2991 42.7394 67.5086C42.7394 67.8291 42.9996 68.0893 43.3201 68.0893H45.5319C45.8524 68.0893 46.1142 67.8291 46.1142 67.5086C46.1142 67.1897 45.8524 66.9279 45.5319 66.9279H43.3201Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M84.5972 87.5679C83.8055 87.5679 83.0629 87.2601 82.5044 86.7L80.9399 85.1403C79.7849 83.9804 79.7864 82.1018 80.9399 80.9483C82.057 79.8297 84.0101 79.8297 85.1272 80.9483L86.6916 82.5128C87.8467 83.6679 87.8467 85.5449 86.6916 86.7C86.1315 87.2601 85.3874 87.5679 84.5972 87.5679ZM83.0328 82.4636C82.9423 82.4636 82.7694 82.4858 82.6218 82.6318C82.3949 82.8587 82.3965 83.23 82.6234 83.4584L84.1847 85.0165C84.483 85.3148 84.7114 85.3132 85.0066 85.0181C85.2335 84.7912 85.2335 84.4231 85.0066 84.1962L83.4421 82.6318C83.2946 82.4842 83.1216 82.4636 83.0328 82.4636Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M51.9662 54.9376C51.176 54.9376 50.4318 54.6314 49.8733 54.0729L48.3089 52.5068C47.1538 51.3502 47.1554 49.4716 48.3105 48.318C49.4227 47.2042 51.3775 47.1947 52.4977 48.3212L54.0621 49.8841C54.6222 50.4442 54.9285 51.1867 54.9285 51.9769C54.9285 52.7671 54.6206 53.5112 54.0621 54.0697C53.5005 54.6314 52.7563 54.9376 51.9662 54.9376ZM50.4017 49.8301C50.3128 49.8301 50.1415 49.8524 49.9939 49.9999C49.7639 50.2284 49.7654 50.5965 49.9923 50.825L51.5552 52.3878C51.8503 52.683 52.0804 52.6846 52.3787 52.3863C52.5262 52.2403 52.5469 52.0673 52.5469 51.9769C52.5469 51.8865 52.5247 51.7135 52.3771 51.566L50.8126 50.0015C50.6635 49.8524 50.4906 49.8301 50.4017 49.8301Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M102.53 110.191H32.4693C31.8124 110.191 31.2793 109.658 31.2793 109.001V26.0152C31.2793 25.3583 31.8124 24.8252 32.4693 24.8252H102.529C103.185 24.8252 103.719 25.3583 103.719 26.0152V109.003C103.72 109.658 103.187 110.191 102.53 110.191ZM33.6593 107.811H101.339V27.2052H33.6593V107.811Z"
                    fill="#18BBA2"
                  />
                  <path
                    d="M78.9584 27.2054H32.4691C31.8884 27.2054 31.3918 26.7849 31.295 26.2121C31.1982 25.6394 31.5314 25.0793 32.082 24.8905L78.5713 8.87464C78.9362 8.75088 79.3361 8.808 79.6502 9.03172C79.9644 9.25544 80.1484 9.61561 80.1484 9.99959V26.0154C80.1484 26.6723 79.6153 27.2054 78.9584 27.2054ZM39.5774 24.8254H77.7684V11.6688L39.5774 24.8254Z"
                    fill="#18BBA2"
                  />
                </svg>
              </Box>
            </Box>
          </Col>
          <Col xs={12} sm={4}>
            <Box className={classes.greetingBox}>
              <Box sx={{ padding: "20px 10px", width: "100%" }}>
                <Typography className={classes.statisticsText}>
                  Learning progress
                </Typography>
                <Box>
                  <Typography className={classes.progressTitle}>
                    Courses Finished
                  </Typography>
                  <ProgressBar variant="success" now={70} label={`${70}%`} />
                </Box>
                <Box>
                  <Typography className={classes.progressTitle}>
                    Lessons Viewed
                  </Typography>
                  <ProgressBar variant="info" now={50} label={`${50}%`} />
                </Box>
                <Box>
                  <Typography className={classes.progressTitle}>
                    Assignments Done
                  </Typography>
                  <ProgressBar variant="warning" now={88} label={`${88}%`} />
                </Box>
              </Box>
            </Box>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col xs={12} sm={8}>
            <Box className={classes.statsBox}>
              <Box sx={{ padding: "20px 10px" }}>
                <Typography className={classes.statisticsText}>
                  Statistics
                </Typography>
              </Box>
            </Box>
          </Col>
          <Col xs={12} sm={4}>
            <Box className={classes.assignmentsBox}>
              <Box sx={{ padding: "20px 20px" }}>
                <Typography className={classes.statisticsText}>
                  Upcoming Events
                </Typography>
                {events?.map((eventsData, i) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="24" cy="24" r="24" fill="#F5FAF8" />
                      <path
                        d="M17.9002 29H30.0902C31.9902 29 32.9902 28 32.9902 26.1V14H14.9902V26.1C15.0002 28 16.0002 29 17.9002 29Z"
                        stroke="#18BBA2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 14H34"
                        stroke="#18BBA2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 34L24 32V29"
                        stroke="#18BBA2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M28 34L24 32"
                        stroke="#18BBA2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.5 23L22.65 20.37C22.9 20.16 23.23 20.22 23.4 20.5L24.6 22.5C24.77 22.78 25.1 22.83 25.35 22.63L28.5 20"
                        stroke="#18BBA2"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Box>
                      <Typography className={classes.eventsTitle}>
                        Maths presentation
                      </Typography>
                      <Typography className={classes.eventsSubTitle}>
                        the basics lesson no 5
                      </Typography>
                    </Box>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" fill="#F5FAF8" />
                      <path
                        d="M12 14L16 18L20 14"
                        stroke="#2C3935"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="24" cy="24" r="24" fill="#F5FAF8" />
                    <path
                      d="M34 22V27C34 32 32 34 27 34H21C16 34 14 32 14 27V21C14 16 16 14 21 14H26"
                      stroke="#1A97EF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M34 22H30C27 22 26 21 26 18V14L34 22Z"
                      stroke="#1A97EF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <Box>
                    <Typography className={classes.eventsTitle}>
                      signature document
                    </Typography>
                    <Typography className={classes.eventsSubTitle}>
                      the basics lesson no 5
                    </Typography>
                  </Box>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#F5FAF8" />
                    <path
                      d="M12 14L16 18L20 14"
                      stroke="#2C3935"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="24" cy="24" r="24" fill="#F5FAF8" />
                    <path
                      d="M17.9002 29H30.0902C31.9902 29 32.9902 28 32.9902 26.1V14H14.9902V26.1C15.0002 28 16.0002 29 17.9002 29Z"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14H34"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 34L24 32V29"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28 34L24 32"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.5 23L22.65 20.37C22.9 20.16 23.23 20.22 23.4 20.5L24.6 22.5C24.77 22.78 25.1 22.83 25.35 22.63L28.5 20"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <Box>
                    <Typography className={classes.eventsTitle}>
                      Maths presentation
                    </Typography>
                    <Typography className={classes.eventsSubTitle}>
                      the basics lesson no 5
                    </Typography>
                  </Box>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#F5FAF8" />
                    <path
                      d="M12 14L16 18L20 14"
                      stroke="#2C3935"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="24" cy="24" r="24" fill="#F5FAF8" />
                    <path
                      d="M17.9002 29H30.0902C31.9902 29 32.9902 28 32.9902 26.1V14H14.9902V26.1C15.0002 28 16.0002 29 17.9002 29Z"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14H34"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 34L24 32V29"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28 34L24 32"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.5 23L22.65 20.37C22.9 20.16 23.23 20.22 23.4 20.5L24.6 22.5C24.77 22.78 25.1 22.83 25.35 22.63L28.5 20"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <Box>
                    <Typography className={classes.eventsTitle}>
                      Maths presentation
                    </Typography>
                    <Typography className={classes.eventsSubTitle}>
                      the basics lesson no 5
                    </Typography>
                  </Box>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#F5FAF8" />
                    <path
                      d="M12 14L16 18L20 14"
                      stroke="#2C3935"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="24" cy="24" r="24" fill="#F5FAF8" />
                    <path
                      d="M17.9002 29H30.0902C31.9902 29 32.9902 28 32.9902 26.1V14H14.9902V26.1C15.0002 28 16.0002 29 17.9002 29Z"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14H34"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 34L24 32V29"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28 34L24 32"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.5 23L22.65 20.37C22.9 20.16 23.23 20.22 23.4 20.5L24.6 22.5C24.77 22.78 25.1 22.83 25.35 22.63L28.5 20"
                      stroke="#18BBA2"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <Box>
                    <Typography className={classes.eventsTitle}>
                      Maths presentation
                    </Typography>
                    <Typography className={classes.eventsSubTitle}>
                      the basics lesson no 5
                    </Typography>
                  </Box>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#F5FAF8" />
                    <path
                      d="M12 14L16 18L20 14"
                      stroke="#2C3935"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
              </Box>
            </Box>
          </Col>
        </Row>
        <Row className={classes.statisticsBox}>
          <Box>
            <Box sx={{ padding: "20px 10px" }}>
              <Typography
                sx={{ marginBottom: "10px" }}
                className={classes.statisticsText}
              >
                Published Programs
              </Typography>
              <Box>
                <Slider {...settings}>
                  {programs?.map((programsData, i) => (
                    <>
                      <CardTem
                        data={programsData}
                        handleClickOpenDetail={() =>
                          handleClickOpenDetailPrograms(programsData?.id)
                        }
                      />
                    </>
                  ))}
                </Slider>
              </Box>
            </Box>
          </Box>
        </Row>
        <Row className={classes.statisticsBox}>
          <Box>
            <Box sx={{ padding: "20px 10px" }}>
              <Typography
                sx={{ marginBottom: "10px" }}
                className={classes.statisticsText}
              >
                Published Course
              </Typography>
              <Box>
                <Slider {...settings}>
                  {courses?.map((courseData, i) => (
                    <>
                      <CardTem
                        data={courseData}
                        handleClickOpenDetail={() =>
                          handleClickOpenDetail(courseData?.id)
                        }
                      />
                    </>
                  ))}
                </Slider>
              </Box>
            </Box>
          </Box>
        </Row>
      </Box>
    </>
  );
};

export default Home;
