import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";

import "./forms.css";
import { InputBase, Typography, Button } from "@mui/material";
import { axiosPrivate } from "api/axios";
import { toast } from "react-toastify";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  select: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  fieldCol: {
    padding: "0 5px",
  },
  change_img: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "10px",
    background: "transparent",
    border: "1px solid var(--admin)",
    color: "var(--admin) !important",
    margin: "10px auto",
    transition: "0.3s",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  responsiveForm: {
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  responsiveImage: {
    padding: "0 5px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  submtBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const EditStudent = ({ handleClose, student, reload }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(student.firstname);
  const [lastName, setFName] = useState(student.lastname);
  const [email, setEmail] = useState(student.email);
  const [phoneNumber, setPhoneNumber] = useState(student.phone_number);
  const [rollNumber, setRollNumber] = useState(student.roll_number);
  const [degree, setDegree] = useState(student.degree);
  const [institute, setInstitute] = useState(student.institute);
  const [dob, setDOB] = useState(student.dob);
  const [joinDate, setJoinDate] = useState(student.join_date);
  const [meritalStatus, setMaritalStatus] = useState(student.marital_status);
  const [gender, setGender] = useState(student?.gender);
  const [batch, setBatch] = useState(student?.batch);
  const [address, setAddress] = useState(student?.address);
  const [domicile, setDomicile] = useState(student.domicile);
  const [province, setProvince] = useState(student.province);
  const [cnic, setCNIC] = useState(student.cnic);
  const [image, setImage] = useState(
    student?.image?.startsWith("/")
      ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${student.image}`
      : student.image
  );
  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const year = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => index + year);

  const [validationErrors, setValidationErrors] = useState({
    firstNameErr: "",
    fNameErr: "",
    emailErr: "",
    phoneNumberErr: "",
    rollNumberErr: "",
    degreeErr: "",
    instituteErr: "",
    dobErr: "",
    joinDateErr: "",
    meritalStatusErr: "",
    genderErr: "",
    batchErr: "",
    addressErr: "",
    domicileErr: "",
    provinceErr: "",
    cnicErr: "",
    imageErr: "",
  });

  const {
    firstNameErr,
    fNameErr,
    emailErr,
    phoneNumberErr,
    rollNumberErr,
    degreeErr,
    instituteErr,
    dobErr,
    joinDateErr,
    meritalStatusErr,
    genderErr,
    batchErr,
    addressErr,
    domicileErr,
    provinceErr,
    cnicErr,
    imageErr,
  } = validationErrors;

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [cropImage, setCropImage] = useState(null);
  const [output, setOutput] = useState(null);

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = cropImage.naturalWidth / cropImage.width;
    const scaleY = cropImage.naturalHeight / cropImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      cropImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setImage(base64Image);
    // // Converting to base64
    // const base64Image = canvas.toDataURL("image/jpeg");
    // setOutput(base64Image);
  };

  const validate = () => {
    if (!firstName) {
      setValidationErrors((prev) => ({
        ...prev,
        firstNameErr: "Please provide name",
      }));
    }
    if (!lastName) {
      setValidationErrors((prev) => ({
        ...prev,
        fNameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setValidationErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!phoneNumber) {
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Please provide phone number",
      }));
    }
    if (phoneNumber.length < 11 || phoneNumber.length > 11) {
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Phone Number must be atleast 11 characters ",
      }));
    }
    if (!rollNumber) {
      setValidationErrors((prev) => ({
        ...prev,
        rollNumberErr: "Please provide roll number",
      }));
    }
    if (!cnic) {
      setValidationErrors((prev) => ({
        ...prev,
        cnicErr: "Please provide cnic",
      }));
    }
    if (cnic.length < 13 || cnic.length > 13) {
      setValidationErrors((prev) => ({
        ...prev,
        cnicErr: "CNIC must be atleast 13 characters ",
      }));
    }
    if (!degree) {
      setValidationErrors((prev) => ({
        ...prev,
        degreeErr: "Please provide degree",
      }));
    }
    if (!institute) {
      setValidationErrors((prev) => ({
        ...prev,
        instituteErr: "Please provide institute",
      }));
    }
    if (!gender) {
      setValidationErrors((prev) => ({
        ...prev,
        genderErr: "Please provide gender",
      }));
    }
    if (!batch) {
      setValidationErrors((prev) => ({
        ...prev,
        batchErr: "Please provide batch",
      }));
    }
    if (!dob) {
      setValidationErrors((prev) => ({
        ...prev,
        dobErr: "Please provide date of birth",
      }));
    }
    if (!joinDate) {
      setValidationErrors((prev) => ({
        ...prev,
        joinDateErr: "Please provide join date",
      }));
    }
    if (!meritalStatus) {
      setValidationErrors((prev) => ({
        ...prev,
        meritalStatusErr: "Please provide marital status",
      }));
    }
    if (!domicile) {
      setValidationErrors((prev) => ({
        ...prev,
        domicileErr: "Please provide domicile district",
      }));
    }
    if (!province) {
      setValidationErrors((prev) => ({
        ...prev,
        provinceErr: "Please provide province",
      }));
    }
    if (!address) {
      setValidationErrors((prev) => ({
        ...prev,
        addressErr: "Please provide address",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      phoneNumber.length < 11 ||
      phoneNumber.length > 11 ||
      cnic.length < 13 ||
      cnic.length > 13 ||
      !rollNumber ||
      !cnic ||
      !degree ||
      !institute ||
      !gender ||
      !batch ||
      !dob ||
      !joinDate ||
      !meritalStatus ||
      !domicile ||
      !province ||
      image === null ||
      !address
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("firstname", firstName);
        bodyFormData.append("lastname", lastName);
        bodyFormData.append("phone_number", phoneNumber);
        bodyFormData.append("degree", degree);
        bodyFormData.append("institute", institute);
        bodyFormData.append("dob", dob);
        bodyFormData.append("join_date", joinDate);
        bodyFormData.append("marital_status", meritalStatus);
        bodyFormData.append("gender", gender);
        bodyFormData.append("batch", batch);
        bodyFormData.append("address", address);
        bodyFormData.append("domicile", domicile);
        bodyFormData.append("province", province);
        bodyFormData.append("cnic", cnic);
        isImageUpdate && bodyFormData.append("image", image);
        const res = await axiosPrivate({
          method: "put",
          url: `std_auth/profile/edit/${student.id}`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.code === 400) {
          toast.error("Validation error occurred");
        } else {
          toast.error(err?.response?.data?.message ?? err?.message);
        }
      }
    }
  };

  const handleChangePhone = (e) => {
    const limit = 11;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhoneNumber(e.target.value.slice(0, limit));
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "",
      }));
    }
  };
  const handleChangeCNIC = (e) => {
    const limit = 13;
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setCNIC(e.target.value.slice(0, limit));
      setValidationErrors((prev) => ({
        ...prev,
        cnicErr: "",
      }));
    }
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    fileToBase64(file, (err, result) => {
      if (result) {
        setImage((prev) => ({
          ...prev,
          image: result,
        }));
        setValidationErrors((prev) => ({
          ...prev,
          imageErr: "",
        }));
      }
    });
    setSrc(URL.createObjectURL(file));
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
    // if (file.size > 1000000) {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     imageErr: `Maximum file size 1MB`,
    //   }));
    //   return false;
    // }
    // setValidationErrors((prev) => ({
    //   ...prev,
    //   imageErr: "",
    // }));
    // setImage(e.target.files[0]);
  };

  return (
    <>
      <Container>
        <Form>
          <Row className={classes.responsiveForm}>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>First Name</Form.Label>
                <div
                  className={
                    firstNameErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => {
                      if (!/^[a-z A-Z]*$/.test(e.target.value)) {
                        setValidationErrors((prev) => ({
                          ...prev,
                          firstNameErr: e.target.value
                            ? "only alphabets are allowed"
                            : "first name can't be empty",
                        }));
                        setFirstName(e.target.value);
                      } else {
                        setFirstName(e.target.value);
                        setValidationErrors((prev) => ({
                          ...prev,
                          firstNameErr: "",
                        }));
                      }
                    }}
                  />
                </div>
                {firstNameErr ? (
                  <Typography className={classes.errorMsg}>
                    {firstNameErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Last Name</Form.Label>
                <div
                  className={
                    fNameErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => {
                      if (!/^[a-z A-Z]*$/.test(e.target.value)) {
                        setValidationErrors((prev) => ({
                          ...prev,
                          fNameErr: e.target.value
                            ? "only alphabets are allowed"
                            : "last name can't be empty",
                        }));
                        setFName(e.target.value);
                      } else {
                        setFName(e.target.value);
                        setValidationErrors((prev) => ({
                          ...prev,
                          fNameErr: "",
                        }));
                      }
                    }}
                  />
                </div>
                {fNameErr ? (
                  <Typography className={classes.errorMsg}>
                    {fNameErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className={classes.responsiveForm}>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Email</Form.Label>
                <div
                  className={
                    emailErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="email"
                    disabled
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        emailErr: "",
                      }));
                    }}
                  />
                </div>
                {emailErr ? (
                  <Typography className={classes.errorMsg}>
                    {emailErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Phone Number</Form.Label>
                <div
                  className={
                    phoneNumberErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="number"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={handleChangePhone}
                  />
                </div>
                {phoneNumberErr ? (
                  <Typography className={classes.errorMsg}>
                    {phoneNumberErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className={classes.responsiveForm}>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Roll Number</Form.Label>
                <div
                  className={
                    rollNumberErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    disabled
                    value={rollNumber}
                    placeholder="Enter roll number"
                    onChange={(e) => {
                      setRollNumber(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        rollNumberErr: "",
                      }));
                    }}
                  />
                </div>
                {rollNumberErr ? (
                  <Typography className={classes.errorMsg}>
                    {rollNumberErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>CNIC</Form.Label>
                <div
                  className={
                    cnicErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="number"
                    value={cnic}
                    placeholder="Enter CNIC"
                    onChange={handleChangeCNIC}
                  />
                </div>
                {cnicErr ? (
                  <Typography className={classes.errorMsg}>
                    {cnicErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Degree</Form.Label>
                <div
                  className={
                    degreeErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    value={degree}
                    placeholder="Enter degree"
                    onChange={(e) => {
                      setDegree(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        degreeErr: "",
                      }));
                    }}
                  />
                </div>
                {degreeErr ? (
                  <Typography className={classes.errorMsg}>
                    {degreeErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Institute</Form.Label>
                <div
                  className={
                    instituteErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    value={institute}
                    placeholder="Enter Institute"
                    onChange={(e) => {
                      setInstitute(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        instituteErr: "",
                      }));
                    }}
                  />
                </div>
                {instituteErr ? (
                  <Typography className={classes.errorMsg}>
                    {instituteErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Join Date</Form.Label>
                <div
                  className={
                    joinDateErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="date"
                    value={joinDate}
                    placeholder="Enter date of birth"
                    onChange={(e) => {
                      setJoinDate(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        joinDateErr: "",
                      }));
                    }}
                  />
                </div>
                {joinDateErr ? (
                  <Typography className={classes.errorMsg}>
                    {joinDateErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className={classes.responsiveForm}>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Gender</Form.Label>
                <Form.Select
                  value={gender}
                  aria-label="Default select example"
                  className={
                    genderErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                  onChange={(e) => {
                    setGender(e.target.value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      genderErr: "",
                    }));
                  }}
                >
                  <option selected disabled>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
                {genderErr ? (
                  <Typography className={classes.errorMsg}>
                    {genderErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Batch</Form.Label>
                <Form.Select
                  value={batch}
                  aria-label="Default select example"
                  className={
                    batchErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                  onChange={(e) => {
                    setBatch(e.target.value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      batchErr: "",
                    }));
                  }}
                >
                  <option selected disabled>
                    Select Batch
                  </option>
                  {years.map((year, index) => {
                    return (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Form.Select>
                {genderErr ? (
                  <Typography className={classes.errorMsg}>
                    {genderErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className={classes.responsiveForm}>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Date of birth</Form.Label>
                <div
                  className={
                    dobErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="date"
                    value={dob}
                    placeholder="Enter date of birth"
                    onChange={(e) => {
                      setDOB(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        dobErr: "",
                      }));
                    }}
                  />
                </div>
                {dobErr ? (
                  <Typography className={classes.errorMsg}>{dobErr}</Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group className="mb-3" controlId="formBasicTitle">
                <Form.Label className={classes.label}>
                  Marital Status
                </Form.Label>
                <Form.Select
                  value={meritalStatus}
                  className={
                    meritalStatusErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                  type="text"
                  placeholder="Enter status"
                  onChange={(e) => {
                    setMaritalStatus(e.target.value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      meritalStatusErr: "",
                    }));
                  }}
                >
                  <option selected disabled>
                    Select Status
                  </option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                </Form.Select>
                {meritalStatusErr ? (
                  <Typography className={classes.errorMsg}>
                    {meritalStatusErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className={classes.responsiveForm}>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Domicile</Form.Label>
                <div
                  className={
                    domicileErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    value={domicile}
                    placeholder="Enter domicile district"
                    onChange={(e) => {
                      setDomicile(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        domicileErr: "",
                      }));
                    }}
                  />
                </div>
                {domicileErr ? (
                  <Typography className={classes.errorMsg}>
                    {domicileErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Province</Form.Label>
                <div
                  className={
                    provinceErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    value={province}
                    placeholder="Enter province"
                    onChange={(e) => {
                      setProvince(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        provinceErr: "",
                      }));
                    }}
                  />
                </div>
                {provinceErr ? (
                  <Typography className={classes.errorMsg}>
                    {provinceErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className={classes.fieldCol}>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Address</Form.Label>
                <div
                  className={
                    addressErr
                      ? `${classes.inputFieldErr}`
                      : `${classes.inputField}`
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    value={address}
                    placeholder="Enter address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        addressErr: "",
                      }));
                    }}
                  />
                </div>
                {addressErr ? (
                  <Typography className={classes.errorMsg}>
                    {addressErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className={classes.fieldCol}>
              {isImageUpdate && (
                <Form.Group
                  controlId="formBasicTitle"
                  className={classes.formGroup}
                >
                  <Form.Label className={classes.label}>Image</Form.Label>
                  <div
                    className={
                      imageErr
                        ? `${classes.inputFieldErr}`
                        : `${classes.inputField}`
                    }
                  >
                    <InputBase
                      className={classes.input}
                      type="file"
                      placeholder="Enter image"
                      accept="image/*"
                      onChange={(e) => onUploadFileChange(e)}
                    />
                  </div>
                  {imageErr ? (
                    <Typography className={classes.errorMsg}>
                      {imageErr}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Form.Group>
              )}
            </Col>
          </Row>
          <Form.Group className={classes.submtBtn}>
            {loading ? (
              <LoadingBtn />
            ) : (
              <Button
                className={classes.addBtn}
                type="submit"
                onClick={handleSubmit}
              >
                Save Changes
              </Button>
            )}
          </Form.Group>
        </Form>
        <Row>
          <Col className={classes.responsiveImage}>
            {!isImageUpdate ? (
              <img src={image} className={classes.UserImage} alt="img" />
            ) : (
              src && (
                <div>
                  <ReactCrop
                    src={src}
                    onImageLoaded={setCropImage}
                    crop={crop}
                    onChange={setCrop}
                  />
                  <br />
                  <button className={classes.addBtn} onClick={cropImageNow}>
                    Crop
                  </button>
                  <br />
                  <br />
                </div>
              )
            )}
            {/* {output && <img src={output} />}
              {!isImageUpdate && (
                <Button
                  className={classes.change_img}
                  disabled
                  onClick={() => {
                    setIsImageUpdate(() => !isImageUpdate);
                    setImage((prev) => ({
                      ...prev,
                      image: null,
                    }));
                  }}
                >
                  Change Image
                </Button>
              )} */}

            {/* {!isImageUpdate && (
                <img
                  src={image}
                  style={{ width: "40%", height: "40%" }}
                  alt="std-img"
                />
              )} */}
            {!isImageUpdate && (
              <Button
                className={classes.change_img}
                onClick={() => {
                  setIsImageUpdate(() => !isImageUpdate);
                  setImage((prev) => ({
                    ...prev,
                    image: null,
                  }));
                }}
              >
                Change Image
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditStudent;
