import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { format } from "date-fns";
import { getDateObject } from "helpers/common";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { FormControlLabel, Typography } from "@mui/material";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
      justifyContent: "center",
    },
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginTop: "5px",
    color: "var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "10px 5px",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
    "@media (max-width: 600px)": {
      margin: "10px auto",
    },
  },
  statusBtn: {
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "0 25px",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
    "@media (max-width: 600px)": {
      margin: "10px auto",
    },
  },
});

const ClassDetails = ({ index }) => {
  const navigate = useNavigate();

  const handleClickOpenDetail = () => {
    navigate(`/teacher/classes/${index?.id}`);
  };

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          <div onClick={handleClickOpenDetail}>{index?.title}</div>
        </>
      }
    />
  );
};

const TeacherClasses = () => {
  const classes = useStyles();
  const { teacherClasses } = useAuth();

  const columns = [
    {
      field: "title",
      flex: 0.5,
      headerName: "Title",
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <ClassDetails index={params?.row} />
          </div>
        );
      },
    },
    {
      field: "batch",
      headerName: "Batch",
    },
    {
      field: "year",
      headerName: "Year",
    },
    {
      field: "startDate",
      type: "date",
      width: 100,
      headerName: "Start Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "endDate",
      type: "date",
      width: 100,
      headerName: "End Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "course",
      headerName: "Course",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.value?.title}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Box className={classes.btnBox}>
        <Box>
          <Typography className={classes.pageTitle}>Classes</Typography>
          <Typography className={classes.subtitle}>
            You Have Assign Following Classes
          </Typography>
        </Box>
      </Box>
      <div
        style={{
          width: "100%",
          background: "#FBFBFB",
          borderRadius: "4px",
        }}
      >
        <DataGrid
          sx={{
            boxShadow: 2,
            color: "rgba(0, 0, 0, 0.87)",
            "& .MuiDataGrid-cell:hover": {
              color: "var(--admin)",
            },
          }}
          autoHeight
          hideFooter={true}
          rows={teacherClasses}
          getRowId={(teacherClasses) => teacherClasses?.id}
          columns={columns}
          ColumnWidth="*"
        />
      </div>
    </>
  );
};

export default TeacherClasses;
