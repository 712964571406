import React, { useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import Model from "components/atoms/Model";
import { useState } from "react";
import Loading from "components/atoms/Loading";
import { Box, Grid } from "@mui/material";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import LibraryCard from "components/atoms/libraryCard";
import EditLibraryContent from "components/Forms/EditLibraryContent";
import AddResources from "components/Forms/AddResources";
import BtnBox from "components/atoms/BtnBox";
import ResourcesService from "services/resources.service";
import EditResources from "components/Forms/EditResources";
import EditorModel from "components/atoms/EditorModel";
import TablePagination from "components/atoms/Pagination";

const Resources = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [filter, setFilter] = useState({
    filterStatus: [],
    filterPrograms: [],
  });
  const { filterStatus, filterPrograms } = filter;
  const [data, setData] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, resources_data } = ResourcesService.GetResources({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterPrograms,
    reload,
  });

  const { totalItems, resources } = useMemo(
    () => ({
      resources: resources_data?.resources,
      totalItems: resources_data.totalItems,
    }),
    [resources_data]
  );

  const reversResources = [...resources].reverse();

  const deleteLibrary = async (id, handleCloseDelete) => {
    try {
      const { data } = await axiosPrivate.delete(`resource/${id}`);
      toast.success(data);
      handleCloseDelete();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // delete news model

  const handleClickOpenDelete = ({ libraryData }) => {
    setData(libraryData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete news model

  // course details

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/resources/${id}`);
  };
  // end course details

  // edit news model

  const handleClickOpenEdit = ({ libraryData }) => {
    setData(libraryData);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  // end edit news model

  return (
    <>
      <ToastContainer autoClose={3000} />
      <BtnBox
        handleClickOpen={handleClickOpen}
        title={"Resources"}
        subtitle={"You Have Added Following Resources"}
        btnText={"Add Resources"}
      />

      <EditorModel
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddResources
            handleClose={handleClose}
            toast={toast}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Publication"
      />

      {/* edit news model */}

      <EditorModel
        openModel={openEdit}
        handleClose={handleCloseEdit}
        bodyContent={
          <EditResources
            handleClose={handleCloseEdit}
            toast={toast}
            resource={data}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Edit Resources"
      />
      {/* end edit news model */}

      {/* delete news model */}

      <DeleteConfirmModel
        openDelete={openDelete}
        handleClose={handleCloseDelete}
        onClick={() => deleteLibrary(data?.id, handleCloseDelete)}
      />

      {/* end delete news model */}
      {loading === true ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          >
            {reversResources?.map((libraryData, i) => (
              <>
                <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                  <LibraryCard
                    data={libraryData}
                    onClick={() => handleClickOpenEdit({ libraryData })}
                    handleClickOpenDelete={() =>
                      handleClickOpenDelete({ libraryData })
                    }
                    handleClickOpenDetail={() =>
                      handleClickOpenDetail(libraryData?.id)
                    }
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
      {totalItems > 0 && (
        <div
          className="my-3"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TablePagination
            count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
            page={page}
            onChange={(e, v) =>
              setSearchQuery((prev) => ({ ...prev, page: v }))
            }
          />
        </div>
      )}
    </>
  );
};

export default Resources;
