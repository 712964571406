import LoadingBtn from "components/atoms/LoadingBtn";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import MyEditor from "components/atoms/TestEditor";
import { convertToHTML } from "draft-convert";
import ProgramService from "services/programs.service";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./forms.css";
import { Box, Button, Grid, InputBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select from "components/atoms/Select";
import parse from "html-react-parser";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  formGroupImage: {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",

    "@media (max-width: 600px)": {
      flexDirection: "column !important",
    },
  },
  change_img: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "10px",
    background: "transparent",
    border: "1px solid var(--admin)",
    color: "var(--admin) !important",
    margin: "10px auto",
    transition: "0.3s",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
    "@media (min-width: 600px)": {
      fontSize: "18px",
    },
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.titleErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldDes: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.descriptionErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "fit-content",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  select: {
    border: "none",
    backgroundColor: "var(--input-background-color)",
    padding: "0",
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const EditCourse = ({ handleClose, course, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(course?.title);
  const [description, setDescription] = useState(course?.description);
  const [program, setProgram] = useState({
    value: course?.program_details?.id,
    label: course?.program_details?.title,
  });
  const [programs, setPrograms] = useState([]);
  const [image, setImage] = useState(
    course?.image?.startsWith("/")
      ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${course.image}`
      : course.image
  );
  const [validationErrors, setValidationErrors] = useState({
    titleErr: "",
    descriptionErr: "",
    imageErr: "",
    programErr: "",
  });
  const { titleErr, descriptionErr, imageErr, programErr } = validationErrors;

  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const blocksFromHTML = convertFromHTML(course.description);
  const EditorContent = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(EditorContent)
  );

  const handleProgramsSearch = async (value) => {
    try {
      const response = await ProgramService.getPrograms({
        page: 1,
        pageSize: 1000,
        searchText: value,
        axiosPrivate,
      });
      const result = response.programs?.map((p) => ({
        value: p.id,
        label: p?.title,
      }));
      setPrograms(result);
      return result;
    } catch (error) {
      console.log("error:", error?.message);
      return [];
    }
  };

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleErr: "Please provide title",
      }));
    }
    if (description === null) {
      setValidationErrors((prev) => ({
        ...prev,
        descriptionErr: "Please provide description",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (!program?.value) {
      setValidationErrors((prev) => ({
        ...prev,
        programErr: "Please provide program",
      }));
    }

    if (!title || description === null || !program?.value || image == null) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("description", description);
        isImageUpdate && bodyFormData.append("image", image);
        bodyFormData.append("program", program?.value);
        const res = await axiosPrivate({
          method: "put",
          url: `courses/${course.id}`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.code === 400) {
          toast.error("Validation error occurred");
        } else {
          toast.error(err?.response?.data?.message ?? err?.message);
        }
      }
    }
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    if (file.size > 10000000) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `Maximum file size 10MB`,
      }));
      return false;
    }
    setValidationErrors((prev) => ({
      ...prev,
      imageErr: "",
    }));
    setImage(e.target.files[0]);
  };

  const classes = useStyles({
    titleErr,
    descriptionErr,
    programErr,
    imageErr,
  });

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setValidationErrors((prev) => ({
      ...prev,
      descriptionErr: "",
    }));
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setDescription(currentContentAsHTML);
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Form>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Title</Form.Label>
                <div className={classes.inputField}>
                  <InputBase
                    className={classes.input}
                    type="text"
                    value={title}
                    placeholder="Enter title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        titleErr: "",
                      }));
                    }}
                  />
                </div>
                {titleErr ? (
                  <Typography className={classes.errorMsg}>
                    {titleErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                className={classes.formGroup}
                controlId="formBasicDesc"
              >
                <Form.Label className={classes.label}>Description</Form.Label>
                <MyEditor
                  className={
                    descriptionErr
                      ? `${classes.inputFieldDesErr}`
                      : `${classes.inputFieldDes}`
                  }
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                />
                {descriptionErr ? (
                  <Typography className={classes.errorMsg}>
                    {descriptionErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroupImage}
              >
                <img
                  src={image}
                  style={{ width: "40%", height: "40%" }}
                  alt="img"
                />
                {!isImageUpdate && (
                  <Button
                    className={classes.change_img}
                    onClick={() => {
                      setIsImageUpdate(() => !isImageUpdate);
                    }}
                  >
                    Change Image
                  </Button>
                )}
                {isImageUpdate && (
                  <Form.Group
                    controlId="formBasicTitle"
                    className={classes.formGroup}
                  >
                    <Form.Label className={classes.label}>Image</Form.Label>
                    <div
                      className={
                        imageErr
                          ? `${classes.inputFieldErr}`
                          : `${classes.inputField}`
                      }
                    >
                      <InputBase
                        className={classes.input}
                        type="file"
                        placeholder="Enter image"
                        accept="image/*"
                        onChange={(e) => onUploadFileChange(e)}
                      />
                    </div>
                    {imageErr ? (
                      <Typography className={classes.errorMsg}>
                        {imageErr}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
              </Form.Group>

              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Select
                  label={
                    <Form.Label className={classes.label}>Program</Form.Label>
                  }
                  open
                  async
                  value={program}
                  error={programErr}
                  errorClass={classes.errorMsg}
                  onChange={(value) => {
                    setProgram(value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      programErr: "",
                    }));
                  }}
                  options={programs}
                  defaultOptions
                  filterOption={false}
                  loadOptions={handleProgramsSearch}
                  menuPortalTarget={document.querySelector("body")}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </Form.Group>

              <Form.Group
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <>
                    <Button
                      className={classes.addBtn}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                  </>
                )}
              </Form.Group>
            </Form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>{parse(`${description}`)}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditCourse;
