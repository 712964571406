import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    padding: "30px !important",
    height: "100vh",
    backgroundImage:
      "url(https://images.unsplash.com/20/cambridge.JPG?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1147&q=80)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 768px)": {
      height: "80vh",
    },
  },
  box: {
    height: "337px",
    background: "#252827",
    width: "666px",
    padding: "10px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",

    "@media (max-width: 768px)": {
      height: "300px",
      width: "600px",
    },
  },
  text: {
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textAlign: "center",
    color: "#FFFFFF !important",

    "@media (max-width: 768px)": {
      fontSize: "30px !important",
    },
  },
});

export default function NewsMainSec() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="100%" className={classes.container}>
        {/* <Box className={classes.box}>
          <Typography className={classes.text}>
            “For over 100 years our graduates & faculty have shaped business
            around the world”
          </Typography>
        </Box> */}
      </Container>
    </React.Fragment>
  );
}
