import React, { useState, useMemo } from "react";
import Card from "./Card";
import NewsService from "../../../../services/news.service";
import { format } from "date-fns";
import Carousel from "react-elastic-carousel";
import "./style.css";

export default function NewsEventsList(props) {
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({
    filterStatus: [],
  });
  const { filterStatus } = filter;
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, news_data } = NewsService.GetNewsLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    reload,
  });

  const { totalItems, news } = useMemo(
    () => ({
      news: news_data?.news,
      totalItems: news_data.totalItems,
    }),
    [news_data]
  );
  const eventsList = news?.map((event, i) => (
    <Card
      key={i}
      isLandingPage={props.isLandingPage ? props.isLandingPage : false}
      style={{ width: "100%", border: "none" }}
      id={event?.id}
      image={event?.image}
      title={event.title}
      description={event.description}
      date={format(new Date(event.created_at), "dd MMM yyy")}
    />
  ));
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 6 },
  ];

  return (
    <>
      <div className="news-eventlist mobile-hidden-news">{eventsList}</div>
      <div className="desktop-hidden-news">
        <Carousel
          itemsToShow={3}
          itemsToScroll={1}
          enableAutoPlay
          autoPlaySpeed={2000}
          focusOnSelect={true}
          breakPoints={breakPoints}
        >
          {news?.map((event, i) => (
            <>
              <Card
                key={i}
                isLandingPage={
                  props.isLandingPage ? props.isLandingPage : false
                }
                style={{ width: "100%", border: "none" }}
                id={event?.id}
                image={event?.image}
                title={event.title}
                description={event.content}
                date={format(new Date(event.created_at), "dd MMM yyy")}
              />
            </>
          ))}
        </Carousel>
      </div>
    </>
  );
}
