import BackToTop from "components/atoms/BackToTop/BackToTop";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import ResourcesContent from "components/Layout/Resources/ResourcesContent";
import ResourcesMainSec from "components/Layout/Resources/ResourcesMainSec";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect } from "react";

const ResourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopNav />
      <Navbar />
      <ResourcesMainSec />
      <ResourcesContent />
      <BackToTop />
      <Footer />
    </>
  );
};

export default ResourcesPage;
