import axios from "../api/axios";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";

const useRefreshToken = () => {
  const { setUser, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const response = await axios.post(
        `${localStorage.getItem("route")}/refresh`,
        {
          withCredentials: true,
          refresh: localStorage.getItem("refresh"),
        }
      );

      setUser((prev) => {
        return {
          ...prev,
          role: localStorage.getItem("role"),
          isSuperAdmin:
            localStorage.getItem("role") === "admin"
              ? JSON.parse(localStorage.getItem("isSuperAdmin"))
              : "",
          user: localStorage.getItem("isSuperAdmin"),
          accessToken: response?.data?.accessToken,
        };
      });
      setIsLoggedIn(true);
      return response.data.accessToken;
    } catch (error) {
      console.log({ refreshError: error });
      if (error?.response?.status === 403) {
        setIsLoggedIn(false);
        navigate("/login", { replace: true });
      }
    }
  };
  return refresh;
};

export default useRefreshToken;
