import { useLocation, Navigate } from "react-router-dom";

export default function PublicRoute({ user, isLoggedIn, children }) {
  const location = useLocation();
  if (isLoggedIn && user?.role === "admin") {
    return (
      <Navigate to="/dashboard/statistics" state={{ from: location }} replace />
    );
  }
  if (isLoggedIn && user?.role === "student") {
    return <Navigate to="/student/home" state={{ from: location }} replace />;
  }
  if (isLoggedIn && user?.role === "teacher") {
    return <Navigate to="/teacher/home" state={{ from: location }} replace />;
  }

  return children;
}
