import BackToTop from "components/atoms/BackToTop/BackToTop";
import NavMenu from "components/Layout/LandingPage/Navbar/Menu";
import React, { useEffect } from "react";
import backgroundImg from "../Assets/Images/LMSHomePagePic.JPG";
import HeroSection from "components/Layout/LandingPage/HeroSection/HeroSection";
import Programs from "components/Layout/LandingPage/Programs/Programs";
import Stories from "components/Layout/LandingPage/Stories/Stories";
import NewsEvents from "components/Layout/LandingPage/News&Events/NewsEvents";
import LibraryBlooming from "components/Layout/LandingPage/LibraryBlooming.js/LibraryBlooming";
import ResearchPublication from "components/Layout/LandingPage/ResearchPublication/ResearchPublication";
import Footer from "components/Layout/Footer/Footer";
import "./pageStyle.css";
import Glimpse from "components/Layout/LandingPage/Glimpse/Glimpse";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavMenu />
      <HeroSection />
      <div
        className="content"
        style={{
          backgroundColor: "#3b3b3b",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <h3
                    className="mb-4"
                    style={{
                      color: "#18bba2",
                    }}
                  >
                    Learning Management System
                  </h3>
                  <p
                    className="text-white"
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    LMS is an academic review and performance analysis database
                    of IRS Academy. It is a Teacher-Student interactive module
                    which provides an overview of the various training courses
                    in different training modules being run in the academy. It
                    includes the profile of teachers and students, description
                    and duration of the courses, attendance, results and overall
                    grading of performance in different training modules. It
                    also includes several news publications and assignments
                    during an academic calendar.
                  </p>
                </div>
                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="border-left p-0">
                    <img
                      className="img"
                      src={backgroundImg}
                      alt="detailsImage"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Programs />
      <Stories />
      {/* <StudentAlumni /> */}
      <NewsEvents />
      {/* <FacultySearch /> */}
      <LibraryBlooming />
      <ResearchPublication />
      {/* <LAtCampus /> */}
      <Glimpse />
      <BackToTop />
      <Footer />
    </>
  );
};

export default LandingPage;
