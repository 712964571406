import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Col, Form, Row } from "react-bootstrap";
import { InputBase, Typography, Box, Button } from "@mui/material";
import LoadingBtn from "components/atoms/LoadingBtn";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import CoursesService from "services/courses.service";
import InstructorService from "services/instructor.service";
import Select from "components/atoms/Select";
import { classBatchOptions, classBatchYearOptions } from "docs/data";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  formGroupDate: {
    display: "grid",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
  },
  dateBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  toBox: {
    display: "flex",
    alignItems: "center",
    margin: "0 10px !important",
    paddingTop: "10px",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: (props) =>
      props.firstNameErr &&
      props.lastNameErr &&
      props.emailErr &&
      props.passwordErr &&
      props.confirmPasswordErr
        ? "0.5px solid var(--error)"
        : "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  textField: {
    color: "var(--text-color) !important",
    width: "100% !important",
  },
  fieldCol: {
    padding: "0 5px",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const EditClasses = ({ handleClose, toast, classesData, reload }) => {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(classesData?.title);
  const [batch, setBatch] = useState({
    value: classesData.batch,
    label: classesData.batch,
  });
  const [batchYear, setBatchYear] = useState({
    value: classesData?.year,
    label: classesData?.year,
  });
  const [startDate, setStartDate] = useState(
    new Date(classesData.startDate)?.toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    classesData?.endDate
      ? new Date(classesData?.endDate)?.toISOString().substring(0, 10)
      : null
  );
  const [course, setCourse] = useState({
    value: classesData?.course_details?.id,
    label: classesData?.course_details?.title,
  });

  const [teacher, setTeacher] = useState(
    classesData?.teacher_details && {
      value: classesData?.teacher_details?.id,
      label:
        classesData?.teacher_details?.firstname +
        " " +
        classesData?.teacher_details?.lastname,
    }
  );
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [validationErrors, setValidationErrors] = useState({
    titleErr: "",
    batchErr: "",
    batchYearErr: "",
    startDateErr: "",
    endDateErr: "",
    courseErr: "",
  });
  const {
    titleErr,
    batchErr,
    batchYearErr,
    startDateErr,
    endDateErr,
    courseErr,
  } = validationErrors;

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleErr: "Please provide title",
      }));
    }
    if (!batch?.value) {
      setValidationErrors((prev) => ({
        ...prev,
        batchErr: "Please provide batch",
      }));
    }
    if (!batchYear?.value) {
      setValidationErrors((prev) => ({
        ...prev,
        batchYearErr: "Please provide batch year",
      }));
    }
    if (startDate == null) {
      setValidationErrors((prev) => ({
        ...prev,
        startDateErr: "Please provide start date",
      }));
    }
    if (endDate == null) {
      setValidationErrors((prev) => ({
        ...prev,
        endDateErr: "Please provide end date",
      }));
    }
    if (startDate > endDate) {
      setValidationErrors((prev) => ({
        ...prev,
        endDateErr: "End date is greater then start date",
      }));
    }
    if (!course?.value) {
      setValidationErrors((prev) => ({
        ...prev,
        courseErr: "Please provide course",
      }));
    }
    if (
      !title ||
      !batch?.value ||
      !batchYear?.value ||
      startDate == null ||
      endDate == null ||
      startDate > endDate ||
      !course?.value
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const { data } = await axiosPrivate.put(
          `/irsa_classes/${classesData?.id}`,
          {
            title,
            batch: batch?.value,
            year: batchYear?.value,
            startDate,
            endDate,
            course: course?.value,
            teacher: teacher?.value ? teacher?.value : undefined,
          }
        );
        setLoading(false);
        toast.success(data);
        handleClose();
        reload("reload");
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data);
      }
    }
  };

  const handleTeacherSearch = async (searchValue) => {
    try {
      const { teachers } = await InstructorService.searchTeachers({
        page: 1,
        pageSize: 1000,
        searchText: searchValue,
        axiosPrivate,
      });
      let options = teachers?.map((ins) => ({
        value: ins?.id,
        label: ins?.firstname + " " + ins?.lastname,
      }));
      options.unshift({ label: "---Select Teacher---", value: "" });
      setTeachers(options);
      return options ?? [];
    } catch {
      return [];
    }
  };
  const handleCourseSearch = async (searchValue) => {
    try {
      const response = await CoursesService.searchCourses({
        page: 1,
        pageSize: 10,
        searchText: searchValue,
        axiosPrivate,
      });
      const options = response?.courses?.map((c) => ({
        value: c?.id,
        label: c?.title,
      }));

      setCourses(() => response.courses);

      return options;
    } catch {
      return [];
    }
  };

  return (
    <>
      <Form>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>Title</Form.Label>
          <div
            className={
              titleErr ? `${classes.inputFieldErr}` : `${classes.inputField}`
            }
          >
            <InputBase
              className={classes.input}
              type="text"
              name="firstName"
              value={title}
              placeholder="Enter title"
              fullWidth
              onChange={(e) => {
                if (e.target.value.length < 3) {
                  setValidationErrors((prev) => ({
                    ...prev,
                    titleErr: !e.target.value
                      ? "Title can't be empty"
                      : e.target.value.length < 3
                      ? "Title must be Minimum 2 characters long"
                      : "Only alphanumeric values are allowed",
                  }));
                  setTitle(e.target.value);
                } else {
                  setTitle(e.target.value);
                  setValidationErrors((prev) => ({
                    ...prev,
                    titleErr: "",
                  }));
                }
              }}
            />
          </div>
          {titleErr ? (
            <Typography className={classes.errorMsg}>{titleErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Row>
          <Col className={classes.fieldCol}>
            <Form.Group
              controlId="formBasicTitle"
              className={classes.formGroup}
            >
              <Select
                label={<Form.Label className={classes.label}>Batch</Form.Label>}
                open
                value={batch}
                error={batchErr}
                errorClass={classes.errorMsg}
                onChange={(value) => {
                  setBatch(value);
                  setValidationErrors((prev) => ({
                    ...prev,
                    batchErr: "",
                  }));
                }}
                options={classBatchOptions}
                defaultOptions
                filterOption={false}
              />
            </Form.Group>
          </Col>
          <Col className={classes.fieldCol}>
            <Form.Group
              controlId="formBasicTitle"
              className={classes.formGroup}
            >
              <Select
                label={<Form.Label className={classes.label}>Year</Form.Label>}
                open
                value={batchYear}
                error={batchYearErr}
                errorClass={classes.errorMsg}
                onChange={(value) => {
                  setBatchYear(value);
                  setValidationErrors((prev) => ({
                    ...prev,
                    batchYearErr: "",
                  }));
                }}
                options={classBatchYearOptions}
                defaultOptions
              />
            </Form.Group>
          </Col>
        </Row>
        <Box className={classes.dateBox}>
          <Form.Group
            controlId="formBasicTitle"
            className={classes.formGroupDate}
          >
            <Form.Label className={classes.label}>Start Date</Form.Label>
            <div
              className={
                startDateErr
                  ? `${classes.inputFieldErr}`
                  : `${classes.inputField}`
              }
            >
              <InputBase
                className={classes.input}
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidationErrors((prev) => ({
                    ...prev,
                    startDateErr: "",
                  }));
                }}
              />
            </div>
            {startDateErr ? (
              <Typography className={classes.errorMsg}>
                {startDateErr}
              </Typography>
            ) : (
              ""
            )}
          </Form.Group>
          <Box className={classes.toBox}>
            <Typography className={classes.label}>To</Typography>
          </Box>
          <Form.Group
            controlId="formBasicTitle"
            className={classes.formGroupDate}
          >
            <Form.Label className={classes.label}>End Date</Form.Label>
            <div
              className={
                endDateErr
                  ? `${classes.inputFieldErr}`
                  : `${classes.inputField}`
              }
            >
              <InputBase
                className={classes.input}
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidationErrors((prev) => ({
                    ...prev,
                    endDateErr: "",
                  }));
                }}
              />
            </div>
            {endDateErr ? (
              <Typography className={classes.errorMsg}>{endDateErr}</Typography>
            ) : (
              ""
            )}
          </Form.Group>
        </Box>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Select
            label={<Form.Label className={classes.label}>Course</Form.Label>}
            open
            async
            value={course}
            error={courseErr}
            errorClass={classes.errorMsg}
            onChange={(value) => {
              setCourse(value);
              setValidationErrors((prev) => ({
                ...prev,
                courseErr: "",
              }));
            }}
            options={courses.map((item) => ({
              value: item.id,
              label: item.title,
            }))}
            defaultOptions
            filterOption={false}
            loadOptions={handleCourseSearch}
            menuPortalTarget={document.querySelector("body")}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Select
            label={
              <Form.Label className={classes.label}>Assign Teacher</Form.Label>
            }
            open
            async
            value={teacher}
            onChange={(value) => {
              setTeacher(value);
            }}
            options={teachers}
            defaultOptions
            filterOption={false}
            loadOptions={handleTeacherSearch}
            menuPortalTarget={document.querySelector("body")}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </Form.Group>
        <Form.Group className={classes.saveBtn}>
          {loading ? (
            <LoadingBtn />
          ) : (
            <Button
              className={classes.addBtn}
              type="submit"
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default EditClasses;
