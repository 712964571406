import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "./ReasearchPub.css";
import { GiEarthAmerica } from "react-icons/gi";
import { BsSearch } from "react-icons/bs";

const data = [
  {
    id: "1",
    title: "Research",
    desc: "Initiatives are research-fueled communities of engagement where faculty, students, and alumni connect with practitioners in the field to develop insights that drive impact.",
  },
  {
    id: "2",
    title: "Publications",
    desc: "Research Projects allow faculty to pursue topics of inquiry that have the potential to make a substantive difference in the world. The forms of knowledge dissemination that broaden the impact of their findings.",
  },
];

const ResearchPublication = () => {
  const [click, setClick] = useState(false);
  const [details, setDetails] = useState("");

  const handleClickOpen = (p) => {
    setClick(true);
    setDetails(p);
  };
  return (
    <>
      <div style={{ textAlign: "center", padding: "20px 0" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {data?.map((d, i) => (
            <Card
              key={i}
              className="text-center"
              style={{ width: "fit-content", border: "none" }}
              onClick={() => handleClickOpen(d)}
            >
              <Card.Body
                className="card-Body"
                style={{
                  backgroundColor:
                    details?.id === d?.id && click ? "#E24F57" : "#3b3b3b",
                }}
              >
                <div
                  style={{
                    background: "#fff",
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                    margin: "0 auto 30px",
                  }}
                >
                  {d?.id === "1" && (
                    <GiEarthAmerica
                      style={{
                        color: "#3b3b3b",
                        width: "44px",
                        height: "44px",
                        margin: "28px 0",
                      }}
                    />
                  )}
                  {d?.id === "2" && (
                    <BsSearch
                      style={{
                        color: "#3b3b3b",
                        width: "44px",
                        height: "44px",
                        margin: "28px 0",
                      }}
                    />
                  )}
                </div>
                <Card.Title style={{ fontWeight: "bold" }}>
                  {d?.title}
                </Card.Title>
                <Card.Text className="text-center text-white">
                  {d?.desc}
                </Card.Text>
                {/* <Card.Link
                href="#"
                className="text-white"
                style={{ textDecoration: "none" }}
              >
                Read More
                <RiShareCircleLine style={{ marginLeft: "20px" }} />
              </Card.Link> */}
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default ResearchPublication;
