import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import Model from "components/atoms/Model";
import AddStudentInClass from "components/Forms/AddStudentInClass";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "components/atoms/Loading";
import img1 from "../components/Assets/Images/background.jpg";
import parse from "html-react-parser";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import moment from "moment/moment";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
      justifyContent: "center",
    },
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginTop: "5px",
    color: "var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "10px 5px",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
    "@media (max-width: 600px)": {
      margin: "10px auto",
    },
  },
  backBtn: {
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.87)",
  },
  classBox: {
    height: "200px",
    background: "#fff",
    marginBottom: "20px",
    boxSizing: "border-box",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: " 1.43",
    letterSpacing: "0.01071em",
    outline: "none",
    display: "flex",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  desText: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: " var(--link-text-color) !important",
    display: "-webkit-box",
    lineClamp: "3",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const Actions = ({ index, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const { id } = useParams();

  const deleteStudent = async (handleCloseDelete) => {
    try {
      const { data } = await axiosPrivate.post("/irsa_classes/removestudent", {
        classId: id,
        student: deleteId,
      });
      toast.success(data);
      handleCloseDelete();
      reload("reload");
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response) {
      } else if (err?.request) {
        toast.error(err.request);
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleClickOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete student model

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          {/* delete course model */}
          <DeleteConfirmModel
            openDelete={openDelete}
            handleClose={handleCloseDelete}
            onClick={() => deleteStudent(handleCloseDelete)}
          />
          {/* end delete course model */}

          <Button onClick={() => handleClickOpenDelete(index?.id)}>
            Remove
          </Button>
        </>
      }
    />
  );
};

function getFullName(params) {
  return `${params.row.firstname || ""} ${params.row.lastname || ""}`;
}

function setFullName(params) {
  const [firstname, lastname] = params.value.toString().split(" ");
  return { ...params.row, firstname, lastname };
}

function parseFullName(value) {
  return String(value)
    .split(" ")
    .map((str) => (str?.length > 0 ? str[0].toUpperCase() + str.slice(1) : ""))
    .join(" ");
}

const ClassDetails = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [students, setStudents] = useState([]);
  const [classData, setClassData] = useState("");

  useEffect(() => {
    async function getTeacherDetails() {
      setLoading(true);
      try {
        const { data } = await axiosPrivate.get(`/irsa_classes/getclass/${id}`);
        setStudents(data?.students);
        setClassData(data?.class);
        setLoading(false);
      } catch (err) {
        if (err?.response) {
          console.log(err?.response);
        } else if (err?.request) {
          console.log(err?.request);
        } else {
          console.log(err?.message);
        }
      }
    }
    getTeacherDetails();
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "roll_number",
      type: "string",
      headerName: "Roll Number",
      width: 120,
    },
    {
      field: "fullName",
      type: "string",
      width: 200,
      headerName: "Full Name",
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "email", headerName: "Email", flex: 1.5 },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Actions
              index={params?.row}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          </div>
        );
      },
    },
  ];

  // end delete course model
  return (
    <>
      <ToastContainer autoClose={3000} />
      <Model
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddStudentInClass
            handleClose={handleClose}
            toast={toast}
            classData={classData}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Add Students"
      />

      <Box className={classes.btnBox}>
        <ArrowBackIcon
          className={classes.backBtn}
          onClick={() => navigate(-1)}
        />
        <Button onClick={handleClickOpen} className={classes.addBtn}>
          <AddIcon />
          Add Student
        </Button>
      </Box>

      <ToastContainer autoClose={3000} />
      <Box className={classes.btnBox}></Box>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <Row>
            <Col xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Class Details
              </Typography>
              <Box className={classes.classBox}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "16px",
                      width: "100%",
                    }}
                  >
                    <CardContent sx={{ padding: "0 !important" }}>
                      <Typography
                        sx={{
                          cursor: "pointer",
                          textAlign: "center",
                          margin: "5px",
                        }}
                        component="div"
                        variant="h5"
                      >
                        Batch:
                        {classData?.batch}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", margin: "5px" }}
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        Start Date:
                        {moment.utc(classData?.startDate).format("MM/DD/YYYY")}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", margin: "5px" }}
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        End Date:
                        {moment.utc(classData?.endDate).format("MM/DD/YYYY")}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          margin: "5px",
                          textTransform: "capitalize",
                        }}
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        Status:
                        {classData?.status}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Box>
            </Col>
            <Col xs={12} sm={8}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Course
              </Typography>
              <Box className={classes.classBox}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",

                      padding: "16px",
                    }}
                  >
                    <CardContent sx={{ padding: "0 !important" }}>
                      {classData?.course_details?.title ? (
                        <Typography
                          sx={{ cursor: "pointer" }}
                          component="div"
                          variant="h5"
                          onClick={() => {
                            navigate(
                              `/teacher/courses/${classData?.course_details?.id}`,
                              {
                                replace: true,
                              }
                            );
                          }}
                        >
                          {classData?.course_details?.title}
                        </Typography>
                      ) : (
                        <h2 className="text-center">No Course Assigned</h2>
                      )}
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        Program:
                        {classData?.course_details?.program_details?.title
                          ? classData?.course_details?.program_details?.title
                          : "Not Assigned"}
                      </Typography>
                      <Box>
                        <Typography className={classes.desText}>
                          {classData?.course_details?.description
                            ? parse(`${classData?.course_details?.description}`)
                            : ""}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Box>
                  <CardMedia
                    component="img"
                    sx={{ width: "30%" }}
                    image={img1}
                    alt="Live from space album cover"
                  />
                </Card>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  width: "100%",
                  background: "#FBFBFB",
                  borderRadius: "4px",
                }}
              >
                <DataGrid
                  sx={{
                    boxShadow: 2,
                    color: "rgba(0, 0, 0, 0.87)",
                    "& .MuiDataGrid-cell:hover": {
                      color: "var(--admin)",
                    },
                  }}
                  autoHeight
                  rows={students}
                  getRowId={(students) => students.id}
                  columns={columns}
                  ColumnWidth="*"
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ClassDetails;
