import React from "react";
import profileImage from "../Assets/Images/profile.png";

const StudentProfile = ({ student }) => {
  return (
    <>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src={student?.image ? student?.image : profileImage}
                    alt="avatar"
                    className="rounded-circle"
                    width="100px"
                    height="100px"
                  />
                  <h5 className="my-3">gwegwegwegw</h5>
                  <p className="my-3">ewegwegweg</p>
                </div>
              </div>
              <div className="card mb-4 mb-lg-0">
                <div className="card-body p-0">
                  <h5 className="my-3 mx-1">Courses</h5>
                  <ul className="list-group list-group-flush rounded-3">
                    {/* {student?.courses?.map((studentCourse, i) => (
                      <li
                        key={i}
                        className="list-group-item d-flex justify-content-between align-items-center p-3"
                      >
                        <p className="mb-0">gewgwegwegweg</p>
                      </li>
                    ))} */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">gewgwegwegweg</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">wgwegwegweg</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">gwegwegweg</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">CNIC</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">gwegwegweg</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Gender</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">gwegwegwegwe</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Marital Status</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">wegwegwegweg</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Date of birth</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">fqwfqwfqwfqw</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Domicile</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">fqwfqwfqw</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Province</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">fqwfqwfqwf</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">RollNumber</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">fqwfqwfqwfq</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Degree</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">qwfqwfqwfqwf</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">Institute</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">fqwfqwfqwfqw</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentProfile;
