import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};
const ClassesService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetClasses(page, pageSize, searchText, reload) {
    const axiosPrivate = useAxiosPrivate();
    const [classes, setClasses] = useState({
      courses: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getClasses(page, pageSize, searchText, axiosPrivate)
      )
        .then((res) => {
          setClasses(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, searchText, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      classes_data: classes,
    };
  },
  async getClasses(page, pageSize, searchText, axiosPrivate) {
    const { data } = await axiosPrivate.get(
      `${this._url}/irsa_classes?page=${page}&page_size=${pageSize}&searchText=${searchText}`
    );

    return { classes: data?.results, totalItems: data?.count };
  },
  async addClass(payload) {
    try {
      let response = await makeAxiosRequest(
        "POST",
        `${this._url}/irsa_classes`,
        payload
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
};

export default ClassesService;
