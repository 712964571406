import React, { useContext } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";

// material-ui
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  Grid,
  Box,
  Typography,
  Button,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AuthContext } from "context/authContext";
import { makeStyles } from "@mui/styles";
import { Form } from "react-bootstrap";

const useStyles = makeStyles({
  gridContainer: {
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse !important",
    },
  },
  form: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f6f4fb !important",
  },
  boxForm: {
    width: "75%",
  },

  formHeading: {
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--heading-color) !important",
  },
  formSubtitle: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    color: "var(--text-color) !important",
  },
  inputDiv: {
    margin: "10px 0",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  submitBtn: {
    margin: "40px 0px !important",
    width: "100%",
    height: "56px",
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    color: "#FFFFFF",
    background: "var(--admin) !important",
    boxShadow: " 0px 14px 28px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "#FFF !important",
      color: "var(--admin) !important",
      border: "1px solid var(--admin)",
    },
  },
  updateLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "120%",
    textTransform: "capitalize",
    color: " var(--admin)",
    textDecoration: "underline !important",
  },
  sideBox: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--admin) !important",

    "@media (max-width: 600px)": {
      height: "20vh",
      padding: "60px",
    },
  },
});

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthResetPassword = () => {
  const location = useLocation();
  const classes = useStyles();
  const email = location?.state?.email;
  const { resetPass, error } = useContext(AuthContext);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik
        initialValues={{
          otp: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          otp: Yup.string()
            .max(255)
            .required("OTP is required")
            .matches(
              "^[0-9]{6}$",
              "OTP contains only digits, Max length 6 digits"
            ),
          password: Yup.string().max(255).required("Password is required"),
          confirmPassword: Yup.string()
            .max(255)
            .required("Confirm password is required")
            .oneOf([Yup.ref("password")], "Passwords do not match"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            resetPass({
              otp: parseInt(values.otp),
              password: values.password,
              email: email,
            });

            setStatus({ success: false });
            setSubmitting(false);
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Grid container className={classes.gridContainer}>
            <Grid item sm={7} xs={12}>
              <Form
                noValidate
                onSubmit={handleSubmit}
                className={classes.form}
                autoComplete="off"
              >
                <Box className={classes.boxForm}>
                  <Typography variant="h3" className={classes.formHeading}>
                    Reset Password
                  </Typography>
                  <Typography
                    variant="subtitle"
                    className={classes.formSubtitle}
                  >
                    Please check your email inbox for an OTP.
                  </Typography>
                  <div className={classes.inputDiv}>
                    <label>OTP</label>
                    <div className={classes.inputField}>
                      <svg
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.31307 0.668464C9.7464 0.668464 11.7281 2.65013 11.7281 5.08596L11.728 6.3998C13.2073 6.91464 14.2715 8.32297 14.2715 9.9758V13.55C14.2715 15.6375 12.574 17.335 10.4865 17.335H4.11898C2.03148 17.335 0.333984 15.6375 0.333984 13.55V9.9758C0.333984 8.32328 1.39775 6.91517 2.87666 6.40009L2.87723 5.08596C2.88223 3.88596 3.3464 2.77846 4.1839 1.94846C5.02223 1.11763 6.1289 0.636798 7.31307 0.668464ZM10.4865 7.4408H4.11898C2.72065 7.4408 1.58398 8.57746 1.58398 9.9758V13.55C1.58398 14.9483 2.72065 16.085 4.11898 16.085H10.4865C11.884 16.085 13.0215 14.9483 13.0215 13.55V9.9758C13.0215 8.57746 11.884 7.4408 10.4865 7.4408ZM7.30257 10.2123C7.64757 10.2123 7.92757 10.4923 7.92757 10.8373V12.6881C7.92757 13.0331 7.64757 13.3131 7.30257 13.3131C6.95757 13.3131 6.67757 13.0331 6.67757 12.6881V10.8373C6.67757 10.4923 6.95757 10.2123 7.30257 10.2123ZM7.31057 1.91846H7.29723C6.45307 1.91846 5.66223 2.24346 5.06473 2.83596C4.46307 3.43096 4.13057 4.22513 4.12723 5.0718L4.12648 6.19033H10.4773L10.4781 5.08596C10.4781 3.3393 9.05723 1.91846 7.31057 1.91846Z"
                          fill="var(--border-color)"
                        />
                      </svg>

                      <InputBase
                        className={classes.input}
                        id="otp"
                        type="text"
                        value={values.opt}
                        name="otp"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter otp"
                        fullWidth
                        error={Boolean(touched.otp && errors.otp)}
                      />
                    </div>
                    {touched.otp && errors.otp && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-otp"
                      >
                        {errors.otp}
                      </FormHelperText>
                    )}
                  </div>
                  <div className={classes.inputDiv}>
                    <label>Password</label>
                    <div className={classes.inputField}>
                      <svg
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.31307 0.668464C9.7464 0.668464 11.7281 2.65013 11.7281 5.08596L11.728 6.3998C13.2073 6.91464 14.2715 8.32297 14.2715 9.9758V13.55C14.2715 15.6375 12.574 17.335 10.4865 17.335H4.11898C2.03148 17.335 0.333984 15.6375 0.333984 13.55V9.9758C0.333984 8.32328 1.39775 6.91517 2.87666 6.40009L2.87723 5.08596C2.88223 3.88596 3.3464 2.77846 4.1839 1.94846C5.02223 1.11763 6.1289 0.636798 7.31307 0.668464ZM10.4865 7.4408H4.11898C2.72065 7.4408 1.58398 8.57746 1.58398 9.9758V13.55C1.58398 14.9483 2.72065 16.085 4.11898 16.085H10.4865C11.884 16.085 13.0215 14.9483 13.0215 13.55V9.9758C13.0215 8.57746 11.884 7.4408 10.4865 7.4408ZM7.30257 10.2123C7.64757 10.2123 7.92757 10.4923 7.92757 10.8373V12.6881C7.92757 13.0331 7.64757 13.3131 7.30257 13.3131C6.95757 13.3131 6.67757 13.0331 6.67757 12.6881V10.8373C6.67757 10.4923 6.95757 10.2123 7.30257 10.2123ZM7.31057 1.91846H7.29723C6.45307 1.91846 5.66223 2.24346 5.06473 2.83596C4.46307 3.43096 4.13057 4.22513 4.12723 5.0718L4.12648 6.19033H10.4773L10.4781 5.08596C10.4781 3.3393 9.05723 1.91846 7.31057 1.91846Z"
                          fill="var(--border-color)"
                        />
                      </svg>

                      <InputBase
                        className={classes.input}
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        id="-password-login"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? <BsEye /> : <BsEyeSlash />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Enter password"
                      />
                    </div>
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </div>
                  <div className={classes.inputDiv}>
                    <label>Confirm Password</label>
                    <div className={classes.inputField}>
                      <svg
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.31307 0.668464C9.7464 0.668464 11.7281 2.65013 11.7281 5.08596L11.728 6.3998C13.2073 6.91464 14.2715 8.32297 14.2715 9.9758V13.55C14.2715 15.6375 12.574 17.335 10.4865 17.335H4.11898C2.03148 17.335 0.333984 15.6375 0.333984 13.55V9.9758C0.333984 8.32328 1.39775 6.91517 2.87666 6.40009L2.87723 5.08596C2.88223 3.88596 3.3464 2.77846 4.1839 1.94846C5.02223 1.11763 6.1289 0.636798 7.31307 0.668464ZM10.4865 7.4408H4.11898C2.72065 7.4408 1.58398 8.57746 1.58398 9.9758V13.55C1.58398 14.9483 2.72065 16.085 4.11898 16.085H10.4865C11.884 16.085 13.0215 14.9483 13.0215 13.55V9.9758C13.0215 8.57746 11.884 7.4408 10.4865 7.4408ZM7.30257 10.2123C7.64757 10.2123 7.92757 10.4923 7.92757 10.8373V12.6881C7.92757 13.0331 7.64757 13.3131 7.30257 13.3131C6.95757 13.3131 6.67757 13.0331 6.67757 12.6881V10.8373C6.67757 10.4923 6.95757 10.2123 7.30257 10.2123ZM7.31057 1.91846H7.29723C6.45307 1.91846 5.66223 2.24346 5.06473 2.83596C4.46307 3.43096 4.13057 4.22513 4.12723 5.0718L4.12648 6.19033H10.4773L10.4781 5.08596C10.4781 3.3393 9.05723 1.91846 7.31057 1.91846Z"
                          fill="var(--border-color)"
                        />
                      </svg>

                      <InputBase
                        className={classes.input}
                        fullWidth
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        id="-password-login"
                        type={showPasswordConfirm ? "text" : "password"}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPasswordConfirm}
                              edge="end"
                              size="large"
                            >
                              {showPasswordConfirm ? <BsEye /> : <BsEyeSlash />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Re-enter password"
                      />
                    </div>
                    {errors.confirmPassword && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.confirmPassword}
                      </FormHelperText>
                    )}
                  </div>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <div className={classes.inputDiv}>
                    <Button
                      type="submit"
                      disableElevation
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.submitBtn}
                    >
                      Reset
                    </Button>
                  </div>
                  {error && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {error}
                    </FormHelperText>
                  )}
                </Box>
              </Form>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box className={classes.sideBox}>
                <svg
                  width="344"
                  height="170"
                  viewBox="0 0 344 170"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 74.7135C0 74.7135 156.06 -26.2808 343.148 74.7135C343.139 74.7135 187.759 9.31361 0 74.7135Z"
                    fill="white"
                  />
                  <path
                    d="M36.0312 81.0146H57.6583V168.481H36.0312V81.0146Z"
                    fill="white"
                  />
                  <path
                    d="M103.302 139.958H94.754V168.481H73.127V81.0146H111.353C128.955 81.0146 142.408 93.8135 142.408 111.057C142.408 122.723 135.995 132.096 126.062 136.787L144.927 168.481H119.021L103.302 139.958ZM94.754 120.949H110.848C116.259 120.949 120.276 116.767 120.276 111.189C120.276 105.734 116.25 101.553 110.848 101.553H94.754V120.949Z"
                    fill="white"
                  />
                  <path
                    d="M147.938 156.313L159.004 139.71C166.297 146.176 176.605 150.612 185.406 150.612C192.324 150.612 198.232 147.827 198.232 143.382C198.232 138.691 191.444 136.917 182.138 134.633C164.031 130.197 151.458 121.579 151.458 106.11C151.458 90.0081 165.164 79.4932 185.658 79.4932C199.112 79.4932 210.3 84.184 216.844 90.2716L206.911 106.874C201.752 102.183 193.335 98.8891 185.406 98.8891C178.487 98.8891 173.712 101.551 173.712 105.354C173.712 109.791 180.5 112.198 188.168 113.972C195.714 115.623 220.356 121.702 220.356 142.495C220.356 158.974 205.142 169.999 185.528 169.999C171.952 169.999 157.244 164.553 147.938 156.313Z"
                    fill="white"
                  />
                  <path
                    d="M281.351 154.663H248.153L243.5 168.481H220.234L252.17 81.0146H277.691L309.627 168.481H285.987L281.351 154.663ZM274.685 134.635L264.752 105.101L254.818 134.635H274.685Z"
                    fill="white"
                  />
                  <path
                    d="M171.541 25.2773C164.692 25.2773 159.133 30.8291 159.133 37.6721C159.133 44.5152 164.683 50.0669 171.541 50.0669C178.39 50.0669 183.949 44.5152 183.949 37.6721C183.949 30.8291 178.39 25.2773 171.541 25.2773ZM171.541 35.2916C172.857 35.2916 173.92 36.3545 173.92 37.6721C173.92 38.9898 172.857 40.0527 171.541 40.0527C170.225 40.0527 169.162 38.9898 169.162 37.6721C169.162 36.3545 170.225 35.2916 171.541 35.2916Z"
                    fill="#F9D100"
                  />
                  <path
                    d="M171.559 0L167.141 14.5517L162.723 29.0685H162.711H162.723L162.711 29.1035H162.769L167.934 32.1793L171.897 34.7191L175.978 32.0627L180.349 29.1035H180.408L180.396 29.0685L180.408 29.0569H180.396L175.989 14.5401L171.559 0Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M171.559 0.0126953L171.733 34.8133L175.977 32.0521L180.349 29.0928H180.407L180.395 29.0579L180.407 29.0463H180.395L175.989 14.5295L171.559 0.0126953Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M171.566 75.5L175.984 60.9483L180.402 46.4315H180.414H180.402L180.414 46.3966H180.356L175.191 43.3208L171.228 40.7809L167.147 43.4372L162.776 46.3966H162.717L162.729 46.4315L162.717 46.4431H162.729L167.136 60.9599L171.566 75.5Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M171.555 75.4785L171.38 40.6779L167.136 43.4391L162.765 46.3984H162.706L162.718 46.4333L162.706 46.445H162.718L167.125 60.9618L171.555 75.4785Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M209.018 37.8887L194.478 33.4091L179.974 28.9295L179.974 28.9179L179.974 28.9295L179.939 28.9177L179.939 28.976L176.836 34.1236L174.276 38.0737L176.914 42.1631L179.854 46.5451L179.854 46.6034L179.889 46.5919L179.9 46.6036L179.9 46.592L194.447 42.2519L209.018 37.8887Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M208.992 37.8877L174.17 37.9092L176.912 42.1622L179.852 46.5442L179.851 46.6024L179.886 46.591L179.898 46.6026L179.898 46.591L194.445 42.251L208.992 37.8877Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M134.095 37.8191L148.663 42.2032L163.196 46.5873L163.196 46.599L163.196 46.5873L163.231 46.5989L163.231 46.5406L166.3 41.3726L168.835 37.4059L166.17 33.3339L163.202 28.9713L163.202 28.913L163.167 28.9247L163.156 28.9131L163.156 28.9247L148.637 33.3603L134.095 37.8191Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M134.115 37.8076L168.937 37.5573L166.167 33.3224L163.199 28.9598L163.199 28.9016L163.164 28.9132L163.152 28.9017L163.152 28.9132L148.634 33.3488L134.115 37.8076Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M171.541 40.0551C172.855 40.0551 173.92 38.9893 173.92 37.6745C173.92 36.3598 172.855 35.2939 171.541 35.2939C170.227 35.2939 169.162 36.3598 169.162 37.6745C169.162 38.9893 170.227 40.0551 171.541 40.0551Z"
                    fill="var(--icons-color)"
                  />
                </svg>
              </Box>
            </Grid>
          </Grid>
          // <form noValidate onSubmit={handleSubmit}>
          //   <Grid container spacing={3}>
          //     <Grid item xs={12}>
          //       <p style={{ color: "red" }}>
          //         * Please check your email inbox for an OTP
          //       </p>
          //       <Stack spacing={1}>
          //         <InputLabel htmlFor="otp">OTP</InputLabel>
          //         <OutlinedInput
          //           id="otp"
          //           type="text"
          //           value={values.opt}
          //           name="otp"
          //           onBlur={handleBlur}
          //           onChange={handleChange}
          //           placeholder="Enter otp"
          //           fullWidth
          //           error={Boolean(touched.otp && errors.otp)}
          //         />
          //         {touched.otp && errors.otp && (
          //           <FormHelperText error id="standard-weight-helper-text-otp">
          //             {errors.otp}
          //           </FormHelperText>
          //         )}
          //       </Stack>
          //     </Grid>
          //     <Grid item xs={12}>
          //       <Stack spacing={1} padding={0}>
          //         <InputLabel htmlFor="password-login">Password</InputLabel>
          //         <OutlinedInput
          //           fullWidth
          //           error={Boolean(touched.password && errors.password)}
          //           id="-password-login"
          //           type={showPassword ? "text" : "password"}
          //           value={values.password}
          //           name="password"
          //           onBlur={handleBlur}
          //           onChange={handleChange}
          //           endAdornment={
          //             <InputAdornment position="end">
          //               <IconButton
          //                 aria-label="toggle password visibility"
          //                 onClick={handleClickShowPassword}
          //                 onMouseDown={handleMouseDownPassword}
          //                 edge="end"
          //                 size="large"
          //               >
          //                 {showPassword ? <BsEye /> : <BsEyeSlash />}
          //               </IconButton>
          //             </InputAdornment>
          //           }
          //           placeholder="Enter password"
          //         />
          //         {touched.password && errors.password && (
          //           <FormHelperText
          //             error
          //             id="standard-weight-helper-text-password-login"
          //           >
          //             {errors.password}
          //           </FormHelperText>
          //         )}
          //       </Stack>
          //       <Stack spacing={1} padding={0}>
          //         <InputLabel htmlFor="confirmpassword">
          //           Confirm Password
          //         </InputLabel>
          //         <OutlinedInput
          //           fullWidth
          //           error={Boolean(
          //             touched.confirmPassword && errors.confirmPassword
          //           )}
          //           id="-password-login"
          //           type={showPassword ? "text" : "password"}
          //           value={values.confirmPassword}
          //           name="confirmPassword"
          //           onBlur={handleBlur}
          //           onChange={handleChange}
          //           endAdornment={
          //             <InputAdornment position="end">
          //               <IconButton
          //                 aria-label="toggle password visibility"
          //                 onClick={handleClickShowPassword}
          //                 onMouseDown={handleMouseDownPassword}
          //                 edge="end"
          //                 size="large"
          //               >
          //                 {showPassword ? <BsEye /> : <BsEyeSlash />}
          //               </IconButton>
          //             </InputAdornment>
          //           }
          //           placeholder="Re-enter password"
          //         />
          //         {errors.confirmPassword && (
          //           <FormHelperText
          //             error
          //             id="standard-weight-helper-text-password-login"
          //           >
          //             {errors.confirmPassword}
          //           </FormHelperText>
          //         )}
          //       </Stack>
          //     </Grid>
          //     {errors.submit && (
          //       <Grid item xs={12}>
          //         <FormHelperText error>{errors.submit}</FormHelperText>
          //       </Grid>
          //     )}
          //     <Grid item xs={12}>
          //       <AnimateButton>
          //         <Button
          //           disableElevation
          //           disabled={isSubmitting}
          //           fullWidth
          //           size="large"
          //           type="submit"
          //           variant="contained"
          //           style={{ background: "var(--secondary)" }}
          //         >
          //           Submit
          //         </Button>
          //       </AnimateButton>
          //     </Grid>
          //   </Grid>
          // </form>
        )}
      </Formik>
    </>
  );
};

export default AuthResetPassword;
