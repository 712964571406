import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { Form, Row, Col, Image } from "react-bootstrap";
import LoadingBtn from "components/atoms/LoadingBtn";
import { useParams } from "react-router-dom";
import { Button, InputBase, Typography, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "components/atoms/Loading";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  backBtn: {
    cursor: "pointer",
    color: "#252827 !important",
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    marginLeft: "20px",
    "@media (max-width: 600px)": {
      margin: "0",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    textTransform: "none",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  formGroupBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
    },
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  editIcon: {
    marginLeft: "10px",
    color: "#252827 !important",
    cursor: "pointer",
  },
  change_img: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "transparent",
    border: "1px solid var(--admin)",
    color: "var(--admin) !important",
    margin: "10px auto",
    transition: "0.3s",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  UserImage: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    display: "flex",
    margin: "0 auto",
  },
  fieldRow: {
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  fieldCol: {
    padding: "0 5px",
  },
});

const ProfileSetting = () => {
  const classes = useStyles();
  const { id } = useParams();
  let navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    rollNumber: "",
    degree: "",
    institute: "",
    dob: "",
    meritalStatus: "",
    gender: "",
    batch: "",
    address: "",
    domicile: "",
    province: "",
    cnic: "",
    image: "",
  });

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    rollNumber,
    degree,
    institute,
    dob,
    meritalStatus,
    gender,
    batch,
    address,
    domicile,
    province,
    cnic,
    image,
  } = state;

  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    firstNameErr: "",
    lastNameErr: "",
    emailErr: "",
    phoneNumberErr: "",
    rollNumberErr: "",
    degreeErr: "",
    instituteErr: "",
    dobErr: "",
    meritalStatusErr: "",
    genderErr: "",
    batchErr: "",
    addressErr: "",
    domicileErr: "",
    provinceErr: "",
    cnicErr: "",
    imageErr: "",
  });

  const {
    firstNameErr,
    lastNameErr,
    emailErr,
    phoneNumberErr,
    rollNumberErr,
    degreeErr,
    instituteErr,
    dobErr,
    meritalStatusErr,
    genderErr,
    batchErr,
    addressErr,
    domicileErr,
    provinceErr,
    cnicErr,
    imageErr,
  } = validationErrors;

  useEffect(() => {
    async function getTeacherDetails() {
      try {
        setLoading(true);
        const response = await axiosPrivate.get(
          `${localStorage.getItem("route")}/me`,
          {
            withCredentials: true,
          }
        );
        console.log("-p-p-p", response);
        setState((prev) => ({
          ...prev,
          firstName: response?.data?.data?.firstName,
          lastName: response?.data?.data?.lastName,
          email,
          phoneNumber,
          rollNumber,
          degree,
          institute,
          dob,
          meritalStatus,
          gender,
          batch,
          address,
          domicile,
          province,
          cnic: response?.data?.data?.cnic,
          image: response?.data?.data?.image?.startsWith("/")
            ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${response?.data?.data?.image}`
            : response?.data?.data?.image,
        }));
        setLoading(false);
      } catch (err) {
        if (err?.response) {
          console.log(err?.response);
        } else if (err?.request) {
          console.log(err?.request);
        } else {
          console.log(err?.message);
        }
      }
    }
    getTeacherDetails();
  }, []);

  const validate = () => {
    if (!firstName) {
      setValidationErrors((prev) => ({
        ...prev,
        firstNameErr: "Please provide first name",
      }));
    }
    if (!lastName) {
      setValidationErrors((prev) => ({
        ...prev,
        lNameErr: "Please provide last name",
      }));
    }
    if (!email) {
      setValidationErrors((prev) => ({
        ...prev,
        emailErr: "Please provide email",
      }));
    }
    if (!phoneNumber) {
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "Please provide phone number",
      }));
    }
    if (!cnic) {
      setValidationErrors((prev) => ({
        ...prev,
        cnicErr: "Please provide cnic",
      }));
    }
    if (image === null) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !cnic ||
      image === null
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("firstName", firstName);
        bodyFormData.append("lastName", lastName);
        bodyFormData.append("email", email);
        bodyFormData.append("phoneNumber", phoneNumber);
        bodyFormData.append("cnic", cnic);
        bodyFormData.append("file", image);
        const res = await axiosPrivate({
          method: "put",
          url: `tchr_auth/profile/${id}`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        navigate("/dashboard/instructor", { replace: true });
      } catch (err) {
        toast.error(err.response.data.message);
        setLoading(false);
        if (err?.response?.data?.code === 400) {
          toast.error("Validation error occurred");
        } else {
          toast.error(err?.response?.data?.message ?? err?.message);
        }
      }
    }
  };

  const handleChangePhone = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState((prev) => ({
        ...prev,
        phoneNumber: e.target.value,
      }));
      setValidationErrors((prev) => ({
        ...prev,
        phoneNumberErr: "",
      }));
    }
  };
  const handleChangeCNIC = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setState((prev) => ({
        ...prev,
        cnic: e.target.value,
      }));
      setValidationErrors((prev) => ({
        ...prev,
        cnicErr: "",
      }));
    }
  };

  const onUploadFileChange = (e) => {
    if (e.target.files.length === 0 || !e.target.validity.valid) {
      return false;
    }
    const file = e.target.files[0];
    if (!/^image\//.test(file.type)) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `File ${file.name} is not an image.`,
      }));
      return false;
    }
    if (file.size > 10000000) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: `Maximum file size 10MB`,
      }));
      return false;
    }
    setState((prev) => ({
      ...prev,
      image: file,
    }));
  };
  const handleEdit = () => {
    setCheck((prevCheck) => !prevCheck);
    setValidationErrors((prev) => ({
      ...prev,
      firstNameErr: "",
      lNameErr: "",
      emailErr: "",
      phoneNumberErr: "",
      cnicErr: "",
      designationErr: "",
      qualificationErr: "",
      imageErr: "",
      courseErr: "",
    }));
  };
  return (
    <>
      <Box>
        <Box className={classes.btnBox}>
          <ArrowBackIcon
            className={classes.backBtn}
            onClick={() => navigate(-1)}
            // onClick={() => navigate("/dashboard/instructor", { replace: true })}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <Typography className={classes.pageTitle}>
              Personal Information
            </Typography>
            <EditIcon className={classes.editIcon} onClick={handleEdit} />
          </Box>
        </Box>
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Form>
                  <Row>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          First Name
                        </Form.Label>
                        <div
                          className={
                            firstNameErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="text"
                            disabled={check}
                            value={firstName}
                            placeholder="Enter first name"
                            onChange={(e) => {
                              if (
                                !/^[a-zA-Z]*$/.test(e.target.value) ||
                                e.target.value.length < 3
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  firstNameErr: !e.target.value
                                    ? "First name can't be empty"
                                    : e.target.value.length < 3
                                    ? "First name must be Minimum 2 characters long"
                                    : "Only alphanumeric values are allowed",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  firstName: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  firstName: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  firstNameErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {firstNameErr ? (
                          <Typography className={classes.errorMsg}>
                            {firstNameErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          Last Name
                        </Form.Label>
                        <div
                          className={
                            lastNameErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="text"
                            value={lastName}
                            disabled={check}
                            placeholder="Enter last name"
                            onChange={(e) => {
                              if (
                                !/^[a-zA-Z ]*$/.test(e.target.value) ||
                                e.target.value.length < 3
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  lastNameErr: !e.target.value
                                    ? "Last name can't be empty"
                                    : e.target.value.length < 3
                                    ? "Last name must be Minimum 2 characters long"
                                    : "Only alphanumeric values are allowed",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  lastName: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  lastName: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  lastNameErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {lastNameErr ? (
                          <Typography className={classes.errorMsg}>
                            {lastNameErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={classes.fieldRow}>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>Email</Form.Label>
                        <div
                          className={
                            emailErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="email"
                            value={email}
                            disabled={check}
                            placeholder="Enter email"
                            onChange={(e) => {
                              if (
                                !/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(
                                  e.target.value
                                )
                              ) {
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  emailErr: e.target.value
                                    ? "Invalid email address"
                                    : "Email can't be empty",
                                }));
                                setState((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                              } else {
                                setState((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                                setValidationErrors((prev) => ({
                                  ...prev,
                                  emailErr: "",
                                }));
                              }
                            }}
                          />
                        </div>
                        {emailErr ? (
                          <Typography className={classes.errorMsg}>
                            {emailErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>
                          Phone Number
                        </Form.Label>
                        <div
                          className={
                            phoneNumberErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="number"
                            disabled={check}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={handleChangePhone}
                          />
                        </div>
                        {phoneNumberErr ? (
                          <Typography className={classes.errorMsg}>
                            {phoneNumberErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className={classes.fieldRow}>
                    <Col className={classes.fieldCol}>
                      <Form.Group
                        controlId="formBasicTitle"
                        className={classes.formGroup}
                      >
                        <Form.Label className={classes.label}>CNIC</Form.Label>
                        <div
                          className={
                            cnicErr
                              ? `${classes.inputFieldErr}`
                              : `${classes.inputField}`
                          }
                        >
                          <InputBase
                            className={classes.input}
                            type="number"
                            disabled={check}
                            placeholder="Enter CNIC"
                            value={cnic}
                            onChange={handleChangeCNIC}
                          />
                        </div>
                        {cnicErr ? (
                          <Typography className={classes.errorMsg}>
                            {cnicErr}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box>
                  <Image
                    src={image}
                    className={classes.UserImage}
                    alt="std-img"
                  />
                  {!isImageUpdate && (
                    <Button
                      className={classes.change_img}
                      disabled={check}
                      value={image}
                      onClick={() => {
                        setIsImageUpdate(() => !isImageUpdate);
                      }}
                    >
                      Change Photo
                    </Button>
                  )}
                </Box>
                {isImageUpdate && (
                  <Form.Group
                    controlId="formBasicTitle"
                    className={classes.formGroup}
                  >
                    <Form.Label className={classes.label}>Image</Form.Label>
                    <div
                      className={
                        imageErr
                          ? `${classes.inputFieldErr}`
                          : `${classes.inputField}`
                      }
                    >
                      <InputBase
                        className={classes.input}
                        disabled={check}
                        type="file"
                        placeholder="Enter image"
                        accept="image/*"
                        onChange={(e) => onUploadFileChange(e)}
                      />
                    </div>
                    {imageErr ? (
                      <Typography className={classes.errorMsg}>
                        {imageErr}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
              </Grid>
            </Grid>
            <Box>
              <Form.Group className={classes.formGroupBtn}>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    className={classes.addBtn}
                    type="submit"
                    disabled={check}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                )}
              </Form.Group>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileSetting;
