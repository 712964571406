import React, { useMemo, useState } from "react";
import CoursesService from "../services/courses.service";
import { ToastContainer, toast } from "react-toastify";
import TablePagination from "components/atoms/Pagination";
import Loading from "components/atoms/Loading";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Box, Grid } from "@mui/material";
import CardTem from "components/atoms/Card";
import EditCourse from "components/Forms/EditCourse";
import AddCourses from "../components/Forms/AddCourses";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import { useNavigate } from "react-router-dom";
import EditorModel from "components/atoms/EditorModel";
import BtnBox from "components/atoms/BtnBox";

export default function Courses() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 9,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });

  const { page, pageSize } = searchQuery;

  const { loading, courses_data } = CoursesService.GetCourses({
    page,
    pageSize,
    reload,
  });

  const { totalItems, courses } = useMemo(
    () => ({
      courses: courses_data?.courses,
      totalItems: courses_data.totalItems,
    }),
    [courses_data]
  );

  const reversCourses = [...courses].reverse();

  const deleteCourse = async (id, onClose) => {
    try {
      const { data } = await axiosPrivate.delete(`courses/${id}`);
      toast.success(data);
      onClose();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };

  // add course model

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = (isReload) => {
    setOpenAdd(false);
  };
  // end add course model

  const handleClickOpen = ({ courseData }) => {
    setData(courseData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // course details

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/courses/${id}`);
  };
  // end course details

  // delete course model

  const handleClickOpenDelete = ({ courseData }) => {
    setData(courseData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete course model

  return (
    <>
      <Box sx={{ position: "relative", height: "100%" }}>
        <ToastContainer autoClose={3000} />
        <BtnBox
          handleClickOpen={handleOpenAdd}
          title={"Published Course"}
          subtitle={"You Have Published Following Course"}
          btnText={"Add Course"}
        />

        {/* add course model */}
        <EditorModel
          openModel={openAdd}
          handleClose={handleCloseAdd}
          bodyContent={
            <AddCourses
              handleClose={handleCloseAdd}
              toast={toast}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Published New Course"
        />
        {/* end add course model */}

        <EditorModel
          openModel={open}
          handleClose={handleClose}
          bodyContent={
            <EditCourse
              handleClose={handleClose}
              toast={toast}
              course={data}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Edit Course"
        />

        {/* delete course model */}

        <DeleteConfirmModel
          openDelete={openDelete}
          handleClose={handleCloseDelete}
          onClick={() => deleteCourse(data?.id, handleCloseDelete)}
        />
        {/* end delete course model */}

        {loading === true ? (
          <div>
            <Loading />
          </div>
        ) : totalItems > 0 ? (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              >
                {reversCourses?.map((courseData, i) => (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                      <CardTem
                        data={courseData}
                        onClick={() => handleClickOpen({ courseData })}
                        handleClickOpenDelete={() =>
                          handleClickOpenDelete({ courseData })
                        }
                        handleClickOpenDetail={() =>
                          handleClickOpenDetail(courseData?.id)
                        }
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <h2 className="text-center">No Record found</h2>
        )}
        {totalItems > 0 && (
          <div
            className="my-3"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TablePagination
              count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
              page={page}
              onChange={(e, v) =>
                setSearchQuery((prev) => ({ ...prev, page: v }))
              }
            />
          </div>
        )}
      </Box>
    </>
  );
}
