import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import "./navbar.css";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100px",
    background: "#252827",
    padding: "10px",

    "@media (max-width: 768px)": {
      height: "fit-content",
      textAlign: "center",
    },
  },
  navHeading: {
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: " 120% !important",
    display: " flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    textAlign: "center",
    textTransform: "capitalize !important",
    color: "var(--primary) !important",
    "@media (max-width: 600px)": {
      fontSize: "28px !important",
    },
  },

  linkContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 768px)": {
      display: "none !important",
    },
  },

  linkGrid: {
    width: "100%",
    justifyContent: "center",
    textAlign: "justify !important",
    textTransform: "capitalize !important",
  },

  navLink: {
    color: "#fff",
    textDecoration: "none",

    "&:hover": {
      color: "var(--primary)",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
});
const Navbar = ({ title }) => {
  const classes = useStyles();
  const Location = useLocation();
  const stickyHeader = useRef();

  useLayoutEffect(() => {
    const mainHeader = document.getElementById("mainHeader");
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainHeader.classList.add("fixedTop");
      } else {
        mainHeader.classList.remove("fixedTop");
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);
  return (
    <>
      <Box className="mainHeader" id="mainHeader" ref={stickyHeader}>
        <Box className={classes.linkContainer}>
          <Box className={classes.linkGrid}>
            <Row>
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/" ? "var(--primary)" : "#fff",
                    textDecoration:
                      Location?.pathname === "/" ? "underline" : "none",
                  }}
                  to="/"
                >
                  Home
                </Link>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/about"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/about" ? "underline" : "none",
                  }}
                  to="/about"
                >
                  About
                </Link>
              </Col>

              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/training-programs"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/training-programs"
                        ? "underline"
                        : "none",
                  }}
                  to="/training-programs"
                >
                  Training Programs
                </Link>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/login"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/login" ? "underline" : "none",
                  }}
                  to="/login"
                >
                  LMS
                </Link>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/campus-culture"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/campus-culture"
                        ? "underline"
                        : "none",
                  }}
                  to="/campus-culture"
                >
                  Campus Culture
                </Link>
              </Col>
              {/* <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/campus-culture"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/campus-culture"
                        ? "underline"
                        : "none",
                  }}
                  to="/campus-culture"
                >
                   Campus &amp; Culture
                </Link>
              </Col> */}
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/library"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/library" ? "underline" : "none",
                  }}
                  to="/library"
                >
                  Library
                </Link>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/news"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/news" ? "underline" : "none",
                  }}
                  to="/news"
                >
                  News Events
                </Link>
              </Col>

              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/stories"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/stories" ? "underline" : "none",
                  }}
                  to="/stories"
                >
                  Stories
                </Link>
              </Col>
              {/* <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/faculty"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/faculty" ? "underline" : "none",
                  }}
                  to="/faculty"
                >
                  Faculty
                </Link>
              </Col> */}
              {/* <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid #fff",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/publication"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/publication"
                        ? "underline"
                        : "none",
                  }}
                  to="/publication"
                >
                  Publication
                </Link>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px !important",
                }}
              >
                <Link
                  className={classes.navLink}
                  style={{
                    color:
                      Location?.pathname === "/resources"
                        ? "var(--primary)"
                        : "#fff",
                    textDecoration:
                      Location?.pathname === "/resources"
                        ? "underline"
                        : "none",
                  }}
                  to="/resources"
                >
                  Resources
                </Link>
              </Col> */}
            </Row>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
