import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChatInput from "./Chatinput";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import "./chatmsg.css";
import moment from "moment";
import styled from "styled-components";

const useStyles = makeStyles({
  container: {
    margin: "30px 10px",
  },
  headingText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "var(--heading-color) !important",
  },
  statisticsText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  text: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120% !important",
    color: "var(--body-text-color) !important",
    margin: "10px 0",
  },
  listBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "70vh",
    padding: "20px",
  },
  chatBox: {
    position: "relative",
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "70vh",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "10px",
    margin: "20px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
    width: "100%",
  },
  input: {
    color: "#F1F1F1 !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  messageInput: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  msgBox: {
    display: "flex",
    alignItems: "center",
    height: "70px",
    padding: "10px",
    margin: "10px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      cursor: "pointer",
    },
    borderRadius: "4px",
    width: "100%",
  },
  chatTitleBox: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "20px 30px",
    margin: "0",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
    width: "100%",
  },
  chatSendBox: {
    position: "absolute",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "20px 30px",
    margin: "0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    borderRadius: "4px",
    width: "100%",
  },
  sendMsgBox: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "0 30px",
    margin: "20px 0 ",
    marginLeft: "auto",
    borderRadius: "4px",
    width: "400px",
  },
  receivedMsgBox: {
    display: "flex",
    alignItems: "center",
    height: "50px",
    padding: "0 30px",
    margin: "20px 0 ",
    borderRadius: "4px",
    width: "400px",
  },
  name: {
    fontWeight: "700 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#2C3935 !important",
    margin: "5px 0",
  },
  time: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#7A8883 !important",
    margin: "5px 0",
  },
  message: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: "#7A8883 !important",
    margin: "5px 0",
    display: "-webkit-box",
    lineClamp: "1",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sendMessage: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: "#7A8883 !important",
    margin: "5px 0",
  },
  status: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize !important",
    margin: "0",
    display: "-webkit-box",
    lineClamp: "1",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sendMsg: {
    height: "fit-content",
    padding: "10px 10px",
    margin: "0 10px ",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  messageInputBox: {
    display: "flex",
    alignItems: "center",
    height: "60px",
    padding: "10px",
    margin: "20px 10px 20px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#F1F1F1",
    borderRadius: "4px",
    width: "80%",
  },
  sendBtn: {
    width: "119px",
    height: "60px",
    background: "#18BBA2",
    boxShadow: "0px 23px 24px rgba(0, 0, 0, 0.06)",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#18BBA2",
    },
  },
  btnText: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "120%",
    color: "#FFFFFF",
    marginRight: "5px",
  },
});

export default function ChatContainer({ currentChat, user }) {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get(`classchat/${currentChat?.id}`, {
        withCredentials: true,
      });

      setMessages(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, 20000);
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    try {
      const { data } = await axiosPrivate.post(`chat/${currentChat?.id}`, {
        title: msg,
      });
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Container>
      <Box className={classes.chatTitleBox}>
        <Box style={{ width: "100%", display: "flex" }}>
          <Box
            style={{
              width: "80%",
              padding: "0 10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.name}>
                {currentChat.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.status}
                style={{
                  color:
                    currentChat?.status === "inactive" ? "#d32f2f" : "#18BBA2",
                }}
              >
                {currentChat.status}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <div className="chat-messages">
        {messages.map((message) => {
          return (
            <>
              <div
                className="chat-log_item chat-log_item-own z-depth-0"
                ref={scrollRef}
                key={message?.id}
                style={{
                  background:
                    user?.user === message?.sender ? "rgb(225, 250, 246)" : "",
                  marginLeft: user?.user === message?.sender ? "auto" : "",
                  justifyContent: user?.user === message?.sender ? "end" : "",
                  borderRadius:
                    user?.user === message?.sender
                      ? "20px 0 20px 20px"
                      : "0 20px 20px 20px",
                }}
              >
                <div className="row justify-content-end mx-1 d-flex">
                  <div className="col-auto px-0">
                    <span className="chat-log_author">
                      {message?.sender_name}
                    </span>
                  </div>
                  <div className="col-auto px-0"></div>
                </div>
                <hr
                  className="my-1 py-0 col-8"
                  style={{ opacity: "0.5", color: "" }}
                />
                <div className="chat-log_message">
                  <p>{message?.title}</p>
                </div>
                <hr className="my-1 py-0 col-8" style={{ opacity: "0.5" }} />
                <div className="row chat-log_time m-0 p-0 justify-content-end">
                  {message?.timeStamp
                    ? moment(message?.timeStamp).format("LT")
                    : ""}
                </div>
              </div>
            </>
          );
        })}
      </div>
      {!user?.role === "admin" && (
        <ChatInput handleSendMsg={handleSendMsg} currentChat={currentChat} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 15% 70% 15%;
  gap: 0.1rem;
  overflow: hidden;
  margin: 15px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 70% 15%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: white;
        }
      }
    }
  }
  .chat-messages {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    background-color: #fff;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
  }
  .message {
    display: flex;
    align-items: center;
    .content {
      max-width: 40%;
      overflow-wrap: break-word;
      padding: 1rem;
      font-size: 1.1rem;
      border-radius: 1rem;
      color: #d1d1d1;
      @media screen and (min-width: 720px) and (max-width: 1080px) {
        max-width: 70%;
      }
    }
  }
  .sended {
    justify-content: flex-end;
    .content {
      background-color: #4f04ff21;
    }
  }
  .recieved {
    justify-content: flex-start;
    .content {
      background-color: #9900ff20;
    }
  }
`;
