import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import "./facultyStyle.css";
import { RiEqualizerFill } from "react-icons/ri";
import CloseIcon from "@mui/icons-material/Close";
import FacultyService from "services/faculty.service";
import TablePagination from "components/atoms/Pagination";
import Loading from "components/atoms/Loading";

const useStyles = makeStyles({
  desktop_view: {
    "@media (max-width: 900px)": {
      display: "none !important",
    },
  },
  main_container: {
    "@media (max-width: 900px)": {
      justifyContent: "center !important",
    },
  },
  search_sidebar: {
    display: "none !important",

    "@media (max-width: 900px)": {
      padding: "10px 20px",
      display: "flex !important",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  checkBoxHeading: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    display: "flex !important",
    alignItems: "center !important",
    textAlign: "center !important",
    textTransform: "capitalize",
    color: " #252827 !important",
  },
  checkBoxLabel: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    textAlign: "left !important",
    textTransform: "capitalize",
    color: "var(--text-color) !important",
    marginLeft: "20px",
    marginBottom: "0px !important",
  },

  top_box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "@media (max-width: 768px)": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  input_box: {
    display: "flex",
    alignItems: "center",

    "@media (max-width: 550px)": {
      display: "block !important",
    },
  },
  formGroup: {
    display: "flex",
    alignItems: "center",
  },
  search_bt: {
    width: "112px",
    height: "48px",
    backgroundColor: "#E24F57 !important",
    color: "#fff !important",
    borderRadius: "4px !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
    marginLeft: "10px !important",
    // zIndex: "-1",

    "@media (max-width: 550px)": {
      width: "100%",
      margin: "0 !important",
    },
  },
  search_felid: {
    width: "352px",
    height: "48px",
    background: "#F3F3F3",
    borderRadius: "4px",
    border: "none !important",
    padding: "20px",
    outline: "none",

    "@media (max-width: 768px)": {
      width: "270px !important",
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px auto !important",
    },
  },
  formControl: {
    display: "flex !important",
  },
  total_result: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120%",
    textAlign: "center",
    margin: "0px",
    textTransform: "capitalize",
    color: "var(--text-color)  !important",

    "@media (max-width: 900px)": {
      display: "none !important",
    },
  },
  total_result_mobile: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120%",
    textAlign: "center",
    margin: "0px",
    textTransform: "capitalize",
    color: "var(--text-color)  !important",
    display: "none",

    "@media (max-width: 900px)": {
      display: "block !important",
    },
  },
  card: {
    width: "398px",
    height: "261px",
    background: "#F9F9F9 !important",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15) !important",
    borderRadius: "4px !important",
    border: "none !important",
    padding: "30px",
    margin: "0 0 40px 0",

    "@media (max-width: 420px)": {
      width: "250px",
      margin: "10px auto",
    },
  },
  card_img: {
    width: "112px",
    height: "112px",
    borderRadius: "50%",
  },
  image_box: {
    display: "flex",
    justifyContent: "center",
  },
  heading: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120%",
    textAlign: "center",
    textTransform: "capitalize",
    color: " #252827 !important",
    margin: "5px !important",
  },
  subHeading: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120%",
    textAlign: "center",
    textTransform: "capitalize",
    color: "var(--body-text-color)  !important",
  },
});

const FacultyMembers = ({ programs }) => {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    filterStatus: [],
    filterPrograms: [],
  });
  const { filterStatus, filterPrograms } = filter;
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, faculty_data } = FacultyService.GetFaculty({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterPrograms,
    reload,
  });

  const { totalItems, faculty } = useMemo(
    () => ({
      faculty: faculty_data?.faculty,
      totalItems: faculty_data.totalItems,
    }),
    [faculty_data]
  );

  const ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const onSearchChange = (e) => {
    setSearchQuery((ev) => ({
      ...ev,
      searchText: e.target.value,
    }));
    if (!e.target.value) {
      setReload(() => !reload);
    }
  };
  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setFilter((prev) => ({
        ...prev,
        filterStatus: [...filterStatus, value],
      }));
    }

    // Case 2  : The user unchecks the box
    else {
      setFilter((prev) => ({
        ...prev,
        filterStatus: filterStatus.filter((e) => e !== value),
      }));
    }
  };

  const handleChangeProgram = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      setFilter((prev) => ({
        ...prev,
        filterPrograms: [...filterPrograms, value],
      }));
    }

    // Case 2  : The user unchecks the box
    else {
      setFilter((prev) => ({
        ...prev,
        filterPrograms: filterPrograms.filter((e) => e !== value),
      }));
    }
  };

  return (
    <>
      <Box style={{ padding: "60px 60px" }}>
        <Box className={classes.search_sidebar}>
          <Button onClick={ToggleSidebar}>
            <RiEqualizerFill
              color="var(--primary)"
              style={{ marginRight: "5px" }}
            />
            <strong style={{ color: "var(--primary)" }}>Filter</strong>
          </Button>
          <p className={classes.total_result_mobile}>{totalItems} results</p>
          <Box className={isOpen === true ? "active_sidebar" : "sidebar"}>
            <Box className="sd-header">
              <h4 className="mb-0">Search Faculty</h4>
              <Button onClick={ToggleSidebar}>
                <CloseIcon color="var(--primary)" />
              </Button>
            </Box>
            <Box className="sd-body">
              <Box sx={{ margin: "10px 0px" }}>
                <Typography variant="h5" className={classes.checkBoxHeading}>
                  Academic
                </Typography>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  {programs?.map((programsData, i) => (
                    <Form.Group className={classes.formGroup} key={i}>
                      <Form.Check
                        type="checkbox"
                        aria-label="radio 1"
                        value={programsData?.id}
                        onChange={handleChangeProgram}
                      />
                      <Form.Label className={classes.checkBoxLabel}>
                        {programsData?.title}
                      </Form.Label>
                    </Form.Group>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={3} className={classes.main_container}>
            {/* <Grid item md={3} className={classes.desktop_view}>
              <Box sx={{ margin: "10px 0px" }}>
                <Typography variant="h5" className={classes.checkBoxHeading}>
                  Academic
                </Typography>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  {programs?.map((programsData, i) => (
                    <Form.Group className={classes.formGroup} key={i}>
                      <Form.Check
                        type="checkbox"
                        aria-label="radio 1"
                        value={programsData?.id}
                        onChange={handleChangeProgram}
                      />
                      <Form.Label className={classes.checkBoxLabel}>
                        {programsData?.title}
                      </Form.Label>
                    </Form.Group>
                  ))}
                </Box>
              </Box>
            </Grid> */}
            <Grid item md={12} sx={{ paddingLeft: "0px" }}>
              <Box className={classes.top_box}>
                <Box className={classes.input_box}>
                  <input
                    id="search-bar"
                    className={classes.search_felid}
                    type="search"
                    placeholder="Type Here"
                    onChange={(e) => onSearchChange(e)}
                  />
                  <IconButton
                    className={classes.search_bt}
                    type="submit"
                    aria-label="search"
                    onClick={() => searchText && setReload(() => !reload)}
                  >
                    Search
                  </IconButton>
                </Box>
                <Box>
                  <p className={classes.total_result}>{totalItems} results</p>
                </Box>
              </Box>
              <Box sx={{ marginTop: "50px" }}>
                <Row>
                  {/* ""Faculty"" Intoductory text  */}
                  <Box className="align-items-center p-0">
                    <Col>
                      <p
                        className="text-dark"
                        style={{
                          marginBottom: "50px",
                          textAlign: "justify",
                          textJustify: "inter-word",
                        }}
                      >
                        The faculty at IRSA is highly qualified and motivated to
                        train and groom officers to successfully assume
                        responsibilities once they are posted in filed
                        formations of IRS. The objective of IRSA is to introduce
                        best learning resources to trainee officers and impart
                        valuable insight regarding tax issues arising from
                        different business and financial scenarios. The
                        permanent and visiting faculty members in IRSA have been
                        assigned different courses on the basis of building a
                        strong knowledge base and sound understanding of tax
                        laws
                      </p>
                    </Col>
                  </Box>

                  {loading === true ? (
                    <div>
                      <Loading />
                    </div>
                  ) : totalItems > 0 ? (
                    faculty?.map((members, i) => (
                      <>
                        <Col key={i} style={{ padding: "0" }}>
                          <Card className={classes.card}>
                            <Box className={classes.image_box}>
                              <Image
                                className={classes.card_img}
                                src={
                                  members?.image
                                    ? members?.image
                                    : "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                                }
                              />
                            </Box>
                            <Typography
                              variant="h5"
                              component="div"
                              className={classes.heading}
                            >
                              {members?.firstname + " " + members?.lastname}
                            </Typography>
                            <Typography
                              sx={{ mb: 1.5 }}
                              color="text.secondary"
                              className={classes.subHeading}
                            >
                              {members?.designation}
                            </Typography>
                          </Card>
                        </Col>
                      </>
                    ))
                  ) : (
                    <h2 className="text-center">No Record found</h2>
                  )}
                  {totalItems > 0 && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <TablePagination
                        count={Math.ceil(
                          parseInt(totalItems) / parseInt(pageSize)
                        )}
                        page={page}
                        onChange={(e, v) =>
                          setSearchQuery((prev) => ({ ...prev, page: v }))
                        }
                      />
                    </Box>
                  )}
                </Row>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default FacultyMembers;
