import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../styles/dashboard.css";
import { Avatar, InputBase, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems } from "./listItems";
import useAuth from "hooks/useAuth";
import useLogout from "hooks/useLogout";
import { Dropdown, Image } from "react-bootstrap";
import logo from "../Assets/Images/IRSA";
import CancelIcon from "@mui/icons-material/Cancel";
import { BsSearch } from "react-icons/bs";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useState } from "react";
import StudentListItems from "./StudentListItems";
import TeacherListItems from "./TeacherListItem";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const useStyles = makeStyles({
  appBar: {
    backgroundColor: "#FFFFFF !important",
    height: "80px",
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
  },
  toolbar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    height: "100%",
    alignItems: "center",
    "@media (max-width: 600px)": {
      justifyContent: "end",
    },
  },
  logoLg: {
    display: "none",
    height: "54px",
    marginBottom: "5px",
    "@media (min-width: 600px)": {
      display: "block",
    },
  },
  logoSm: {
    display: "block",
    marginBottom: "5px",
    "@media (min-width: 600px)": {
      display: "none",
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
    width: "600px",
    "@media (max-width: 768px)": {
      width: "500px",
    },
    "@media (max-width: 600px)": {
      display: (props) => (props.openSearch ? "flex" : "none"),
      width: "100%",
    },
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  cancel: {
    color: "var(--text-color)",
    fontSize: "24px",
    "@media (min-width: 600px)": {
      display: "none !important",
    },
  },
  searchButton: {
    color: "var(--text-color)",
    fontSize: "24px",
    marginRight: "10px",
    "@media (min-width: 600px)": {
      display: "none",
    },
  },
  icons: {
    alignItems: "center",
    display: (props) => (props.openSearch ? "none" : "flex"),
  },
  badge: {
    marginRight: "10px",
  },
  appBarIcon: {
    color: "var(--text-color)",
  },
  containerOutlet: {
    color: "white",
    paddingTop: "80px",
    paddingBottom: "50px",
    maxWidth: "100%",
    position: "sticky",
    top: 0,
    "@media (min-width: 600px)": {
      color: "#555",
    },
  },
  mainContainer: {
    height: "100vh",
    backgroundColor: (props) =>
      props?.user?.role === "admin" ? "#f6f4fb" : "#f5faf8",
    "@media (max-width: 600px)": {
      height: "100%",
    },
  },
  sideToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "80px",

    "@media (max-width: 600px)": {
      minHeight: "80px !important",
    },
  },
});

function DashboardContent() {
  const navigate = useNavigate();
  const logout = useLogout();
  const { user, setUser } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [openSearch, setOpenSearch] = useState(false);
  const classes = useStyles({ openSearch, user });
  const [profile, setProfile] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getProfile = async () => {
    try {
      const response = await axiosPrivate.get(
        `${localStorage.getItem("route")}/me`,
        {
          withCredentials: true,
        }
      );
      setProfile(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getProfile();
  }, []);

  const signOut = async () => {
    await logout();
    setUser();
    navigate("/login");
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      ref={ref}
      style={{ background: "transparent", border: "none" }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {user?.role === "admin" && (
        <Tooltip title={user?.firstname}>
          <Avatar
            alt={user?.firstname}
            src={
              user?.image?.startsWith("/")
                ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${user?.image}`
                : user?.image
            }
          />
        </Tooltip>
      )}
      {user?.role === "teacher" && (
        <Tooltip title={profile?.teacheruser?.firstname}>
          <Avatar
            alt={profile?.teacheruser?.firstname}
            src={
              profile?.teacheruser?.image?.startsWith("/")
                ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${profile?.teacheruser?.image}`
                : profile?.teacheruser?.image
            }
          />
        </Tooltip>
      )}
      {user?.role === "student" && (
        <Tooltip title={profile?.studentuser?.firstname}>
          <Avatar
            alt={profile?.studentuser?.firstname}
            src={
              profile?.studentuser?.image?.startsWith("/")
                ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${profile?.studentuser?.image}`
                : profile?.studentuser?.image
            }
          />
        </Tooltip>
      )}
    </button>
  ));
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} className={classes.appBar}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon style={{ color: "#757575" }} />
            </IconButton>
            <div className={classes.toolbar}>
              <div className={classes.search}>
                <BsSearch
                  style={{ color: "var(--text-color)", fontSize: "24px" }}
                />
                <InputBase placeholder="Search..." className={classes.input} />
                <CancelIcon
                  className={classes.cancel}
                  onClick={() => setOpenSearch(false)}
                />
              </div>
              <div className={classes.icons}>
                <BsSearch
                  className={classes.searchButton}
                  onClick={() => setOpenSearch(true)}
                />
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    {!user.isSuperAdmin && (
                      <Dropdown.Item
                        onClick={
                          user?.role === "admin"
                            ? () =>
                                navigate("/dashboard/profile", {
                                  replace: true,
                                })
                            : user?.role === "student"
                            ? () =>
                                navigate("/student/profile", { replace: true })
                            : () =>
                                navigate("/teacher/profile", { replace: true })
                        }
                      >
                        Profile
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => signOut()}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar className={classes.sideToolbar} sx={{ px: [1], pl: [5] }}>
            {user.role === "admin" && (
              <Link to="/">
                <Image src={logo} style={{ width: "100px" }} />
              </Link>
            )}
            {user.role === "teacher" && (
              <Link to="/">
                <Image src={logo} style={{ width: "100px" }} />
              </Link>
            )}
            {user.role === "student" && (
              <Link to="/">
                <Image src={logo} style={{ width: "100px" }} />
              </Link>
            )}
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider style={{ width: "100%" }} />
          <List component="nav">
            {user?.role === "admin" && (
              <List component="nav" sx={{ height: "100%" }}>
                <MainListItems signOut={signOut} />
              </List>
            )}
            {user?.role === "student" && (
              <List component="nav">
                <StudentListItems />
              </List>
            )}
            {user?.role === "teacher" && (
              <List component="nav">
                <TeacherListItems />
              </List>
            )}
          </List>
        </Drawer>
        <Box
          className={classes.mainContainer}
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container className={classes.containerOutlet}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return (
    <>
      {" "}
      <DashboardContent />
      {/* <Copyright /> */}
    </>
  );
}
