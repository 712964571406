import { Button } from "@mui/material";
import React from "react";
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 5px",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: "1px solid var(--admin)",
    },
  },
});

function LoadingBtn() {
  const classes = useStyles();
  return (
    <>
      <Button className={classes.addBtn} disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />{" "}
        <strong style={{ marginLeft: "5px" }}>Submitting...</strong>
      </Button>
    </>
  );
}

export default LoadingBtn;
