import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
    "@media (max-width: 600px)": {
      height: "fit-content",
      display: "block",
      justifyContent: "center",
    },
  },
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  subtitle: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    marginTop: "5px",
    color: "var(--icons-color) !important",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    transition: "0.3s",
    margin: "10px 5px",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#fff",
      border: ".5px solid var(--admin)",
    },
    "@media (max-width: 600px)": {
      margin: "10px auto",
    },
  },
});

const BtnBox = ({ handleClickOpen, title, subtitle, btnText }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.btnBox}>
        <Box>
          <Typography className={classes.pageTitle}>{title}</Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </Box>
        <Button onClick={handleClickOpen} className={classes.addBtn}>
          {/* <AddIcon /> */}
          {btnText}
        </Button>
      </Box>
    </>
  );
};

export default BtnBox;
