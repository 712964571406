import BackToTop from "components/atoms/BackToTop/BackToTop";
import CampusCultureCard from "components/Layout/CampusCulture/CampusCultureCard";
import CampusCultureMainSec from "components/Layout/CampusCulture/CCMainSec";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import DetailsCard from "components/Layout/Stories/BottomCard";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect } from "react";

const CampusCulture = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <TopNav /> */}
      <Navbar />
      <CampusCultureMainSec />
      <CampusCultureCard />
      {/* <DetailsCard /> */}
      <BackToTop />
      <Footer />
    </>
  );
};

export default CampusCulture;
