import { makeStyles } from "@mui/styles";
import { Box, InputBase, Typography } from "@mui/material";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { Image } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

const useStyles = makeStyles({
  container: {
    margin: "30px 10px",
  },
  headingText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "var(--heading-color) !important",
  },
  statisticsText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  text: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120% !important",
    color: "var(--body-text-color) !important",
    margin: "10px 0",
  },
  listBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "80vh",
    padding: "20px",
    margin: "15px 0",
  },
  chatBox: {
    position: "relative",
    background: "#FFFFFF",
    boxShadow: " 0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    height: "70vh",
  },
  search: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "10px",
    margin: "20px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
    width: "100%",
  },
  input: {
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  messageInput: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  msgBoxDesktop: {
    display: "flex",
    alignItems: "center",
    height: "70px",
    padding: "10px",
    margin: "10px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      cursor: "pointer",
    },
    borderRadius: "4px",
    width: "100%",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  msgBoxMobile: {
    display: "flex",
    alignItems: "center",
    height: "70px",
    padding: "10px",
    margin: "10px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
      cursor: "pointer",
    },
    borderRadius: "4px",
    width: "100%",
    "@media (min-width: 600px)": {
      display: "none",
    },
  },
  chatTitleBox: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "20px 30px",
    margin: "0",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
    width: "100%",
  },
  chatSendBox: {
    position: "absolute",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "20px 30px",
    margin: "0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    borderRadius: "4px",
    width: "100%",
  },
  sendMsgBox: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "0 30px",
    margin: "20px 0 ",
    marginLeft: "auto",
    borderRadius: "4px",
    width: "400px",
  },
  receivedMsgBox: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: "0 30px",
    margin: "20px 0 ",
    borderRadius: "4px",
    width: "400px",
  },
  name: {
    fontWeight: "700 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#2C3935 !important",
    margin: "5px 0",
  },
  time: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#7A8883 !important",
    margin: "5px 0",
  },
  message: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: "#7A8883 !important",
    margin: "5px 0",
    display: "-webkit-box",
    lineClamp: "1",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sendMessage: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: "#7A8883 !important",
    margin: "5px 0",
  },
  status: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: "var(--primary) !important",
    margin: "0",
    display: "-webkit-box",
    lineClamp: "1",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sendMsg: {
    height: "fit-content",
    padding: "10px 10px",
    margin: "0 10px ",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  messageInputBox: {
    display: "flex",
    alignItems: "center",
    height: "60px",
    padding: "10px",
    margin: "20px 10px 20px 0 ",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#F1F1F1",
    borderRadius: "4px",
    width: "80%",
  },
  sendBtn: {
    width: "119px",
    height: "60px",
    background: "#18BBA2",
    boxShadow: "0px 23px 24px rgba(0, 0, 0, 0.06)",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#18BBA2",
    },
  },
  btnText: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "120%",
    color: "#FFFFFF",
    marginRight: "5px",
  },
});

function Contacts({ contacts, classesData, changeChat, handleSearch }) {
  const classes = useStyles();
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const { user } = useAuth();

  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
  };

  return (
    <>
      <Box className={classes.listBox}>
        <Typography className={classes.headingText}>My Chat</Typography>
        <Box className={classes.search}>
          <BsSearch
            style={{
              color: "#3b3b3b",
              fontSize: "24px",
            }}
          />
          <InputBase
            placeholder="Search..."
            className={classes.input}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>
        <Box style={{ height: "80%" }}>
          {user.role === "admin"
            ? classesData?.map((contact, index) => (
                <>
                  <Box key={index}>
                    <Box
                      className={classes.msgBoxDesktop}
                      style={{
                        background:
                          index === currentSelected ? "#e1faf6" : "#FAFAFA",
                      }}
                      onClick={() => changeCurrentChat(index, contact)}
                    >
                      <Box style={{ width: "100%", display: "flex" }}>
                        <Box style={{ width: "100%", padding: "0 10px" }}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className={classes.name}>
                              {contact?.title}
                            </Typography>
                            <Typography className={classes.time}>
                              {contact?.last_message?.timeStamp
                                ? moment(
                                    contact?.last_message?.timeStamp
                                  ).format("LT")
                                : ""}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.message}>
                              {contact?.last_message?.title}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className={classes.msgBoxMobile}
                      style={{
                        background:
                          index === currentSelected ? "#e1faf6" : "#FAFAFA",
                      }}
                      onClick={() => {
                        user?.role === "student"
                          ? navigate(`/student/inbox/${contact.id}`, {
                              replace: true,
                            })
                          : user?.role === "teacher"
                          ? navigate(`/teacher/inbox/${contact.id}`, {
                              replace: true,
                            })
                          : navigate(`/dashboard/inbox/${contact.id}`, {
                              replace: true,
                            });
                      }}
                    >
                      <Box style={{ width: "100%", display: "flex" }}>
                        <Box style={{ width: "80%", padding: "0 10px" }}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className={classes.name}>
                              {contact?.title}
                            </Typography>
                            <Typography className={classes.time}>
                              {contact?.last_message?.timeStamp
                                ? moment(
                                    contact?.last_message?.timeStamp
                                  ).format("LT")
                                : ""}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.message}>
                              {contact?.last_message?.title}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))
            : contacts?.map((contact, index) => (
                <>
                  <Box
                    key={contact.id}
                    className={classes.msgBoxDesktop}
                    style={{
                      background:
                        index === currentSelected ? "#e1faf6" : "#FAFAFA",
                    }}
                    onClick={() => changeCurrentChat(index, contact)}
                  >
                    <Box style={{ width: "100%", display: "flex" }}>
                      <Box style={{ width: "100%", padding: "0 10px" }}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className={classes.name}>
                            {contact?.title}
                          </Typography>
                          <Typography className={classes.time}>
                            {contact?.last_message?.timeStamp
                              ? moment(contact?.last_message?.timeStamp).format(
                                  "LT"
                                )
                              : ""}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.message}>
                            {contact?.last_message?.title}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    key={contact.id}
                    className={classes.msgBoxMobile}
                    style={{
                      background:
                        index === currentSelected ? "#e1faf6" : "#FAFAFA",
                    }}
                    onClick={() => {
                      user?.role === "student"
                        ? navigate(`/student/inbox/${contact.id}`, {
                            replace: true,
                          })
                        : user?.role === "teacher"
                        ? navigate(`/teacher/inbox/${contact.id}`, {
                            replace: true,
                          })
                        : navigate(`/admin/inbox/${contact.id}`, {
                            replace: true,
                          });
                    }}
                  >
                    <Box style={{ width: "100%", display: "flex" }}>
                      <Box
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          width={50}
                          height={50}
                          style={{ borderRadius: "50%" }}
                          src="https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=881&q=80"
                        />
                      </Box>
                      <Box style={{ width: "80%", padding: "0 10px" }}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className={classes.name}>
                            {contact?.title}
                          </Typography>
                          <Typography className={classes.time}>
                            {contact?.last_message?.timeStamp
                              ? moment(contact?.last_message?.timeStamp).format(
                                  "LT"
                                )
                              : ""}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.message}>
                            {contact?.last_message?.title}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))}
        </Box>
      </Box>
    </>
  );
}

export default Contacts;
