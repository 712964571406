import React from "react";
import { Container } from "react-bootstrap";
import "./stories.css";
//import backgroundImg from "../../../Assets/Images/background.jpg";
import backgroundImg from "../../../Assets/Images/StoriesHomeBg.png";
//import backgroundImg from "../../../Assets/Images/StoriesBgHome.png";
import { RiShareCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Stories = () => {
  let navigate = useNavigate();
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          style={{
            height: "110vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div className="text-center my-5">
              <h4
                style={{ fontSize: "36px", fontWeight: "bold", color: "#fff" }}
              >
                An Extraordinary
                <br /> Experience with IRSA
              </h4>
            </div>
            <div className="text-center">
              <button
                onClick={() => {
                  navigate("stories", { replace: true });
                }}
                className="story-btn"
              >
                Stories <RiShareCircleLine style={{ marginLeft: "10px" }} />
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Stories;
