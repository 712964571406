import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./forms.css";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import { Typography, Button } from "@mui/material";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "191px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#FFFFFF",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const AddStoryGallery = ({ handleClose, data, toast, reload }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState([]);

  const [validationErrors, setValidationErrors] = useState({
    imageErr: "",
  });
  const { imageErr } = validationErrors;

  const validate = () => {
    if (files?.length <= 0) {
      setValidationErrors((prev) => ({
        ...prev,
        imageErr: "Please provide image",
      }));
    }
    if (files?.length <= 0) {
      return false;
    }
    return true;
  };

  // const handleFileChange = (event) => {
  //   const selectedFiles = Array.from(event.target.files);
  //   const filePromises = selectedFiles.map((file) => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   });

  //   Promise.all(filePromises).then((results) => {
  //     setValidationErrors((prev) => ({
  //       ...prev,
  //       imageErr: "",
  //     }));
  //     setFiles(results);
  //   });
  // };

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        files.forEach((file, i) => {
          const res = axiosPrivate.post(
            `/story/add_image/${data?.id}`,
            {
              image: file,
            },
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
        });

        setLoading(false);
        // toast.success(res?.data?.message);
        handleClose();
        reload("reload");
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
      console.log("Validated");
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <Form>
        <Form.Group controlId="formBasicTitle" className={classes.formGroup}>
          <Form.Label className={classes.label}>Image</Form.Label>
          <div
            className={imageErr ? classes.inputFieldErr : classes.inputField}
          >
            <input
              className={classes.input}
              type="file"
              multiple
              onChange={handleFileChange}
            />
          </div>
          {imageErr ? (
            <Typography className={classes.errorMsg}>{imageErr}</Typography>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className={classes.saveBtn}>
          <Typography className={classes.errorMsg}>
            Upload Only Compressed Images*
          </Typography>
          {loading ? (
            <LoadingBtn />
          ) : (
            <Button
              className={classes.addBtn}
              type="submit"
              onClick={handleSubmit}
            >
              Add
            </Button>
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default AddStoryGallery;
