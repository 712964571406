import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getDateObject } from "helpers/common";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "components/atoms/Loading";

const useStyles = makeStyles({
  container: {
    margin: "30px 10px",
  },
  headingText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "var(--primary) !important",
  },
  nameText: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  emailText: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120% !important",
    color: "#000000 !important",
    margin: "10px 0",
  },
  detailsHeading: {
    fontWeight: "700 !important",
    fontSize: "14px !important",
    lineHeight: " 120% !important",
    color: "#000000 !important",
    margin: "10px 0",
  },
  text: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120% !important",
    textTransform: "capitalize",
    color: "#000000 !important",
    margin: "10px 0",
  },
  statisticsBox: {
    margin: "15px 7px",
    height: "315px",
    borderRadius: "4px",
  },
  programBox: {
    margin: "15px 7px",
    height: "315px",
    borderRadius: "4px",
  },
  boxes: {
    background: "#FFFFFF",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  },
  detailsBoxes: {
    background: "#FFFFFF",
    height: "100%",
    padding: "20px",
    borderRadius: "5px",
  },
});

function getFullName(params) {
  return `${params?.row?.teacher?.firstName || ""} ${
    params?.row?.teacher?.lastName || ""
  }`;
}

function setFullName(params) {
  const [firstName, lastName] = params.value.toString().split(" ");
  return { ...params.row, firstName, lastName };
}

function parseFullName(value) {
  return String(value)
    .split(" ")
    .map((str) => (str?.length > 0 ? str[0].toUpperCase() + str.slice(1) : ""))
    .join(" ");
}

const ClassDetails = ({ index }) => {
  const navigate = useNavigate();

  const handleClickOpenDetail = () => {
    navigate(`/student/classes/${index?.id}`);
  };

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          <div onClick={handleClickOpenDetail}>{index?.title}</div>
        </>
      }
    />
  );
};

const MyProfile = () => {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState("");
  const { studentClasses } = useAuth();

  const columns = [
    {
      field: "title",
      flex: 1,
      headerName: "Title",
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <ClassDetails index={params?.row} />
          </div>
        );
      },
    },
    {
      field: "batch",
      headerName: "Batch",
    },
    {
      field: "year",
      headerName: "Year",
    },
    {
      field: "startDate",
      type: "date",
      flex: 1,
      headerName: "Start Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "endDate",
      type: "date",
      flex: 1,
      headerName: "End Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "MM/dd/yyyy"),
    },
    {
      field: "course",
      headerName: "Course",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            {params?.value?.title}
          </div>
        );
      },
    },
    {
      field: "fullName",
      type: "string",
      flex: 1,
      headerName: "Teacher Name",
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
  ];

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(
        `${localStorage.getItem("route")}/me`,
        {
          withCredentials: true,
        }
      );
      setProfile(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <Box>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row className={classes.statisticsBox}>
              <Col xs={12} sm={4}>
                <Box className={classes.boxes}>
                  <Image
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "50px",
                      marginBottom: "20px",
                    }}
                    src={
                      profile?.studentuser?.image?.startsWith("/")
                        ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${profile?.studentuser?.image}`
                        : profile?.studentuser?.image ??
                          "https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=881&q=80"
                    }
                  />
                  <Typography
                    sx={{ margin: "5px" }}
                    className={classes.nameText}
                  >
                    {profile?.studentuser?.firstname +
                      " " +
                      profile?.studentuser?.lastname}
                  </Typography>
                  <Typography className={classes.emailText}>
                    {profile?.studentuser?.email}
                  </Typography>
                  <Box>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2058 4.5C15.0289 1.71539 20.9711 1.71539 25.7942 4.5C30.6173 7.28461 33.5885 12.4308 33.5885 18C33.5885 23.5692 30.6173 28.7154 25.7942 31.5C20.9711 34.2846 15.0289 34.2846 10.2058 31.5C5.38269 28.7154 2.41154 23.5692 2.41154 18C2.41154 12.4308 5.38269 7.28461 10.2058 4.5Z"
                        fill="#EBF8F3"
                      />
                      <path
                        d="M21 15C22.1935 15 23.3381 15.4741 24.182 16.318C25.0259 17.1619 25.5 18.3065 25.5 19.5V24.75H22.5V19.5C22.5 19.1022 22.342 18.7206 22.0607 18.4393C21.7794 18.158 21.3978 18 21 18C20.6022 18 20.2206 18.158 19.9393 18.4393C19.658 18.7206 19.5 19.1022 19.5 19.5V24.75H16.5V19.5C16.5 18.3065 16.9741 17.1619 17.818 16.318C18.6619 15.4741 19.8065 15 21 15V15Z"
                        stroke="#2C3935"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5 15.75H10.5V24.75H13.5V15.75Z"
                        stroke="#2C3935"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                        stroke="#2C3935"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>
                </Box>
              </Col>
              <Col xs={12} sm={8}>
                <Box className={classes.detailsBoxes}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 0",
                    }}
                  >
                    <Col>
                      <Typography className={classes.detailsHeading}>
                        Roll Number
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className={classes.emailText}>
                        {profile?.studentuser?.roll_number}
                      </Typography>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      background: "#252827",
                      color: "#252827",
                      borderColor: "#252827",
                      width: "100%",
                    }}
                  />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 0",
                    }}
                  >
                    <Col>
                      <Typography className={classes.detailsHeading}>
                        Degree
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className={classes.emailText}>
                        {profile?.studentuser?.degree}
                      </Typography>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      background: "#252827",
                      color: "#252827",
                      borderColor: "#252827",
                      width: "100%",
                    }}
                  />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 0",
                    }}
                  >
                    <Col>
                      <Typography className={classes.detailsHeading}>
                        Batch
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className={classes.emailText}>
                        {profile?.studentuser?.batch}
                      </Typography>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      background: "#252827",
                      color: "#252827",
                      borderColor: "#252827",
                      width: "100%",
                    }}
                  />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 0",
                    }}
                  >
                    <Col>
                      <Typography className={classes.detailsHeading}>
                        Institute
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className={classes.emailText}>
                        {profile?.studentuser?.institute}
                      </Typography>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      background: "#252827",
                      color: "#252827",
                      borderColor: "#252827",
                      height: "1px",
                      width: "100%",
                    }}
                  />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 0",
                    }}
                  >
                    <Col>
                      <Typography className={classes.detailsHeading}>
                        Phone Number
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className={classes.emailText}>
                        {profile?.studentuser?.phone_number}
                      </Typography>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
            <Row className={classes.programBox}>
              <Col xs={12} sm={12}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Classes
                </Typography>
                <Box>
                  <div
                    style={{
                      width: "100%",
                      background: "#FBFBFB",
                      borderRadius: "4px",
                    }}
                  >
                    <DataGrid
                      sx={{
                        boxShadow: 2,
                        color: "rgba(0, 0, 0, 0.87)",
                        "& .MuiDataGrid-cell:hover": {
                          color: "var(--admin)",
                        },
                      }}
                      autoHeight
                      rows={studentClasses}
                      getRowId={(studentClasses) => studentClasses?.id}
                      columns={columns}
                      ColumnWidth="*"
                    />
                  </div>
                </Box>
              </Col>
            </Row>
          </>
        )}
      </Box>
    </>
  );
};

export default MyProfile;
