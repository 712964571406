import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import backgroundImage from "../../Assets/Images/HutAndLawn.jpg";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Details = () => {
  return (
    <>
      <Box sx={{ width: "100%", background: "#E7FFFB", padding: "50px" }}>
        <Typography
          variant="h4"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: " 120%",
            textTransform: "capitalize",
            color: "var(--primary)",
            margin: "10px 0",
          }}
        >
          About Us
        </Typography>
        <Box>
          <Img
            sx={{
              width: "503px",
              height: "324px",
              float: "right",
              marginLeft: "30px",
            }}
            alt="complex"
            src={backgroundImage}
          />
          <Typography
            sx={{
              margin: "10px 0",
              textAlign: "justify",
            }}
          >
            A well trained workforce is a prerequisite for the success of any
            organization. Federal Board of Revenue (FBR), the federal tax
            collecting agency of Pakistan, has entrusted this important task of
            training to Inland Revenue Service Academy Lahore. The Academy is
            realizing this objective through:
            <br />
            <br />
            <ul>
              <li style={{ listStyle: "disc", textDecoration: "none" }}>
                Specialized Training Programme (STP) for probationary officers
                of IRS
              </li>
              <li style={{ listStyle: "disc", textDecoration: "none" }}>
                Domain Specific Training of MCMC officers in the promotion zone
              </li>
              <li style={{ listStyle: "disc", textDecoration: "none" }}>
                In-service Capacity Building Program and Refresher Courses for
                officers and staff
              </li>
              <li style={{ listStyle: "disc", textDecoration: "none" }}>
                Tax related Research, Analysis and Implementation
              </li>
              <li style={{ listStyle: "disc", textDecoration: "none" }}>
                Local and International Tax Seminars and Conferences.
              </li>
            </ul>
            Main campus of the Academy is located at Sutlej Block, Allama Iqbal
            Town, Lahore. Besides the main campus at Lahore, the Academy has two
            regional offices: one in Islamabad and the other in Karachi.
            <br />
            <br /> IRS Academy has evolved as the premier tax training institute
            over the years. In 1954, a separate training institute known as
            ‘Directorate of Training’ was established in Karachi. The objective
            of this training institute was to train the probationary officers of
            the Department of Income Tax, Federal Board of Revenue (previously
            Central Board of Revenue (CBR)).
            <br />
            <br /> In 1973, the Directorate was shifted to Lahore. In 1981, a
            separate building was hired at 15 Birdwood Road, Lahore. This campus
            was constituted of two blocks namely Administrative Block and
            Teaching Block. The Teaching Block consisted of classrooms, a
            Library and a Computer Laboratory. In the Administrative Block, the
            offices of faculty members, an Auditorium and a three storied hostel
            accommodation were present. In 1986, the task of Tax related
            ‘Research’ was assigned to ‘Directorate of Training’.
            <br />
            <br /> In 1988, the Academy was shifted to its present site as land
            was allocated for the construction of its purpose built campus. In
            1994, ‘Directorate of Training and Research (Direct Taxes)’ was
            upgraded to ‘Directorate General of Training and Research (Direct
            Taxes)’. In 2001, construction of two new blocks was started. State
            of the art hostel known as ‘International Hostel’ and an academic
            block known as ‘Tariq Aziz Block’ were constructed.
            <br />
            <br /> The Academy campus at Lahore is spread over a large areaand
            is known for its scenic beauty due to its lush green and
            well-maintained lawns. This aesthetic beauty of the campus has made
            it a preferable venue for many official functions and gatherings.
            The purpose built training campus of IRSA comprises Lecture
            Theatres, Conference Room, Computer Labs, Library, Auditorium,
            Gymnasium, Indoor sports room and fully furnished hostels for the
            accommodation of probationary officers and other trainees/
            delegates. The infrastructure of the academy is fully equipped with
            the latest audio-visual aids and modern equipment. A state of the
            art sports complex is also under construction. The academy has
            always hired the services of best training professionals from market
            and from the department to achieve the objective of providing a well
            trained work force to Federal Board of Revenue.
            <br />
            <br /> With the formation of Inland Revenue Service in 2010, DOT was
            renamed as ‘Directorate General of Training and Research (Inland
            Revenue).DGTR was renamed as Inland Revenue Service Academy (IRSA)
            in 2021. <br />
            <br /> IRSA is imparting training to the workforce of FBR in Income
            Tax Law, Sales Tax Act, Federal Excise Act, Accountancy, Information
            Technology etc.In addition to being a training institute, IRS
            Academy is also responsible for conducting local and international
            tax conferences and tax workshops.
            <br />
          </Typography>
          <Box></Box>
        </Box>
      </Box>
    </>
  );
};

export default Details;
