import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import "../Faculty/facultyStyle.css";
import { RiEqualizerFill } from "react-icons/ri";
import CloseIcon from "@mui/icons-material/Close";
import TablePagination from "components/atoms/Pagination";
import NewsService from "services/news.service";
import Loading from "components/atoms/Loading";
import { getDateObject } from "helpers/common";
import { format } from "date-fns";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { BsCalendar2Date } from "react-icons/bs";

const useStyles = makeStyles({
  desktop_view: {
    "@media (max-width: 900px)": {
      display: "none !important",
    },
  },
  main_container: {
    "@media (max-width: 900px)": {
      justifyContent: "center !important",
    },
  },
  search_sidebar: {
    display: "none !important",

    "@media (max-width: 900px)": {
      padding: "10px 20px",
      display: "flex !important",
      justifyContent: "space-between",
    },
  },
  checkBoxHeading: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    display: "flex !important",
    alignItems: "center !important",
    textAlign: "center !important",
    textTransform: "capitalize",
    color: " #252827 !important",
  },
  checkBoxLabel: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    display: "flex !important",
    alignItems: "center !important",
    textAlign: "center !important",
    textTransform: "capitalize",
    color: "var(--text-color) !important",
    marginLeft: "20px",
    marginBottom: "0px !important",
  },

  top_box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  input_box: {
    display: "flex",
    alignItems: "center",

    "@media (max-width: 550px)": {
      display: "block !important",
    },
  },
  formGroup: {
    display: "flex",
    alignItems: "center",
  },
  search_bt: {
    width: "112px",
    height: "48px",
    backgroundColor: "#E24F57 !important",
    color: "#fff !important",
    borderRadius: "4px !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
    marginLeft: "10px !important",
    // zIndex: "-1",

    "@media (max-width: 550px)": {
      width: "100%",
      margin: "0 !important",
    },
  },
  search_felid: {
    width: "352px",
    height: "48px",
    background: "#F3F3F3",
    borderRadius: "4px",
    border: "none !important",
    padding: "20px",
    outline: "none",

    "@media (max-width: 768px)": {
      width: "270px !important",
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px auto !important",
    },
  },
  formControl: {
    display: "flex !important",
  },
  total_result: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120%",
    textAlign: "center",
    margin: "0px",
    textTransform: "capitalize",
    color: "var(--text-color)  !important",
  },
  total_result_mobile: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: " 120%",
    textAlign: "center",
    margin: "0px",
    textTransform: "capitalize",
    color: "var(--text-color)  !important",
    display: "none",

    "@media (max-width: 900px)": {
      display: "block !important",
    },
  },
  card: {
    width: "400px",
    height: "455px",
    background: "#F7FDFC !important",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.02) !important",
    borderRadius: "4px !important",
    padding: "30px",
    margin: "0 0 40px 0",

    "@media (max-width: 420px)": {
      width: "250px",
      margin: "0 0 10px 0",
    },
  },
  card_img: {
    width: "100%",
    height: "237px",
    borderRadius: "4px !important",
  },
  date_posted: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120%",
    textTransform: "capitalize",
    color: " var(--body-text-color) !important",
    margin: "5px 0 !important",
  },
  heading: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: " 120% !important",
    letterSpacing: "-0.02em !important",
    textTransform: "capitalize",
    color: " var(--heading-color) !important",
    margin: "15px 0!important",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:hover": {
      color: "var(--primary) !important",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  subHeading: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 120%",
    textTransform: "capitalize",
    color: "var(--body-text-color)  !important",
    marginBottom: "0px !important",

    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bottom_box: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
});

const NewsContent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    filterStatus: [],
  });
  const { filterStatus } = filter;
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 9,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, news_data } = NewsService.GetNewsLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    reload,
  });

  const { totalItems, news } = useMemo(
    () => ({
      news: news_data?.news,
      totalItems: news_data.totalItems,
    }),
    [news_data]
  );

  const ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const onSearchChange = (e) => {
    setSearchQuery((ev) => ({
      ...ev,
      searchText: e.target.value,
    }));
    if (!e.target.value) {
      setReload(() => !reload);
    }
  };

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    // Case 1 : The user checks the box
    if (checked) {
      setFilter((prev) => ({
        ...prev,
        filterStatus: [...filterStatus, value],
      }));
    }
    // Case 2  : The user unchecks the box
    else {
      setFilter((prev) => ({
        ...prev,
        filterStatus: filterStatus.filter((e) => e !== value),
      }));
    }
  };

  const handleClickOpenDetail = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <>
      <Box style={{ padding: "60px 60px" }}>
        <Box>
          <Grid container spacing={2} className={classes.main_container}>
            {/* <Grid item md={3} className={classes.desktop_view}>
              <Box sx={{ margin: "10px 0px" }}>
                <Typography variant="h5" className={classes.checkBoxHeading}>
                  Category
                </Typography>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="News"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      News
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className={classes.formGroup}>
                    <Form.Check
                      type="checkbox"
                      aria-label="radio 1"
                      value="Event"
                      onChange={handleChange}
                    />
                    <Form.Label className={classes.checkBoxLabel}>
                      Events
                    </Form.Label>
                  </Form.Group>
                </Box>
              </Box>
            </Grid> */}
            <Grid item md={12} sx={{ paddingLeft: "0px" }}>
              <Box className={classes.top_box}>
                <Box className={classes.input_box}>
                  <input
                    id="search-bar"
                    className={classes.search_felid}
                    type="search"
                    placeholder="Type Here"
                    onChange={(e) => onSearchChange(e)}
                  />
                  <IconButton
                    className={classes.search_bt}
                    type="submit"
                    aria-label="search"
                    onClick={() => searchText && setReload(() => !reload)}
                  >
                    Search
                  </IconButton>
                </Box>
                <Box>
                  <p className={classes.total_result}>{totalItems} results</p>
                </Box>
              </Box>
              <Box sx={{ marginTop: "50px" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {loading === true ? (
                    <div>
                      <Loading />
                    </div>
                  ) : totalItems > 0 ? (
                    news?.map((newsData, i) => (
                      // <Col key={i} style={{ padding: "0" }}>
                      <Card
                        key={i}
                        className={classes.card}
                        onClick={() => handleClickOpenDetail(newsData?.id)}
                      >
                        <Box className={classes.image_box}>
                          <Image
                            className={classes.card_img}
                            src={
                              newsData?.image
                                ? newsData?.image?.startsWith("/")
                                  ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${newsData?.image}`
                                  : newsData?.image
                                : "https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                            }
                          />
                        </Box>
                        <Typography variant="h5" className={classes.heading}>
                          {newsData?.title}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          color="text.secondary"
                          className={classes.subHeading}
                        >
                          {parse(`${newsData?.description}`)}
                        </Typography>
                        <Box className={classes.bottom_box}>
                          <BsCalendar2Date
                            style={{
                              color: "#8294aa",
                              fontSize: "18",
                              marginRight: "3px",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            className={classes.date_posted}
                          >
                            {format(
                              getDateObject(newsData?.created_at),
                              "dd/MM/yyyy"
                            )}
                          </Typography>
                        </Box>
                      </Card>
                      // </Col>
                    ))
                  ) : (
                    <h2 className="text-center">No Record found</h2>
                  )}
                </div>
                {totalItems > 0 && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <TablePagination
                      count={Math.ceil(
                        parseInt(totalItems) / parseInt(pageSize)
                      )}
                      page={page}
                      onChange={(e, v) =>
                        setSearchQuery((prev) => ({ ...prev, page: v }))
                      }
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default NewsContent;
