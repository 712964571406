import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const InstructorService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetInstructor({ page, pageSize, reload, searchText }) {
    const axiosPrivate = useAxiosPrivate();

    const [instructor, setInstructoe] = useState({
      students: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getInstructor({ page, pageSize, axiosPrivate, searchText })
      )
        .then((res) => {
          setInstructoe(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, reload, searchText]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      instructor_data: instructor,
    };
  },
  async getInstructor({ page, pageSize, axiosPrivate, searchText }) {
    try {
      const { data } = await axiosPrivate.get(
        `${this._url}/teachers/adr?page=${page}&page_size=${pageSize}`
      );

      return { instructor: data?.results, totalItems: data?.count };
    } catch (err) {
      if (err.response) {
        /* 
          The request was made and the server responded with a status code
          that falls out of the range of 2xx
        */
        throw err.response.data;
      } else if (err.request) {
        // Client never received a response, or request never left
        throw err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Error: ${err.message}`);
      }
    }
  },
  async addInstructor(payload) {
    try {
      let response = await makeAxiosRequest(
        "POST",
        `${this._url}/teachers`,
        payload
      );
      return response;
    } catch (err) {
      throw err;
    }
  },

  async searchTeachers({ page, pageSize, searchText, axiosPrivate }) {
    try {
      const response = await axiosPrivate.get(
        `${this._url}/teachers/adr?page=${page}&page_size=${pageSize}&searchText=${searchText}`
      );

      return {
        teachers: response?.data?.results,
        totalItems: response?.data?.count,
      };
    } catch (err) {
      throw new Error(err?.response?.data?.message ?? err?.message);
    }
  },
};

export default InstructorService;
