import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import parse from "html-react-parser";

const useStyles = makeStyles({
  btnBox: {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "left",
  },
  backBtn: {
    cursor: "pointer",
  },
  image: {
    height: "317px",
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundPosition: "center",
    width: "100%",
    objectFit: "cover",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
  },
  cardTitle: {
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #252827 !important",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
    },
  },
  deleteBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #E24F57 !important",
    minWidth: "fit-content!important",
  },
  listBox: {
    display: "flex",
    alignItems: "center",
    margin: "10px 5px 20px",
  },

  listText: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--link-text-color) !important",
    marginLeft: "10px",
  },
  desText: {
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: " var(--link-text-color) !important",
    marginBottom: "20px",
  },
});

const ProgramsDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [details, setDetails] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    async function getTeacherDetails() {
      try {
        const { data } = await axiosPrivate.get(`programs/${id}`);
        setDetails(data);
      } catch (err) {
        if (err?.response) {
          console.log(err?.response);
          /* 
                The request was made and the server responded with a status code
                that falls out of the range of 2xx
              */
        } else if (err?.request) {
          // Client never received a response, or request never left
          console.log(err?.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // throw new Error(`Error: ${err.message}`);
          console.log(err?.message);
        }
      }
    }
    getTeacherDetails();
  }, []);
  return (
    <>
      <Box>
        <Box className={classes.btnBox}>
          <ArrowBackIcon
            className={classes.backBtn}
            onClick={() => navigate(-1)}
          />
        </Box>
        <Box>
          <Image className={classes.image} src={details?.image} />
        </Box>
        <Box className={classes.titleBox}>
          <Box sx={{ width: " 60%" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className={classes.cardTitle}
            >
              {details?.title}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box className={classes.listBox}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3337 13.9501V3.89174C18.3337 2.89174 17.517 2.15008 16.5253 2.23341H16.4753C14.7253 2.38341 12.067 3.27508 10.5837 4.20841L10.442 4.30008C10.2003 4.45008 9.80033 4.45008 9.55866 4.30008L9.35033 4.17508C7.86699 3.25008 5.21699 2.36674 3.46699 2.22508C2.47533 2.14174 1.66699 2.89174 1.66699 3.88341V13.9501C1.66699 14.7501 2.31699 15.5001 3.11699 15.6001L3.35866 15.6334C5.16699 15.8751 7.95866 16.7917 9.55866 17.6667L9.59199 17.6834C9.81699 17.8084 10.1753 17.8084 10.392 17.6834C11.992 16.8001 14.792 15.8751 16.6087 15.6334L16.8837 15.6001C17.6837 15.5001 18.3337 14.7501 18.3337 13.9501Z"
                stroke="var(--icons-color)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 4.5752V17.0752"
                stroke="var(--icons-color)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.45801 7.0752H4.58301"
                stroke="var(--icons-color)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.08301 9.5752H4.58301"
                stroke="var(--icons-color)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Typography className={classes.listText}>
              {details?.course_count} Courses
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.desText}>
              {parse(`${details?.description}`)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProgramsDetails;
