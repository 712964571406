import { useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Model from "components/atoms/Model";
import AddAdmin from "components/Forms/AddAdmin";
import AdminService from "services/admin.service";
import { getDateObject } from "helpers/common";
import TablePagination from "components/atoms/Pagination";
import { format } from "date-fns";
import Table from "components/atoms/Table";
import ModalContainer from "components/atoms/ModalContainer";
import Loading from "components/atoms/Loading";
import AlertDialogSlide from "components/atoms/AlertDialog";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import AdminDetails from "components/Details/AdminDetails";
import EditAdmin from "components/Forms/EditAdmin";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ProfileCard from "components/atoms/ProfileCard";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import BtnBox from "components/atoms/BtnBox";

export default function Admin() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 9,
  });

  const { page, pageSize } = searchQuery;
  const { loading, admins_data } = AdminService.GetAdmins({
    page,
    pageSize,
    reload,
  });

  const deleteAdmins = async (id, onClose) => {
    try {
      const { data } = await axiosPrivate.delete(
        `admin_auth/profile/delete/${id}`
      );
      toast.success(data);
      onClose();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response) {
      } else if (err?.request) {
        toast.error(err.request);
      } else {
        toast.error(err.message);
      }
    }
  };
  const { totalItems, admins } = useMemo(
    () => ({
      admins: admins_data?.admins,
      totalItems: admins_data.totalItems,
    }),
    [admins_data]
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isReload) => {
    setOpen(false);
  };

  // delete teacher model
  const handleClickOpenDelete = ({ adminsData }) => {
    setData(adminsData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };
  // end delete teacher model

  // teacher details
  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/admin/${id}`);
  };
  // end teacher details

  return (
    <>
      <Box sx={{ position: "relative", height: "100%" }}>
        <ToastContainer autoClose={3000} />
        <BtnBox
          handleClickOpen={handleClickOpen}
          title={"Admins"}
          subtitle={"You Have Added Following Admins"}
          btnText={"Add Admin"}
        />
        <Model
          openModel={open}
          handleClose={handleClose}
          bodyContent={
            <AddAdmin
              handleClose={handleClose}
              toast={toast}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          }
          title="Add New Admin"
        />

        {/* delete course model */}

        <DeleteConfirmModel
          openDelete={openDelete}
          handleClose={handleCloseDelete}
          onClick={() => deleteAdmins(data?.id, handleCloseDelete)}
        />
        {/* end delete course model */}

        {loading === true ? (
          <div>
            <Loading />
          </div>
        ) : totalItems > 0 ? (
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 8, sm: 8, md: 8, lg: 12 }}
              >
                {" "}
                {admins?.map((adminsData, i) => (
                  <>
                    <Grid item xs={12} sm={8} md={4} lg={4} key={i}>
                      <ProfileCard
                        data={adminsData}
                        onClick={() => handleClickOpen({ adminsData })}
                        handleClickOpenDelete={() =>
                          handleClickOpenDelete({ adminsData })
                        }
                        handleClickOpenDetail={() =>
                          handleClickOpenDetail(adminsData?.id)
                        }
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <h2 className="text-center">No Record found</h2>
        )}
        {totalItems > 0 && (
          <Box
            className="my-5"
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TablePagination
              count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
              page={page}
              onChange={(e, v) =>
                setSearchQuery((prev) => ({ ...prev, page: v }))
              }
            />
          </Box>
        )}
      </Box>
    </>
  );
}
