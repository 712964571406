import { useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "context/authContext";
import { makeStyles } from "@mui/styles";
import { Form } from "react-bootstrap";
import {
  FormHelperText,
  InputBase,
  Grid,
  Box,
  Typography,
  Button,
} from "@mui/material";
import logo from "../../Assets/Images/home.svg";

const useStyles = makeStyles({
  gridContainer: {
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },
  homeImg: {
    webkitFilter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3))",
    filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3))",
    transition: "transform .5s ease",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  form: {
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f6f4fb !important",
  },
  boxForm: {
    width: "75%",
  },
  homeBox: {
    backgroundColor: "#f6f4fb !important",
    padding: "20px",
    height: "20%",
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  homeBtn: {
    fontWeight: "400 !important",
    fontSize: "24px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var( --admin) !important",
    cursor: "pointer",
  },
  formHeading: {
    fontWeight: "700 !important",
    fontSize: "40px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--heading-color) !important",
  },
  formSubtitle: {
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    color: "var(--text-color) !important",
  },
  inputDiv: {
    margin: "10px 0",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  submitBtn: {
    margin: "40px 0px",
    width: "100%",
    height: "56px",
    textAlign: "center",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    color: "#FFFFFF",
    background: "var(--admin)",
    boxShadow: " 0px 14px 28px rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "#FFF !important",
      color: "var(--admin) !important",
      border: "1px solid var(--admin)",
    },
  },
  updateLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "120%",
    textTransform: "capitalize",
    color: " var(--admin)",
    textDecoration: "underline !important",
  },
  sideBox: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--admin) !important",

    "@media (max-width: 600px)": {
      height: "20vh",
      padding: "60px",
    },
  },
});

export default function ForgetPassword() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { forgetPass, error } = useContext(AuthContext);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .required("Email is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            forgetPass({ email: values.email });
            setStatus({ success: false });
            setSubmitting(false);
            navigate("/reset-password", { state: { email: values.email } });
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Grid container className={classes.gridContainer}>
            <Grid item sm={7} xs={12}>
              <Box className={classes.homeBox}>
                <Link className={classes.homeBtn} to="/">
                  <img src={logo} alt="logo" className={classes.homeImg} />
                </Link>
              </Box>
              <Form noValidate onSubmit={handleSubmit} className={classes.form}>
                <Box className={classes.boxForm}>
                  <Typography variant="h3" className={classes.formHeading}>
                    Update Password
                  </Typography>
                  <Typography
                    variant="subtitle"
                    className={classes.formSubtitle}
                  >
                    Enter your email, OPT will be sent to your email
                  </Typography>
                  <div className={classes.inputDiv}>
                    <label>Email</label>
                    <div className={classes.inputField}>
                      <svg
                        width="19"
                        height="17"
                        viewBox="0 0 19 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.8831 0.666992C16.6581 0.666992 18.7506 2.93116 18.7506 5.93366V11.657C18.7506 13.1937 18.2073 14.582 17.2198 15.567C16.334 16.4495 15.1848 16.917 13.8965 16.917H5.68565C4.39982 16.917 3.25148 16.4503 2.36482 15.567C1.37732 14.582 0.833984 13.1937 0.833984 11.657V5.93366C0.833984 2.93116 2.92648 0.666992 5.70148 0.666992H13.8831ZM13.8831 1.91699H5.70148C3.60565 1.91699 2.08398 3.60616 2.08398 5.93366V11.657C2.08398 12.8595 2.49732 13.9337 3.24732 14.6812C3.89398 15.327 4.73815 15.667 5.68815 15.667H13.8831C13.8848 15.6653 13.8915 15.667 13.8965 15.667C14.8473 15.667 15.6906 15.327 16.3373 14.6812C17.0881 13.9337 17.5006 12.8595 17.5006 11.657V5.93366C17.5006 3.60616 15.979 1.91699 13.8831 1.91699ZM15.1965 5.77433C15.414 6.04183 15.3731 6.43516 15.1056 6.65349L11.4023 9.66349C10.934 10.0352 10.374 10.221 9.81482 10.221C9.25732 10.221 8.70148 10.0368 8.23648 9.66849L4.49898 6.65516C4.22982 6.43849 4.18815 6.04433 4.40398 5.77599C4.62148 5.50849 5.01482 5.46599 5.28315 5.68183L9.01732 8.69182C9.48648 9.06349 10.1473 9.06349 10.6198 8.68849L14.3165 5.68349C14.5848 5.46433 14.9781 5.50599 15.1965 5.77433Z"
                          fill="var(--text-color)"
                        />
                      </svg>
                      <InputBase
                        id="email-login"
                        type="email"
                        name="email"
                        value={values.email}
                        placeholder="example@gmail.com"
                        fullWidth
                        autoComplete="off"
                        className={classes.input}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </div>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <div className={classes.inputDiv}>
                    <Button
                      type="submit"
                      disableElevation
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.submitBtn}
                    >
                      Update
                    </Button>
                  </div>
                  {error && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {error}
                    </FormHelperText>
                  )}
                </Box>
              </Form>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box className={classes.sideBox}>
                <svg
                  width="344"
                  height="170"
                  viewBox="0 0 344 170"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 74.7135C0 74.7135 156.06 -26.2808 343.148 74.7135C343.139 74.7135 187.759 9.31361 0 74.7135Z"
                    fill="white"
                  />
                  <path
                    d="M36.0312 81.0146H57.6583V168.481H36.0312V81.0146Z"
                    fill="white"
                  />
                  <path
                    d="M103.302 139.958H94.754V168.481H73.127V81.0146H111.353C128.955 81.0146 142.408 93.8135 142.408 111.057C142.408 122.723 135.995 132.096 126.062 136.787L144.927 168.481H119.021L103.302 139.958ZM94.754 120.949H110.848C116.259 120.949 120.276 116.767 120.276 111.189C120.276 105.734 116.25 101.553 110.848 101.553H94.754V120.949Z"
                    fill="white"
                  />
                  <path
                    d="M147.938 156.313L159.004 139.71C166.297 146.176 176.605 150.612 185.406 150.612C192.324 150.612 198.232 147.827 198.232 143.382C198.232 138.691 191.444 136.917 182.138 134.633C164.031 130.197 151.458 121.579 151.458 106.11C151.458 90.0081 165.164 79.4932 185.658 79.4932C199.112 79.4932 210.3 84.184 216.844 90.2716L206.911 106.874C201.752 102.183 193.335 98.8891 185.406 98.8891C178.487 98.8891 173.712 101.551 173.712 105.354C173.712 109.791 180.5 112.198 188.168 113.972C195.714 115.623 220.356 121.702 220.356 142.495C220.356 158.974 205.142 169.999 185.528 169.999C171.952 169.999 157.244 164.553 147.938 156.313Z"
                    fill="white"
                  />
                  <path
                    d="M281.351 154.663H248.153L243.5 168.481H220.234L252.17 81.0146H277.691L309.627 168.481H285.987L281.351 154.663ZM274.685 134.635L264.752 105.101L254.818 134.635H274.685Z"
                    fill="white"
                  />
                  <path
                    d="M171.541 25.2773C164.692 25.2773 159.133 30.8291 159.133 37.6721C159.133 44.5152 164.683 50.0669 171.541 50.0669C178.39 50.0669 183.949 44.5152 183.949 37.6721C183.949 30.8291 178.39 25.2773 171.541 25.2773ZM171.541 35.2916C172.857 35.2916 173.92 36.3545 173.92 37.6721C173.92 38.9898 172.857 40.0527 171.541 40.0527C170.225 40.0527 169.162 38.9898 169.162 37.6721C169.162 36.3545 170.225 35.2916 171.541 35.2916Z"
                    fill="#F9D100"
                  />
                  <path
                    d="M171.559 0L167.141 14.5517L162.723 29.0685H162.711H162.723L162.711 29.1035H162.769L167.934 32.1793L171.897 34.7191L175.978 32.0627L180.349 29.1035H180.408L180.396 29.0685L180.408 29.0569H180.396L175.989 14.5401L171.559 0Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M171.559 0.0126953L171.733 34.8133L175.977 32.0521L180.349 29.0928H180.407L180.395 29.0579L180.407 29.0463H180.395L175.989 14.5295L171.559 0.0126953Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M171.566 75.5L175.984 60.9483L180.402 46.4315H180.414H180.402L180.414 46.3966H180.356L175.191 43.3208L171.228 40.7809L167.147 43.4372L162.776 46.3966H162.717L162.729 46.4315L162.717 46.4431H162.729L167.136 60.9599L171.566 75.5Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M171.555 75.4785L171.38 40.6779L167.136 43.4391L162.765 46.3984H162.706L162.718 46.4333L162.706 46.445H162.718L167.125 60.9618L171.555 75.4785Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M209.018 37.8887L194.478 33.4091L179.974 28.9295L179.974 28.9179L179.974 28.9295L179.939 28.9177L179.939 28.976L176.836 34.1236L174.276 38.0737L176.914 42.1631L179.854 46.5451L179.854 46.6034L179.889 46.5919L179.9 46.6036L179.9 46.592L194.447 42.2519L209.018 37.8887Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M208.992 37.8877L174.17 37.9092L176.912 42.1622L179.852 46.5442L179.851 46.6024L179.886 46.591L179.898 46.6026L179.898 46.591L194.445 42.251L208.992 37.8877Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M134.095 37.8191L148.663 42.2032L163.196 46.5873L163.196 46.599L163.196 46.5873L163.231 46.5989L163.231 46.5406L166.3 41.3726L168.835 37.4059L166.17 33.3339L163.202 28.9713L163.202 28.913L163.167 28.9247L163.156 28.9131L163.156 28.9247L148.637 33.3603L134.095 37.8191Z"
                    fill="#E2BA14"
                  />
                  <path
                    d="M134.115 37.8076L168.937 37.5573L166.167 33.3224L163.199 28.9598L163.199 28.9016L163.164 28.9132L163.152 28.9017L163.152 28.9132L148.634 33.3488L134.115 37.8076Z"
                    fill="#E8D170"
                  />
                  <path
                    d="M171.541 40.0551C172.855 40.0551 173.92 38.9893 173.92 37.6745C173.92 36.3598 172.855 35.2939 171.541 35.2939C170.227 35.2939 169.162 36.3598 169.162 37.6745C169.162 38.9893 170.227 40.0551 171.541 40.0551Z"
                    fill="var(--icons-color)"
                  />
                </svg>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
}
