import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./forms.css";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import MyEditor from "components/atoms/TestEditor";
import LoadingBtn from "components/atoms/LoadingBtn";
import { makeStyles } from "@mui/styles";
import { InputBase, Typography, Button, Grid, Box } from "@mui/material";
import parse from "html-react-parser";
import { AiFillCloseCircle } from "react-icons/ai";

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#252827 !important",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "var(--admin)",
    margin: "10px 0",
    transition: "0.3s",
    boxShadow: "0px 14px 28px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: "#FFFFFF !important",
    "&:hover": {
      color: "var(--admin) !important",
      background: "#FFFFFF",
      border: "1px solid var(--admin)",
    },
  },
  errorMsg: {
    color: "var(--error)",
    fontWeight: "400",
    fontSize: "0.75rem",
    textTransform: "none",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "0",
    marginBottom: "0",
    marginLeft: "0",
  },
  label: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: " 120%",
    margin: "0",
    textTransform: "capitalize",
    color: "var(--body-text-color)",
  },
  formGroup: {
    width: "100%",
    marginBottom: "10px",
  },
  change_img: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
    width: "fit-content",
    padding: "15px",
    background: "transparent",
    border: "1px solid var(--admin)",
    color: "var(--admin) !important",
    margin: "10px auto",
    transition: "0.3s",
    borderRadius: "4px",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  inputFieldErr: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--error)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  inputField: {
    display: "flex",
    alignItems: "center",
    border: "0.5px solid var(--border-color)",
    height: "48px",
    padding: "10px",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "var(--input-background-color)",
    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    borderRadius: "4px",
  },
  input: {
    color: "var(--text-color) !important",
    marginLeft: "10px",
    width: "100%",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: " 121.5% !important",
  },
  formGroupImage: {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  saveBtn: {
    display: "flex",
    justifyContent: "end",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  },
});

const EditStory = ({ stories, handleClose, toast, reload, onClick }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(stories?.title);
  const [content, setContent] = useState(stories?.description);
  const blocksFromHTML = convertFromHTML(stories.description);
  const EditorContent = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(EditorContent)
  );

  const [validationErrors, setValidationErrors] = useState({
    titleErr: "",
    contentErr: "",
    imageErr: "",
  });
  const { titleErr, contentErr } = validationErrors;

  const validate = () => {
    if (!title) {
      setValidationErrors((prev) => ({
        ...prev,
        titleErr: "Please provide title",
      }));
    }
    if (content === null) {
      setValidationErrors((prev) => ({
        ...prev,
        contentErr: "Please provide description",
      }));
    }
    if (!title || content === null) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("title", title);
        bodyFormData.append("description", content);
        const res = await axiosPrivate({
          method: "put",
          url: `story/${stories?.id}`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        setLoading(false);
        toast.success(res);
        handleClose();
        reload("reload");
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
      console.log("Validated");
    } else {
      console.log("error");
    }
  };
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setValidationErrors((prev) => ({
      ...prev,
      contentErr: "",
    }));
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setContent(currentContentAsHTML);
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Form>
              <Form.Group
                controlId="formBasicTitle"
                className={classes.formGroup}
              >
                <Form.Label className={classes.label}>Title</Form.Label>
                <div
                  className={
                    titleErr ? classes.inputFieldErr : classes.inputField
                  }
                >
                  <InputBase
                    className={classes.input}
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setValidationErrors((prev) => ({
                        ...prev,
                        titleErr: "",
                      }));
                    }}
                  />
                </div>
                {titleErr ? (
                  <Typography className={classes.errorMsg}>
                    {titleErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group
                className={classes.formGroup}
                controlId="formBasicDesc"
              >
                <Form.Label className={classes.label}>Content</Form.Label>
                <MyEditor
                  className={contentErr ? "titleErr" : ""}
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                />
                {contentErr ? (
                  <Typography className={classes.errorMsg}>
                    {contentErr}
                  </Typography>
                ) : (
                  ""
                )}
              </Form.Group>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {stories?.all_images?.map((imgData, i) => (
                  <div class="edit_hover_class">
                    <a
                      href="#"
                      onClick={() =>
                        onClick(stories?.id, imgData?.id, handleClose, reload)
                      }
                    >
                      <AiFillCloseCircle
                        style={{ color: "red", fontSize: "30px" }}
                      />
                    </a>
                    <img
                      key={i}
                      src={
                        imgData?.image?.startsWith("/")
                          ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${imgData?.image}`
                          : imgData?.image
                      }
                      alt="img"
                      width={200}
                    />
                  </div>
                ))}
              </div>

              <Form.Group className={classes.saveBtn}>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    className={classes.addBtn}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                )}
              </Form.Group>
            </Form>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>{parse(`${content}`)}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditStory;
