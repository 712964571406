const axios = require("axios");

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_IRSA_BASE_URL, // The endpoint is the base url
  timeout: 50000, // Timeout limit = 50 secs.
  withCredentials: true,
  headers: {
    // Base headers
    "Content-Type": "application/json",
  },
});
function makeAxiosRequest(method, urlPath, data=null, credentials=true) {
    /* Auxiliar function to make different axios requests to my endpoint */
  
    return axiosInstance({
      method,
      url: urlPath,
      data,
      // withCredentials: credentials
    })
      .then((res) => res.data)
      .catch((err) => {
        if (err?.response) {
          /* 
            The request was made and the server responded with a status code
            that falls out of the range of 2xx
          */
          throw err.response?.data?.message??err?.message;
        } else if (err?.request) {
          // Client never received a response, or request never left
          throw err?.request?.data??err?.message;
        } else {
          // Something happened in setting up the request that triggered an Error
          throw err?.message;
        }
      });
  }

  export default makeAxiosRequest;