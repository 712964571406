import { Navigate, useLocation } from "react-router-dom";
const TeacherRoute = ({ isLoggedIn, user, children }) => {
  const location = useLocation();
  if (isLoggedIn && user.role === "teacher") {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default TeacherRoute;
