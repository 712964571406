import React, { useMemo, useState } from "react";
import { getDateObject } from "../helpers/common";
import { format } from "date-fns";
import Model from "components/atoms/Model";
import AddStudent from "components/Forms/AddStudent";
import StudentService from "services/student.service";
import EditStudent from "components/Forms/EditStudent";
import { ToastContainer, toast } from "react-toastify";
import Loading from "components/atoms/Loading";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { makeStyles } from "@mui/styles";
import { FormControlLabel } from "@mui/material";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import {
  DataGrid,
  gridClasses,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import "./dataTable.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BtnBox from "components/atoms/BtnBox";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const useStyles = makeStyles({
  editIcon: {
    marginRight: "10px",
    color: "#252827 !important",
    cursor: "pointer",
  },
  deleteIcon: {
    color: "#252827 !important",
    cursor: "pointer",
  },
});

const Edit = ({ index, reload }) => {
  const classes = useStyles();
  const axiosPrivate = useAxiosPrivate();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const deleteStudent = async (handleCloseDelete) => {
    try {
      const { data } = await axiosPrivate.delete(`students/adr/${deleteId}`);
      toast.success(data);
      handleCloseDelete();
      reload("reload");
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response) {
      } else if (err?.request) {
        toast.error(err.request);
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = (isReload) => {
    setOpenEdit(false);
  };

  // delete student model

  const handleClickOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete student model

  return (
    <FormControlLabel
      sx={{ margin: "0" }}
      control={
        <>
          <Model
            openModel={openEdit}
            handleClose={handleCloseEdit}
            bodyContent={
              <EditStudent
                handleClose={handleCloseEdit}
                toast={toast}
                student={index}
                reload={reload}
              />
            }
            title="Edit Student"
          />

          {/* delete course model */}
          <DeleteConfirmModel
            openDelete={openDelete}
            handleClose={handleCloseDelete}
            onClick={() => deleteStudent(handleCloseDelete)}
          />
          {/* end delete course model */}

          <EditIcon
            className={classes.editIcon}
            onClick={handleClickOpenEdit}
          />
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={() => handleClickOpenDelete(index?.id)}
          />
        </>
      }
    />
  );
};

function getFullName(params) {
  return `${params.row.firstname || ""} ${params.row.lastname || ""}`;
}

function setFullName(params) {
  const [firstname, lastname] = params.value.toString().split(" ");
  return { ...params.row, firstname, lastname };
}

function parseFullName(value) {
  return String(value)
    .split(" ")
    .map((str) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : ""))
    .join(" ");
}

export default function Student() {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10000,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize } = searchQuery;
  const { loading, students_data } = StudentService.GetStudents(
    page,
    pageSize,
    reload
  );

  const { totalItems, students } = useMemo(
    () => ({
      students: students_data?.students,
      totalItems: students_data?.totalItems,
    }),
    [students_data]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "roll_number",
      type: "string",
      headerName: "Roll Number",
      width: 150,
    },
    {
      field: "batch",
      width: 100,
      headerName: "Batch",
    },
    {
      field: "join_date",
      type: "date",
      width: 100,
      headerName: "Join Date",
      valueFormatter: (params) =>
        format(getDateObject(params?.value), "dd-MM-yyyy"),
    },
    {
      field: "fullName",
      type: "string",
      flex: 0.5,
      headerName: "Full Name",
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "email", headerName: "Email", flex: 0.5 },
    {
      field: "phone_number",
      headerName: "Contact",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Edit
              index={params?.row}
              reload={(isReload) => isReload && setReload(() => !reload)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer autoClose={3000} />
      <BtnBox
        handleClickOpen={handleClickOpen}
        title={"Student"}
        subtitle={"You Have Added Following Student"}
        btnText={"Add Student"}
      />

      <Model
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddStudent
            handleClose={handleClose}
            toast={toast}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Add Student"
      />

      {loading ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <>
          <div
            style={{
              width: "100%",
              background: "#FBFBFB",
              borderRadius: "4px",
            }}
          >
            <StripedDataGrid
              sx={{
                boxShadow: 2,
                color: "rgba(0, 0, 0, 0.87)",
                "& .MuiDataGrid-cell:hover": {
                  color: "var(--admin)",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              autoHeight
              pageSize={25}
              rowsPerPageOptions={[5]}
              components={{
                Pagination: CustomPagination,
              }}
              rows={students}
              getRowId={(classesData) => classesData.id}
              columns={columns}
              ColumnWidth="*"
            />
          </div>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
    </>
  );
}
