import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const ResourcesService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetResources({ page, pageSize, searchText, filterStatus, reload }) {
    const axiosPrivate = useAxiosPrivate();
    const [resources, setResources] = useState({
      resources: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getResources({
          page,
          pageSize,
          searchText,
          axiosPrivate,
          filterStatus,
        })
      )
        .then((res) => {
          setResources(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, searchText, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      resources_data: resources,
    };
  },
  GetResourcesLandingPage({
    page,
    pageSize,
    searchText,
    filterStatus,
    reload,
  }) {
    const [resources, setResources] = useState({
      resources: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getResourcesLandingPage({
          page,
          pageSize,
          searchText,
          filterStatus,
        })
      )
        .then((res) => {
          setResources(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      resources_data: resources,
    };
  },
  async getResources({
    page,
    pageSize,
    searchText,
    axiosPrivate,
    filterStatus,
  }) {
    const response = await axiosPrivate.get(
      `${this._url}/resource?page=${page}&page_size=${pageSize}&searchText=${searchText}&filterStatus=${filterStatus}`
    );

    return {
      resources: response?.data?.results,
      totalItems: response.data.count,
    };
  },

  async getResourcesLandingPage({
    page,
    pageSize,
    searchText,
    axiosPrivate,
    filterStatus,
  }) {
    try {
      const response = await makeAxiosRequest(
        "GET",
        `${this._url}/resources/p/getall/?page=${page}&page_size=${pageSize}&search=${searchText}&filtertype=${filterStatus}`
      );
      return {
        resources: response?.results,
        totalItems: response?.count,
      };
    } catch (err) {
      throw err;
    }
  },
  async addPublication(payload) {
    let response = await makeAxiosRequest(
      "POST",
      `${this._url}/resource`,
      payload
    );
    if (response?.error) {
      throw Error(response.message ?? "Something went wrong");
    }

    return response;
  },
  // async addNews(payload) {
  //   let response = await makeAxiosRequest(
  //     "POST",
  //     `${this._url}/resources/`,
  //     payload
  //   );
  //   if (response?.error) {
  //     throw Error(response.message ?? "Something went wrong");
  //   }

  //   return response;
  // },
  // async deleteNews({ id }) {
  //   let response = await makeAxiosRequest("DELETE", `${this._url}/resources/${id}`);
  //   if (response?.error) {
  //     throw Error(response.message ?? "Something went wrong");
  //   }

  //   return response;
  // },
};

export default ResourcesService;
