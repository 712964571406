import { useState, useEffect } from "react";
import makeAxiosRequest from "../helpers/makeAxiosRequest";
import { useCancellablePromise } from "../helpers/promiseHandler";

const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const FacultyService = {
  _url: process.env.REACT_APP_IRSA_BACKEND_API,

  GetFaculty({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterPrograms,
    reload,
  }) {
    console.log({ filterPrograms, filterStatus });
    const [faculty, setFaculty] = useState({
      faculty: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getFaculty({
          page,
          pageSize,
          searchText,
          filterStatus,
          filterPrograms,
        })
      )
        .then((res) => {
          setFaculty(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, filterStatus, filterPrograms, reload]);
    return {
      loading: status === STATUS.LOADING,
      error: status === STATUS.ERROR ? status : "",
      faculty_data: faculty,
    };
  },
  async getFaculty({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterPrograms,
  }) {
    const response = await makeAxiosRequest(
      "GET",
      `${this._url}/teachers/p/faculty?page=${page}&page_size=${pageSize}&search=${searchText}&filterStatus=${filterStatus}&filterPrograms=${filterPrograms}`
    );
    return { faculty: response.results, totalItems: response.count };
  },
};

export default FacultyService;
