import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import parse from "html-react-parser";
import { format } from "date-fns";

const useStyles = makeStyles({
  card: {
    padding: "10px",
    position: "relative",
    maxWidth: "341px",
    height: "358px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  cardImage: {
    maxWidth: "317px",
    height: "162px",
    filter: "drop-shadow(0px 8px 21px rgba(0, 0, 0, 0.06))",
    borderRadius: "4px",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontWeight: "700 !important",
    fontSize: "16px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #252827 !important",
    display: "-webkit-box",
    lineClamp: "2",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
    },
  },
  editBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--text-color) !important",
    minWidth: "fit-content!important",
    "&:hover": {
      color: "var(--admin) !important",
      cursor: "pointer",
    },
  },
  deleteBtn: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " #E24F57 !important",
    minWidth: "fit-content!important",
  },
  dateBox: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    bottom: "10px",
  },

  listText: {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "120% !important",
    textTransform: "capitalize",
    color: " var(--link-text-color) !important",
    marginLeft: "10px",
  },
  desText: {
    marginTop: "10px",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "120% !important",
    textTransform: "none",
    color: " var(--link-text-color) !important",
    display: "-webkit-box",
    lineClamp: "3",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
const NewsCard = ({
  data,
  onClick,
  handleClickOpenDelete,
  handleClickOpenDetail,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardImage}
          component="img"
          height="140"
          image={
            data.image?.startsWith("/")
              ? `${process.env.REACT_APP_IRSA_BACKEND_STATIC_ASSETS}${data.image}`
              : data.image
          }
          alt="img"
        />
        <CardContent sx={{ padding: "16px 0" }}>
          <Box className={classes.titleBox}>
            <Box sx={{ width: " 60%" }}>
              <Typography
                onClick={() => handleClickOpenDetail()}
                gutterBottom
                variant="h5"
                component="div"
                className={classes.cardTitle}
              >
                {data?.title}
              </Typography>
            </Box>
            <Box>
              <Button className={classes.editBtn} onClick={() => onClick()}>
                Edit
              </Button>
              <Button
                className={classes.deleteBtn}
                onClick={() => handleClickOpenDelete()}
              >
                Remove
              </Button>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography className={classes.desText}>
                {parse(`${data?.description}`)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Box className={classes.dateBox}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 22.5H3C2.172 22.5 1.5 21.828 1.5 21V5C1.5 4.172 2.172 3.5 3 3.5H21C21.828 3.5 22.5 4.172 22.5 5V17.5"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 6.5C7.27614 6.5 7.5 6.27614 7.5 6C7.5 5.72386 7.27614 5.5 7 5.5C6.72386 5.5 6.5 5.72386 6.5 6C6.5 6.27614 6.72386 6.5 7 6.5Z"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 6.5C17.2761 6.5 17.5 6.27614 17.5 6C17.5 5.72386 17.2761 5.5 17 5.5C16.7239 5.5 16.5 5.72386 16.5 6C16.5 6.27614 16.7239 6.5 17 6.5Z"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 6V1.5"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 6V1.5"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.5 8.5H22.5"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5 17.5H19C18.172 17.5 17.5 18.172 17.5 19V22.5L22.5 17.5Z"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 14C7.5 13.172 8.172 12.5 9 12.5C9.828 12.5 10.5 13.172 10.5 14C10.501 14.646 10.27 15.27 9.85 15.76L7.5 18.5H10.5"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 12.5H13L12.5 15.5C12.82 15.178 13.256 14.998 13.71 15H13.8C14.739 14.994 15.504 15.751 15.51 16.69C15.51 16.693 15.51 16.697 15.51 16.7V17C15.51 17.828 14.838 18.5 14.01 18.5C14.007 18.5 14.003 18.5 14 18.5C13.172 18.5 12.5 17.828 12.5 17"
              stroke="#7A8883"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography className={classes.listText}>
            {format(new Date(data?.created_time), "dd MMM yyy")}
          </Typography>
        </Box>
      </Card>
    </div>
  );
};

export default NewsCard;
