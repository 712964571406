import BackToTop from "components/atoms/BackToTop/BackToTop";
import Footer from "components/Layout/Footer/Footer";
import Navbar from "components/Layout/Navbar";
import PublicationContent from "components/Layout/Publication/PublicationContent";
import PublicationMainSec from "components/Layout/Publication/PublicationMainSec";
import TopNav from "components/Layout/TopNavBar/TopNav";
import React, { useEffect } from "react";

const PublicationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopNav />
      <Navbar />
      <PublicationMainSec />
      <PublicationContent />
      <BackToTop />
      <Footer />
    </>
  );
};

export default PublicationPage;
