import React, { useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import Model from "components/atoms/Model";
import { useState } from "react";
import Loading from "components/atoms/Loading";
import { Box, Grid } from "@mui/material";
import DeleteConfirmModel from "components/atoms/DeleteModel/DeleteConfirmModel";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import LibraryService from "services/library.service";
import LibraryCard from "components/atoms/libraryCard";
import EditLibraryContent from "components/Forms/EditLibraryContent";
import AddPublication from "components/Forms/AddPublication";
import PublicationService from "services/publication.service";
import BtnBox from "components/atoms/BtnBox";
import EditorModel from "components/atoms/EditorModel";
import EditPublication from "components/Forms/EditPublication";
import TablePagination from "components/atoms/Pagination";

const Publication = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [filter, setFilter] = useState({
    filterStatus: [],
    filterPrograms: [],
  });
  const { filterStatus, filterPrograms } = filter;
  const [data, setData] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [reload, setReload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 8,
    searchText: "",
    startDate: "",
    endDate: "",
    filterText: "",
  });
  const { page, pageSize, searchText } = searchQuery;
  const { loading, publication_data } = PublicationService.GetPublication({
    page,
    pageSize,
    searchText,
    filterStatus,
    filterPrograms,
    reload,
  });

  const { totalItems, publication } = useMemo(
    () => ({
      publication: publication_data?.publication,
      totalItems: publication_data.totalItems,
    }),
    [publication_data]
  );

  const reversPublication = [...publication].reverse();

  const deleteLibrary = async (id, handleCloseDelete) => {
    try {
      const { data } = await axiosPrivate.delete(`publication/${id}`);
      toast.success(data);
      handleCloseDelete();
      setReload(() => !reload);
    } catch (err) {
      toast.error(err.response.data.message);
      if (err?.response?.data?.code === 400) {
        toast.error("Validation error occurred");
      } else {
        toast.error(err?.response?.data?.message ?? err?.message);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // delete news model

  const handleClickOpenDelete = ({ libraryData }) => {
    setData(libraryData);
    setOpenDelete(true);
  };

  const handleCloseDelete = (isReload) => {
    setOpenDelete(false);
  };

  // end delete news model

  // course details

  const handleClickOpenDetail = (id) => {
    navigate(`/dashboard/library/${id}`);
  };
  // end course details

  // edit news model

  const handleClickOpenEdit = ({ libraryData }) => {
    setData(libraryData);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  // end edit news model

  return (
    <>
      <ToastContainer autoClose={3000} />
      <BtnBox
        handleClickOpen={handleClickOpen}
        title={"Publication"}
        subtitle={"You Have Following Publication"}
        btnText={"Publish"}
      />
      <EditorModel
        openModel={open}
        handleClose={handleClose}
        bodyContent={
          <AddPublication
            handleClose={handleClose}
            toast={toast}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Publication"
      />

      {/* edit news model */}

      <EditorModel
        openModel={openEdit}
        handleClose={handleCloseEdit}
        bodyContent={
          <EditPublication
            handleClose={handleCloseEdit}
            toast={toast}
            publication={data}
            reload={(isReload) => isReload && setReload(() => !reload)}
          />
        }
        title="Edit Publication"
      />
      {/* end edit news model */}

      {/* delete news model */}

      <DeleteConfirmModel
        openDelete={openDelete}
        handleClose={handleCloseDelete}
        onClick={() => deleteLibrary(data?.id, handleCloseDelete)}
      />

      {/* end delete news model */}
      {loading === true ? (
        <div>
          <Loading />
        </div>
      ) : totalItems > 0 ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
            >
              {reversPublication?.map((libraryData, i) => (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={3} key={i}>
                    <LibraryCard
                      data={libraryData}
                      onClick={() => handleClickOpenEdit({ libraryData })}
                      handleClickOpenDelete={() =>
                        handleClickOpenDelete({ libraryData })
                      }
                      handleClickOpenDetail={() =>
                        handleClickOpenDetail(libraryData?.id)
                      }
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <h2 className="text-center">No Record found</h2>
      )}
      {totalItems > 0 && (
        <div
          className="my-3"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <TablePagination
            count={Math.ceil(parseInt(totalItems) / parseInt(pageSize))}
            page={page}
            onChange={(e, v) =>
              setSearchQuery((prev) => ({ ...prev, page: v }))
            }
          />
        </div>
      )}
    </>
  );
};

export default Publication;
