import * as React from "react";
import Slider1 from "../../Assets/Images/CampusCultureSlider/1.JPG";
import Slider2 from "../../Assets/Images/CampusCultureSlider/2.JPG";
import Slider3 from "../../Assets/Images/CampusCultureSlider/3.JPG";
import Slider4 from "../../Assets/Images/CampusCultureSlider/4.png";
import Slider5 from "../../Assets/Images/CampusCultureSlider/5.JPG";
import Slider6 from "../../Assets/Images/CampusCultureSlider/6.JPG";
import Slider7 from "../../Assets/Images/CampusCultureSlider/7.JPG";
import Slider8 from "../../Assets/Images/CampusCultureSlider/8.jpg";
import { Carousel } from "react-bootstrap";
import { useState } from "react";

export default function CampusCultureMainSec() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <React.Fragment>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider3} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider4} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider5} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider6} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider7} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider8} alt="Second slide" />
        </Carousel.Item>
      </Carousel>
    </React.Fragment>
  );
}
