import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AuthContextProvider } from "context/authContext";
import { BrowserRouter } from "react-router-dom";
import "react-modal-video/css/modal-video.css";
import 'react-image-crop/dist/ReactCrop.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
